<template>
    <div class="container flex flex-col">
        <PracticeList @updated-practice-list="updatePracticeList" :page-size="25" />
    </div>
</template>

<script>
import PracticeList from '@/components/practice-list/List'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import { PAYMENT_TYPES } from '@/config/constants'

export default {
    name: 'MainPracticeList',
    mixins: [ BackendHelpers, HttpMixin ],
    components: { PracticeList },
    data() {
        return {
            loading: true,
            practiceList: [],
            showList: true,
        }
    },
    methods: {
        async updatePracticeList(practiceList) {
            this.loading = false
            this.practiceList = practiceList
            if (this.practiceList?.length === 1) {
                const practice = this.practiceList[0]
                const reappStage = practice.hasReapplicationPaymentDue || practice.hasReapplicationDue
                if (practice.status.toUpperCase() === PAYMENT_TYPES.PaymentPending && !reappStage) {
                    await this.redirectOnPaymentPending(practice)
                } else {
                    this.$router.push({name: 'Staff', params: {id: practice.organisationPtrId}})
                }
            }
        },
    },
    computed: {
        isNewUser() {
            return this.$auth.user() != null && this.$auth.user().isNewUser
        },
    },
    watch: {
        practiceList() {
            this.showList = this.practiceList.length > 0
        }
    }
}
</script>
