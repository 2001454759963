<template>
    <div class="relative" v-if="isIndicatorVisible">
        <div class="absolute bg-transparent p-3 w-full grid grid-cols-8 pointer-events-none">
            <div class="col-start-5 col-span-2 pointer-events-auto flex flex-row">
                <div
                    v-for="code in indicatorSet.indicatorSetIndicator"
                    :key="`code-${code.indicatorId}`"
                >
                    <a
                        class="indicator-set__code mr-3 font-display font-bold text-sm lg:text-base cursor-pointer no-underline hover:underline"
                        href="#"
                        :ref="`code-${code.indicatorId}`"
                        @click.prevent="codeClicked(code.indicatorId)"
                        v-if="filteredIndicator(code.indicatorId)"
                    >
                        {{ code.indicator.code }}
                    </a>
                </div>
                <!-- {{ collapseArr }} -->
            </div>
        </div>
        <el-collapse-item class="indicator-set" :name="indicatorSet.id">
            <template slot="title">
                <div class="grid grid-cols-2 gap-4 w-full">
                    <div class="text-grey-1 text-sm xl:text-base">

                    <el-tag class="mr-3 bg-primary-lightest text-primary-light font-display font-semibold">{{ indicatorNumber(indicatorSet.name) }}</el-tag>
                    {{ indicatorName(indicatorSet.name) }}
                    </div>
                </div>
            </template>
            <el-collapse class="ml-6" v-model="collapseArr">
                <IndicatorView
                    :id="`indicator-${indicatorSet.id}-${item.indicatorId}`"
                    v-for="(item, index) in indicatorSetData"
                    :key="`indicator-${index}`"
                    :surveyType="surveyType"
                    :indicatorSet="item.indicator"
                    :assessmentId="assessmentId"
                    :set-name="indicatorSet.fullCode"
                    :indicatorId="item.indicatorId"
                    :filterType="filterType"
                    :adminFilter="adminFilter"
                    :status="status"
                    :isSelfAssess="isSelfAssess"
                    :indicatorLockArr="indicatorLockArr"
                    :submissions="submissions"
                    @admin-controls="adminControlsHandler"
                    @indicator-value="indicatorValue"
                />
            </el-collapse>
        </el-collapse-item>
    </div>
</template>

<script>
import {removeElement} from '@/utils/array.helper'
import IndicatorView from './IndicatorView'

export default {
    name:'IndicatorSet',
    components: {
        IndicatorView
    },
    props: {
        surveyType: {
            type: String,
            default: null
        },
        indicatorSet: {
            type: Object,
            default: () => {}
        },
        indicatorsData: {
            type: Array,
            default: () => []
        },
        filterSet: {
            type: Array,
            default: () => []
        },
        indicatorLockArr: {
            type: Array,
            default: () => []
        },
        filterType: {
            type: String,
            default: null
        },
        indicatorId: {
            type: String,
            default: null
        },
        status: {
            type: String,
            default: null
        },
        adminFilter: {
            type: String,
            default: null
        },
        isSelfAssess: {
            type: Boolean,
            default: null
        },
        assessmentId: {
            type: String,
            default: null
        },
        submissions: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            collapseArr: [],
            indicatorsFilterObj: {},
            adminControlsObj: {
                open: [],
                closed: [],
                referToCa: [],
            },
            closeAllEvent: false,
        }
    },
    mounted() {
        // add the indicator if it exists to the collapseArr in order to open the collapse item on page load
        if (this.indicatorId) this.collapseArr.push(Number(this.indicatorId))
        this.indicatorsFilterObj = this.setIndicatorsFilterObj()
        // open all indicators event
        this.$root.$on('open-all', () => {
            let allArr = this.setAllIndicatorIds()
            setTimeout(() => {
                this.collapseArr = allArr
            }, 250)
        })
        // close all indicators event
        this.$root.$on('close-all', () => {
            setTimeout(() => {
                this.closeAllEvent = true
                this.collapseArr = []
            }, 250)
        })
    },
    computed: {
        indicatorSetData() {
            let data = this.indicatorsData.find(item => {
                return item.id === this.indicatorSet.id
            })

            if (data) return data.indicatorSetIndicator
            return []
        },
        isIndicatorFiltered() {
            if (this.filterSet && this.filterSet.length > 0 && this.indicatorSet) {
                let idFiltered = this.filterSet.find(value => {
                    return this.indicatorSet.id === value
                })
                if (idFiltered) return true
                return false
            }
            return true
        },
        isIndicatorVisible() {
            let isVisible = this.isIndicatorFiltered
            if (isVisible) {
                // console.log('isIndicatorVisible 2', isVisible)
                if (this.adminFilter !== null) {
                    // console.log('isIndicatorVisible 3', isVisible)
                    if (this.adminFilter === 'OPEN') {
                        // console.log('isIndicatorVisible OPEN', this.adminControlsObj)
                        isVisible = this.adminControlsObj.open.length > 0
                    } else if (this.adminFilter === 'CLOSED') {
                        isVisible = this.adminControlsObj.closed.length > 0
                    } else if (this.adminFilter === 'REFER') {
                        isVisible = this.adminControlsObj.referToCa.length > 0
                    } else {
                        isVisible =  true
                    }
                }
            }
            // console.log(`isIndicatorVisible ${this.adminFilter}, isVisible: `, isVisible, this.indicatorSet.name, this.indicatorId)
            return isVisible
        },
        currentFilter() {
            if (this.filterType === 'ALL') {
                return []
            } else if (this.filterType === 'MET') {
                return this.indicatorsFilterObj.met
            } else if (this.filterType === 'NOT_MET') {
                return this.indicatorsFilterObj.notMet
            } else if (this.filterType === 'NOT_APPLICABLE') {
                return this.indicatorsFilterObj.notApplicable
            }  else if (this.filterType === 'ACTION_REQUIRED') {
                return this.indicatorsFilterObj.furtherActionRequired
            } else if (this.filterType === 'COMPLETE') {
                return this.indicatorsFilterObj.complete
            } else if (this.filterType === 'INCOMPLETE') {
                return this.indicatorsFilterObj.incomplete
            } else if (this.filterType === 'REVIEWED') {
                return this.indicatorsFilterObj.reviewedResponses
            } else if (this.filterType === 'UNREVIEWED') {
                return this.indicatorsFilterObj.unreviewedResponses
            } else {
                return []
            }
        },
    },
    methods: {
        setAllIndicatorIds() {
            return this.indicatorSet.indicatorSetIndicator.map(item => {
                return item.indicatorId
            })
        },
        adminControlsHandler(value) {
            removeElement(this.adminControlsObj.open, value.id)
            removeElement(this.adminControlsObj.closed, value.id)
            removeElement(this.adminControlsObj.referToCa, value.id)

            // console.log('adminControlsHandler indicatorSet.name:', this.indicatorSet.name)
            if (value.adminControls.nonConformityStatus !== null && value.adminControls.nonConformityStatus !== '') {
                if (value.adminControls.nonConformityStatus == 'OPEN' && value.compliance === 'NOT_MET') {
                    if (!this.adminControlsObj.open.includes(value.id)) this.adminControlsObj.open.push(value.id)
                }
                if (value.adminControls.nonConformityStatus == 'CLOSED' && value.compliance === 'NOT_MET') {
                    if (!this.adminControlsObj.closed.includes(value.id)) this.adminControlsObj.closed.push(value.id)
                }
                if (value.adminControls.referToCa  && value.compliance === 'NOT_MET') {
                    if (!this.adminControlsObj.referToCa.includes(value.id)) this.adminControlsObj.referToCa.push(value.id)
                }
            }
            // console.log('adminControlsHandler this.adminControlsObj:', this.adminControlsObj)
        },
        filteredIndicator(id) {
            if (this.currentFilter && this.currentFilter.length) {
                if (this.currentFilter === 'ALL') {
                    return true
                } else {
                    return this.currentFilter.includes(id)
                }
            }
            return true
        },
        codeClass(id) {
            // console.log('codeClass collapseArr', this.collapseArr, typeof this.collapseArr)
            let classArr = []
            let containsId
            if (this.collapseArr) {
                if (this.collapseArr.length && this.collapseArr.includes(id)) {
                    classArr.push('text-black', 'hover:underline')
                }
            }

            return classArr
        },
        codeClicked(id) {
            const pos = this.collapseArr.indexOf(id)
            if (pos > -1){
                this.collapseArr.splice(pos, 1)
            } else {
                this.collapseArr.push(id)
                this.$emit('expand-parent', this.indicatorSet.id)

                setTimeout(() => {
                    this.$el.querySelector(`#indicator-${this.indicatorSet.id}-${id}`).scrollIntoView({behavior: 'smooth', block: 'nearest'})
                }, 500)
            }
        },
        indicatorValue(value) {
            let className = ''
            let classArr= ['text-green', 'text-red', 'text-orange', 'text-light-blue', 'text-grey-2']

            switch (value.compliance) {
                case 'MET':
                    className = 'text-green'
                    break
                case 'NOT_MET':
                    className = 'text-red'
                    break
                case 'NOT_APPLICABLE':
                    className = 'text-orange'
                    break
                case 'COMPLETE':
                    className = 'text-light-blue'
                    break
                default:
                    className = 'text-grey-2'
            }

            if (this.$refs[`code-${value.indicatorId}`] && this.$refs[`code-${value.indicatorId}`][0]) {
                let indicator = this.$refs[`code-${value.indicatorId}`][0].classList

                // remove the previous colour class
                classArr.forEach((arrItem) => {
                    if (indicator.contains(arrItem)) {
                        indicator.toggle(arrItem)
                    }
                })

                // Add new colour class
                indicator.add(className)
            }
        },
        getTxtParts(txt) {
            return txt.split(/\s*(\d\.\d+)\s*/)
        },
        indicatorNumber(txt) {
            let parts = this.getTxtParts(txt)
            return `${parts[0]} ${parts[1]}`
        },
        indicatorName(txt) {
            let parts = this.getTxtParts(txt)
            return `${parts[2]}`
        },
        setIndicatorsFilterObj() {
            let met = []
            let notMet = []
            let notApplicable = []
            let complete = []
            let incomplete = []
            let furtherActionRequired = []
            let reviewedResponses = []
            let unreviewedResponses = []

            this.indicatorSetData.forEach(item => {
                const id = item.indicatorId
                const response = item?.indicator?.responses
                const compliance = response?.compliance
                const actionRequired = response?.furtherActionRequired
                const reviewed = response?.reviewed
                const isMarkedComplete = response?.isMarkedComplete


                if (compliance === 'MET') {
                    if (!met.includes(id)) met.push(id)
                } else if (compliance === 'NOT_MET') {
                    if (!notMet.includes(id)) notMet.push(id)

                    if (
                        !this.isSelfAssess && (
                            !response?.nonComplianceStatement ||
                            !response?.nonComplianceType ||
                            !response?.riskImpact ||
                            !response?.riskLikelihood ||
                            !response?.riskRating ||
                            !response?.riskType
                        )
                    ) { 
                        if (!incomplete.includes(id)) incomplete.push(id)
                    }
                } else if (compliance === 'NOT_APPLICABLE') {
                    if (!notApplicable.includes(id)) notApplicable.push(id)
                } else if (compliance === 'COMPLETE') {
                    if (!complete.includes(id)) complete.push(id)
                } else {
                    if (!this.isSelfAssess) {
                        if (this.surveyType === 'ACCREDITATION' && !compliance) {
                            if (!incomplete.includes(id)) incomplete.push(id)
                        }
                    } else {
                        if (!isMarkedComplete && !compliance) {
                            if (!incomplete.includes(id)) incomplete.push(id)
                        }
                    }
                }

                if (actionRequired) {
                    if (!furtherActionRequired.includes(id)) furtherActionRequired.push(id)
                }
                if (reviewed) {
                    if (!reviewedResponses.includes(id)) reviewedResponses.push(id)
                } else {
                    if (!unreviewedResponses.includes(id)) unreviewedResponses.push(id)
                }
            })
            return {met, notMet, notApplicable, complete, incomplete, furtherActionRequired, reviewedResponses, unreviewedResponses}
        },
    },
    watch: {
        adminFilter() {
            // console.log('WATCH indicatorsSet adminFilter', this.adminFilter)
        },
        indicatorsData() {
            // console.log('WATCH indicatorsData', this.indicatorsData)
            this.indicatorsFilterObj = this.setIndicatorsFilterObj()
        },
        collapseArr() {
            if (!this.closeAllEvent) {
                let childCollapseId = null
                let indicatorSetId = this.indicatorSet.id

                if (this.collapseArr.length) {
                    childCollapseId = this.collapseArr[this.collapseArr.length - 1]
                }
                this.$emit('expand-parent', indicatorSetId, childCollapseId)
            } else {
                this.closeAllEvent = false
            }
        },
    },
    beforeDestroy() {
        this.$root.$off('open-all')
        this.$root.$off('close-all')
    }
}
</script>
