<template>
    <div class="relative">
        <h2 class="text-lg my-4" data-cy="deleteText">Are you sure you want to delete this document?</h2>
        <p class="text-base text-center text-grey-1 my-8">{{ document.name }}</p>
        <div class="flex flex-row justify-center my-4">
            <el-button
                type="primary"
                @click="deleteHandler"
            >
                Delete Document
            </el-button>
            <el-button
                @click="cancelHandler"
                data-cy="cancelHandler"
            >
                Cancel
            </el-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ModalDeleteDocument',
    data() {
        return {
            document: {}
        }
    },
    mounted() {
        console.log('mounted() data.document', this.data)
        this.document = this.data
    },
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData,
            isVisible: state => state.app.modal.isVisible,
        }),
        id() {
            console.log('data for component', this.data)
            return this.data && this.data.id ? this.data.id : null
        }
    },
    methods: {
        deleteHandler() {
            // clearing the modal data
            this.$root.$emit('delete-document', this.id)
            this.$store.commit('MODAL_IS_VISIBLE', false)

            console.log('deleteHandler')
        },
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
    }
}
</script>

