<template>
    <div class="flex flex-col h-full p-4 summary-modal">
        <h2 class="text-2xl text-center text-black mb-4">Submit for review</h2>
        <div class="flex flex-row justify-center my-4">
            <div class="text-center">
                <p>
                    You can only submit the assessment <strong>once for review.</strong><br />
                    <strong>Please ensure you have finalised the surveyor's summary</strong><br />
                    Are you sure you would like to submit it now?
                </p>
            </div>
        </div>
        <div class="flex flex-row justify-center my-4">
            <v-form ref="surveyVisitorForm" v-model="isValid">
                <div class="grid grid-cols-2 gap-x-4 mb-0 w-full">
                    <div class="col-span-1">
                        <EditableDatetimePicker
                            label="Time I started this visit"
                            type="time"
                            :format="this.timeFormat"
                            v-model="surveyVisitorForm.actualStartTime"
                            :rules="[required]"
                        />
                    </div>
                    <div class="col-span-1">
                        <EditableDatetimePicker
                            label="Time I ended this visit"
                            type="time"
                            :format="this.timeFormat"
                            v-model="surveyVisitorForm.actualEndTime"
                            :rules="[required]"
                        />
                    </div>
                    <div class="col-span-1">
                        <v-text-field
                            v-model="surveyVisitorForm.hoursWorkedBeforeVisit"
                            label="Hours spent prior to this visit"
                            :rules="[required, numbersOnly]"
                            @keydown="numbersOnlyEvent"
                        />
                    </div>
                    <div class="col-span-1">
                        <v-text-field
                            v-model="surveyVisitorForm.hoursWorkedAfterVisit"
                            label="Hours spent post visit"
                            :rules="[required, numbersOnly]"
                            @keydown="numbersOnlyEvent"
                        />
                    </div>
                </div>
                <div>
                    <v-checkbox
                        v-model="surveyVisitorForm.hoursWorkedConfirmed"
                        :ripple="false"
                        color="primary"
                        :label="`I confirm that this information is true and correct`"
                        :rules="[required]"
                    ></v-checkbox>
                </div>
            </v-form>
        </div>

        <div class="flex flex-row justify-center my-4">
            <v-btn
                type="button"
                color="grey"
                outlined
                depressed
                @click="cancelHandler"
            >Cancel</v-btn>
            <v-btn
                type="submit"
                color="success"
                class="ml-2"
                depressed
                @click="submitHandler"
            >Yes, submit</v-btn>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {FormRules, FormEvents} from '@/components/shared/mixins/formMixins'
import {format, formatISO, isValid, parse, parseISO} from 'date-fns'
import EditableDatetimePicker from '@/components/partials/EditableDatetimePicker'

export default {
    name: 'ModalSurveySubmit',
    components: {EditableDatetimePicker},
    mixins: [FormRules, FormEvents],
    data() {
        return {
            isValid: true,
            timeFormat: 'HH:mm'
        }
    },
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData,
            isVisible: state => state.app.modal.isVisible
        }),
        surveyVisitorForm() {
            let actualStartTime, actualEndTime
            if (this.data.surveyVisitor.actualStartTime) {
                actualStartTime = parseISO(this.data.surveyVisitor.actualStartTime)
                actualEndTime = parseISO(this.data.surveyVisitor.actualEndTime)
            } else {
                actualStartTime = parse(this.data.surveyVisit.startTime, 'HH:mm:ss', new Date(this.data.surveyVisit.date))
                actualEndTime = parse(this.data.surveyVisit.endTime, 'HH:mm:ss', new Date(this.data.surveyVisit.date))
            }
            return {
                actualStartTime: format(
                    isValid(actualStartTime) ? actualStartTime : new Date(),
                    this.timeFormat
                ),
                actualEndTime: format(
                    isValid(actualEndTime) ? actualEndTime : new Date(),
                    this.timeFormat
                ),
                hoursWorkedBeforeVisit: this.data.surveyVisitor.hoursWorkedBeforeVisit,
                hoursWorkedAfterVisit: this.data.surveyVisitor.hoursWorkedAfterVisit,
                hoursWorkedConfirmed: false
            }
        }
    },
    methods: {
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        validate() {
            this.isValid = this.$refs.surveyVisitorForm.validate()

            return this.isValid
        },
        submitHandler() {
            if (!this.validate()) return

            const surveyVisitorForm = {...this.surveyVisitorForm}

            surveyVisitorForm.actualStartTime = formatISO(
                parse(surveyVisitorForm.actualStartTime, this.timeFormat, new Date(this.data.surveyVisit.date))
            )
            surveyVisitorForm.actualEndTime = formatISO(
                parse(surveyVisitorForm.actualEndTime, this.timeFormat, new Date(this.data.surveyVisit.date))
            )

            this.$root.$emit('submit-survey', surveyVisitorForm)
            this.$store.commit('MODAL_IS_VISIBLE', false)
        }
    }
}
</script>
