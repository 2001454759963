import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api'
import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import { IS_NOT_PROD, TEST_DATA } from '@/config/constants'


export const usePracticeReviewedStore = defineStore('practice-reviewed-store', () => {
    const history = ref([])

    const computedHistory = computed(() => history.value)

    const updateHistory = (newHistory) => {
        history.value = [...(newHistory || [])]
    }

    const loadTestData = () => {
        if (IS_NOT_PROD && !history.value.length) history.value = [...camelcaseKeys(TEST_DATA)]
    }

    const getHistory = async (assessmentId) => {
        if (!assessmentId) return {}

        const url = `web.assessment/${assessmentId}/nc_reviewed_history/`
        const { data } = await axios.get(url)
        history.value = camelcaseKeys(data || [], { deep: true })
    }

    return { computedHistory, getHistory, updateHistory, loadTestData }
})