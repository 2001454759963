<template>
    <div class="flex flex-col h-full p-4 summary-modal">
        <h2 class="text-2xl text-center text-black mb-4">Delete Documents</h2>
        <div class="flex flex-row justify-center my-4">
            <div class="text-center">
                <p>
                    Are you sure you would like to delete the selected documents?
                </p>
            </div>
        </div>

        <div class="flex flex-row justify-center my-4">
            <v-btn
                type="button"
                color="default"
                depressed
                @click="cancelHandler"
            >Cancel</v-btn>
            <v-btn
                type="submit"
                color="success"
                class="ml-2"
                depressed
                @click="submitHandler"
            >Yes, delete</v-btn>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'ModalFileDeleteConfirm',
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData,
            isVisible: state => state.app.modal.isVisible
        }),
    },
    methods: {
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        submitHandler() {
            this.cancelHandler()
            this.$root.$emit('delete-documents')
        }
    }
}
</script>
