<template>
    <div class="c-risk-matrix flex flex-col items-start mt-1">
        <div class="grid grid-cols-6 gap-4 w-full">
            <div class="col-span-2">
                <div class="matrix-container">
                    <div class="col-span-3 text-sm text-center mt-5">Risk Matrix</div>
                    <table class="mt-2">
                    <!-- Risk matrix table generator -->
                        <tr
                        v-for="(row, index) in matrixObj"
                        :key="`risk-row-${index}`"
                        >
                            <td
                                v-for="(cell, key) in row"
                                :key="`cell-${index}-${key}`"
                                :ref="`cell-${index}-${key}`"
                                :class="[getCellColour(cell), pointerStyle]"
                                class="c-risk-matrix__td border border-grey-5"
                                @click="matrixClickHandler(index, key)"
                            >
                            <p class="text-white text-center font-bold uppercase m-0 p-0 text-base" v-if="cell.active">
                                <fa-icon icon="check" class="text-white" />
                            </p>
                            </td>
                        </tr>
                    </table>

                    <div class="text-center text-grey-2 text-xs uppercase mt-1 font-display">Impact 
                        <fa-icon icon="question-circle" class="grey-1 ml-1"/>
                        <fa-icon icon="long-arrow-alt-right" class="grey-1 ml-1"/>
                    </div>
                    <div class="c-risk-matrix__chart-left absolute">
                        <p class="whitespace-no-wrap text-grey-2 text-xs uppercase font-display">Likelihood 
                            <fa-icon icon="question-circle" class="grey-1 ml-1"/> 
                            <fa-icon icon="long-arrow-alt-right" class="grey-1 ml-1"/> 
                        </p>
                    </div>
                </div>
                <div class="grid grid-cols-4 mt-5 text-center text-sm">
                    <div class="col-span-3">Risk Rating</div>
                    <div 
                        class="col-span-3 font-bold"
                        :class="activeColourClass"
                    >
                        {{ responseObj.riskRating }}
                    </div>
                </div>
            </div>
            <div class="col-span-4">
                <div class="flex flex-col items-start mt-5 w-full">
                    <p class="mb-3 text-sm">What's not met?</p>
                    <el-select 
                        v-model="responseObj.nonComplianceType" 
                        placeholder="Select"
                        :disabled="inputsDisabled"
                        class="w-full"
                        @focus="focusHandler('select')"
                        @blur="blurHandler('select')"
                    >
                        <el-option
                            v-for="item in nonCompOptions"
                            :key="`met-${item.value}`"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="flex flex-col items-start mt-5 w-full">
                    <p class="text-sm mb-3">Why is this indicator not met &amp; determination of the risk rating?</p>
                    <el-input
                        v-model="responseObj.nonComplianceStatement"
                        type="textarea"
                        ref="compStateInput"
                        class="w-full"
                        :autosize="{ minRows: 4, maxRows: 8}"
                        rows="6"
                        :disabled="compStatementDisabled"
                        @focus="focusHandler('compliance')"
                        @blur="blurHandler('compliance')"
                    >
                    </el-input>
                </div>
                <div class="flex flex-row items-center mt-5">
                    <p class="mr-3 text-sm">Risk type</p>
                    <el-select 
                        v-model="responseObj.riskType" 
                        placeholder="Select"
                        :disabled="inputsDisabled"
                        @focus="focusHandler('select')"
                        @blur="blurHandler('select')"
                    >
                        <el-option
                            v-for="item in riskOptions"
                            :key="`met-${item.value}`"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'RiskMatrix',
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        status: {
            type: String,
            default: null
        },
        isDisabled: {
            type: Boolean,
            default: null
        },
        indicatorLockArr: {
            type: Array,
            default: () => []
        },
        indicatorRespId: {
            type: Number,
            default: null
        },
        userId: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            matrixObj: {
                high: {
                    low: {value: 'bg-risk-matrix-yellow', active: false, text: 'moderate'},
                    moderate: {value: 'bg-risk-matrix-orange', active: false, text: 'high'},
                    high: {value: 'bg-risk-matrix-red', active: false, text: 'critical'}
                },
                moderate: {
                    low: {value: 'bg-risk-matrix-green', active: false, text: 'low'},
                    moderate: {value: 'bg-risk-matrix-yellow', active: false, text: 'moderate'},
                    high: {value: 'bg-risk-matrix-orange', active: false, text: 'high'}
                },
                low: {
                    low: {value: 'bg-risk-matrix-green', active: false, text: 'low'},
                    moderate: {value: 'bg-risk-matrix-green', active: false, text: 'low'},
                    high: {value: 'bg-risk-matrix-yellow', active: false, text: 'moderate'}
                },
            },
            activeColourClass: '',
            responseObj: {
                nonComplianceStatement: '',
                nonComplianceType: null,
                riskLikelihood: null,
                riskType: '',
                riskImpact: null,
                riskRating: null,
                ncStatusCreatedById: null
            },
            highlightClass: 'font-bold text-grey-1',
            normalClass: 'text-grey-2',
        }
    },
    mounted() {
        // console.log('RISKMATRIX mounted this.value', this.value)
        this.setData()
    },
    methods: {
        matrixClickHandler(index, key) {
            if (!this.inputsDisabled) {
                if (index === 'high' && key === 'high' && ['IN_PROGRESS', 'PARTIALLY_SUBMITTED'].some(s => s === this.status )) {
                    this.$store.commit('MODAL_IS_VISIBLE', true)
                    this.$store.commit('MODAL_DATA')
                    this.$store.commit('MODAL_COMPONENT_NAME', 'ModalCriticalConfirm')
                    this.$root.$on('matrix-critical-confirm', this.confirmMatrixCritical)
                } else {
                    this.responseObj.riskLikelihood = index.toString().toUpperCase()
                    this.responseObj.riskImpact = key.toString().toUpperCase()
                }
            }
        },
        confirmMatrixCritical({confirm}) {
            this.$root.$off('matrix-critical-confirm')
            if (confirm) {
                this.responseObj.riskLikelihood = 'HIGH'
                this.responseObj.riskImpact = 'HIGH'
                this.$emit('select-click-event')
            }
        },
        setActiveCell() {
            let riskLikelihood = this.responseObj && this.responseObj.riskLikelihood ? this.responseObj.riskLikelihood.toLowerCase() : null
            let riskImpact = this.responseObj && this.responseObj.riskImpact ? this.responseObj.riskImpact.toLowerCase() : null

            for (let row in this.matrixObj) {
                for (let col in this.matrixObj[row]) {
                    this.matrixObj[row][col].active = row === riskLikelihood && col === riskImpact
                    // set risk rating
                    if (row === riskLikelihood && col === riskImpact) this.responseObj.riskRating = this.matrixObj[row][col].text.toUpperCase()
                }
            }
        },
        getCellColour(cell) {
            return cell.active ? cell.value + '-dark' : cell.value + '-light'
        },
        focusHandler(event) {
            // console.log('focusHandler')
            this.$emit('input-interaction', {
                name: event,
                event: 'focus'
            })
        },
        blurHandler(event) {
            // console.log('blurHandler')
            this.$emit('input-interaction', { 
                name: event,
                event: 'blur'
            })
        },
        setData() {
            this.responseObj.nonComplianceStatement = this.value.nonComplianceStatement
            this.responseObj.riskType = this.value.riskType
            this.responseObj.nonComplianceType = this.value.nonComplianceType
            this.responseObj.riskLikelihood = this.value.riskLikelihood
            this.responseObj.riskImpact = this.value.riskImpact
            this.responseObj.riskRating = this.value.riskRating
            this.responseObj.ncStatusCreatedById = this.value.ncStatusCreatedById
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices,
            permissions: state => state.app.permissions.assessment
        }),
        compStatementDisabled() {
            for (let item of this.indicatorLockArr) {
                if (item.indicatorResponseId === this.indicatorRespId) {
                    if (!this.isDisabled) return this.inputIsLocked
                }
            }
            return this.isDisabled
        },
        inputIsLocked() {
            for (let item of this.indicatorLockArr) {
                if (item.indicatorResponseId === this.indicatorRespId) {
                    if (item.fields === 'compliance') {
                        console.log('inputIsLocked() return', item.userId, this.userId, item.userId !== this.userId)
                        return item.userId !== this.userId
                    } 
                    return false
                }
            }
            return false
        },
        riskOptions() {
            return this.staticChoices['indicator_response.risk_type'] || []
        },
        nonCompOptions() {
            return this.staticChoices['indicator_response.non_compliance_type'] || []
        },
        inputsDisabled() {
            return this.isDisabled
        },
        colLow() { return this.responseObj.riskImpact === 'LOW' ? this.highlightClass : this.normalClass },
        colMod() { return this.responseObj.riskImpact === 'MODERATE' ? this.highlightClass : this.normalClass },
        colHigh() { return this.responseObj.riskImpact === 'HIGH' ? this.highlightClass : this.normalClass },
        rowLow() { return this.responseObj.riskLikelihood === 'LOW' ? this.highlightClass : this.normalClass },
        rowMod() { return this.responseObj.riskLikelihood === 'MODERATE' ? this.highlightClass : this.normalClass },
        rowHigh() { return this.responseObj.riskLikelihood === 'HIGH' ? this.highlightClass : this.normalClass },
        pointerStyle() { return this.inputsDisabled ? 'cursor-not-allowed' : 'cursor-pointer' },
        ncStatusCreatedByIdLocked() {
            return this.value.ncStatusCreatedById !== null && this.value.ncStatusCreatedById !== undefined
        }
    },
    watch: {
        'responseObj.riskLikelihood': function() {
            this.setActiveCell()
        },
        'responseObj.riskImpact': function() {
            this.setActiveCell()
        },
        'responseObj.riskRating': function() {
            for (const risk in this.matrixObj) {
                for (const subRisk in this.matrixObj[risk]) {
                    if (this.matrixObj[risk][subRisk].text === this.responseObj?.riskRating?.toLowerCase()) {
                        this.activeColourClass = `text${this.matrixObj[risk][subRisk].value.slice(2)}-dark`
                        break
                    }
                }
            }
        },
        'responseObj.nonComplianceStatement': function() {
            // firstly we set the ncStatus created if the current user starts entering the value in
            // if they completely delete their statement before saving it, then we clear it out
            // this is because if the statement isn't set, then it shouldn't be locked to that user
            if (!this.ncStatusCreatedByIdLocked && !this.responseObj.ncStatusCreatedById && this.responseObj.nonComplianceStatement) {
                this.responseObj.ncStatusCreatedById = this.$auth.user()?.userId
            } else if (!this.ncStatusCreatedByIdLocked && this.responseObj.ncStatusCreatedById && !this.responseObj.nonComplianceStatement) {
                this.responseObj.ncStatusCreatedById = null
            }
        },
        responseObj: {
            handler() {
                this.$emit('input', this.responseObj)
            },
            deep: true
        },
        value() {
            this.setData()
        },
        inputsDisabled() {
            this.$store.commit('MODAL_DATA', {inputsDisabled: this.inputsDisabled})
        }
    },
    destroyed() {
        this.$root.$off('matrix-critical-confirm')
    }
}
</script>
