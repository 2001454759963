import camelcaseKeys from 'camelcase-keys'

export default {
    methods: {
        goToAdmin() {
            const url = `${process.env.VUE_APP_DJANGO_BASE_URL}/${this.backendAdminUrl || process.env.VUE_APP_DEFAULT_BACKEND_ADMIN_URL}`
            this.goToBackendUrl(url)
            // console.log('go to backend admin url')
        },
        goToBackendUrl(url, openInNewTab) {
            if (openInNewTab) {
                window.open(url, '_blank')
            } else {
                window.location = url
            }
        },
        async redirectOnPaymentPending(practice) {
            
            // // need to add the practice to the store, but skipping that for now
            // this.$router.push({name: 'application-payment'})
            const httpOptions = this.getPostOrPatchOptions({}, `web.practice/${practice.organisationPtrId}`, true)
        
            try {
                const response = await this.$http(httpOptions)
                const practice = camelcaseKeys(response.data, {deep: true})
                this.goToBackendUrl(practice.applicationPaymentUrl)
            } catch(e) {
                // TODO: do something if the get doesn't work
            }
        },
    }
}
