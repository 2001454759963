<template>
    <div class="c-staff__table editable_table flex flex-col">
        <v-data-table
            ref="documentTable"
            item-key="id"
            :headers="headers"
            :items="value"
            class="c-table"
            data-cy="documentTable"
            show-select
            v-model="selected"
        >
            <template v-slot:[`item.name`]="{item}">
                {{ item.name }}
            </template>
            <template v-slot:[`item.created`]="{item}">
                {{ parseDate(item.created) }}
            </template>
            <template v-slot:[`item.fileSize`]="{item}">
                {{ getHumanReadable(item.fileSize) }}
            </template>
            <template v-slot:[`item.editRecord`]="{item}">
                <div
                    class="c-staff__table__edit-record ml-2 absolute border-l border-grey-5"
                    :class="{'active-tr': item.active}"
                >
                    <a href="#" @click.prevent="downloadHandler(item.id, 'document')">
                        <fa-icon icon="download" class="text-grey-6 hover:text-dark-blue" />
                    </a>
                    <a href="#" class="mx-2" @click.prevent="deleteHandler(item)">
                        <fa-icon icon="trash-alt" class="text-grey-6 hover:text-red" data-cy="deleteRecord"/>
                    </a>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {format, isValid, parseISO} from 'date-fns'
import HumanReadable from '@/utils/humanReadable'
import {mapState} from 'vuex'
import FileMethods from '@/components/shared/mixins/fileMethods'

export default {
    name: 'DocumentList',
    mixins: [HumanReadable, FileMethods],
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            message: '',
            type: '',
            headers: [
                // { text: 'verified', align: 'left', value: 'verified', class: 'verified--header' },
                {
                    text: 'Document',
                    align: 'left',
                    value: 'name',
                    class: ''
                },
                {text: 'Date', align: 'left', value: 'created', class: ''},
                {text: 'File Size', align: 'left', value: 'fileSize', class: ''},
                {text: '', align: 'left', value: 'editRecord', fixed: true}
            ],
            selected: [],
        }
    },
    mounted() {
        console.log('filesList passed in', this.value)
        this.$root.$on('delete-document', this.deleteDocumentHandler)
    },
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData
        })
    },
    methods: {
        deleteDocumentHandler(id) {
            // now we clear the modal data
            this.$store.commit('MODAL_DATA', {})
            if (id !== null && id !== undefined) {
                this.$http
                    .put(`document/${id}/`, {archived: true})
                    .then(response => {
                        console.log('delete response', response)
                        this.type = 'success'
                        this.message = 'Successfully archived document'
                        this.$emit('document-list-update', true)
                    })
                    .catch(err => {
                        console.log('document delete error', err)
                        this.type = 'error'
                        this.message = 'Could not archive document, please '
                    })
                    .finally(() => {
                        // this.showMessage()
                        this.$emit('show-message', {message: this.message, type: this.type})
                    })
            }
        },
        deleteHandler(item) {
            console.log('deleteHandler')
            this.$emit('reset-message')
            if (this.currentMessageDialog) {
                this.currentMessageDialog.close()
                this.currentMessageDialog = null
            }
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalDeleteDocument')
            console.log('row to delete', item)
            this.$store.commit('MODAL_DATA', item)
        },
        cancelHandler() {
            // update the data on client
            const files = this.value.map(item => {
                if (item.id === this.activeRowId) {
                    return this.activeRowObj
                }
                return item
            })
            this.$emit('input', files)
            this.activeRowId = null
        },
        parseDate(dateString) {
            return dateString ? format(new Date(dateString), 'd LLL yyyy - h:mma') : ''
        }
    },
    watch: {
        selected() {
            this.$emit('selection-changed', this.selected.map(({id}) => id))
        },
    },
    beforeDestroy() {
        this.$root.$off('delete-document')
    }
}
</script>
