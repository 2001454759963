<template>
    <div v-if="!loading" class="c-documents text-black mt-4">
        <div>
            <div class="flex flex-col lg:flex-row justify-between">
                <div class="mt-2">
                    <div v-if="folder" class="flex items-center justify-between">
                        <div class="flex items-center">
                            <fa-icon
                                icon="chevron-left"
                                class="text-black mr-2 hover:text-primary-light cursor-pointer"
                                @click="showFolderList"
                            />
                            <FolderSvg
                                class="w-12 hideSmallTablet"
                                :style="{color: folder.color}"
                            />
                            <span
                                class="font-semibold text-lg lg:text-xl ml-0 lg:ml-2 text-black flex flex-row justify-center mr-1 lg:mr-2 font-display"
                            >{{ folder.name }}</span>
                        </div>
                    </div>
                    <div v-else class="font-semibold text-lg lg:text-xl text-black font-display">
                        All Documents
                    </div>
                </div>
                <Search v-model="search" placeholder="Search all documents" />
            </div>

            <!--for below div v-if: if there is zero documents uploaded, please display -->
            <v-alert
                v-if="!documentsLoading && showAlert && (!documents || !documents.length || forceDocumentsFirstView)"
                dismissible text
                v-model="showAlert"
                color="primary" type="success"
                class="first-state rounded py-2 px-4 mb-4"
            >
                <div class="flex grid grid-cols-7 gap-8 mt-4">
                    <div class="col-span-7 md:col-span-3 xl:col-span-2">
                        <img src="@/assets/images/uploaddocuments.png" class="m-auto" :style="imgStyle" alt="Upload Documents" />
                    </div>
                    <div class="col-span-7 md:col-span-4 xl:col-span-5">
                        <span class="text-green text-xs uppercase font-bold font-display">Step 2</span>
                        <h3 class="text-black font-semibold mr-2 text-lg pb-2">Upload required documents</h3>
                        <p class="pb-3 text-sm text-black">
                            As part of the practice’s accreditation we require you to upload the following documents:
                        </p>
                        <ul class="pb-3 pl-6 fa-ul c-staff__check-list text-sm text-black">
                            <li>
                                <span class="fa-li">
                                    <fa-icon icon="check-square" class="mr-1 text-green"/>
                                </span>
                                <span class="align-middle">Proof of current accreditation certificate (can be a photo of the certificate - must clearly show all details)</span>
                            </li>
                            <li>
                                <span class="fa-li">
                                    <fa-icon icon="check-square" class="mr-1 text-green"/>
                                </span>
                                <span class="align-middle">Policy and Procedure manual</span>
                            </li>
                            <li>
                                <span class="fa-li">
                                    <fa-icon icon="check-square" class="mr-1 text-green"/>
                                </span>
                                <span class="align-middle">If you are a medical deputising service provide proof by uploading your deed of agreement</span>
                            </li>
                        </ul>
                        <p class="pb-3 text-sm text-black">
                            To upload documents, click into the desired folder and then click the 'Upload' button. <br />
                            If the document is part of the self-assessment, please upload through the relevant indicator.
                        </p>
                    </div>
                </div>
            </v-alert>

            <div v-if="folder && !documentsLoading" class="mb-4">
                <v-btn
                    color="success"
                    data-cy="fileUpload"
                    depressed
                    @click.prevent="uploadClickHandler"
                >
                    <fa-icon icon="upload" data-cy="uploadFile"></fa-icon>
                    <span class="ml-2 hideSmallTablet" data-cy="fileUploadText">Upload</span>
                </v-btn>
                <v-btn
                    class="ml-2"
                    color="error"
                    data-cy="filesDelete"
                    depressed
                    :disabled="!selectedDocuments.length"
                    @click.prevent="deleteSelectedClickHandler"
                >
                    <fa-icon icon="trash" data-cy="filesDelete"></fa-icon>
                    <span class="hideSmallTablet ml-2">Delete</span>
                </v-btn>
                <v-btn
                    class="ml-2"
                    color="primary"
                    data-cy="moveUpload"
                    depressed
                    :disabled="!selectedDocuments.length"
                    @click.prevent="moveClickHandler"
                >
                    <fa-icon icon="folder" data-cy="moveUpload"></fa-icon>
                    <span class="ml-2 hideSmallTablet">Move</span>
                </v-btn>
                <v-btn
                    class="ml-2"
                    color="info"
                    data-cy="downloadAll"
                    depressed
                    @click.prevent="downloadAllClickHandler"
                >
                    <fa-icon icon="download" data-cy="downloadAll"></fa-icon>
                    <span class="ml-2"><span class="hideSmallTablet">Download</span> All</span>
                </v-btn>
                <v-btn
                    class="ml-2"
                    color="secondary"
                    data-cy="downloadedSelected"
                    depressed
                    :disabled="!selectedDocuments.length"
                    @click.prevent="downloadSelectedClickHandler"
                >
                    <fa-icon icon="download" data-cy="downloadedSelected"></fa-icon>
                    <span class="ml-2"><span class="hideSmallTablet">Download</span> Selected</span>
                </v-btn>
            </div>

            <DocumentList
                v-show="!documentsLoading && shouldShowDocuments"
                v-model="documents"
                @document-list-update="updateDocuments"
                @selection-changed="updateSelection"
                @show-message="showMessageEvent"
                @reset-message="resetMessageEvent"
            />
            <FolderList
                v-show="!documentsLoading && !shouldShowDocuments"
                v-model="folders"
                data-cy="folderList"
                @select="selectFolder"
            />
        </div>
    </div>
    <div class="flex items-center justify-center h-20 w-full" v-else>
        <Loader class="w-12 h-12" />
    </div>
</template>

<script>
import GET_DOCS_CONTENT_TYPE from '@/graphql/queries/getDocsContentType.gql'
import GET_DOCUMENTS from '@/graphql/queries/getDocuments.gql'
import Upload from './partials/Upload'
import DocumentList from './partials/DocumentList'
import Search from '@/components/partials/Search'
import Loader from '@/assets/loader.svg'
import {mapState} from 'vuex'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import FolderList from './partials/FolderList'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import FolderSvg from '@/assets/icons/icons_folder.svg'
import FileMethods from '@/components/shared/mixins/fileMethods'

export default {
    name: 'DocumentsComponent',
    components: {
        FolderList,
        Upload,
        DocumentList,
        Loader,
        Search,
        FolderSvg
    },
    mixins: [MessageDialog, FileMethods],
    props: {
        forceDocumentsFirstView: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            skipDocsQuery: true,
            loading: false,
            search: '',
            folder: null,
            selectedDocuments: [],
            documents: [],
            showAlert: true,
            contentTypeId: '',
            documentsLoading: true,
            imgStyle: {
                width: '100%',
                height: 'auto',
            },
        }
    },
    apollo: {
        contentType: {
            query: GET_DOCS_CONTENT_TYPE,
            result({ data: { contentType } }) {
                console.log('contentType')
                this.contentTypeId = contentType[0].id
            }
        },
        documents: {
            query: GET_DOCUMENTS,
            variables() {
                return {
                    practiceId: this.practiceId,
                    folderId: this.folderId,
                    search: this.search ? `%${this.search}%` : null
                }
            },
            skip() {
                return this.skipDocsQuery && !this.practiceId
            },
            result({ data: { documents } }) {
                this.documents = [...camelcaseKeys(documents || [])]
                this.documentsLoading = false
            },
        }
    },
    mounted() {
        if (this.practiceId) this.loadFolders()
        this.$root.$on('document-list-update', this.updateDocuments)
        this.$root.$on('delete-documents', this.deleteDocuments)
        this.$root.$on('move-documents', this.moveDocuments)
    },
    methods: {
        loadFolders() {
            this.loading = true
            this.$store
                .dispatch('fetchFolders', {
                    apolloClient: this.$apolloProvider.defaultClient,
                    practiceId: this.practiceId
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async selectFolder(folder) {
            // await this.$apollo.provider.defaultClient.resetStore()
            this.folder = folder
            await this.$apollo.queries.documents.refetch()
        },
        showFolderList() {
            this.search = ''
            this.folder = null
        },
        uploadClickHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalUpload')
            this.$store.commit('MODAL_DATA', {
                folderId: this.folderId
            })
        },
        deleteSelectedClickHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalFileDeleteConfirm')
            this.$store.commit('MODAL_DATA', {})
        },
        moveClickHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalMoveToFolder')
            this.$store.commit('MODAL_DATA', {
                folders: this.folders,
                folderId: this.folderId
            })
        },
        downloadSelectedClickHandler() {
            this.$http
                .post(`document/download`, {
                    action: 'download',
                    documents: this.selectedDocuments
                })
                .then(({data}) => {
                    // console.log('download seleccted response', data)
                    this.downloadFromURL(data.url, data.name)
                    this.type = 'success'
                    this.message = 'Successfully downloaded documents.'
                })
                .catch(err => {
                    console.log('documents download error', err)
                    this.type = 'error'
                    this.message = 'Could not download documents.'
                })
                .finally(() => {
                    this.showMessage({message: this.message, type: this.type})
                })
        },
        downloadAllClickHandler() {
            this.$http
                .post(`document/download-folder`, {
                    action: 'download-folder',
                    folder: this.folderId,
                    practiceId: this.practiceId
                })
                .then(({data}) => {
                    // console.log('download folder response', data)
                    this.downloadFromURL(data.url, data.name)
                    this.type = 'success'
                    this.message = 'Successfully downloaded folder.'
                })
                .catch(err => {
                    console.log('folder download error', err)
                    this.type = 'error'
                    this.message = 'Could not download folder.'
                })
                .finally(() => {
                    this.showMessage({message: this.message, type: this.type})
                })
        },
        updateDocuments(e) {
            this.$apollo.queries.documents.refetch()
            this.showDocuments()
        },
        showMessageEvent(message) {
            this.message = message.message
            this.type = message.type
            this.showMessage(message.options ? message.options : {})
        },
        resetMessageEvent() {
            this.resetMessage()
        },
        showDocuments() {
            this.$emit('cancelForceDocumentsFirstView')
        },
        updateSelection(selected) {
            this.selectedDocuments = selected
        },
        deleteDocuments() {
            console.log('delete documents', this.selectedDocuments)

            this.loading = true
            this.$http
                .post(`document/batch-delete`, {
                    action: 'archive',
                    documents: this.selectedDocuments
                })
                .then(response => {
                    console.log('delete response', response)
                    this.type = 'success'
                    this.message = 'Successfully archived documents.'
                    this.updateDocuments()
                })
                .catch(err => {
                    console.log('documents delete error', err)
                    this.type = 'error'
                    this.message = 'Could not archive documents.'
                })
                .finally(() => {
                    this.loading = false
                    this.showMessage({message: this.message, type: this.type})
                })
        },
        moveDocuments(folderId) {
            console.log('move documents', this.selectedDocuments, folderId)

            this.loading = true
            this.$http
                .post(
                    `document/batch-move`,
                    snakecaseKeys(
                        {
                            action: 'move',
                            documents: this.selectedDocuments,
                            oldFolder: this.folder.id,
                            folder: folderId
                        },
                        {deep: true}
                    )
                )
                .then(response => {
                    console.log('move response', response)
                    this.type = 'success'
                    this.message = 'Successfully moved documents.'
                    this.updateDocuments()
                })
                .catch(err => {
                    console.log('documents move error', err)
                    this.type = 'error'
                    this.message = 'Could not move documents.'
                })
                .finally(() => {
                    this.loading = false
                    this.showMessage({message: this.message, type: this.type})
                })
        }
    },
    computed: {
        ...mapState({
            practiceId: state => state.app.practiceId,
            folders: state => state.app.folders
        }),
        isNewUser() {
            // console.log(this.$auth.user())
            return this.$auth.user() != null && this.$auth.user().isNewUser
        },
        isFirstView() {
            return this.isNewUser && this.documents?.length === 0
        },
        shouldShowDocuments() {
            return this.search || this.folder
        },
        folderId() {
            return this.folder?.id ?? null
        }
    },
    watch: {
        practiceId() {
            if (this.practiceId != null) this.loadFolders()
        },
        contentTypeId() {
            // console.log('contentTypeId', this.contentTypeId)
            if (this.contentTypeId) {
                this.skipDocsQuery = false
                this.$apollo.queries.documents.refetch()
            }
        },
        documents() {
            this.$store.commit('SET_DOCS_TOTAL', this.documents.length)
            this.$store.commit('SET_DOCUMENTS_FIRST_VIEW', this.isFirstView)
            this.loading = false
            this.selectedDocuments = []
            if (!this.forceDocumentsFirstView && !this.shouldShowDocuments) this.showAlert = !this.documents?.length
        },
        forceDocumentsFirstView() {
            if (this.forceDocumentsFirstView) this.showAlert = true
            else this.showAlert = !this.documents?.length
        },
        showAlert() {
            this.$emit('updateForceDocumentsFirstView', this.showAlert)
        }
    },
    beforeDestroy() {
        this.resetMessage()
        // this.$root.$off()
        this.$root.$off('document-list-update')
        this.$root.$off('delete-documents')
        this.$root.$off('move-documents')
    }
}
</script>
