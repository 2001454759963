<template>
<div class="c-modal__send-staff-invite relative flex flex-col items-center">
    <h2 class="mt-8" data-cy="headerTitle">Invite Staff to Connect</h2>
    <el-form class="flex flex-col items-center">
        <div class="flex flex-col justify-center my-4">
            <p class="text-center">
                Would you like to send an email invite to <strong class="staff-name">{{ staff.firstName }} {{ staff.lastName }}</strong><br />
                to create a Connect account and add<br />
                <strong class="practice-name">{{ practiceName }}</strong> to their account?
            </p>
        </div>
        <div class="flex flex-row justify-center my-8">
            <el-button @click="cancelHandler" :disabled="sending">Cancel</el-button>
            <el-button type="primary" @click="submitHandler" :loading="sending">Send Invite</el-button>
        </div>
    </el-form>
</div>
</template>

<script>
import { mapState } from 'vuex'
import GET_PRACTICE from '@/graphql/queries/getPractice.gql'
import MessageDialog from '@/components/shared/mixins/messageDialog'

export default {
    name: 'ModalSendStaffInvite',
    data() {
        return {
            sending: false
        }
    },
    mixins: [MessageDialog],
    computed: {
        ...mapState({
            staff: state => state.app.modal.componentData,
            practiceId: state => state.app.practiceId
        }),
        practiceName() {
            return this.practice ? this.practice.name : ''
        },
    },
    apollo: {
        practice: {
            query: GET_PRACTICE,
            variables() {
                return {
                    id: this.practiceId
                }
            },
            skip() {
                return !this.practiceId
            },
        }
    },
    methods: {
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        submitHandler() {
            this.sending = true

            this.$http({
                method: 'POST',
                url: `web.practicestaff/${this.staff.id}/send_invite/`,
                data: {
                    practice: this.practiceId,
                }
            }).then(res => {
                this.message = `You have submitted an invite to <strong>${this.staff.email}</strong>.`
                this.type = 'success'
                this.showMessage({duration: 5000, dangerouslyUseHTMLString: true})
                this.$root.$emit('staff-send-invite', {id: this.staff.id, invite: res.data.invite})
            }).catch(err => {
                this.message = `Invite was not sent to <strong>${this.staff.email}</strong>. Please check you have entered a valid email address &amp; try again.`
                this.type = 'error'
                this.showMessage({duration: 5000, dangerouslyUseHTMLString: true})
                console.error(err)
            }).finally(() => {
                this.$store.commit('MODAL_IS_VISIBLE', false)
                this.sending = false
            })
        },
    }
}
</script>
