<template>
    <div class="flex flex-col w-full p-4 my-4 mb-0 rounded" :class="bodyClass">
        <h4 :class="headingClass" class="font-semibold" v-text="heading"></h4>
        <p>{{ bodyText }}</p>
    </div>
</template>

<script>
export default {
    name: 'StatusInformation',
    props: {
        success: {
            type: Boolean,
            default: null
        }
    },
    data() {
        return {
            textSuccess: 'You have completed the required checkboxes for this indicator. You will receive feedback after QPA has reviewed your final submission.',
            textWarning: 'The checkboxes with green text have not been met. These are the standards the practice must meet and are what the surveyor(s) will be checking during the survey visit.'
        }
    },
    computed: {
        heading() {
            return this.success ? 'Indicator completed!' : 'Your practice may not be compliant with this indicator!'
        },
        bodyText() {
            return this.success ? this.textSuccess : this.textWarning
        },
        headingClass() {
            return this.success ? 'text-light-blue' : 'text-red'
        },
        bodyClass() {
            return this.success ? 'bg-light-blue-light' : 'bg-red-light'
        },
    }
}
</script>
