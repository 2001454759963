<template>
    <span 
        :class="statusClass" 
        class="text-xs lg:text-xs uppercase px-1 py-1 rounded font-bold"
    >{{ statusName }}</span>
</template>

<script>
export default {
    name: 'StatusBadge',
    props: {
        statusType: {
            type: String,
            default: ''
        },
        statusList: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        statusName() {
            if (this.statusList.length) {
                const status = this.statusList.find(sl => sl.value.toLowerCase() === this.statusType.toLowerCase())
                return status !== undefined && status !== null ? status.label : ''
            } else {
                return this.statusType
            }
        },
        statusClass() {
            if (this.statusName.toLowerCase() === 'in progress' || this.statusName.toLowerCase() === 'open') {
                return ['text-light-blue']
            } else if (this.statusName.toLowerCase() === 'accredited' || this.statusName.toLowerCase() === 'approved' || this.statusName.toLowerCase() === 'closed') {
                return ['text-green']
            } else if (this.statusName.toLowerCase() === 'action required') {
                return ['text-red']
            } else if (this.statusName.toLowerCase() === 'payment pending') {
                return ['text-yellow']
            } else if (this.statusName.toLowerCase() === 'under review') {
                return ['text-yellow']
            } else if (this.statusName.toLowerCase() === 'completed' || this.statusName.toLowerCase() === 'complete' || this.statusName.toLowerCase() === 'certified') {
                return ['text-grey-2']
            } else {
                return []
            }
        }
    }
}
</script>
