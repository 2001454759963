<template>
    <div
        v-if="isVisible"
        class="c-modal absolute inset-0 flex flex-col z-50 items-center justify-center active"
        :class="additionalClass"
        tabindex="0"
    >
        <div class="c-modal__content absolute bg-white rounded-lg p-8" >
            <component :is="computedComponentName" />
        </div>
        <div
            class="c-modal__screen-bg w-full h-full inset-0"
            @click="bgClickHandler"
            @keyup.escape="bgClickHandler"
            @keyup.enter="bgClickHandler"
        ></div>
    </div>
</template>

<script>
import ModalDefault from '@/components/partials/ModalDefault'
import ModalDeleteUser from '@/components/partials/ModalDeleteUser'
import ModalTermsAndConditions from '@/components/partials/ModalTermsAndConditions'
import ModalAddAssessment from '@/components/partials/ModalAddAssessment'
import ModalApproveCert from '@/components/partials/ModalApproveCert'
import ModalSummary from '@/components/partials/ModalSummary'
import ModalSubmit from '@/components/partials/ModalSubmit'
import ModalSurveySubmit from '@/components/partials/ModalSurveySubmit'
import ModalComplianceSetMetConfirm from '@/components/partials/ModalComplianceSetMetConfirm'
import ModalComplianceUnsetMetConfirm from '@/components/partials/ModalComplianceUnsetMetConfirm'
import ModalCertificate from '@/components/partials/ModalCertificate'
import ModalDeleteDocument from '@/components/partials/ModalDeleteDocument'
import ModalUpload from '@/components/partials/ModalUpload'
import ModalMoveToFolder from '@/components/partials/ModalMoveToFolder'
import ModalFileDeleteConfirm from '@/components/partials/ModalFileDeleteConfirm'
import ModalAgreement from '@/components/partials/ModalAgreement'
import ModalDeleteUnavailability from '@/components/partials/ModalDeleteUnavailability'
import ModalValidation from '@/components/partials/ModalValidation'
import ModalDownloadCertificate from '@/components/partials/ModalDownloadCertificate'
import ModalSendStaffInvite from '@/components/partials/ModalSendStaffInvite'
import ModalRequestAssistance from '@/components/partials/ModalRequestAssistance'
import ModalCriticalConfirm from '@/components/partials/ModalCriticalConfirm'
import { useActions, useGetters } from 'vuex-composition-helpers'
import { computed } from '@vue/composition-api'
import { useShowModalStore } from '@/stores/useShowModalStore'
import ModalReviewAssessment from '@/components/partials/ModalReviewAssessment.vue'

export default {
    name: 'ModalComponent',
    components: {
        ModalDefault,
        ModalDeleteUser,
        ModalTermsAndConditions,
        ModalAddAssessment,
        ModalApproveCert,
        ModalSummary,
        ModalSubmit,
        ModalSurveySubmit,
        ModalComplianceSetMetConfirm,
        ModalComplianceUnsetMetConfirm,
        ModalCertificate,
        ModalDeleteDocument,
        ModalUpload,
        ModalMoveToFolder,
        ModalFileDeleteConfirm,
        ModalAgreement,
        ModalDeleteUnavailability,
        ModalValidation,
        ModalDownloadCertificate,
        ModalSendStaffInvite,
        ModalRequestAssistance,
        ModalCriticalConfirm,
        ModalReviewAssessment
    },
    setup() {
        const showModalStore = useShowModalStore()
        const { hideModal: piniaHideModal } = showModalStore
        const { modalIsVisible, componentData: data, componentName } = useGetters(['modalIsVisible', 'componentData', 'componentName'])
        const { hideModal } = useActions(['hideModal'])
        const isVisible = computed(() => showModalStore.computedIsVisible || modalIsVisible.value)
        const additionalClass = computed(() => {
            if (componentName.value === 'ModalAgreement') return 'large-modal'
            return ''
        })

        const computedComponentName = computed(() => showModalStore.computedComponentName || componentName.value)

        const bgClickHandler = () => {
            if (!data.value?.keepOpen) {
                hideModal()
                piniaHideModal()
            }
        }
        return { isVisible, data, computedComponentName, additionalClass, bgClickHandler }
    }
}
</script>
