<template>
    <el-radio-group :disabled="surveyInputsDisabled || savingIndicator" v-model="complianceResponse">
        <el-radio class="mr-4 my-3 xl:mr-12" label="MET" disabled data-cy="metRadioOption">Met</el-radio>
        <el-radio class="mr-4 my-3 xl:mr-12" label="NOT_MET" data-cy="notMetRadioOption">Not met</el-radio>
        <el-radio class="mr-4 my-3 xl:mr-12" label="NOT_APPLICABLE">Not applicable</el-radio>
    </el-radio-group>
</template>

<script>
export default {
    name: 'IndicatorRadioGroup',
    props: {
        value: {
            type: String,
            default: null
        },
        surveyInputsDisabled: {
            type: Boolean,
            default: null
        },
        savingIndicator: {
            type: Boolean,
            default: null
        },
    },
    data() {
        return {
            complianceResponse: ''
        }
    },
    mounted() {
        this.complianceResponse = this.value
    },
    watch: {
        complianceResponse() {
            this.$emit('input', this.complianceResponse)
        },
        value() {
            this.complianceResponse = this.value
        }
    }
}
</script>
