<template>
    <div>
        <component
            :is="renderForm"
            :model="formInput"
            :rules="rules"
            ref="formInput"
            class="c-editable-cell"
        >
            <el-form-item prop="inputValue">
                <component
                    :is="renderEditableComponent"
                    :placeholder="placeholder"
                    v-show="editActive"
                    ref="input"
                    v-model="formInput.inputValue"
                    class="p-0 m-0"
                    format="dd/MM/yy"
                    :disabled="disabled"
                >
                </component>
            </el-form-item>
        </component>
        <el-link
            @click="cellClickHandler"
            v-show="!editActive"
            class="justify-start py-1 m-0 hover:text-grey-1 text-grey-2 leading-normal border border-transparent border-l-0 border-r-0 border-t-0"
            :v-html="!linkText ? '&nbsp;': ''"
            :class="{'w-4/5' : !linkText, 'fieldEmpty': !formInput.inputValue}"
        >
            {{ linkText }}
        </el-link>
    </div>
</template>

<script>
export default {
    name: 'DatePickerCell',
    props: {
        value: {
            type: [ Date, String ],
            default: () => {}
        },
        displayValue: {
            type: String,
            default: ''
        },
        cellId: {
            type: Number,
            default: null
        },
        editableComponent: {
            type: String,
            default: 'el-date-picker'
        },
        editActive: {
            type: Boolean,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            focused: false,
            formInput: {inputValue: ''},
            rules: {
                inputValue: [
                    { required: this.isRequired, message: 'Required field', trigger: 'blur' },
                    // { required: this.isRequired, message: 'Required field', trigger: 'change' },
                ],
            },
        }
    },
    mounted() {
        // if (this.isRequired) console.log('this is required', this.value)
        this.formInput.inputValue = this.value
    },
    methods: {
        cellClickHandler() {
            if (this.disabled) return

            this.focused = true
            this.$emit('edit-row', this.cellId)
        },
        validate(){
            if (this.$refs.formInput) return this.$refs.formInput.validate()
        },
        clearValidate(){
            return this.$refs.formInput.clearValidate()
        }
    },
    computed: {
        linkText(){
            let text =  this.displayValue || this.value
            // reject anything that's not a date
            if (text && text.constructor.name === 'Date') return ''
            return text || ''
        },
        renderEditableComponent() {
            if (this.editActive) return this.editableComponent
            return ''
        },
        renderForm() {
            if (this.editActive) return 'el-form'
            return ''
        },
    },
    watch: {
        value() {
            this.formInput.inputValue = this.value
        },
        'formInput.inputValue': function() {
            if (this.formInput.inputValue) {
                this.$emit('input', this.formInput.inputValue)
            }
        },
    },
}
</script>
