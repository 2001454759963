import Vue from 'vue'
import VueRouter from 'vue-router'
import PracticeList from '../views/PracticeList'
import Accreditation from '@/components/accreditation/Accreditation'
import Assessment from '@/components/accreditation/partials/Assessment'
import Documents from '@/components/documents/Documents'
import Staff from '@/components/staff/Staff'
import store from '@/store/index'
import { authOptions, getAccessToken } from '@/utils/auth/auth.helpers'

Vue.use(VueRouter)
// const isDev = process.env.NODE_ENV === 'development'
const isDev = false

const routes = [
    {
        path: '/',
        redirect: isDev ? '/Dashboard' : '/Login',
    },
    {
        path: '/redirect-from-backend',
        beforeEnter(to, from, next) {
            const token = JSON.stringify({
                access: to.query.access_token,
                refresh: to.query.refresh_token
            })
            Vue.auth.token(null, token, false)
            Vue.auth.check()
            location.replace(to.query.url)
        }
    },
    {
        path: '/login-from-backend',
        beforeEnter(to, from, next) {
            if (to.query.url) {
                store.commit('SET_ROUTE_TO', to.query.url)
            }

            if (Vue.auth.check()) {
                Vue.auth.logout()
                    .then(response => {
                        console.log('response from logout', response)
                    })
                    .catch(err => {
                        console.log('error logging user out', err)
                    })
            } else {
                Vue.router.push({ name: 'Login' })
            }
        }
    },
    {
        path: '/GoToWebinars/callback:code?',
        name: 'goto-callback',
        beforeEnter(to, from, next) {
            const code = to.query.code
            
            if (Vue.auth.check()) {
                store.dispatch('fetchStaticChoices')
                store.commit('SET_WEBINAR_LOGIN_CODE', code)
                store.dispatch('getGoToWebinarAccessToken')
    
                Vue.router.push({name: 'webinars'})
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/Login/:invitationToken?',
        name: 'Login',
        props: true,
        // chunking example, not necessary for
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        beforeEnter(to, from, next) {
            if (Vue.auth.check()) {
                if (to.params.invitationToken) {
                    Vue.auth.logout()
                        .then(response => {
                            Vue.router.push(to)
                        })
                        .catch(err => {
                            console.log('error logging user out', err)
                        })  
                } else {
                    Vue.router.push({ name: 'PracticeList' })
                }
            } else {
                next()
            }
        }
    },
    {
        path: '/signup/:invitationToken?',
        name: 'Signup',
        props: true,
        // chunking example, not necessary for
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        beforeEnter(to, from, next) {
            if (Vue.auth.check()) {
                    Vue.auth.logout()
                        .then(response => {
                            if (to.params.invitationToken) {
                                Vue.router.push(to)
                            }
                        })
                        .catch(err => {
                            console.log('error logging user out', err)
                        })
            } else {
                next()
            }
        }
    },
    {
        path: '/Reset',
        name: 'reset-password',
        // chunking example, not necessary for
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    },
    {
        path: '/Practice/Add',
        name: 'add-practice',
        component: () => import(/* webpackChunkName: "practice-details" */ '../views/PracticeDetails.vue'),
        meta: {
            // change to true to activate authorisation, also accepts an object
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                store.dispatch('fetchStaticChoices')
                next()
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/Practice/List',
        name: 'PracticeList',
        component: PracticeList,
        meta: {
            // change to true to activate authorisation, also accepts an object
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                store.dispatch('fetchStaticChoices')

                next()
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/PracticeOverview/:id',
        name: 'practice-overview',
        component: () => import(/* webpackChunkName: "practice-overview" */ '../views/Main.vue'),
        props: true,
        meta: {
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                // refreshing the token when backend redirects user from payment
                if (from.name == null && from.path === '/') {
                    Vue.auth.refresh().catch(() => {
                        console.log('failed in router index.js')
                    })
                }
                store.dispatch('fetchStaticChoices')
                store.dispatch('getPermissions', {practiceId: to.params.id})
                next()
            } else {
                // setRoute(to)
                next({name: 'Login'})
            }
        },
        children: [
            {
                path: '/PracticeOverview/:id/',
                redirect: '/PracticeOverview/:id/Staff'
            },
            {
                path: '/PracticeOverview/:id/Staff',
                name: 'Staff',
                component: Staff
            },
            {
                path: '/PracticeOverview/:id/Documents',
                name: 'Documents',
                component: Documents
            },
            {
                path: '/PracticeOverview/:id/Accreditation',
                name: 'Accreditation',
                component: Accreditation,
                props: true,
            },
            {
                path: '/PracticeOverview/:id/Accreditation/Assessment/:assessmentId/:indicatorSetId?/:indicatorId?',
                name: 'Assessment',
                component: Assessment,
                props: true,
            }
        ]
    },
    {
        path: '/Practice/Details/:id',
        name: 'edit-practice',
        component: () => import(/* webpackChunkName: "practice-details" */ '../views/PracticeDetails.vue'),
        meta: {
            // change to true to activate authorisation, also accepts an object
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                store.dispatch('fetchStaticChoices')
                next()
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/Practice/SurveyConfirmation/:id',
        name: 'practice-survey-confirmation',
        props: true,
        component: () => import('../views/SurveyConfirmation.vue'),
        meta: {
            // change to true to activate authorisation, also accepts an object
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                store.dispatch('fetchStaticChoices')

                next()
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/Practice/Reapplication/:id',
        name: 'practice-reapplication',
        props: {
            isReapplying: true
        },
        component: () => import(/* webpackChunkName: "practice-details" */ '../views/PracticeDetails.vue'),
        meta: {
            // change to true to activate authorisation, also accepts an object
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                store.dispatch('fetchStaticChoices')
                next()
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/Practice/Invite/:invitationToken',
        name: 'practice-invite',
        props: true,
        component: () => import('../views/PracticeInvitation.vue'),
        meta: {
            // change to true to activate authorisation, also accepts an object
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                store.dispatch('fetchStaticChoices')
                next()
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/AccountSettings/:id',
        name: 'account-settings',
        // chunking example, not necessary for
        component: () => import(/* webpackChunkName: "account-settings" */ '../views/AccountSettings.vue'),
        meta: {
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                if (from.name !== 'Login') {
                    store.commit('SET_ROUTE_FROM', from)
                }
                store.dispatch('fetchStaticChoices')

                next()
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/ChangePassword/',
        name: 'change-password',
        // chunking example, not necessary for
        component: () => import(/* webpackChunkName: "change-password" */ '../views/ChangePassword.vue'),
        meta: {
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                if (from.name !== 'Login') {
                    store.commit('SET_ROUTE_FROM', from)
                }
                store.dispatch('fetchStaticChoices')

                next()
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/Dashboard',
        name: 'dashboard',
        props: true,
        component: () => import('../views/Dashboard.vue'),
        meta: {
            // change to true to activate authorisation, also accepts an object
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                store.dispatch('fetchStaticChoices')
        
                next()
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/Factsheets',
        name: 'factsheets',
        props: true,
        component: () => import('../views/Factsheets.vue'),
        meta: {
            // change to true to activate authorisation, also accepts an object
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                store.dispatch('fetchStaticChoices')
        
                next()
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/Resources',
        name: 'resources',
        props: true,
        component: () => import('../views/Resources.vue'),
        meta: {
            // change to true to activate authorisation, also accepts an object
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                store.dispatch('fetchStaticChoices')
        
                next()
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/Webinars',
        name: 'webinars',
        props: true,
        component: () => import('../views/Webinars.vue'),
        meta: {
            // change to true to activate authorisation, also accepts an object
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                store.dispatch('fetchStaticChoices')
        
                next()
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/Reports/Comparative',
        name: 'Reports/Comparative',
        props: true,
        component: () => import('../views/Reports.vue'),
        meta: {
            // change to true to activate authorisation, also accepts an object
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                store.dispatch('fetchStaticChoices')
        
                next()
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/Reports/Management',
        name: 'Reports/Management',
        props: true,
        component: () => import('../views/ManagementReports.vue'),
        meta: {
            // change to true to activate authorisation, also accepts an object
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                store.dispatch('fetchStaticChoices')
        
                next()
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/Reports/Analytics',
        name: 'Reports/Analytics',
        props: true,
        component: () => import('../views/AnalyticsReports.vue'),
        meta: {
            // change to true to activate authorisation, also accepts an object
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                store.dispatch('fetchStaticChoices')
        
                next()
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/SurveyVisits/Planning',
        name: 'SurveyVisitPlanning',
        props: true,
        component: () => import('../views/SurveyVisitPlanning.vue'),
        meta: {
            // change to true to activate authorisation, also accepts an object
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                store.dispatch('fetchStaticChoices')
        
                next()
            } else {
                next({name: 'Login'})
            }
        }
    },
    {
        path: '/SurveyVisits/:id/PreferredDays',
        name: 'SurveyVisitPreferredDays',
        props: true,
        component: () => import('../views/PreferredDays.vue'),
        meta: {
            // change to true to activate authorisation, also accepts an object
            auth: authOptions
        },
        beforeEnter(to, from, next) {
            if (isDev || Vue.auth.check()) {
                store.dispatch('fetchStaticChoices')
        
                next()
            } else {
                next({name: 'Login'})
            }
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
