<template>
    <div class="search">
        <v-text-field
            :placeholder="placeholder"
            v-model="search"
            clearable
            data-cy="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
        >
        </v-text-field>
    </div>
</template>

<script>
export default {
    name: 'SearchComponent',
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Search ...'
        }
    },
    computed: {
        search: {
            get() {
                return this.value
            },
            set(search) {
                this.$emit('input', search)
            }
        }
    },
}
</script>
