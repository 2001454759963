<template>
    <div class="grid grid-cols-4 lg:grid-cols-6 gap-4">
        <div v-for="folder in folders" :key="`folder-${folder.id}`" class="p-4 text-center border border-grey-4 rounded items-center hover:bg-grey-8 cursor-pointer" @click="folderClickHandler(folder)">
            <div class="folder-icon">
                 <FolderSvg class="m-auto fill-current w-20" :style="{color: folder.color}" />
            </div>
            <div class="folder-name text-xs lg:text-sm">
                {{ folder.name }}
            </div>
        </div>
    </div>
</template>

<script>
import FolderSvg from '@/assets/icons/icons_folder.svg'

export default {
    name: 'FolderList',
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    components: { FolderSvg },
    data() {
        return {
            colors: []
        }
    },
    methods: {
        folderClickHandler(folder) {
            this.$emit('select', folder)
        }
    },
    computed: {
        folders() {
            return this.value || []
        }
    }
}
</script>
