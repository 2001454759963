<template>
    <div class="c-phone-number">
        <el-form
            :model="phoneNumberForm"
            :rules="validationRules"
            ref="phoneNumberForm"
            class="pd-form"
        >
            <el-form-item prop="phone" class="mb-0">
                <v-phone-input
                    ref="localPhone"
                    v-model="localPhone.phone"
                    default-country-code="AU"
                    :disabled="readonly"
                    @phone-number-blur="onPhoneBlur"
                    @update="updatePhone"
                />
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: 'PhoneNumber',
    props: {
        value: {
            type: Object,
            required: true,
            default: () =>  {
                return {
                    countryCode: '',
                    phone: '',
                    archived: false
                }
            }
        },
        required: {
            type: Boolean,
            default: true
        },
        validPhone: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            firstLoad: true,
            phone: '',
            isValid: false,
            defaultCountryCode: 'AU',
            phoneNumberForm: {
                countryCode: '',
                phone: '',
                archived: false
            },
            validationRules: {
                phone: [{ validator: this.validateInput, trigger: 'blur' }]
            },
            phoneRegex: /^\(?(?:\+?61|0)(?:(?:2\)?[ -]?(?:3[ -]?[38]|[46-9][ -]?[0-9]|5[ -]?[0-35-9])|3\)?(?:4[ -]?[0-57-9]|[57-9][ -]?[0-9]|6[ -]?[1-67])|7\)?[ -]?(?:[2-4][ -]?[0-9]|5[ -]?[2-7]|7[ -]?6)|8\)?[ -]?(?:5[ -]?[1-4]|6[ -]?[0-8]|[7-9][ -]?[0-9]))(?:[ -]?[0-9]){6}|4\)?[ -]?(?:(?:[01][ -]?[0-9]|2[ -]?[0-57-9]|3[ -]?[1-9]|4[ -]?[7-9]|5[ -]?[018])[ -]?[0-9]|3[ -]?0[ -]?[0-5])(?:[ -]?[0-9]){5})$/
        }
    },
    methods: {
        validate() {
            // validate returns a promise if we don't provide a callback
            return this.$refs.phoneNumberForm.validate()
        },
        validateInput(rule, value, cb) {
            // first we check to see if we just loaded and there's no validation on the phone number
            if (!this.isValid) {
                this.isValid = this.phoneRegex.test(this.localPhone.phone)
            }

            // first we check if we are adding or editing
            if (this.required) {
                if (!this.localPhone.phone) {
                    cb('This field is required')
                } else if (this.isValid !== undefined && this.isValid !== true) {
                    // if isValid is undefined, then the value would be from the backend and we don't need to do anything
                    cb('Invalid phone number format')
                } else {
                    cb()
                }
            } else if(this.validPhone) {
                if (this.isValid !== undefined && this.isValid !== true && this.localPhone.phone !==  '') {
                    // if isValid is undefined, then the value would be from the backend and we don't need to do anything
                    cb('Invalid phone number format')
                } else {
                    cb()
                }
            } else {
                cb()
            }
        },
        onPhoneBlur() {
            if (this.isValid) {
                this.$refs.phoneNumberForm.resetFields()
            } else {
                this.$refs.phoneNumberForm.validate()
            }
            this.$emit('blur', this.value)
        },
        updatePhone(e) {
            this.isValid = e.isValid
        }
    },
    computed: {
        localPhone: {
            get() {
                return this.value
            },
            set(localPhone) {
                this.$refs.phoneNumberForm.phone = localPhone.phone
                this.phoneNumberForm.phone = localPhone.phone
                this.$emit('input', localPhone)
            }
        },
    },
}
</script>
