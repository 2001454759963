<template>
    <div class="profile-display flex flex-row items-center">
        <!--Please display following for admin only, and open link in new tab-->
            <el-link
                v-if="isAdmin"
                type="primary"
                :underline="false"
                class="connectAdminLink uppercase font-bold bg-primary-lightest rounded px-2 y-4 text-sm inline-block mr-6"
                data-cy="addStaff"
                target="_blank"
                @click.prevent="goToAdmin"
            >
                <fa-icon icon="external-link-alt" class="mr-1" />Connect Admin
            </el-link>
        <!--end-->

        <el-dropdown data-cy="profileDropdown" trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
                <i class="el-icon-arrow-down el-icon--right float-right pt-2"></i>
                <div class="inline-block relative float-right pt-1">
                    <p class="text-sm text-grey-2">{{ name }}</p>
                </div>
                <ProfileAvatar :user-profile="userProfile" class="w-8 h-8 mr-3 inline-block relative float-right" />
            </span>
            <el-dropdown-menu slot="dropdown" class="profile-display-menu">
                <el-dropdown-item
                    v-if="hasSinglePractice"
                    command="add-practice"
                    data-cy="addPracticeDropdownItem"
                >
                    <fa-icon icon="plus" class="text-grey-2 mr-2"/><span class="text-grey-2">Add practice</span>
                </el-dropdown-item>
                <el-dropdown-item
                    v-if="isSurveyor"
                    command="survey-offers"
                    data-cy="surveyOffersDropdownItem"
                >
                    <fa-icon icon="exclamation-circle" class="text-grey-2 mr-2" /><span class="text-grey-2">Survey Offers</span>
                </el-dropdown-item>
                <el-dropdown-item
                    command="account-settings"
                    data-cy="accountSettingsDropdownItem"
                >
                    <fa-icon icon="cog" class="text-grey-2 mr-2" /><span class="text-grey-2">Account Settings</span>
                </el-dropdown-item>
                <el-dropdown-item
                    command="change-password"
                    data-cy="changePasswordDropdownItem"
                >
                    <fa-icon icon="lock" class="text-grey-2 mr-2" /><span class="text-grey-2">Change Password</span>
                </el-dropdown-item>
                <el-dropdown-item
                    command="logout"
                    divided
                    data-cy="logoutDropdownItem"
                >
                    <fa-icon icon="sign-out-alt" class="text-grey-2 mr-2" /><span class="text-grey-2">Sign out</span>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
import { ProfileMixin } from '@/components/shared/mixins/profileFns'
import {AuthMixin, AuthUtilities} from '@/components/shared/mixins/authHelpers'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import ProfileAvatar from '@/components/partials/ProfileAvatar'

export default {
    name: 'ProfileDisplay',
    components: { ProfileAvatar },
    mixins: [ProfileMixin, AuthMixin, BackendHelpers, HttpMixin],
    data() {
        return {
            user: {},
            backendOffersUrl: 'offer/surveyor/',
            backendAdminUrl: 'admin-mnqa/'
        }
    },
    computed: {
        name() {
            return this.$auth.user() != null ? this.$auth.user().name : ''
        },
        hasSinglePractice() {
            return this.$auth.user() ? this.$auth.user()['singlePractice'] || false : false
        }
    },
    methods: {
        handleCommand(command) {
            switch (command) {
                case 'add-practice':
                    this.addPractice()
                    break
                case 'survey-offers':
                    this.goToSurveyOffers()
                    break
                case 'account-settings':
                    this.goToAccountSettings()
                    break
                case 'change-password':
                    this.goToChangePassword()
                    break
                case 'logout':
                    this.logoutUser()
                    break
            }
        },
        logoutUser() {
            this.$auth.logout()
                .then(response => {
                    console.log('response from logout', response)
                    this.$apollo.provider.defaultClient.resetStore()
                    AuthUtilities.clearAuthCookies.apply(this)
                })
                .catch(err => {
                    console.log('error logging user out', err)
                })
        },
        addPractice() {
            this.$router.push({name: 'add-practice'})
        },
        goToAccountSettings() {
            this.$router.push({name: 'account-settings', params: {id: this.$auth.user().userId}})
        },
        goToChangePassword() {
            this.$router.push({name: 'change-password'})
        },
        goToSurveyOffers() {
            const url = `${process.env.VUE_APP_DJANGO_BASE_URL}/${this.backendOffersUrl}`
            this.goToBackendUrl(url)
            console.log('go to survey offers in backend if we are a surveyor')
        },
    },
}
</script>
