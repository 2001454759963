<template>
    <div class="flex">
        <el-button
            v-if="showAutoCompleteSurvey"
            class="font-bold uppercase border-transparent text-primary-light hover:text-primary text-sm"
            @click.prevent="autoCompleteClickHandler"
        >
            <fa-icon icon="check" class="mr-1"/>
            Auto complete
        </el-button>
        <el-button
            v-if="showAutoClearSurvey"
            class="font-bold uppercase border-transparent text-primary-light hover:text-primary text-sm"
            data-cy="autoClearButton"
            @click.prevent="autoClearClickHandler"
        >
            <fa-icon icon="ban" class="mr-1"/>
            Auto clear
        </el-button>
        <el-button
            v-if="showAutoClearSurvey"
            class="font-bold uppercase border-transparent text-primary-light hover:text-primary text-sm"
            data-cy="autoReopenButton"
            @mouseover.native="isHovering = true"
            @mouseout.native="isHovering = false"
            @click.prevent="reopenClickHandler"
        >
            <fa-icon :icon="reopenIcon" class="mr-1"/>
            Reopen
        </el-button>
        <el-button
            v-if="showRequestAssistanceButton"
            class="font-bold uppercase border-transparent text-primary-light hover:text-primary text-sm"
            @click.prevent="assistanceClickHandler"
        >
            <fa-icon icon="question-circle" class="mr-1"/>
            Request Assistance
        </el-button>
        <el-button
            v-if="showReportButton"
            class="font-bold uppercase border-transparent text-primary-light hover:text-primary text-sm"
            @click.prevent="reportClickHandler"
        >
            <fa-icon icon="download" class="mr-1"/>
            {{ isSelfAssess ? 'Report' : 'Survey Visit Report' }}
        </el-button>
        <el-button
            v-if="showActionPlanButton"
            class="font-bold uppercase text-primary-light border-transparent hover:text-primary text-sm"
            @click.prevent="actionPlanClickHandler"
        >
            <fa-icon icon="download" class="mr-1"/>
            Action plan
        </el-button>
        <el-button
            v-if="!isSelfAssess && showEditSummaryButton"
            class="border-transparent font-bold uppercase text-primary-light hover:text-primary text-sm"
            @click.prevent="editSummaryHandler"
        >
            <fa-icon icon="pencil-alt" class="mr-1"/>
            Edit summary
        </el-button>
        <el-button
            v-if="showIssueCertificateButton"
            type="primary"
            class="uppercase rounded text-sm font-bold text-white border-risk-matrix-green-dark bg-risk-matrix-green-dark hover:bg-risk-matrix-green-light hover:border-risk-matrix-green-light "
            @click.prevent="issueCertHandler"
        >
            Issue certificate
        </el-button>
        <el-button
            v-if="!isSelfAssess && showActionRequiredButton"
            type="danger"
            class="font-bold uppercase bg-red rounded text-sm text-white"
            @click.prevent="createPlanHandler"
        >
            Action required
        </el-button>
        <v-btn
            v-if="showReviewButton"
            depressed
            :ripple="false"
            class="font-bold uppercase rounded text-sm text-white mx-2 py-5"
            color="warning"
            @click.prevent="reviewButtonClickHandler"
        >
            Reviewed
        </v-btn>
        <el-button
            v-if="!isSelfAssess && showApproveButton"
            class="font-bold uppercase rounded text-sm text-white"
            type="success"
            @click.prevent="approveButtonClickHandler"
        >
            Approve
        </el-button>
        <el-button
            v-if="showSubmitForReviewButton"
            type="success"
            class="font-bold uppercase rounded text-sm text-white"
            data-cy="submitForReviewButton"
            @click.prevent="submitForReviewClickHandler"
        >
            Submit for review
        </el-button>
        <el-button
            v-if="showCompletedButton"
            type="primary"
            class="uppercase bg-primary-light rounded mr-4 text-sm text-white"
            @click.prevent="completedHandler"
        >
            Completed
        </el-button>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import generalComments from '@/components/accreditation/mixins/generalComments'
import FileMethods from '@/components/shared/mixins/fileMethods'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import AssessmentPermissions from '@/components/accreditation/mixins/assessmentPermissions'
import { AuthMixin } from '@/components/shared/mixins/authHelpers'
import snakecaseKeys from 'snakecase-keys'
import { DEBUG, IS_NOT_PROD } from '@/config/constants'
import { computed, onMounted } from '@vue/composition-api'
import { useAssessmentStore } from '@/stores/useAssessmentStore'
import { useShowModalStore } from '@/stores/useShowModalStore'

export default {
    name: 'ActionsPanel',
    mixins: [generalComments, FileMethods, MessageDialog, AssessmentPermissions, AuthMixin],
    props: {
        assessmentData: {
            type: Object,
            default: () => {}
        },
        isSelfAssess: {
            type: Boolean,
            default: null
        },
        validationObj: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            isHovering: false,
            submitSelfAsHtml: '<p>You can only submit the assessment <strong>once for review</strong>.<br>Please ensure all indicators and documents have been completed for your Quality Accreditation Manager to review.<br>Are you sure you would like to submit it now?</p>',
            submitReviewHtml: '<p>You can only submit the assessment <strong>once for review.</strong><br><strong>Please ensure you have finalised the surveyor\'s summary</strong><br>Are you sure you would like to submit it now?</p>',
            completedHtml: '<p>This will mark the assessment as complete and notify the practice to read feedback.<br><strong>Please ensure you have finalised all comments</strong></p>',
            actionPlanHtml: '<p>This will set the assessment as <strong>Action required</strong><br>and create an Action plan view for practice users.</p><p>The assessment can only be set as <strong>Action required</strong> if all indicators marked \'Not met\' have an action plan entered.<p></p><strong>Please ensure you have filled in all these fields.</strong></p>',
            assistanceHtml: "<p>Questions about an indicator? </p><p>Please contact your <strong>Quality Accreditation Manager (QAM)</strong> for a tailored response or the QPA team on <strong><span class='text-blue-500'>02 6944 4042</span></strong>."
        }
    },
    mounted() {
        // self assessment actions
        this.$root.$on('assessment-action', val => { this.actionsMutationCall(val) })
        // survey submit
        this.$root.$on('submit-survey', this.submitSurvey)
        // certfiy
        this.$root.$on('certify-assessment', val => { this.certifyMutationCall(val) })
    },
    methods: {
        assistanceClickHandler(e) {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                body: this.assistanceHtml,
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalRequestAssistance')
        },
        reportClickHandler(e) {
            // commit action
            let url = `web.assessment/${this.assessmentData.id}/generate_assessment_report/`
            this.downloadRemoteFileHandler('report', url, 'GET')
        },
        actionPlanClickHandler(e) {
            // console.log('actionPlanClickHandler e: ', e)
            // commit action
            let url = `web.assessment/${this.assessmentData.id}/generate_action_plan_report/`
            this.downloadRemoteFileHandler('action_plan', url, 'GET')
        },
        editSummaryHandler(e) {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                editMode: true,
                assessmentId: this.assessmentData.id,
                assessmentData: this.assessmentData
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalSummary')
        },
        approveButtonClickHandler(e) {
            if (this.status === 'ACTION_REQUIRED') {
                this.approveActionRequiredHandler(e)
            } else {
                this.approveHandler(e)
            }
        },
        autoCompleteClickHandler(e) {
            this.$emit('complete-survey', true)
        },
        autoClearClickHandler(e) {
            this.$emit('clear-survey', true)
        },
        // Survey assessment modals
        approveHandler(e) {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalApproveCert')
        },
        approveActionRequiredHandler(e) {
            if (this.validationObj.openNonConformities.length > 0) {
                this.$store.commit('MODAL_IS_VISIBLE', true)
                this.$store.commit('MODAL_DATA', {
                    openNonConformities: this.validationObj.openNonConformities
                })
                this.$store.commit('MODAL_COMPONENT_NAME', 'ModalValidation')
            } else {
                this.$store.commit('MODAL_IS_VISIBLE', true)
                this.$store.commit('MODAL_COMPONENT_NAME', 'ModalApproveCert')
            }
        },
        issueCertHandler(e) {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                assessmentId: this.assessmentData.id,
                userId: this.userId
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalCertificate')
        },
        submitForReviewClickHandler(e) {
            if (this.isSelfAssessment) {
                this.submitSelfAsHandler(e)
            } else {
                this.submitSurveyHandler(e)
            }
        },
        submitSurveyHandler(e) {
            // console.log('validationObj 1', this.validationObj)

            // validate responses generalComments
            if (
                !this.validationObj.invalidResponses.length &&
                !this.validationObj.generalComments.length
            ) {
                if (this.summaries) {
                    const surveyVisitor = this.assessmentData.surveyVisit.surveyVisitors.find(({user}) => user?.id === this.userId)
                    if (surveyVisitor) {
                        this.$store.commit('MODAL_IS_VISIBLE', true)
                        this.$store.commit('MODAL_DATA', {
                            surveyVisit: this.assessmentData.surveyVisit,
                            surveyVisitor
                        })
                        this.$store.commit('MODAL_COMPONENT_NAME', 'ModalSurveySubmit')

                        return
                    }

                    this.$store.commit('MODAL_IS_VISIBLE', true)
                    this.$store.commit('MODAL_DATA', {
                        body: this.submitReviewHtml,
                        type: 'submit'
                    })
                    this.$store.commit('MODAL_COMPONENT_NAME', 'ModalSubmit')
                } else {
                    this.$store.commit('MODAL_IS_VISIBLE', true)
                    this.$store.commit('MODAL_DATA', {
                        editMode: true,
                        assessmentId: this.assessmentData.id,
                        warning: '* This field is required before submission',
                        assessmentData: this.assessmentData
                    })
                    this.$store.commit('MODAL_COMPONENT_NAME', 'ModalSummary')
                }
            } else {
                this.$store.commit('MODAL_IS_VISIBLE', true)
                this.$store.commit('MODAL_DATA', {
                    validationList: this.validationObj.invalidResponses,
                    generalComments: this.validationObj.generalComments
                })
                this.$store.commit('MODAL_COMPONENT_NAME', 'ModalValidation')
            }
        },
        reopenClickHandler(e) {
            this.reopenSurveyHandler(e)
        },
        reopenSurveyHandler(e) {
            // calling to re-open the survey
            // TODO: need to rename this in the backend
            this.actionsMutationCall('reopen')
        },
        createPlanHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                heading: 'Action Required',
                body: this.actionPlanHtml,
                submitText: 'Create Action Plan',
                type: 'create_action_plan'
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalSubmit')
        },
        // Self assessment modals
        submitSelfAsHandler(e) {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                body: this.submitSelfAsHtml,
                type: 'submit'
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalSubmit')
        },
        completedHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                heading: 'Complete self-assessment',
                body: this.completedHtml,
                submitText: 'Complete self-assessment',
                type: 'complete'
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalSubmit')
        },
        // Survey & Self assessment mutation
        actionsMutationCall(type) {
            // console.log('actionsMutationCall(type) 1', type)
            let assessmentId = this.assessmentData.id

            this.$http({
                method: 'POST',
                url: `web.assessment/${assessmentId}/${type}/`
            }).then(res => {
                // console.log('actionsMutationCall(type) 2', res)
                this.callRefetch()
            }).catch(err => {
                console.error('Error in Actionspanel.vue > actionsMutationCall(): ', err)
            })
        },
        submitSurvey(surveyVisitor) {
            let assessmentId = this.assessmentData.id
            
            const data = snakecaseKeys(surveyVisitor, {deep: true})
            this.$http({
                method: 'POST',
                url: `web.assessment/${assessmentId}/submit/`,
                data
            }).then(res => {
                this.callRefetch()
            }).catch(err => {
                console.error('Error in ActionsPanel.vue > submitSurvey(): ', err)
            })
        },
        certifyMutationCall(obj) {
            // console.log('this.certifyMutationCall()', obj)
            let assessmentId = this.assessmentData.id
            const payload = {
                'certification_date': obj.date || ''
            }

            this.$http({
                method: 'POST',
                url: `web.assessment/${assessmentId}/certify/`,
                data: payload
            }).then(res => {
                this.callRefetch()
            }).catch(err => {
                console.error('Error in Actionspanel.vue > certifyMutationCall(): ', err)
            })
        },
        callRefetch() {
            // console.log('callRefetch()')
            this.$root.$emit('refetch-assessment')
        },
    },
    computed: {
        ...mapState({
            summaryData: state => state.app.modal.componentData,
            permissions: state => state.app.permissions.assessment
        }),
        status() { return this.assessmentData.status || null },
        surveyorAsDisabled() {
            let permission
            switch(this.status) {
                case 'UNDER_REVIEW':
                    permission = this.permissions.changeSurveyUnderReview
                    break
                case 'IN_PROGRESS':
                    permission = this.permissions.changeSurveyInProgress
                    break
                case 'PARTIALLY_SUBMITTED':
                    permission = this.permissions.changeSurveyInProgress && !this.hasSubmitted
                    break
                case 'APPROVED':
                    permission = false
                    break
                case 'ACTION_REQUIRED':
                    permission = this.permissions.changeActionPlan
                    break
            }
            return !permission
        },
        isSurveyView() {
            if(!this.isSelfAssess) {
                return this.status === 'ACTION_REQUIRED' || this.status === 'APPROVED'
            }
            return false
        },
        issueCertIsVisible() {
            return this.permissions.certifySurvey && this.status === 'APPROVED'
        },
        completeButtonPermissions() {
            return this.permissions.changeSelfAssessmentUnderReview
        },
        hasSubmitted() {
            if (!this.assessmentData.submissions) return false
            const keys = Object.keys(this.assessmentData.submissions)
            return keys.length !== 0 && keys.indexOf(this.userId.toString()) > -1
        },
        showRequestAssistanceButton() {
            return this.canChangeSurvey && this.isSelfAssessment
        },
        showReportButton() {
            return !(this.status === 'PARTIAL_SUBMITTED' || (this.status === 'IN_PROGRESS' && this.isSurveyVisit))
        },
        showActionPlanButton() {
            return this.isSurveyVisit && (
                this.status === 'CERTIFIED' || this.status === 'APPROVED' || this.status === 'ACTION_REQUIRED' || this.status === 'UNDER_REVIEW'
            )
        },
        showActionRequiredButton() {
            return this.canChangeSurvey && this.isSurveyVisit && this.status === 'UNDER_REVIEW'
        },
        showIssueCertificateButton() {
            return this.canChangeSurvey && this.isSurveyVisit && this.status === 'APPROVED'
        },
        showEditSummaryButton() {
            // console.log('showing edit summary button', this.canChangeSurvey && this.isSurveyVisit && (this.status === 'IN_PROGRESS' || this.status === 'PARTIALLY_SUBMITTED' || this.status === 'UNDER_REVIEW'))
            return this.canChangeSurvey && this.isSurveyVisit && this.surveyInProgress && !this.hasSubmitted
        },
        showSubmitForReviewButton() {
            return this.canChangeSurvey &&
                (
                    (this.isSelfAssessment && this.status === 'IN_PROGRESS') ||
                    (this.isSurveyVisit && (this.status === 'IN_PROGRESS' || (this.status === 'PARTIALLY_SUBMITTED' && !this.hasSubmitted)))
                )
        },
        showCompletedButton() {
            return this.canChangeSurvey && this.isSelfAssessment && this.status === 'UNDER_REVIEW'
        },
        showAutoCompleteSurvey() {
            // using the same permissions as the show edit summary button since it should be a similar situation when we need to complete the survey for testing purposes
            return DEBUG && IS_NOT_PROD && this.surveyInProgress || this.hasSpecialAccess
        },
        showAutoClearSurvey() {
            // using the same permissions as the show edit summary button since it should be a similar situation when we need to complete the survey for testing purposes
            return DEBUG && IS_NOT_PROD && (this.surveyInProgress || this.hasSpecialAccess)
        },
        surveyInProgress() {
            return this.status === 'IN_PROGRESS' || this.status === 'PARTIALLY_SUBMITTED' || this.status === 'UNDER_REVIEW'
        },
        reopenIcon() {
            return !this.isHovering ? 'lock' : 'lock-open'
        }
    },
    beforeDestroy() {
        this.$root.$off('assessment-action')
        this.$root.$off('submit-survey')
        this.$root.$off('refetch-assessment')
    },
    setup(props) {
        const showModalStore = useShowModalStore()
        const { showModal } = showModalStore
        const assessmentStore = useAssessmentStore()
        const { updateAssessment } = assessmentStore

        const showReviewButton = computed(() => {
            return assessmentStore?.canChangeSurvey
                && assessmentStore?.isSurveyVisit
                && assessmentStore?.status === 'ACTION_REQUIRED'
        })
        const showApproveButton = computed(() => {
            return assessmentStore?.canChangeSurvey
                && assessmentStore?.isSurveyVisit
                && (assessmentStore?.status === 'UNDER_REVIEW' || assessmentStore?.status === 'ACTION_REQUIRED')
        })

        const reviewButtonClickHandler = () => {
            showModal('ModalReviewAssessment', {})
        }

        onMounted(() => {
            updateAssessment(props.assessmentData)
        })

        return { showReviewButton, showApproveButton, reviewButtonClickHandler }
    }
}
</script>
