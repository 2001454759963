export default {
    loggedIn: true,
    loginEmail: '',
    staffTotal: 0,
    isStaffFirstView: true,
    isDocumentsFirstView: true,
    isAccreditationFirstView: true,
    docsTotal: 0,
    practiceId: '',
    staticChoices: {},
    listFilter: {
        limit: 25,
        offset: 0,
        pageSize: 25,
        currentPage: 0,
        searchVal: '',
        columnFilters: {}
    },
    modal: {
        isVisible: false,
        componentName: 'ModalDefault',
        componentData: {},
    },
    permissions: {},
    routeTo: {},
    routeFrom: {},
    webinarLoginCode: '',
    webinarAccessObject: {},
    folders: [],
    getVisibleFolders: true
}
