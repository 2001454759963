import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-staff__table editable_table flex flex-col"},[_c('div',{staticClass:"w-full my-4 flex flex-row justify-start"},[_c('el-input',{staticClass:"mr-4 max-w-1/4",attrs:{"placeholder":"Search","data-cy":"searchValue"},model:{value:(_vm.filterSearchValue),callback:function ($$v) {_vm.filterSearchValue=$$v},expression:"filterSearchValue"}},[_c('i',{staticClass:"el-input__icon el-icon-search",attrs:{"slot":"prefix"},slot:"prefix"})])],1),_c(VDataTable,{ref:"staffTableRef",staticClass:"c-table recordsWrapper",attrs:{"single-select":"","item-key":"id","headers":_vm.headers,"items":_vm.tableDataFiltered,"items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"data-cy":"staffTable"},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"click:row":_vm.rowClickHandler},scopedSlots:_vm._u([{key:"item.verified",fn:function(ref){
var item = ref.item;
return [_c('checkbox-cell',{ref:("verified" + (item.id)),attrs:{"disabled":!_vm.canVerifyStaff,"edit-active":item.active,"cell-id":item.id,"isRequired":true,"enforceValid":true},on:{"edit-row":_vm.toggleEdit},model:{value:(item.verified),callback:function ($$v) {_vm.$set(item, "verified", $$v)},expression:"item.verified"}})]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('select-cell',{ref:("title" + (item.id)),attrs:{"edit-active":item.active,"cell-id":item.id,"display-value":_vm.getDisplayValue(item.title, _vm.titles),"isRequired":true,"items":_vm.titles},on:{"edit-row":_vm.toggleEdit},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_c('editable-cell',{ref:("firstName" + (item.id)),attrs:{"edit-active":item.active,"cell-id":item.id,"isRequired":true},on:{"edit-row":_vm.toggleEdit},model:{value:(item.firstName),callback:function ($$v) {_vm.$set(item, "firstName", $$v)},expression:"item.firstName"}})]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_c('editable-cell',{ref:("lastName" + (item.id)),attrs:{"edit-active":item.active,"cell-id":item.id,"isRequired":true},on:{"edit-row":_vm.toggleEdit},model:{value:(item.lastName),callback:function ($$v) {_vm.$set(item, "lastName", $$v)},expression:"item.lastName"}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('select-cell',{ref:("type" + (item.id)),attrs:{"edit-active":item.active,"cell-id":item.id,"display-value":_vm.getDisplayValue(item.type, _vm.staffTypes),"isRequired":true,"items":_vm.staffTypes},on:{"edit-row":_vm.toggleEdit},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}})]}},{key:"header.reg",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('el-popover',{attrs:{"placement":"top-start","title":"APHRA & National Board registration number","width":"200","trigger":"hover"}},[_c('a',{attrs:{"href":"https://www.ahpra.gov.au","target":"_blank"}},[_vm._v("https://www.ahpra.gov.au")]),_c('fa-icon',{staticClass:"grey-1 ml-2",attrs:{"slot":"reference","icon":"question-circle"},slot:"reference"})],1)]}},{key:"item.reg",fn:function(ref){
var item = ref.item;
return [(!_vm.isPracticeStaff(item, ['support', 'other']))?_c('autocomplete-cell',{ref:("reg" + (item.id)),attrs:{"edit-active":item.active,"cell-id":item.id,"isRequired":_vm.isPracticeStaff(item, ['gp', 'nurse']),"fetchSuggestions":_vm.querySearch,"getDropdownDisplay":_vm.getDropdownDisplay},on:{"edit-row":_vm.toggleEdit,"select":_vm.handleRegistrationSelect},model:{value:(item.registrationNum),callback:function ($$v) {_vm.$set(item, "registrationNum", $$v)},expression:"item.registrationNum"}}):_vm._e()]}},{key:"item.employment",fn:function(ref){
var item = ref.item;
return [_c('select-cell',{ref:("employment" + (item.id)),attrs:{"edit-active":item.active,"cell-id":item.id,"display-value":_vm.getDisplayValue(item.employmentType, _vm.employmentTypes),"isRequired":true,"items":_vm.employmentTypes},on:{"edit-row":_vm.toggleEdit},model:{value:(item.employmentType),callback:function ($$v) {_vm.$set(item, "employmentType", $$v)},expression:"item.employmentType"}})]}},{key:"header.startDate",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('el-popover',{attrs:{"placement":"top-start","title":"Employment start date","width":"200","trigger":"hover","content":"This information is required by the safety and quality commission"}},[_c('fa-icon',{staticClass:"grey-1 ml-2",attrs:{"slot":"reference","icon":"question-circle"},slot:"reference"})],1)]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('date-picker-cell',{ref:("startDate" + (item.id)),attrs:{"edit-active":item.active,"cell-id":item.id,"display-value":_vm.getDateDisplayValue(item.startDate),"isRequired":true},on:{"edit-row":_vm.toggleEdit},model:{value:(item.startDate),callback:function ($$v) {_vm.$set(item, "startDate", $$v)},expression:"item.startDate"}})]}},{key:"item.employmentStatus",fn:function(ref){
var item = ref.item;
return [_c('select-cell',{ref:("employmentStatus" + (item.id)),attrs:{"edit-active":item.active,"cell-id":item.id,"display-value":_vm.getDisplayValue(item.employmentStatus, _vm.employmentStatus),"isRequired":true,"items":_vm.employmentStatus},on:{"edit-row":_vm.toggleEdit},model:{value:(item.employmentStatus),callback:function ($$v) {_vm.$set(item, "employmentStatus", $$v)},expression:"item.employmentStatus"}})]}},{key:"item.resignationDate",fn:function(ref){
var item = ref.item;
return [(item.employmentStatus && item.employmentStatus.toLowerCase() === 'inactive')?_c('date-picker-cell',{ref:("resignationDate" + (item.id)),attrs:{"isRequired":item.employmentStatus && item.employmentStatus.toLowerCase() === 'inactive',"edit-active":item.active,"cell-id":item.id,"display-value":_vm.getDateDisplayValue(item.resignationDate)},on:{"edit-row":_vm.toggleEdit},model:{value:(item.resignationDate),callback:function ($$v) {_vm.$set(item, "resignationDate", $$v)},expression:"item.resignationDate"}}):_vm._e()]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('editable-cell',{ref:("email" + (item.id)),staticClass:"w-48",attrs:{"edit-active":item.active,"cell-id":item.id,"isRequired":true},on:{"edit-row":_vm.toggleEdit},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}},[_c('template',{slot:"text"},[_vm._v(" "+_vm._s(item.email)+" "),_c('span',{staticClass:"email-invitation-status"},[(item.user && item.user.isActive && item.invite && item.invite.status === 'ACCEPTED')?_c('el-tooltip',{attrs:{"effect":"dark","content":"Staff has an active Connect account.","placement":"top"}},[_c('i',{staticClass:"el-icon-check"})]):_vm._e(),(item.invite && item.invite.status === 'REJECTED')?_c('el-tooltip',{attrs:{"effect":"dark","content":"Staff has rejected their Connect invite.","placement":"top"}},[_c('i',{staticClass:"el-icon-close"})]):_vm._e(),(item.user && !item.user.isActive)?_c('el-tooltip',{attrs:{"effect":"dark","content":"Staff has deactivated their Connect account.","placement":"top"}},[_c('i',{staticClass:"el-icon-close"})]):_vm._e()],1)])],2)]}},{key:"item.vrStatus",fn:function(ref){
var item = ref.item;
return [(_vm.isPracticeStaff(item, ['gp']))?_c('select-cell',{ref:("vrStatus" + (item.id)),attrs:{"edit-active":item.active,"cell-id":item.id,"display-value":_vm.getDisplayValue(item.vrStatus, _vm.vrStatuses),"isRequired":true,"items":_vm.vrStatuses},on:{"edit-row":_vm.toggleEdit},model:{value:(item.vrStatus),callback:function ($$v) {_vm.$set(item, "vrStatus", $$v)},expression:"item.vrStatus"}}):_vm._e()]}},{key:"item.hoursPerWeek",fn:function(ref){
var item = ref.item;
return [_c('number-input-cell',{ref:("hoursPerWeek" + (item.id)),attrs:{"edit-active":item.active,"cell-id":item.id,"isRequired":true},on:{"edit-row":_vm.toggleEdit},model:{value:(item.hoursPerWeek),callback:function ($$v) {_vm.$set(item, "hoursPerWeek", $$v)},expression:"item.hoursPerWeek"}})]}},{key:"item.cprExpiry",fn:function(ref){
var item = ref.item;
return [_c('DatePickerCell',{ref:("cprExpiry" + (item.id)),attrs:{"edit-active":item.active,"cell-id":item.id,"display-value":_vm.getDateDisplayValue(item.cprExpiry),"isRequired":true},on:{"edit-row":_vm.toggleEdit},model:{value:(item.cprExpiry),callback:function ($$v) {_vm.$set(item, "cprExpiry", $$v)},expression:"item.cprExpiry"}})]}},{key:"item.cpdEvidence",fn:function(ref){
var item = ref.item;
return [(!item.isNew)?_c('file-upload-cell',{attrs:{"item":item,"data-cy":"cpdEvidence","document-key":"cpdEvidence","document-type":"cpd","folderId":_vm.documentFolderId},on:{"upload-document":_vm.uploadClickHandler,"delete-document":_vm.deleteDocumentHandler}}):_vm._e()]}},{key:"item.qicpd",fn:function(ref){
var item = ref.item;
return [(_vm.isPracticeStaff(item, ['gp']))?_c('editable-cell',{ref:("qicpd" + (item.id)),attrs:{"edit-active":item.active,"cell-id":item.id,"isRequired":true},on:{"edit-row":_vm.toggleEdit},model:{value:(item.qicpd),callback:function ($$v) {_vm.$set(item, "qicpd", $$v)},expression:"item.qicpd"}}):_vm._e()]}},{key:"item.trainingEvidence",fn:function(ref){
var item = ref.item;
return [(!item.isNew)?_c('file-upload-cell',{class:{'long-last-column': item.active || (_vm.isStaffInvitable(item) && item.email)},attrs:{"item":item,"document-key":"trainingEvidence","document-type":"training","folderId":_vm.documentFolderId},on:{"upload-document":_vm.uploadClickHandler,"delete-document":_vm.deleteDocumentHandler}}):_vm._e()]}},{key:"item.editRecord",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"c-staff__table__edit-record ml-2 border-l border-grey-5",class:{ 'active-tr': item.active}},[_c('div',{staticClass:"float-right align-middle"},[(_vm.isStaffInvitable(item))?_c('a',{directives:[{name:"show",rawName:"v-show",value:(item.email),expression:"item.email"}],staticClass:"text-green hover:text-primary mx-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.sendInviteHandler(item)}}},[_c('i',{staticClass:"el-icon-message p-1"}),_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(item.invite && item.invite.status === 'INVITED' ? 'RE-INVITE' : 'INVITE'))])]):_vm._e(),(!item.isNew)?_c('a',{staticClass:"mx-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteHandler(item)}}},[_c('i',{staticClass:"el-icon-delete hover:text-red",attrs:{"data-cy":"deleteRecord"}})]):_vm._e(),(item.active)?_c('a',{staticClass:"mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.cancelHandler.apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-close text-lg text-grey-1 hover:text-orange",attrs:{"data-cy":"cancelEdit"}})]):_vm._e(),(item.active)?_c('a',{staticClass:"mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.saveHandler.apply(null, arguments)}}},[_c('i',{staticClass:"el-icon-check text-lg text-grey-1 hover:text-green",attrs:{"data-cy":"saveRecord"}})]):_vm._e()])])]}}],null,true)}),_c('AddUserRow',{on:{"add-user":_vm.addUser}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }