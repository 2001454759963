import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api'

export const useShowModalStore = defineStore('show-modal-store', () => {
    const isVisible = ref(false)
    const data = ref({})
    const componentName = ref('')
    
    const computedIsVisible = computed(() => isVisible.value)
    const computedData = computed(() => data.value)
    const computedComponentName = computed(() => componentName.value)
    
    const updateIsVisible = (newValue) => {
        isVisible.value = newValue
    }
    
    const updateData = (value) => {
        data.value = { ...(value || {})}
    }
    
    const updateComponentName = (value) => {
        componentName.value = value || ''
    }
    
    const showModal = (newComponentName, data) => {
        updateComponentName(newComponentName)
        updateData(data)
        updateIsVisible(true)
    }
    const hideModal = () => {
        updateData()
        updateComponentName()
        updateIsVisible(false)
    }
    
    return { computedData, computedIsVisible, computedComponentName, updateComponentName, updateIsVisible, updateData, showModal, hideModal }
})
