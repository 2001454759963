import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            permissions: state => state.app.permissions.assessment
        }),
        canChangeSurvey() {
            let result = false
            // if we don't have the status of the assessment, return false
            // this should be on the 'this' object as this.status
            if (!this.status) return result
            switch(this.status) {
                case 'IN_PROGRESS':
                    result = this.isSelfAssessment ||
                        (this.isSurveyVisit && this.permissions?.changeSurveyInProgress)
                    break
                case 'UNDER_REVIEW':
                    result = this.isSelfAssessment && this.permissions?.changeSelfAssessmentUnderReview ||
                        (this.isSurveyVisit && this.permissions?.changeSurveyUnderReview)
                    break
                case 'COMPLETED':
                    result = this.isSelfAssessment
                    break
                case 'ACTION_REQUIRED':
                    result = this.isSurveyVisit && this.permissions?.changeActionPlan
                    break
                case 'PARTIALLY_SUBMITTED':
                    result = this.isSurveyVisit && (this.permissions?.changeSurveyInProgress || this.permissions?.changeOtherSurveyorSummary)
                    break
                case 'APPROVED':
                    result = this.isSurveyVisit && this.permissions?.certifySurvey
                    break
                case 'CERTIFIED':
                    result = this.isSurveyVisit
                    break
                default:
                    break
            }
            return result
        },
        isSelfAssessment() {
            if (this.assessmentData) return this.assessmentData.type === 'PRESURVEY'
            return false
        },
        isSurveyVisit() {
            if (this.assessmentData) return this.assessmentData.type === 'SURVEY_VISIT'
            return false
        }
    }
}
