import Vue from 'vue'
import App from './App.vue'
import { getOrCreateProvider, client } from './vue-apollo'
import './plugins/installCompositionApi'
import store from './store'
import router from './router'
import locale from 'element-ui/lib/locale/lang/en'
import VueCardFormat from 'vue-credit-card-validation'
//plugins
import VueAnime from '@/plugins/anime'
import pinia from '@/plugins/piniaPlugin'
// vue-auth imports
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueAuth from '@websanova/vue-auth'
import VueRouterAuth from '@websanova/vue-auth/drivers/router/vue-router.2.x'
import VueBearerAuth from '@/auth/drivers/http/custom-bearer'
import VueHttpAxios from '@websanova/vue-auth/drivers/http/axios.1.x'
import VueJwtDecode from 'vue-jwt-decode'
import VueCookies from 'vue-cookies'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { provide } from '@vue/composition-api'

// google autocomplete
import GmapVue from 'gmap-vue';

// component libraries
import ElementUI from 'element-ui'
import VuePhoneNumberInput from 'vue-phone-number-input'

import { VueReCaptcha } from 'vue-recaptcha-v3'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import vuetify from '@/plugins/vuetify'
import logger from '@/plugins/logger'

// font awesome
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { faComment, faSquare, faFolderOpen } from '@fortawesome/free-regular-svg-icons'
import { faExclamationTriangle, faUserSecret, faPlus, faPencilAlt, faMapMarkerAlt, faPhoneAlt, faUser, faFileAlt, faQuestionCircle, faExternalLinkAlt, faTrashAlt, faDownload, faPaperclip, faFilter, faChevronLeft, faChevronRight, faChevronDown, faCheck, faSignOutAlt, faLongArrowAltRight, faCheckSquare, faLock, faTimes, faCog, faCalendarAlt, faBan, faExclamationCircle, faBars, faLockOpen, faSave, faInfoCircle, faCircle, faUpload, faTrash, faFolder, faHandsHelping, faSpinner, faCaretUp, faCaretDown, faSort, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Vue phone number input
// project scss
import './css/font-awesome.min.css';
import '@/scss/main.scss'
import { VueGotoInstance } from '@/plugins/gotowebinars'

import setupAxiosRefresher from './utils/auth/auth.axios.refresher'

// setting global properties before we do anything
Vue.config.globalProperties = {}
// Use libraries
Vue.use(ElementUI, { locale })
Vue.use(VueCardFormat)
Vue.use(VueAnime)

//config.autoAddCss = false
library.add(faExclamationTriangle, faUserSecret, faPlus, faPencilAlt, faMapMarkerAlt, faPhoneAlt, faUser, faFileAlt, faQuestionCircle, faExternalLinkAlt, faTrashAlt, faDownload, faComment, faPaperclip, faFilter, faChevronLeft, faChevronRight, faChevronDown, faCheck, faSignOutAlt, faCheckSquare, faLongArrowAltRight, faLock, faTimes, faCog, faCalendarAlt, faCheckSquare, faBan, faExclamationCircle, faBars, faSquare, faLockOpen, faSave, faInfoCircle, faCircle, faFolderOpen, faUpload, faTrash, faFolder, faHandsHelping, faSpinner, faCaretUp, faCaretDown, faSort, faSyncAlt)

// Add libraries
Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('v-phone-input', VuePhoneNumberInput)

Vue.use(VueCookies)

Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_DRF_RECAPTCHA_SITE_KEY,
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: true
    }
})

Vue.config.productionTip = false
Vue.router = router


// vue-auth instantiate
Vue.use(VueJwtDecode)
Vue.use(VueAxios, axios)
Vue.use(logger)

setupAxiosRefresher()

Vue.use(VueAuth, {
    tokenDefaultKey: 'connect2_auth_token',
    auth: VueBearerAuth,
    http: VueHttpAxios,
    router: VueRouterAuth,
    loginData: { url: 'auth/login/', method: 'POST', redirect: '/', fetchUser: true },
    logoutData: { url: 'auth/logout/', method: 'GET', redirect: '/', makeRequest: true },
    fetchData: { url: 'user/current/', method: 'GET', enabled: true }, // this is just used to do a fetch on the auth/user endpoint (which we don't have, disabling)
    refreshData: { url: 'auth/refresh/', method: 'POST', enabled: true, interval: 0, fetchUser: true },
    registerData: { url: 'auth/register/', method: 'POST', redirect: '/login', autoLogin: true },
    parseUserData: (data) => ({...(data || {}), isNewUser: data?.newUser})
})
Vue.axios.defaults.baseURL = process.env.VUE_APP_AUTH_HTTP || ''
Vue.prototype.$goToWebinar = {...VueGotoInstance}

Vue.use(GmapVue, {
    load: {
        key: process.env.VUE_APP_GMAP_API_KEY,
        libraries: 'places,drawing',
        region: 'AU',
        language: 'en'
    },
})

if (process.env.NODE_ENV !== 'development') {
    // adding Sentry
    Sentry.init({
        dsn: 'https://1c933e643ac8489eae701487d8e5c581@o289574.ingest.sentry.io/5280744',
        integrations: [new VueIntegration({Vue, attachProps: true})],
        environment: process.env.VUE_APP_ENV,
    })
}

const app = new Vue({
    apolloProvider: getOrCreateProvider(),
    store,
    router,
    vuetify,
    pinia,
    setup() {
        provide(DefaultApolloClient, client)
        provide('vuex-store', store)
    },
    render: h => h(App),
})

app.$mount('#app')

