<template>
    <div class="c-modal-agreement flex flex-col h-full p-4" data-cy="modalAgreement">
        <h2 class="text-2xl text-center text-black mb-4">Confidentiality Agreement</h2>
        <div class="flex flex-row justify-center">
            <AgreementText :userName="userName" />
        </div>
        <div class="relative">
            <el-form 
                class="flex flex-col justify-center my-4"
                :model="contractAgreement"
                ref="contractAgreement"
                :rules="validationRules"
            >
                <el-form-item 
                    class="relative mt-4 mb-0"
                    prop="checkbox1"
                >
                    <el-checkbox v-model="contractAgreement.checkbox1" :label="checkboxLabel1"></el-checkbox>
                </el-form-item>
                <el-form-item 
                    class="relative justify-start mb-0"
                    prop="checkbox2"
                >
                    <el-checkbox v-model="contractAgreement.checkbox2" :label="checkboxLabel2"></el-checkbox>
                </el-form-item>
                <el-form-item class="flex flex-row justify-center my-4">
                    <el-button @click="cancelHandler">Cancel</el-button>
                    <el-button type="success" @click="submitHandler">Submit</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import AgreementText from './AgreementText'
import { formatISO } from 'date-fns'
import { mapState } from 'vuex'

export default {
    name: 'ModalAgreement',
    components: {
        AgreementText
    },
    data() {
        return {
            contractAgreement: {
                checkbox1: false,
                checkbox2: false
            },
            surveyorName: 'test name',
            validationRules: {
                checkbox1: [
                    { type: 'Boolean', required: true, message: 'Agreement is required', validator: this.validatorFunc },
                ],
                checkbox2: [
                    { type: 'Boolean', required: true, message: 'Agreement is required', validator: this.validatorFunc },
                ],
            },
        }
    },
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData,
            isVisible: state => state.app.modal.isVisible,
        }),
        userName() {
            return this.data.userName || ''
        },
        userId(){
            return this.data.userId || null
        },
        assessmentId(){
            return this.data.assessmentId || null
        },
        // confidentialitySigns(){
        //     return this.data.confidentialitySigns || null
        // },
        date() {
            return formatISO(new Date(), { representation: 'date' })
        },
        respObj() {
            return {
                userId: this.userId,
                date: this.date,
                id: this.assessmentId
                // signsArr: { ...this.confidentialitySigns }
            }
        },
        checkboxLabel1(){
            return `I ${this.userName} confirm that prior to the commencement of this survey visit, the practice has provided me with relevant information in relation to work safety, emergency and security procedures.`
        },
        checkboxLabel2(){
            return `I understand all my actions will be recorded`
        },
    },
    methods: {
        validatorFunc(rule, value, callback) {
            if(!value) { 
                callback('error')
            } else {
                callback()
            }
        },
        cancelHandler() {
            if (this.data.emitCancel) {
                this.$root.$emit('agreement-cancel', true)
            }
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        submitHandler() {
            console.log('submitHandler contractAgreement', this.contractAgreement)
            // commit mutation
            this.$refs.contractAgreement.validate(valid => {
                if (valid) {
                    // console.log('item validated', valid)
                    this.$root.$emit('agreement-action', this.respObj)
                    this.$store.commit('MODAL_IS_VISIBLE', false)
                } else {
                    console.error('error, fields not valid', valid)
                    return false
                }
            })
        }
    }
}
</script>
