<template>
    <div class="flex flex-col h-full p-4 summary-modal">
        <h2 class="text-2xl text-center text-black mb-4">This action will mark indicator as Met!</h2>
        <div class="flex flex-row justify-center my-4">
            <div class="text-center">
                <p v-if="previousCompliance == 'NOT_APPLICABLE'">
                    This indicator was already marked as 'Not Applicable'.
                </p>
                <p v-else-if="previousCompliance">
                    This indicator was already marked as 'Not Met' &amp; has input for the non-compliance fields.<br />
                    If you check all required checkboxes, it will mark this indicator as 'Met' &amp; the non-compliance input will be lost.
                </p>
                <p>
                    Are you sure you would like to check all checkboxes?
                </p>
            </div>
        </div>

        <div class="flex flex-row justify-center my-4">
            <v-btn
                type="button"
                color="grey"
                outlined
                depressed
                @click="cancelHandler"
            >Cancel</v-btn>
            <v-btn
                type="submit"
                color="success"
                class="ml-2"
                depressed
                @click="submitHandler"
            >Yes, check &amp; mark this indicator as Met</v-btn>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {FormRules, FormEvents} from '@/components/shared/mixins/formMixins'

export default {
    name: 'ModalComplianceSetMetConfirm',
    mixins: [FormRules, FormEvents],
    mounted() {
        console.log('We want to set the compliance as met!!')
    },
    methods: {
        cancelHandler() {
            this.$root.$emit('indicator-compliance-change-confirm', {confirm: false})
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        submitHandler() {
            this.$root.$emit('indicator-compliance-change-confirm', {confirm: true})
            this.$store.commit('MODAL_IS_VISIBLE', false)
        }
    },
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData,
            isVisible: state => state.app.modal.isVisible
        }),
        previousCompliance() {
            return this.data?.previousCompliance || ''
        }
    },
}
</script>
