import { mapState } from 'vuex'
import { AuthMixin} from "@/components/shared/mixins/authHelpers";

export default {
    mixin: [ AuthMixin ],
    computed: {
        ...mapState({
            permissions: state => state.app.permissions
        }),
        // self assessment 'PRESURVEY' permissions
        selfAsDisabled() {
            switch(this.status) {
                case 'UNDER_REVIEW':
                    return !this.permissions?.assessment?.changeSelfAssessmentUnderReview
                case 'COMPLETED':
                    return !this.permissions?.assessment?.changeSelfAssessmentComplete
                case 'IN_PROGRESS':
                    return false
                default:
                    return true
            }
        },
        commentingIsActive() {
            if (this.isSelfAssess) {
                switch(this.status) {
                    case 'IN_PROGRESS':
                        return true
                    case 'UNDER_REVIEW':
                        return this.permissions?.assessment?.changeSelfAssessmentUnderReview
                    case 'COMPLETED':
                        return this.permissions?.assessment?.changeSelfAssessmentComplete
                    default:
                        return false
                }
            } else {
                switch(this.status) {
                    case 'IN_PROGRESS':
                        return this.permissions?.assessment?.changeSurveyInProgress
                    case 'PARTIALLY_SUBMITTED':
                        return !this.hasSubmitted && this.permissions?.assessment?.changeSurveyInProgress
                    case 'UNDER_REVIEW':
                        return this.permissions?.assessment?.changeSurveyUnderReview
                    case 'ACTION_REQUIRED':
                        return this.permissions?.indicatorResponse?.addActivityToSurveyActionPlan
                    default:
                        return false
                }
            }
        },
        // survey 'SURVEY_VISIT' permissions
        surveyInputsDisabled() {
            if (this.isSelfAssess) {
                return this.selfAsDisabled
            } else {
                switch(this.status) {
                    case 'UNDER_REVIEW':
                        return !this.permissions?.assessment?.changeSurveyUnderReview
                    case 'IN_PROGRESS':
                        return !this.permissions?.assessment?.changeSurveyInProgress
                    case 'PARTIALLY_SUBMITTED':
                        return !this.permissions?.assessment?.changeSurveyInProgress || this.hasSubmitted
                    case 'ACTION_REQUIRED':
                        return true
                    case 'APPROVED':
                        return true
                    case 'COMPLETED':
                        return true
                    default:
                        return true
                }
            }
        },
        surveyAsCheckboxesDisabled() {
            switch(this.status) {
                case 'UNDER_REVIEW':
                    return !this.permissions?.assessment?.changeSurveyUnderReview
                case 'IN_PROGRESS':
                    return !this.permissions?.assessment?.changeSurveyInProgress
                case 'PARTIALLY_SUBMITTED':
                    // if the surveyor has submiited and they can change it, then it should be disabled
                    return !this.permissions?.assessment?.changeSurveyInProgress || this.hasSubmitted
                case 'ACTION_REQUIRED':
                    return true
                case 'APPROVED':
                    return true
                case 'COMPLETED':
                    return true
                default:
                    return true
            }
        },
        showFurtherActionRequired() {
            return this.isSelfAssess && this.underReviewOrCompleted
        },
        showReviewed() {
            return this.isSelfAssess && this.underReviewOrCompleted
        },
        hasSubmitted() {
            if (!this.submissions || !this.$auth.user()) return false
            const keys = Object.keys(this.submissions)
            return keys.length !== 0 && keys.indexOf(this.$auth.user().userId.toString()) > -1
        },
        viewActivityStatus() {
            if (this.complianceResponse !== 'NOT_MET') {
                return false
            }
            
            switch(this.status) {
                case 'ACTION_REQUIRED':
                case 'APPROVED':
                case 'COMPLETED':
                    return true
                default:
                    return false
            }
        },
        changeActivityStatus() {
            if (this.complianceResponse !== 'NOT_MET') {
                return false
            }
            
            switch(this.status) {
                case 'ACTION_REQUIRED':
                    return this.permissions?.assessment?.changeActionPlan
                default:
                    return false
            }
        },
        canChangeOwnComment() {
            if (this.isSelfAssess) {
                switch(this.status) {
                    case 'IN_PROGRESS':
                        return true
                    case 'UNDER_REVIEW':
                        return this.permissions?.assessment?.changeSelfAssessmentUnderReview
                    case 'COMPLETED':
                        return this.permissions?.assessment?.changeSelfAssessmentComplete
                    default:
                        return false
                }
            } else {
                switch(this.status) {
                    case 'IN_PROGRESS':
                        return this.permissions?.assessment?.changeSurveyInProgress
                    case 'PARTIALLY_SUBMITTED':
                        return !this.hasSubmitted && this.permissions?.assessment?.changeSurveyInProgress
                    case 'UNDER_REVIEW':
                        return this.permissions?.assessment?.changeSurveyUnderReview
                    case 'ACTION_REQUIRED':
                        return (this.isAdmin || this.isSurveyor) && this.permissions?.indicatorResponse?.addActivityToSurveyActionPlan
                    default:
                        return false
                }
            }
        },
        canDeleteOwnFile() {
            console.log('self assessment status', this.status)
            if (this.isSelfAssess) {
                switch(this.status) {
                    case 'IN_PROGRESS':
                        return true
                    default:
                        return false
                }
            } else {
                return false
            }
        },
    }
}
