<template>
    <v-app class="flex justify-center">
        <div class="bg-grey-5 browserTooSmallMsg">
            <img src="@/assets/images/image_browserresize.png" alt="Resize browser" class="block m-auto resizeBrowserImage"/>
            <h1 class="text-xl mb-3 text-center">Your browser or device is too small</h1>
            <div class="text-center">
                Connect works best on tablet devices (at minimum Ipad Mini) and desktops. Change device/or resize your browser to be at least 768px wide to get back into Connect.
            </div>
        </div>
        <div class="browserTooSmallOverlay"></div>
        <NavigationMenu v-if="$auth.check()" v-model="drawer" />
        <v-main class="h-full">
            <!--Make following visible if user is not on Chrome Browser-->
            <v-banner
                :sticky="true"
                class="bg-light-blue"
                v-if="!isChromeComputed"
            >
                <fa-icon icon="exclamation-triangle" class="mr-1"/>
                <span class="font-bold">
                Connect is not compatible with Safari &amp; Internet Explorer. Connect works best in the Chrome browser. Please install Chrome &amp; view Connect.
            </span>
                <template v-slot:actions>
                    <a :href="getChromeInstallHref()" target="_blank" class="uppercase font-bold text-white-pure text-sm group hover:text-yellow-light">Install Chrome <fa-icon icon="chevron-right" class="hover:text-yellow-light" /></a>
                </template>
            </v-banner>

            <!--For Ipad devices please change href value to " https://apps.apple.com/au/app/google-chrome/id535886823 " -->

            <div class="flex flex-col h-full">
                <div>
                    <GlobalHeader v-if="$auth.check()" v-model="drawer" @toggle-drawer="setDrawer" />
                </div>
                <router-view />
                <GlobalFooter v-if="$auth.check()" class="mt-auto" />
            </div>
        </v-main>
        <portal selector="#modal-portal-target">
            <component v-if="modalActive" :is="modalActive" class="absolute" />
        </portal>
    </v-app>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import Modal from '@/components/shared/Modal'
import NavigationMenu from '@/components/shared/NavigationMenu'
import GlobalHeader from '@/components/shared/GlobalHeader'
import GlobalFooter from '@/components/shared/GlobalFooter'
import { isTablet, isChrome, isIOS, isMacOs, isWindows, isAndroid } from 'mobile-device-detect'
import { Portal } from '@linusborg/vue-simple-portal'
import { computed, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useShowModalStore } from '@/stores/useShowModalStore'
import { useAssessmentStore } from '@/stores/useAssessmentStore'


const { useGetters } = createNamespacedHelpers('')

export default {
    components: { Modal, NavigationMenu, GlobalHeader, GlobalFooter, Portal },
    setup(_, { root }) {
        const drawer = ref(null)
        const auth = root['$auth']
        const showModalStore = useShowModalStore()
        const assessmentStore = useAssessmentStore()

        const { updateUser } = assessmentStore
        const desktopChrome = ref('https://www.google.com.au/chrome/')
        const appStoreChrome = ref('https://apps.apple.com/au/app/google-chrome/id535886823')
        const androidChrome = ref('https://play.google.com/store/apps/details?id=com.android.chrome&hl=en_AU')
        const { modalIsVisible } = useGetters(['modalIsVisible'])
        const isChromeComputed = computed(() => isChrome && (isTablet || isWindows || isMacOs))
        // need to check both the pinia store and Vuex for now
        const modalActive = computed(() => showModalStore.computedIsVisible || modalIsVisible.value ? 'Modal' : '')
        const setDrawer = (val) => {
            drawer.value = val
        }

        const getChromeInstallHref = () => {
            if (isTablet) {
                if (isIOS) {
                    return appStoreChrome.value
                } else if(isAndroid) {
                    return androidChrome.value
                }
            } else if (isWindows || isMacOs){
                return desktopChrome.value
            }
        }

        onMounted(async () => {
            await auth.load()
                
            updateUser(auth.user())
        })
        return { drawer, desktopChrome, appStoreChrome, androidChrome, modalActive, isChromeComputed, setDrawer, getChromeInstallHref }
    }
}
</script>
