<template>
    <div class="c-survey-results flex flex-row">
        <div class="flex flex-col w-2/5 mr-3 xl:mr-6">
            <div class="c-survey-results__chart-panel relative bg-grey-8 rounded">
                <div class="absolute svg-container pt-3" ref="results"></div> 
                <h3 class="pt-3 pl-3 xl:pl-6 text-base xl:text-xl font-normal">Survey Results</h3>
                <div class="absolute flex flex-col justify-center lg:pl-3 xl:pl-3 pt-3 right-0 top-0 w-1/2 h-full">
                    <div>
                        <p>
                            <span class="font-display text-black font-bold mr-2 text-sm xl:text-base">{{ met }}</span>
                            <span class="font-display text-green uppercase font-bold text-xs xl:text-sm">Met</span>
                        </p>
                    </div>
                    <div>
                        <p>
                            <span class="font-display text-black font-bold mr-2 text-sm xl:text-base">{{ notMet }}</span>
                            <span class="font-display text-red uppercase font-bold text-xs xl:text-sm">Not Met</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="relative c-survey-results__lower-panel flex items-center justify-center">
                <p class="text-center">
                    <span class="text-base font-bold text-black font-display mr-1">{{ improvements }}</span> 
                    <span class="text-grey-2 text-sm">Improvements suggested</span>
                </p>
            </div>
        </div>
        <div class="flex flex-col w-3/5 mr-0 lg:mr-10">
            <div class="w-full relative bg-grey-8 rounded">
                <div class="c-survey-results__chart-panel relative">
                    <div class="absolute svg-container p-3" ref="ratings"></div>
                    <h3 class="pt-3 pl-3 xl:pl-6 text-base xl:text-xl font-normal">Risk Ratings</h3>
                    <div class="pl-1 xl:pl-0 absolute flex flex-col justify-center pt-3 right-0 top-0 w-3/5 h-full grid grid-cols-2">
                        <div class="col-span-1 flex flex-col justify-center">
                            <div>
                                <p>
                                    <span :class="{' text-black ': riskCritical, 'text-grey-2': !riskCritical }"
                                    class="font-display font-bold mr-2 text-sm xl:text-base">{{ riskCritical }}</span>
                                    <span :class="{' text-red ': riskCritical, 'text-grey-2': !riskCritical }" class="font-display uppercase font-bold text-xs xl:text-sm">Critical</span>
                                </p>
                            </div>
                            <div>
                                <p>
                                    <span :class="{' text-black ': riskModerate, 'text-grey-2': !riskModerate }" class="font-display font-bold mr-2 text-sm xl:text-base">{{ riskModerate }}</span>
                                    <span :class="{' text-yellow ': riskModerate, 'text-grey-2': !riskModerate }" class="font-display uppercase font-bold text-xs xl:text-sm">Moderate</span>
                                </p>
                            </div>
                        </div>
                        <div class="col-span-1 flex flex-col justify-center ml-2">
                            <div>
                                <p>
                                    <span :class="{' text-black ': riskMajor, 'text-grey-2': !riskMajor }" class="font-display text-black font-bold mr-2 text-sm xl:text-base">{{ riskMajor }}</span>
                                    <span :class="{' text-risk-matrix-orange-dark ': riskMajor, 'text-grey-2': !riskMajor }" class="uppercase font-bold text-xs xl:text-sm ">Major</span>
                                </p>
                            </div>
                            <div>
                                <p>
                                    <span :class="{' text-black ': riskMinor, 'text-grey-2': !riskMinor }" class="font-display font-bold mr-2 text-sm xl:text-base">{{ riskMinor }}</span>
                                    <span :class="{' text-risk-matrix-green-dark ': riskMinor, 'text-grey-2': !riskMinor }" class="uppercase font-bold text-xs xl:text-sm">Minor</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="relative c-survey-results__lower-panel flex items-center justify-center">
                <p class="whitespace-no-wrap text-grey-2 text-sm font-display">
                    Non conformities closed
                </p>
                <ProgressBar class="w-full ml-2" :progressObj="progressObj" :words="true" customColour="bg-light-blue"></ProgressBar>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart } from "frappe-charts/dist/frappe-charts.min.esm"
import ProgressBar from './ProgressBar'

export default {
    name: 'SurveyResults',
    components: {
        ProgressBar,
    },
    props: {
        assessmentData: {
            type: Object,
            default: () => {}
        }
    },
    data () {
        return {
            chartSize: 200,
        }
    },
    mounted() {
        // console.log('mounted assessmentData', this.assessmentData)
        this.resultsChart()
        this.ratingsChart()
    },
    updated() {
        // console.log('updated SurveyResults')
        this.resultsChart()
        this.ratingsChart()
    },
    methods: {
        resultsChart() {
            new Chart(this.$refs.results, {
                data: {
                    labels: ['Met', 'Not Met'],
                    datasets: [
                        {
                            name: 'met / not met',
                            values: [
                                this.met, 
                                this.notMet
                            ]
                        }
                    ]
                },
                type: 'donut',
                height: this.chartSize,
                width: this.chartSize,
                colors: [
                    '#29AC64', // tailwind: green
                    '#E80729', // tailwind: red
                ]
            })
        },
        ratingsChart() {
            new Chart(this.$refs.ratings, {
                data: {
                    labels: ['Critical', 'Major', 'Moderate', 'Minor'],
                    datasets: [
                        {
                            name: 'risks',
                            values: [
                                this.riskCritical, 
                                this.riskMajor, 
                                this.riskModerate, 
                                this.riskMinor,
                            ]
                        }
                    ]
                },
                type: 'donut',
                height: this.chartSize,
                width: this.chartSize,
                colors: [
                    '#E80729',  // tailwind: red
                    '#FF6A00',  // tailwind: risk-matrix-orange-dark
                    '#FFBC00',  // tailwind: yellow
                    '#7bd823',  // tailwind: risk-matrix-green-dark
                ]
            })
        },
    },
    watch: {
        assessmentData() {
            this.resultsChart()
            this.ratingsChart()
        }
    },
    computed: {
        total() {
            return this.assessmentData ? this.assessmentData.total.aggregate.count : null
        },
        met() {
            return this.assessmentData ? this.assessmentData.mets.aggregate.count : null
        },
        notMet() {
            return this.assessmentData ? this.assessmentData.notMets.aggregate.count : null
        },
        improvements() {
            return this.assessmentData ? this.assessmentData.improvements.aggregate.count : null
        },
        riskClosed() {
            return this.assessmentData ? this.assessmentData.riskClosed.aggregate.count : null
        },
        riskCritical() {
            return this.assessmentData ? this.assessmentData.riskCritical.aggregate.count : null
        },
        riskMajor() {
            return this.assessmentData ? this.assessmentData.riskMajor.aggregate.count : null
        },
        riskMinor() {
            return this.assessmentData ? this.assessmentData.riskMinor.aggregate.count : null
        },
        riskModerate() {
            return this.assessmentData ? this.assessmentData.riskModerate.aggregate.count : null
        },
        riskTotal() {
            return this.riskCritical + this.riskMajor + this.riskMinor + this.riskModerate
        },
        progressObj() {
            return {
                total: this.riskTotal,
                mets: this.riskClosed,
            }
        }
    },
}
</script>
