import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api'

export const useIndicatorSetStore = defineStore('indicatorSet', () => {
    const _reloadChecklists = ref(false)
    
    const computedReloadChecklists = computed(() => _reloadChecklists.value)
    
    const updateReloadChecklists = (val) => {
        _reloadChecklists.value = val
    }
    
    return { computedReloadChecklists, updateReloadChecklists }
})
