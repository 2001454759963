import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api'

export const useAssessmentStore = defineStore('assessment-store', () => {
    const _assessment = ref({})
    const _assessmentPermissions = ref({})
    //TODO: move to a different store
    const _user = ref({})
    
    const assessment = computed(() => _assessment.value)
    const isSelfAssessment = computed(() => _assessment.value?.type === 'PRESURVEY')
    const isSurveyVisit = computed(() => _assessment.value?.type === 'SURVEY_VISIT')
    const status = computed(() => _assessment.value?.status || null)
    const isSuperAdmin = computed(() => _user.value?.isSuperadmin)
    const canChangeSurvey = computed(() => {
        if (!status.value) return false
        switch (status.value) {
            case 'IN_PROGRESS':
                return isSelfAssessment.value || (isSurveyVisit.value && _assessmentPermissions.value?.changeSurveyInProgress)
            case 'UNDER_REVIEW':
                return isSelfAssessment.value && _assessmentPermissions.value?.changeSelfAssessmentUnderReview ||
                    (isSurveyVisit.value && _assessmentPermissions.value?.changeSurveyUnderReview)
            case 'COMPLETED':
                return isSelfAssessment.value
            case 'ACTION_REQUIRED':
                return isSurveyVisit.value && _assessmentPermissions.value?.changeActionPlan
            case 'PARTIALLY_SUBMITTED':
                return isSurveyVisit.value && (_assessmentPermissions.value?.changeSurveyInProgress || _assessmentPermissions.value?.changeOtherSurveyorSummary)
            case 'APPROVED':
                return isSurveyVisit.value && _assessmentPermissions.value?.certifySurvey
            case 'CERTIFIED':
                return isSurveyVisit.value
            default:
                return false
        }
    })
    
    const updateAssessment = (newAssessment) => {
        _assessment.value = { ...(newAssessment || {}) }
    }
    
    const updateAssessmentPermissions = (val) => {
        _assessmentPermissions.value = { ...(val || {}) }
    }

    const updateUser = (val) => {
        _user.value = { ...(val || {}) }
    }
    
    return { status, assessment, isSelfAssessment, isSurveyVisit, canChangeSurvey, isSuperAdmin, updateAssessment, updateAssessmentPermissions, updateUser }
})
