<template>
    <div class="pl-10 w-11/12">
        <div v-if="!loading" class="c-documents grid grid-cols-7 gap-8 mt-4" data-cy="firstStateStaffTable">
            <div class="col-span-7 md:col-span-3 xl:col-span-2">
                <img src="@/assets/images/startselfassessment.png" class="m-auto" :style="imgStyle" alt="Start self assessment" />
            </div>
            <div class="col-span-7 md:col-span-4 xl:col-span-5">
                <span class="text-green text-xs uppercase font-bold font-display">Step 3</span>
                <h3 class="text-black font-semibold mr-2 text-xxl lg:text-xxl pb-3">Let's get started on the Self Assessment</h3>

                <p class="pb-3">
                    The assessment is an online tool used by Quality Practice Accreditation surveyors when they conduct a practice survey visit for accreditation. The assessment checks if your practice meets the RACGP standards to achieve accreditation.
                </p>
                <p>
                    You will complete the following steps prior to submitting your assessment for review:
                </p>
                <ul class="pb-3 pl-6 fa-ul c-staff__check-list">
                    <li>
                        <span class="fa-li">
                            <fa-icon icon="check-square" class="mr-1 text-green"/>
                        </span>
                        <span class="align-middle">Invite staff to signup to Connect &amp; access the assessment</span>
                    </li>
                    <li>
                        <span class="fa-li">
                            <fa-icon icon="check-square" class="mr-1 text-green"/>
                        </span>
                        <span class="align-middle">Go through assessment checkboxes, add your comments, upload documents and complete the staff audit</span>
                    </li>
                    <li>
                        <span class="fa-li">
                            <fa-icon icon="check-square" class="mr-1 text-green"/>
                        </span>
                        <span class="align-middle">Submit to QPA to receive feedback</span>
                    </li>
                </ul>
                <p class="pb-3">
                    Please note the submit for review is a one time-submission to receive feedback from QPA.
                </p>
                <div class="practice-staff-btn">
                    <el-button
                        data-cy="enterStaffDetails"
                        class="bg-primary-light text-white text-bold rounded-md px-4 py-4 uppercase text-xs "
                        type="button"
                        @click.prevent="startAssessment"
                    >Start Self Assessment</el-button>
                </div>
            </div>
        </div>
        <div class="flex items-center justify-center h-20 w-full" v-else>
            <Loader class="w-12 h-12" />
        </div>
    </div>
</template>

<script>
import Loader from '@/assets/loader.svg'

export default {
    name: 'FirstStateAccreditation',
    components: { Loader },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        accreditation: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            loading: true,
            imgStyle: {
                width: '100%',
                height: 'auto',
                'padding-top': '25px',
            },
        }
    },
    mounted() {
        this.loading = false
    },
    methods: {
        startAssessment() {
            this.$emit('start-assessment', this.accreditation.id)
        }
    }
}
</script>
