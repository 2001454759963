<template>
    <div class="pl-10 w-11/12">
        <div v-if="!loading" class="c-documents grid grid-cols-7 gap-8 mt-4" data-cy="firstStateStaffTable">
            <div class="col-span-4">
                <span class="text-green text-xs uppercase font-bold font-display">Step 1</span>
                <h3 class="text-black font-semibold mr-2 text-xxl lg:text-xxl pb-3">Add practice staff</h3>

                <p class="pb-3">
                    As part of the practice’s accreditation we require you to enter details about all staff at the practice. This includes general practitioners, nurses, allied health and support staff.
                </p>
                <p>
                    You will need to have the following information on hand:
                </p>
                <ul class="pb-3 pl-6 fa-ul c-staff__check-list">
                    <li>
                        <span class="fa-li">
                            <fa-icon icon="check-square" class="mr-1 text-green"/>
                        </span>
                        <span class="align-middle">All staff names</span>
                    </li>
                    <li>
                        <span class="fa-li">
                            <fa-icon icon="check-square" class="mr-1 text-green"/>
                        </span>
                        <span class="align-middle">Medical board registration numbers</span>
                    </li>
                    <li>
                        <span class="fa-li">
                            <fa-icon icon="check-square" class="mr-1 text-green"/>
                        </span>
                        <span class="align-middle">Vocational registration status</span>
                    </li>
                    <li>
                        <span class="fa-li">
                            <fa-icon icon="check-square" class="mr-1 text-green"/>
                        </span>
                        <span class="align-middle">QI &amp; CPD or PDP program numbers</span>
                    </li>
                    <li>
                        <span class="fa-li">
                            <fa-icon icon="check-square" class="mr-1 text-green"/>
                        </span>
                        <span class="align-middle">CPR certificate expiry dates</span>
                    </li>
                    <li>
                        <span class="fa-li">
                            <fa-icon icon="check-square" class="mr-1 text-green"/>
                        </span>
                        <span class="align-middle">Email addresses for GPs (GPs will not receive CPD points if they do not provide this)</span>
                    </li>
                    <li>
                        <span class="fa-li">
                            <fa-icon icon="check-square" class="mr-1 text-green"/>
                        </span>
                        <span class="align-middle">Full time equivalent bands</span>
                    </li>
                    <li>
                        <span class="fa-li">
                            <fa-icon icon="check-square" class="mr-1 text-green"/>
                        </span>
                        <span class="align-middle">CPD and training details</span>
                    </li>
                </ul>
<!--                <p class="pb-3">-->
<!--                    Have a CSV export of all the staff? Click ‘Upload CSV’ to bulk upload staff.-->
<!--                </p>-->
                <div class="practice-staff-btn">
                    <el-button
                        data-cy="enterStaffDetails"
                        class="font-bold text-white rounded-md px-4 py-4 uppercase text-xs "
                        type="primary"
                        @click.prevent="manualEnter"
                    >Enter All Staff Details</el-button>
<!--                    <el-button-->
<!--                        class="font-bold uppercase text-white font-display uppercase py-4 px-10 text-xs rounded-md"-->
<!--                        type="success"-->
<!--                    >Upload csv</el-button>-->
                </div>
            </div>
            <div class="col-span-3">
                <img src="@/assets/images/addpracticestaff.png" class="paymentcert ml-auto w-full h-auto"  alt="Add practice staff" />
            </div>
        </div>
        <div class="flex items-center justify-center h-20 w-full" v-else>
            <Loader class="w-12 h-12" />
        </div>
    </div>
</template>

<script>
import Loader from '@/assets/loader.svg'
import { mapState } from 'vuex'
import snakecaseKeys from 'snakecase-keys'

export default {
    name: 'FirstStateStaff',
    components: { Loader },
    data() {
        return {
            loading: true,
        }
    },
    computed: {
        ...mapState({
            practiceId: state => state.app.practiceId
        }),
    },
    mounted() {
        this.loading = false
    },
    methods: {
        manualEnter() {
            this.$emit('enter-staff-list', true)
        }
    }
}
</script>

<style scoped>

</style>
