<template>
    <div class="c-staff__table editable_table flex flex-col">
        <div class="w-full my-4 flex flex-row justify-start">
            <el-input
                placeholder="Search"
                v-model="filterSearchValue"
                class="mr-4 max-w-1/4"
                data-cy="searchValue"
            >
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
        </div>
        <v-data-table
            ref="staffTableRef"
            single-select
            item-key="id"
            :headers="headers"
            :items="tableDataFiltered"
            :items-per-page.sync="itemsPerPage"
            :footer-props="footerProps"
            class="c-table recordsWrapper"
            data-cy="staffTable"
            @click:row="rowClickHandler"
        >
            <template v-slot:[`item.verified`]="{ item }">
                <checkbox-cell
                    :disabled="!canVerifyStaff"
                    :ref="`verified${item.id}`"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    @edit-row="toggleEdit"
                    v-model="item.verified"
                    :isRequired="true"
                    :enforceValid="true"
                >
                </checkbox-cell>
            </template>
            <template  v-slot:[`item.title`]="{ item }">
                <select-cell
                    :ref="`title${item.id}`"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    @edit-row="toggleEdit"
                    v-model="item.title"
                    :display-value="getDisplayValue(item.title, titles)"
                    :isRequired="true"
                    :items="titles"
                ></select-cell>
            </template>
            <template v-slot:[`item.firstName`]="{ item }">
                <editable-cell
                    :ref="`firstName${item.id}`"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    @edit-row="toggleEdit"
                    v-model="item.firstName"
                    :isRequired="true"
                >
                </editable-cell>
            </template>
            <template v-slot:[`item.lastName`]="{ item }">
                <editable-cell
                    :ref="`lastName${item.id}`"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    @edit-row="toggleEdit"
                    v-model="item.lastName"
                    :isRequired="true"
                >
                </editable-cell>
            </template>
            <template v-slot:[`item.type`]="{ item }">
                <select-cell
                    :ref="`type${item.id}`"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    @edit-row="toggleEdit"
                    v-model="item.type"
                    :display-value="getDisplayValue(item.type, staffTypes)"
                    :isRequired="true"
                    :items="staffTypes"
                ></select-cell>
            </template>
            <template v-slot:[`header.reg`]="{ header }">
                {{ header.text }}
                <el-popover
                    placement="top-start"
                    title="APHRA &amp; National Board registration number"
                    width="200"
                    trigger="hover"
                >
                    <a href='https://www.ahpra.gov.au' target='_blank'>https://www.ahpra.gov.au</a>
                    <fa-icon icon="question-circle" slot="reference" class="grey-1 ml-2" />
                </el-popover>
            </template>
            <template v-slot:[`item.reg`]="{ item }">
                <autocomplete-cell
                    :ref="`reg${item.id}`"
                    v-if="!isPracticeStaff(item, ['support', 'other'])"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    v-model="item.registrationNum"
                    :isRequired="isPracticeStaff(item, ['gp', 'nurse'])"
                    :fetchSuggestions="querySearch"
                    :getDropdownDisplay="getDropdownDisplay"
                    @edit-row="toggleEdit"
                    @select="handleRegistrationSelect"
                ></autocomplete-cell>
            </template>
            <template v-slot:[`item.employment`]="{ item }">
                <select-cell
                    :ref="`employment${item.id}`"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    v-model="item.employmentType"
                    :display-value="getDisplayValue(item.employmentType, employmentTypes)"
                    :isRequired="true"
                    :items="employmentTypes"
                    @edit-row="toggleEdit"
                ></select-cell>
            </template>
            <template v-slot:[`header.startDate`]="{ header }">
                {{ header.text }}
                <el-popover
                    placement="top-start"
                    title="Employment start date"
                    width="200"
                    trigger="hover"
                    content="This information is required by the safety and quality commission"
                >
                    <fa-icon icon="question-circle" slot="reference" class="grey-1 ml-2" />
                </el-popover>
            </template>
            <template v-slot:[`item.startDate`]="{ item }">
                <date-picker-cell
                    :ref="`startDate${item.id}`"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    v-model="item.startDate"
                    :display-value="getDateDisplayValue(item.startDate)"
                    :isRequired="true"
                    @edit-row="toggleEdit"
                >
                </date-picker-cell>
            </template>
            <template v-slot:[`item.employmentStatus`]="{ item }">
                <select-cell
                    :ref="`employmentStatus${item.id}`"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    v-model="item.employmentStatus"
                    :display-value="getDisplayValue(item.employmentStatus, employmentStatus)"
                    :isRequired="true"
                    :items="employmentStatus"
                    @edit-row="toggleEdit"
                ></select-cell>
            </template>
            <template v-slot:[`item.resignationDate`]="{ item }">
                <date-picker-cell
                    :ref="`resignationDate${item.id}`"
                    v-if="item.employmentStatus && item.employmentStatus.toLowerCase() === 'inactive'"
                    :isRequired="item.employmentStatus && item.employmentStatus.toLowerCase() === 'inactive'"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    v-model="item.resignationDate"
                    :display-value="getDateDisplayValue(item.resignationDate)"
                    @edit-row="toggleEdit"
                ></date-picker-cell>
            </template>
            <template v-slot:[`item.email`]="{ item }">
                <editable-cell
                    :ref="`email${item.id}`"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    v-model="item.email"
                    :isRequired="true"
                    class="w-48"
                    @edit-row="toggleEdit"
                >
                    <template slot="text">
                        {{ item.email }}
                        <span class="email-invitation-status">
                    <el-tooltip
                        v-if="item.user && item.user.isActive && item.invite && item.invite.status === 'ACCEPTED'"
                        effect="dark"
                        content="Staff has an active Connect account."
                        placement="top"
                    >
                        <i class="el-icon-check"></i>
                    </el-tooltip>
                    <el-tooltip
                        v-if="item.invite && item.invite.status === 'REJECTED'"
                        effect="dark"
                        content="Staff has rejected their Connect invite."
                        placement="top"
                    >
                        <i class="el-icon-close"></i>
                    </el-tooltip>
                    <el-tooltip
                        v-if="item.user && !item.user.isActive"
                        effect="dark"
                        content="Staff has deactivated their Connect account."
                        placement="top"
                    >
                        <i class="el-icon-close"></i>
                    </el-tooltip>
                </span>
                    </template>
                </editable-cell>
            </template>
            <template v-slot:[`item.vrStatus`]="{ item }">
                <select-cell
                    :ref="`vrStatus${item.id}`"
                    v-if="isPracticeStaff(item, ['gp'])"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    v-model="item.vrStatus"
                    :display-value="getDisplayValue(item.vrStatus, vrStatuses)"
                    :isRequired="true"
                    :items="vrStatuses"
                    @edit-row="toggleEdit"
                ></select-cell>
            </template>
            <template v-slot:[`item.hoursPerWeek`]="{ item }">
                <number-input-cell
                    :ref="`hoursPerWeek${item.id}`"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    v-model="item.hoursPerWeek"
                    :isRequired="true"
                    @edit-row="toggleEdit"
                >
                </number-input-cell>
            </template>
            <template v-slot:[`item.cprExpiry`]="{ item }">
                <DatePickerCell
                    :ref="`cprExpiry${item.id}`"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    v-model="item.cprExpiry"
                    :display-value="getDateDisplayValue(item.cprExpiry)"
                    :isRequired="true"
                    @edit-row="toggleEdit"
                />
            </template>
            <template v-slot:[`item.cpdEvidence`]="{ item }">
                <file-upload-cell
                    :item="item"
                    v-if="!item.isNew"
                    data-cy="cpdEvidence"
                    document-key="cpdEvidence"
                    document-type="cpd"
                    :folderId="documentFolderId"
                    @upload-document="uploadClickHandler"
                    @delete-document="deleteDocumentHandler"
                ></file-upload-cell>
            </template>
            <template v-slot:[`item.qicpd`]="{ item }">
                <editable-cell
                    :ref="`qicpd${item.id}`"
                    v-if="isPracticeStaff(item, ['gp'])"
                    :edit-active="item.active"
                    :cell-id="item.id"
                    v-model="item.qicpd"
                    :isRequired="true"
                    @edit-row="toggleEdit"
                >
                </editable-cell>
            </template>
            <template v-slot:[`item.trainingEvidence`]="{ item }">
                <file-upload-cell
                    :item="item"
                    v-if="!item.isNew"
                    document-key="trainingEvidence"
                    document-type="training"
                    :folderId="documentFolderId"
                    :class="{'long-last-column': item.active || (isStaffInvitable(item) && item.email)}"
                    @upload-document="uploadClickHandler"
                    @delete-document="deleteDocumentHandler"
                ></file-upload-cell>
            </template>
            <!--            <template v-slot:[`item.primaryQualification`]="{ item }">-->
            <!--                <file-upload-cell-->
            <!--                    :item="item"-->
            <!--                    document-key="primaryQualification"-->
            <!--                    document-type="primary"-->
            <!--                    @upload-document="uploadClickHandler"-->
            <!--                    @delete-document="deleteDocumentHandler"-->
            <!--                ></file-upload-cell>-->
            <!--            </template>-->
            <!--            <template v-slot:[`item.secondaryQualification`]="{ item }">-->
            <!--                <file-upload-cell-->
            <!--                    :item="item"-->
            <!--                    document-key="secondaryQualification"-->
            <!--                    document-type="secondary"-->
            <!--                    @upload-document="uploadClickHandler"-->
            <!--                    @delete-document="deleteDocumentHandler"-->
            <!--                ></file-upload-cell>-->
            <!--            </template>-->
            <template v-slot:[`item.editRecord`]="{ item }">
                <div class="c-staff__table__edit-record ml-2 border-l border-grey-5" :class="{ 'active-tr': item.active}">
                    <div class="float-right align-middle">
                        <a
                            href="#"
                            v-if="isStaffInvitable(item)"
                            v-show="item.email"
                            class="text-green hover:text-primary mx-2"
                            @click.prevent="sendInviteHandler(item)"
                        >
                            <i class="el-icon-message p-1"></i><span class="text-xs">{{ item.invite && item.invite.status === 'INVITED' ? 'RE-INVITE' : 'INVITE' }}</span>
                        </a>

                        <a
                            href="#"
                            v-if="!item.isNew"
                            class="mx-2"
                            @click.prevent="deleteHandler(item)"
                        >
                            <i class="el-icon-delete hover:text-red" data-cy="deleteRecord"></i>
                        </a>
                        <a href="#" @click.prevent="cancelHandler" v-if="item.active" class="mr-2">
                            <i class="el-icon-close text-lg text-grey-1 hover:text-orange" data-cy="cancelEdit"></i>
                        </a>
                        <a href="#" @click.prevent="saveHandler" v-if="item.active" class="mr-2">
                            <i class="el-icon-check text-lg text-grey-1 hover:text-green" data-cy="saveRecord"></i>
                        </a>
                    </div>
                </div>
            </template>
        </v-data-table>
        <AddUserRow @add-user="addUser" />
    </div>
</template>

<script>
import EditableCell from '@/components/partials/EditableCell'
import AutocompleteCell from '@/components/partials/AutocompleteCell'
import DatePickerCell from '@/components/partials/DatePickerCell'
import AddUserRow from './AddUserRow'
import snakecaseKeys from 'snakecase-keys'
import { mapState } from 'vuex'
import GET_STAFF_LIST_BY_REGISTRATION from '@/graphql/queries/getStaffListByRegistration.gql'
import camelcaseKeys from 'camelcase-keys'
import FileMethods from '@/components/shared/mixins/fileMethods'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import { parse, isValid, format } from 'date-fns'
import SelectCell from '@/components/partials/SelectCell'
import FileUploadCell from '@/components/partials/FileUploadCell'
import CheckboxCell from '@/components/partials/CheckboxCell'
import NumberInputCell from '../../partials/NumberInputCell'
import { STAFF_DOCUMENT_FOLDER_CODE } from '@/config/constants'
import FolderMixin from '@/components/shared/mixins/folderMixin'

export default {
    name: 'StaffTable',
    components: {
        NumberInputCell,
        CheckboxCell,
        FileUploadCell,
        SelectCell,
        EditableCell,
        AddUserRow,
        AutocompleteCell,
        DatePickerCell
    },
    mixins: [FileMethods, MessageDialog, HttpMixin, FolderMixin],
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        filterOptions: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            itemsPerPage: 20,
            footerProps: {
                'items-per-page-options': [10, 20, 30, 40]
            },
            registrationNum: '',
            editModeEnabled: true,
            activeRowId: null,
            activeRowObj: {},
            tableDataArr: [],
            filterSearchValue: '',
            filterSelectValue: 'firstName',
            addRowModel: {
                firstName: '',
                lastName: '',
                type: 'GP',
                registrationNum: '',
                title: 'DR',
                employmentType: 'EMPLOYEE',
                email: '',
                cprExpiry: null,
                qicpd: '',
                vrStatus: 'VR',
                qaCpdNum: '',
                active: true,
                employmentStatus: 'ACTIVE',
            },
            newStaffAdded: false,
            currentRow: null,
            employmentStatus: [
                {label: 'Active', text: 'Active', value: 'ACTIVE'},
                {label: 'Inactive', text: 'Inactive', value: 'INACTIVE'},
            ],
            querySearchDebounce: 2000,
            querySearchTimeout: null,
            headers: [
                { text: 'verified', align: 'left', value: 'verified', class: 'verified--header', fixed: true },
                { text: 'title', align: 'left', value: 'title', class: 'title--header', fixed: true },
                { text: 'first name', align: 'left', value: 'firstName', class: 'first-name--header', fixed: true },
                { text: 'last name', align: 'left', value: 'lastName', class: 'last-name--header', fixed: true },
                { text: 'type', align: 'left', value: 'type', class: 'type--header' },
                { text: 'reg', align: 'left', value: 'reg', class: 'reg--header' },
                { text: 'employment', align: 'left', value: 'employment', class: 'employment--header' },
                { text: 'start Date', align: 'left', value: 'startDate', class: 'start-date--header' },
                { text: 'employment Status', align: 'left', value: 'employmentStatus', class: 'employment-status--header' },
                { text: 'resignation Date', align: 'left', value: 'resignationDate', class: 'resignation-date--header' },
                { text: 'email', align: 'left', value: 'email', class: 'email--header' },
                { text: 'vr status', align: 'left', value: 'vrStatus', class: 'vr-status--header' },
                { text: 'hours/Week', align: 'left', value: 'hoursPerWeek', class: 'hours-per-week--header' },
                { text: 'cpr Expiry', align: 'left', value: 'cprExpiry', class: 'cpr-expiry--header' },
                { text: 'cpr Evidence', align: 'left', value: 'cpdEvidence', class: 'cpd-evidence--header' },
                { text: 'qicpd', align: 'left', value: 'qicpd', class: 'qicpd--header' },
                { text: 'CPD Evidence', align: 'left', value: 'trainingEvidence', class: 'training-evidence--header' },
                // { text: 'primary Qualifications', align: 'left', value: 'primaryQualification' },
                // { text: 'secondary Qualifications', align: 'left', value: 'secondaryQualification' },
                { text: '', align: 'left', value: 'editRecord', fixed: true},
            ],
            documentFolderId: 0,
            getVisibleFolders: true
        }
    },
    beforeMount() {
        this.loadDocumentFolderCode()
    },
    mounted() {
        this.tableDataArr = [...this.tableData]

        // Global listeners
        this.$root.$on('delete-user', this.hardDeleteUser)
        this.$root.$on('delete-document', this.hardDeleteDocument)
        this.$root.$on('upload-complete', this.addDocumentToStaff)
    },
    methods: {
        rowClickHandler(obj, e) {
            // console.log('row clicked handler', obj, e)
            this.currentRow = obj
            e.select(true)
        },
        clearRowSelection() {
            if (this.$refs.staffTable) {
                this.$refs.staffTable.setCurrentRow()
                this.currentRow = null
            }
        },
        isStaffInvitable(staff) {
            return !staff.user && !staff.active && (!staff.invite || staff.invite.status === 'INVITED')
        },
        deleteDocumentHandler() {
            this.cancelHandler()
        },
        uploadClickHandler() {
            console.log('document uploaded')
        },
        deleteHandler(item) {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalDeleteUser')
            this.$store.commit('MODAL_DATA', item)
            this.clearRowSelection()
        },
        hardDeleteUser(userObj) {
            this.mutationCall(userObj, true)
        },
        sendInviteHandler(staff) {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalSendStaffInvite')
            this.$store.commit('MODAL_DATA', staff)
        },
        showDiscardMessage(isEdit) {
            this.type = 'warning'
            this.message = `Please save/discard the ${isEdit ? 'editing' : 'new'} row before moving to other rows.`
            this.showMessage({duration: 1500})
        },
        addUser() {
            if (this.newStaffAdded) {
                return false
            }
            if (this.activeRowId && this.isActiveRowChanged()) {
                this.showDiscardMessage(true)
                return false
            }

            this.newStaffAdded = true
            let newId = Math.floor(Math.random() * 1000000) + 5000000
            const addRowModel = {
                ...this.addRowModel,
                id: newId,
                isNew: true
            }
            this.tableDataArr.push(addRowModel)
            this.activeRowId = newId

            // console.log(this.$refs.staffTableRef, this.tableDataArr)
            // console.log(this.itemsPerPage)
            const lastPage = Math.ceil(this.tableDataArr.length / this.itemsPerPage)
            // TODO: this isn't quite working, need to figure out why
            if (this.$refs.staffTableRef) this.$refs.staffTableRef.$vuetify.goTo(lastPage)
        },
        typeFilterHandler(value, row, column) {
            const property = column['property']
            return row[property] === value
        },
        toggleEdit(val) {
            if (this.newStaffAdded) {
                this.showDiscardMessage(false)
                return false
            } else if (this.activeRowId && this.isActiveRowChanged()) {
                this.showDiscardMessage(true)
                return false
            }
            this.activeRowId = val
            this.activeRowObj = this.getActiveRow(this.tableDataArr)
        },
        isActiveRowChanged() {
            const fields = ['title', 'firstName', 'lastName', 'type', 'registrationNum', 'employmentType', 'startDate', 'employmentStatus', 'resignationDate', 'email', 'vrStatus', 'hoursPerWeek', 'cprExpiry', 'qicpd']
            const changedObj = this.getActiveRow(this.tableDataArr)
            return fields.filter(field => this.activeRowObj[field] != changedObj[field]).length > 0
        },
        filterTable(data) {
            return data.map(item => {
                let isActive = item.id === this.activeRowId
                return {
                    ...item,
                    active: isActive
                }
            })
        },
        getActiveRow(data) {
            const activeRow = data.find((item) => {
                return item.id === this.activeRowId
            })
            return activeRow
        },
        validateInputs(id) {
            return Promise.all([
                this.$refs[`firstName${id}`].validate(),
                this.$refs[`lastName${id}`].validate(),
                this.$refs[`type${id}`].validate(),
                this.$refs[`title${id}`].validate(),
            ])
        },
        saveHandler() {
            this.validateInputs(this.activeRowId).then(values => {
                let isSuccess = true
                values.forEach(value => { if(!value) isSuccess = false })
                if(isSuccess) {
                    // Post back to db
                    this.mutationCall(this.getActiveRow(this.tableDataArr), false)
                }
            }).catch(err => {
                console.error('validation failed: ', err)
            })
        },
        cancelHandler() {
            this.resetTableRow()
            this.activeRowId = null
            this.clearRowSelection()
            this.newStaffAdded = false
        },
        resetTableRow() {
            // if an existing row is being edited
            if(!this.newStaffAdded) {
                // update the data on the row to the original values stored in activeRowObj
                this.tableDataArr = this.tableDataArr.map(item => {
                    if (item.id === this.activeRowId) {
                        return this.activeRowObj
                    }
                    return item
                })
            } else {
                // if its a new staff member, cancel the add by filtering them from the table arr
                this.tableDataArr = this.tableDataArr.filter(item => {
                    if (item.id !== this.activeRowId) return item
                })
            }
        },
        tableScrollBottom() {
            let table = this.$refs.staffTable.$el.querySelector('.el-table__body-wrapper')
            table.scrollTop = table.scrollHeight
        },
        handleCurrentChange(currentRow) {
            this.currentRow = currentRow
        },
        getDisplayValue(val, displayList) {
            if (!displayList || displayList.length === 0 || !val) return ''

            const displayValue = displayList.find(t => t.value.toLowerCase() === val.toLowerCase())
            return displayValue != null ? displayValue.label : ''
        },
        getDocumentKey(documentType) {
            let documentKey
            switch(documentType.toLowerCase()) {
                case 'secondary':
                    documentKey = 'secondaryQualification'
                    break
                case 'training':
                    documentKey = 'trainingEvidence'
                    break
                case 'cpd':
                    documentKey = 'cpdEvidence'
                    break
                default:
                    documentKey = 'primaryQualification'
            }
            return documentKey
        },
        addDocumentToStaff(document) {
            let documentKey = this.getDocumentKey(document.documentType)

            if (!this.currentRow || this.currentRow.id == null) {
                console.error(`We don't have a staff member selected at the moment`)
                return
            }

            let data = {
                id: this.currentRow.id
            }
            data[documentKey] = document.id

            const httpOptions = this.getPostOrPatchOptions(snakecaseKeys(data, {deep: true}), 'web.practicestaff')
            this.$http(httpOptions)
            .then(res => {
                this.$store.commit('MODAL_IS_VISIBLE', false)
                this.$emit('staff-mutation')
                this.cancelHandler()
            })
            .catch( err => {
                console.error(err)
            })
        },
        hardDeleteDocument(id) {
            // now we clear the modal data
            this.$store.commit('MODAL_DATA', {})

            if (id !== null && id !== undefined) {
                this.$http
                    .put(`document/${id}/`, {archived: true})
                    .then((response) => {
                        this.$emit('staff-mutation')
                    })
                    .catch(err => {
                        console.error('document delete error', err)
                    })
            }
        },
        mutationCall(obj, deleteUser) {
            let isDelete = !!deleteUser
            let staffObj = {...obj}
            const addingNewUser = staffObj.isNew
            delete staffObj.isNew

            let methodName
            let urlType
            let hasError = false
            if (!isDelete) {
                // clean up staff object
                if ('active' in staffObj) delete staffObj['active']
                if ('typename' in staffObj) delete staffObj['typename']
                staffObj['archived'] = false
                staffObj['practice'] = this.practiceId

                // console.log('staffObject', staffObj)
                if (staffObj['startDate']) staffObj['startDate'] = this.formatDate(staffObj['startDate'])
                if (staffObj['resignationDate']) staffObj['resignationDate'] = this.formatDate(staffObj['resignationDate'])
                if (staffObj['cprExpiry']) staffObj['cprExpiry'] = this.formatDate(staffObj['cprExpiry'])
                if (staffObj['employmentStatus'].toLowerCase() === 'active') staffObj['resignationDate'] = null
                if (staffObj['primaryQualification'] != null || !(staffObj['primaryQualification'] instanceof File)) delete staffObj['primaryQualification']
                if (staffObj['secondaryQualification'] != null || !(staffObj['secondaryQualification'] instanceof File)) delete staffObj['secondaryQualification']
                if (staffObj['cpdEvidence'] != null || !(staffObj['cpdEvidence'] instanceof File)) delete staffObj['cpdEvidence']
                if (staffObj['trainingEvidence'] != null || !(staffObj['trainingEvidence'] instanceof File)) delete staffObj['trainingEvidence']
                if (staffObj['phone'] != null && (staffObj['phone'].phone === '' || staffObj['phone'].phone == null)) delete staffObj['phone']
                if (!staffObj['cprExpiry']) delete staffObj['cprExpiry']

                urlType = addingNewUser ? '' : `${staffObj.id}/`
                if (addingNewUser) delete staffObj['id']
                staffObj = snakecaseKeys(staffObj)
                methodName = addingNewUser ? 'POST' : 'PATCH'
            } else {
                urlType = `${staffObj.id}/`
                methodName = 'DELETE'
            }

            this.$http({
                method: methodName,
                url: `web.practicestaff/${urlType}`,
                data: staffObj
            }).then(res => {
                if (deleteUser || addingNewUser) {
                    this.$emit('staff-mutation')
                }
            }).catch(err => {
                console.error(err)
                const errData = err.response && err.response.data ? err.response.data : null
                if (errData) {
                    const keys = Object.keys(errData)
                    // let's just show the first error for now
                    const key = keys[0]
                    this.message = `Could not save staff member, value for ${this.snakeToTitleCase(key)} is invalid`
                } else {
                    this.message = 'Could not save staff member'
                }
                // this.resetTableRow()
                this.type = 'error'
                this.showMessage({duration: 5000})
                hasError = true
            }).finally(() => {
                if (!hasError) {
                    if (isDelete) this.$store.commit('MODAL_IS_VISIBLE', false)
                    this.activeRowId = null
                    this.clearRowSelection()
                    if (addingNewUser) {
                        this.newStaffAdded = false
                    }
                }
            })
        },
        querySearch(queryString, cb) {
            if (this.querySearchTimeout) {
                clearTimeout(this.querySearchTimeout)
            }
            this.querySearchTimeout = setTimeout(() => {
                this.$apollo.query({
                    query: GET_STAFF_LIST_BY_REGISTRATION,
                    variables: {
                        regNumQuery: `%${queryString}%`
                    }
                })
                .then(({data: { StaffListByRegNo }, loading, networkStatus, stale}) => {
                    const staffList = camelcaseKeys(StaffListByRegNo, {deep: true})
                    cb(staffList)
                })
                .catch((err) => {
                    console.log('error getting the list of practice staff', err)
                })
            }, this.querySearchDebounce)
        },
        getDropdownDisplay(item) {
            if (!item || !item.firstName || !item.lastName) {
                return ''
            }
            return `${item.firstName} ${item.lastName}`
        },
        handleRegistrationSelect(staff) {
            const tableData = [...this.tableData]
            const index = tableData.findIndex(sl => sl.id === this.currentRow.id)
            if (index > -1) {
                tableData[index] = {...tableData[index],
                    ...{
                        title: staff.title,
                        firstName: staff.firstName,
                        lastName: staff.lastName
                    }
                }
            }
            this.currentRow = {...this.currentRow, ...tableData[index]}
            this.tableDataArr = [...tableData]
        },
        isPracticeStaff(row, staffType) {
            return row.type && staffType.indexOf(row.type.toLowerCase()) > -1
        },
        getTruncatedName(name) {
            if (!name) return ''

            if (name.length < 10) return name

            const splitArr = name.split('.')
            const ext = splitArr.pop()
            const newName = splitArr.join('.')
            return `${newName.substring(0, 5)}..${ext}`
        },
        getDateDisplayValue(dateValue) {
            if (!dateValue) return ''
            if (typeof dateValue === 'string') {
                dateValue = parse(dateValue, 'yyyy-MM-dd', new Date())
            }

            return isValid(dateValue) ? format(dateValue, 'dd/MM/yyyy') : ''
        },
        formatDate(dateValue) {
            if (!dateValue) return null
            if (typeof dateValue === 'string') {
                dateValue = parse(dateValue, 'yyyy-MM-dd', new Date())
            }

            return isValid(dateValue) ? format(dateValue, 'yyyy-MM-dd') : null
        },
        getInvitationAction(invitation) {
            // there should only be 1 invitation
            if (!invitation) return 'invite'

        },
        async loadDocumentFolderCode() {
            const staffDocumentFolder = await this.getFolderByCode(STAFF_DOCUMENT_FOLDER_CODE)
            if (staffDocumentFolder) this.documentFolderId = staffDocumentFolder.id
        }
    },
    computed: {
        ...mapState({
            staffTotal: state => state.app.staffTotal,
            staticChoices: state => state.app.staticChoices,
            practiceId: state => state.app.practiceId,
            permissions: state => state.app.permissions,
            folders: state => state.app.folders,
        }),
        tableDataFiltered() {
            let search = this.filterSearchValue.toLowerCase()
            let data = [...this.tableDataArr]

            // Search function
            if(search !== '') {
                data = this.tableDataArr.filter((item) => {
                    let entries = Object.entries(item)
                    const foundEntries = []

                    for (const [key, value] of entries) {
                        if (value !== null && value !== undefined && value !== '') {
                            foundEntries.push(value != null && typeof value == 'string' && value.toLowerCase().includes(search))
                        }
                    }
                    return foundEntries.indexOf(true) > -1
                })
            }
            return data
        },
        titles() {
            return this.staticChoices['practice_staff.title'] || []
        },
        staffTypes() {
            return this.staticChoices['practice_staff.type'] || []
        },
        employmentTypes() {
            return this.staticChoices['practice_staff.employment_type'] || []
        },
        vrStatuses() {
            return this.staticChoices['practice_staff.vr_status'] || []
        },
        canVerifyStaff() {
            return this.permissions && this.permissions.practiceStaff && this.permissions.practiceStaff.verifyStaff
        },
    },
    watch: {
        tableData() {
            this.tableDataArr = this.tableData
        },
        tableDataArr() {
            this.$emit('table-data-change', this.tableDataArr)
        },
        activeRowId() {
            this.tableDataArr = this.filterTable(this.tableDataArr)
        },
        filterSearchValue() {
            // console.log('filterSearchValue', this.filterSearchValue)
        },
        tableDataFiltered() {
            // console.log('tableDataFiltered', this.tableDataFiltered, this.activeRowId)
        },
    },
    beforeDestroy() {
        this.$root.$off('delete-user')
        this.$root.$off('delete-document')
        this.$root.$off('upload-complete')
    },
}
</script>
