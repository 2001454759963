<template>
  <div class="flex">
      <p v-if="discussion && discussion.length > 0">Discussion module</p>
  </div>
</template>

<script>
export default {
    name: 'DiscussionModule',
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        discussion() {
            return this.data.discussion || []
        }
    }
}
</script>
