<template>
    <div v-if="!loading" class="relative">
        <div class="" v-if="computedHistory.length">
            <h3 class="text-lg my-4" data-cy="deleteText">History</h3>
            <div class="max-h-28 h-28 overflow-auto">
                <div class="flex flex-col my-3" v-for="(history, idx) in computedHistory" :key="idx">
                
                <div class="pb-1 font-bold">{{ formatDate(history.timestamp) }}</div>
                <div class="flex flex-row justify-between">
                    <div class="flex flex-col">
                        <div class="font-bold">From:</div>
                        <div>{{ history.sentBy }}</div>
                    </div>
                    <div class="flex flex-col">
                        <div class="font-bold">To:</div>
                        <div>{{ history.sentTo }}</div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <h2 class="text-lg my-4" data-cy="deleteText">Notify the practice</h2>

        <p class="text-base text-center text-grey-1 my-8">You are about to send a notification to the practice this assessment has been reviewed.</p>
        <p class="text-base text-center text-grey-1 my-8">Are you sure?</p>
        <div class="flex flex-col w-full">
            <v-alert
                v-if="showAlert"
                density="compact"
                :closable="false"
                :type="alertType"
                class="text-lg"
            >
                <span class="ml-2">{{ message }}</span>
            </v-alert>
        </div>
        <div class="flex flex-row justify-center my-4">
            <v-btn
                :class="{'disabled': loading }"
                depressed
                @click="cancelHandler"
            >
                No
            </v-btn>
            <v-btn
                class="ml-8"
                depressed
                :class="{'disabled': loading }"
                color="primary"
                @click="confirmHandler"
            >
                <fa-icon v-if="loading" icon="spinner" spin class="mr-2" />
                {{ btnText }}
            </v-btn>
        </div>
    </div>
    <Loader v-else class="w-12 h-12" />
</template>

<script>
import { storeToRefs } from 'pinia'
import { format, parse, isValid } from 'date-fns'
import { useShowModalStore } from '@/stores/useShowModalStore'
import { computed, onMounted, ref } from '@vue/composition-api'
import { useAssessmentStore } from '@/stores/useAssessmentStore'
import { assessmentService } from '@/services/assessment.service'
import { HttpStatusCodes } from '@/config/constants'
import { usePracticeReviewedStore } from '@/stores/usePracticeReviewedStore'
import Loader from '@/assets/loader.svg'


export default {
    name: 'ModalReviewAssessment',
    components: { Loader },
    setup() {
        const practiceReviewedStore = usePracticeReviewedStore()
        const showModalStore = useShowModalStore()
        const { hideModal } = showModalStore
        const { getHistory } = practiceReviewedStore
        const loading = ref(true)
        const assessmentStore = useAssessmentStore()
        const { assessment } = storeToRefs(assessmentStore)
        const { computedHistory } = storeToRefs(practiceReviewedStore)
        const message = ref('')
        const alertType = ref('success')
        const showAlert = computed(() => !!message.value)

        const btnText = computed(() => loading.value ? 'Please wait...' : 'Yes')
        const formatDate = (d) => {
            let parsed = new Date(d)
            if (!isValid(parsed)) parsed = parse(d, 'yyyy-MM-dd hh:mma', new Date())
            if (!isValid(parsed)) {
                const tempDate = d.substring(0, d.length - 2)
                parsed = parse(tempDate, 'yyyy-MM-dd HH:mm', new Date())
            }
            if (isValid(parsed)) return format(parsed, 'h:mmaaa dd/MM/yyyy')
            return ''
        }
        const cancelHandler = () => {
            hideModal()
        }

        const confirmHandler = async () => {
            alertType.value = 'success'
            message.value = ''
            loading.value = true
            // call BE and show error or confirmation
            const { data, status } = await assessmentService.setReviewed(assessment.value?.id)
            message.value = data?.detail || 'Error '
            loading.value = false
            if (status !== HttpStatusCodes.OK) {
                alertType.value = 'error'
                return
            }
            setTimeout(() => {
                message.value = ''
                hideModal()
            }, 2000)
        }

        onMounted(async () => {
            await getHistory(assessment.value?.id)
            loading.value = false
        })

        return { loading, btnText, showAlert, message, alertType, computedHistory, formatDate, cancelHandler, confirmHandler }
    }
}
</script>
