import Vue from 'vue'
import store from '@/store/index'

const setRoute = (route) => {
    if (route.name !== 'Login') {
        store.commit('SET_ROUTE_TO', route)
    }
}

export const authOptions = {
    redirect: (transition) => {
        if (!Vue.auth.check()) {
            setRoute(transition)
            return '/login'
        }
    },
}

export const getAccessToken = () => {
    const authToken = JSON.parse(Vue.auth.token() || '{}')
    return authToken.access
}
export const getAccessTokenAsync = async () => {
    if (Vue.auth.token()) {
        try {
            await Vue.auth.refresh()
        } catch (e) {
            console.log('errored in getting the authToken', e)
        }
    }
    const authToken = JSON.parse(Vue.auth.token() || '{}')
    return authToken.access
}
