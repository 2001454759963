const ProfileMixin = {
    computed: {
        userProfile() {
            const profile = { avatar: '', name: '', firstName: '', lastName: '' }
            if (!this.$auth.user()) return profile

            return {
                ...profile,
                avatar: this.$auth.user().avatar ? decodeURIComponent(this.$auth.user().avatar) : '',
                name: this.$auth.user().name,
                firstName: this.$auth.user().firstName,
                lastName: this.$auth.user().lastName
            }
        },
    }
}

const ProfileUtilities = {
    getInitials(user) {
        return user ? `${user.firstName ? user.firstName[0] : ''}${user.lastName ? user.lastName[0] : ''}` : ''
    },
}

export {
    ProfileMixin,
    ProfileUtilities
}
