<template>
  <div class="grid grid-cols-3">
      <div class="col-span-2 flex flex-col justify-center mr-3">
          <div class="w-full h-2 bg-grey-4 rounded-full">
              <div class="h-full rounded-l-full" :style="styleObj" :class="classObj"></div>
          </div>
      </div>
      <div class="col-span-1">
        <span class="text-grey-2 text-sm">{{ displayTotal }}</span>
      </div>
  </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        progressObj: {
            total: {
                type: Number,
                default: 0
            },
            mets: {
                type: Number,
                default: 0
            },
        },
        status: {
            type: String,
            default: ''
        },
        customColour: {
            type: String,
            default: ''
        },
        words: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        styleObj() {
            return {
                width: `${this.percentage}%`
            }
        },
        percentage() {
            if(this.progressObj.mets === 0 && this.progressObj.total === 0) {
                return 0
            } else {
                return Math.floor(this.progressObj.mets/this.progressObj.total * 100)
            }
        },
        classObj() {
            // console.log('ProgressBar status', this.status)
            if (!this.customColour) {
                if (this.status !== 'IN_PROGRESS') {
                    if (this.percentage <= 50) {
                        return 'bg-red'
                    } else if(this.percentage > 50 && this.percentage < 70) {
                        return 'bg-yellow'
                    } else {
                        return 'bg-green'
                    }
                } else {
                    return 'bg-light-blue'
                }
            } else {
                return this.customColour
            }
        },
        displayTotal() {
            let operator = !this.words ? '/' : ' of '
            return this.progressObj.mets + operator + this.progressObj.total
            
        },
    }
}
</script>
