<template>
    <div>
        <a href="#"
           v-if="item.active && (!item[documentKey] || item[documentKey].archived)"
           class="group hover:text-primary-light"
           data-cy="fileUploadCell"
           @click.prevent="uploadClickHandler(item.id, documentType)"
        >
            <fa-icon icon="paperclip" class="text-primary-light mr-1 group-hover:text-primary-light" data-cy="uploadFile"></fa-icon>
            <span class="uppercase text-xs font-bold text-primary-light group-hover:text-primary-light">Upload File</span>
        </a>
        <div v-else-if="item[documentKey] && !item[documentKey].archived">
            <a href="#" @click.prevent="downloadHandler(item[documentKey].id, 'document')" class="text-primary-light font-bold hover:text-primary">
                <fa-icon icon="download" class="mr-1"/> {{ getTruncatedName(item[documentKey].name) }}
            </a>
            <a href="#" @click.prevent="deleteDocumentHandler(item, documentKey)"
               v-if="item.active && (item[documentKey] && !item[documentKey].archived)"
            >
                <fa-icon icon="times" class="text-red mr-1" data-cy="clearFile"></fa-icon>
            </a>
        </div>
    </div>
</template>

<script>
import FileMethods from '@/components/shared/mixins/fileMethods'

export default {
    name: "FileUploadCell",
    mixins: [ FileMethods ],
    props: {
        item: {
            type: Object,
            default: () => {}
        },
        documentType: {
            type: String,
            default: 'primary'
        },
        documentKey: {
            type: String,
            default: ''
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        folderId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            focused: false,
        }
    },
    methods: {
        cellClickHandler() {
            this.focused = true
            this.$emit('edit-row', this.cellId)
        },
        onCellClick(e) {
            console.log('onCellClick', e)
        },
        deleteDocumentHandler(item, documentType) {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalDeleteDocument')
            this.$store.commit('MODAL_DATA', item[documentType])
            this.$emit('delete-document')
        },
        uploadClickHandler(staffId, documentType) {
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalUpload')
            this.$store.commit('MODAL_DATA', {
                fileList: this.fileList,
                documentLink: {},
                indicatorFileUpload: false,
                allowMultiple: false,
                documentType: documentType,
                staffId: staffId,
                folderId: this.folderId
            })
            this.$emit('upload-document')
        },
        getTruncatedName(name) {
            if (!name) return ''
            if (name.length < 10) return name

            const splitArr = name.split('.')
            const ext = splitArr.pop()
            const newName = splitArr.join('.')
            return `${newName.substring(0, 5)}..${ext}`
        },
    },
}
</script>
