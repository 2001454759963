export default {
    methods: {
        getPostOrPatchOptions(data, endpoint, isGet=false, isPut=false) {
            if (!data || data === {}){
                throw new Error(`can't create options with a null or empty object`)
            }
            // we strip off the ending slash
            endpoint = endpoint.replace(/\/$/, '')
            let method = 'PATCH'
            
            const endpointUrl = data && data.id == null ?
                `${endpoint}/` : `${endpoint}/${data.id}/`
            if (isGet) {
                return { method: 'GET', url: endpointUrl }
            }
            if (data && data.id == null) {
                method = 'POST'
            } else if (isPut) {
                method = 'PUT'
            }
    
            return { method: method, url: endpointUrl, data: data }
        },
    }
}
