<template>
    <el-upload
        ref="upload"
        class="c-documents__upload-item"
        action="/document"
        :before-remove="beforeRemove"
        :before-upload="beforeUpload"
        :multiple="allowMultiple"
        drag
        :on-exceed="handleExceed"
        :file-list="filesList"
        :show-file-list="true"
        :http-request="uploadHandler"
        :accept="allowedMimeTypes"
        :auto-upload="false"
    >
        <DocumentSvg class="m-4" :style="svgStyle"/>

        <el-button
            :disabled="showProgress"
            size="small" type="primary"
            class="bg-primary-light font-bold border-primary-light text-white text-sm uppercase p-4 px-6 my-4 mb-4 rounded-lg"
            data-cy="uploadDoc"
        >
            Choose File
        </el-button>
        <p class="text-md mb-4 mx-0 text-center text-grey-2 text-sm">or drag and drop files here</p>

        <div v-if="showProgress" class="w-full">
            <div v-for="(item, index) in progress"
                    :key="`progress-bar-${index}`"
                    class="w-full"
            >
                <span>{{item.name}}</span>
                <el-progress class="c_document__upload-progress" :percentage="item['uploadPercentage']"></el-progress>
            </div>
        </div>
    </el-upload>
</template>

<script>
import { mapState } from 'vuex'
import DocumentSvg from '@/assets/documents.svg'
import snakecaseKeys from 'snakecase-keys'
import HttpMixin from '@/components/shared/mixins/httpMixin'

export default {
    name: 'UploadInterface',
    mixins: [ HttpMixin ],
    components: {
        DocumentSvg
    },
    props: {
        filesList: {
            type: Array,
            default: () => []
        },
        indicatorFileUpload: {
            type: Boolean,
            default: false
        },
        indicatorRespId: {
            type: Number,
            default: null
        },
        allowMultiple: {
            type: Boolean,
            default: true
        },
        documentLink: {
            type: Object,
            default: () => {}
        },
        documentType: {
            type: String,
            default: ''
        },
        folderId: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            numFiles: 0,
            numCompleted: 0,
            errorMessage: '',
            showProgress: false,
            svgStyle: {
                width: '120px',
                height: '131px'
            },
            progress: [],
            type: '',
            byteToMB: 1 / Math.pow(1024, 2),
            defaultMaxFileSize: 100 * Math.pow(1024, 2),
            allowedMimeTypes: [
                "image/jpeg,image/png",
                "application/pdf",
                "image/gif",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/vnd.ms-excel",
                "application/vnd.apple.pages",
                ".pages"
            ].join(',')
        }
    },
    methods: {
        upload() {
            this.$refs.upload.submit();
        },
        handleExceed(files, filesList) {
            this.$message.warning(`The limit is 3, you selected ${files.length} files this time, add up to ${files.length + filesList.length} totally`)
        },
        beforeRemove(file) {
            return this.$confirm(`Cancel the transfer of ${file.name} ?`)
        },
        beforeUpload(file) {
            console.log('arguments', arguments)
            // closing any existing message boxes
            const fileSize = file.size
            if (fileSize > this.maxFileSize) {
                const maxSize = Math.round(this.byteToMB * this.maxFileSize)
                this.message = `File size exceeded ${maxSize}MB`
                this.type = "error"
                this.$emit('show-message', {message: this.message, type: this.type})
                return false
            }
            this.$emit('reset-message')
            this.errorMessage = ''
            this.showProgress = true
            this.numFiles += 1
            console.log(file)
            return true
        },
        onUploadProgress(progressEvent) {
            console.log(progressEvent)
        },
        timeout(ms) {
            return new Promise(resolve => setTimeout(resolve, ms))
        },
        uploadComplete(id) {
            // only taking into account single document uploads for now
            this.$emit('upload-complete', {id: id, documentType: this.documentType})
        },
        uploadHandler(request) {
            console.log('uploadHandler file, filesList', request)
            let documentPk = null, url = 'document/'
            const fileToUpload = request.file
            let payload = {
                file: fileToUpload.name,
                name: fileToUpload.name,
                practice: this.practiceId,
                folder: this.folderId
            }

            if (this.documentLink && Object.keys(this.documentLink).length > 0) payload = {...payload, ...this.documentLink}

            payload = snakecaseKeys(payload, {deep: true, exclude: ['__typename']})
            this.$http.post(url, payload)
            .then(( { data: {pk, urls, timeout } } ) => {
                documentPk = pk
                this.progress.push({id: documentPk, name: fileToUpload.name, uploadPercentage: 0})
                const options = {
                    headers: {
                        "content-type": "application/x-www-form-urlencoded"
                    },
                    onUploadProgress: function( progressEvent ) {
                        console.log('progressEvent', progressEvent)
                        if (!isNaN(progressEvent.loaded)) {
                            // need to get the total from the progressEvent
                            const idx = this.progress.findIndex(p => p.id === documentPk)
                            if (idx > -1) {
                                this.progress[idx]['uploadPercentage'] = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
                            }
                        }
                    }.bind(this)
                };
                return this.$http.put(urls.file, fileToUpload, options)
            }).then((val) => {
                // TODO: remove this or do some other clean up if the file failed to upload
                // now we post the updated values to django to store
                const data = {
                    id: documentPk,
                    file_size: fileToUpload.size,
                    file_type: fileToUpload.type
                }
                if (this.documentLink && Object.keys(this.documentLink).length > 0) {
                    data['indicator_response_id'] = this.documentLink.indicatorResponseId
                }
                const httpOptions = this.getPostOrPatchOptions(data, 'document', false, true)

                return this.$http(httpOptions)
            }).then(({ data }) => {
                // now we get the document so we can send it back to whomever openned this component
                this.uploadComplete(documentPk)
            }).catch((err) => {
                console.log('error uploading file', err)
                // checking 403 error cause this is the AWS error when the file times out
                let message = null;
                if (err.status == 403) {
                    if (this.numFiles > 1) {
                        message = '1 or more of the files were too large'
                    } else {
                        message = 'The file was too large'
                    }
                    message += ', please contact your systems administrator'
                } else {
                    message = (err.response?.data && typeof err.response.data === 'string' ? err.response.data : 'Failed to upload file(s), please try again')
                }

                this.errorMessage = (this.errorMessage ? this.errorMessage + '<br />' : '') + message;
                // if the file failed to upload, then we should try and delete it
                if (documentPk != null) {
                    this.$http
                        .delete(`document/${documentPk}/`)
                        .then((response) => {
                            console.log('delete response', response)
                        })
                        .catch(err => err)
                }
            })
            .finally(async () => {
                this.numCompleted += 1
                if (this.numFiles === this.numCompleted) {
                    if (!this.errorMessage) {
                        this.type = 'success'
                        this.$root.$emit('document-list-update', true)
                        this.message = 'File upload was successful!'
                    } else {
                        this.type = 'error'
                        this.message = this.errorMessage
                    }
                    this.$emit('show-message', {message: this.message, type: this.type, options: {dangerouslyUseHTMLString: true}})

                    // do some clean up if we have completed everything
                    this.progress = []
                    // and then we reset the numCompleted
                    this.numCompleted = 0
                    this.showProgress = false
                    this.numFiles = 0
                }
            })
        }
    },
    computed: {
        ...mapState({
            practiceId: state => state.app.practiceId,
            staticChoices: state => state.app.staticChoices
        }),
        maxFileSize() {
            // default is 100MB - change data variable to change it
            return this.staticChoices['max_file_size']?.value || this.defaultMaxFileSize
        }
    }
}
</script>
