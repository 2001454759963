<template>
    <div class="c-documents__upload" data-cy="documentsUpload">
        <UploadInterface :filesList="filesList" @show-message="showMessage" @reset-message="resetMessage" />
    </div>
</template>

<script>
import UploadInterface from '@/components/partials/UploadInterface'

export default {
    name: 'UploadComponent',
    components: {
        UploadInterface
    },
    props: {
        filesList: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        showMessage(message) {
            this.$emit('show-message', message)
        },
        resetMessage() {
            this.$emit('reset-message')
        }
    }
}
</script>
