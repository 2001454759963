<template>
    <div class="c-editing-active-module flex flex-row self-center">
        <div class="flex flex-col">
            <div class="m-0 flex flex-row items-center">
                <div class="flex items-center h-10 ml-4 p-0">
                    <div 
                        class="flex items-center"
                        v-for="(item, index) in userArr"
                        :key="`user-avatar-${index}`"
                        :style="getOffset(index)"
                        data-cy="user"
                    >
                        <div class="absolute w-6 h-6 lg:p-0 lg:w-8 lg:h-8 rounded border border-white bg-primary-light rounded-full flex items-center justify-center user-circle" data-cy="user-initials">
                            <p class="text-white text-xxs lg:text-xs font-display font-semibold">{{ getInitials(item.user) }}</p>
                        </div>
                        <div class="w-10 h-6 lg:h-10"></div>
                    </div>
                </div>
                <i class="text-primary text-xs lg:text-sm mr-2 leading-tight" v-if="userArr.length" data-cy="user-message">{{ indicatorMessage }}</i>
            </div>        
        </div>
    </div>
</template>
<script>
export default {
    name: 'EditingActiveModule',
    props: {
        users: {
            type: Array,
            default: () => []
        },
        indicatorRespId: {
            type: Number,
            default: null
        },
        userId: {
            type: Number,
            default: null
        },
        saving: {
            Boolean,
            default: false
        }
    },
    methods: {
        userNames() {
            if (this.userArr.length) {
                let users = ''
                let verb = this.userArr.length === 1 ? 'is' : 'are'
                
                for (let item of this.userArr) {
                    if (item.userId === this.userId) {
                        let youStr = this.userArr.length === 1 ? 'You ' : 'You and '
                        users = youStr.concat(users)
                        verb = 'are'
                    } else {
                        users += `${item.user.firstName} ` 
                    }
                }
                users += verb

                return users
            }
            return ''
        },
        getInitials(item) {
            if (item !== null) {
                return `${item.firstName.slice(0, 1)}${item.lastName.slice(0, 1)}`
            } else {
                return '-'
            }
        },
        getOffset(index) {
            return `margin-left: -${index * 21}px`
        },

    },
    computed: {
        indicatorMessage() {
            if (this.saving) {
                return 'Saving indicator, please wait...'
            } else {
                return `${this.userNames()} editing this indicator`
            }
        },
        userArr() {
            if (this.users.length) {
                let arr = this.users.filter(item => {
                    return item.indicatorResponseId === this.indicatorRespId
                })
                return arr
            }
            return [
                // use for testing
                // {"assessmentId":990,"indicatorResponseId":105332,"userId":3258,"user":{"lastName":"R","firstName":"C","typename":"user"},"fields":null,"typename":"indicator_lock"},
                // {"assessmentId":990,"indicatorResponseId":105332,"userId":3262,"user":{"lastName":"SU","firstName":"Meena","typename":"user"},"fields":"observation","typename":"indicator_lock"}
            ]
        }
    },
}
</script>
