<template>
    <v-form ref="moveForm" v-model="isValid" class="flex flex-col items-center justify-center">
        <h3>Move to folder</h3>
        <p class="mt-6 mb-6">
            Move selected documents to a different folder
        </p>
        <div class="mb-4">
            <v-select solo flat dense
                label="Select folder"
                style="width: 300px"
                :items="foldersFiltered"
                item-text="name"
                item-value="id"
                v-model="folderId"
                :rules="[required]"
            ></v-select>
        </div>
        <div>
            <v-btn color="default" depressed @click.prevent="cancelHandler">Cancel</v-btn>
            <v-btn
                class="ml-2"
                color="success"
                depressed
                @click.prevent="submitHandler"
            >Move</v-btn>
        </div>
    </v-form>
</template>

<script>
import {mapState} from 'vuex'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import {FormRules} from '@/components/shared/mixins/formMixins'

export default {
    name: 'ModalMoveToFolder',
    components: {},
    mixins: [MessageDialog, FormRules],
    props: {
        fileList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isValid: true,
            folderId: ''
        }
    },
    beforeMount() {
        this.$store.dispatch('fetchFolders', {
            apolloClient: this.$apolloProvider.defaultClient,
            practiceId: this.practiceId
        })
    },
    methods: {
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        submitHandler() {
            this.isValid = this.$refs.moveForm.validate()
            if (!this.isValid) return

            this.cancelHandler()
            this.$root.$emit('move-documents', this.folderId)
        },
        showMessageEvent(message) {
            this.message = message.message
            this.type = message.type
            this.showMessage()
        },
        resetMessageEvent() {
            this.resetMessage()
        }
    },
    computed: {
        ...mapState({
            folders: state => state.app.folders,
            practiceId: state => state.app.practiceId,
            componentData: state => state.app.modal.componentData
        }),
        foldersFiltered() {
            if (!this.currentFolderId) return this.folders

            return this.folders.filter(({id}) => id != this.currentFolderId)
        },
        currentFolderId() {
            return this.componentData?.folderId
        }
    },
    beforeDestroy() {
        this.resetMessage()
    }
}
</script>
