<template>
    <div>
        <component
            :is="renderForm"
            :model="formInput" 
            :rules="rules"
            ref="formInput"
            class="c-editable-cell"
        >
            <el-form-item prop="inputValue">
                <component
                    :is="renderEditableComponent"
                    v-show="editActive"
                    ref="input"
                    v-model="formInput.inputValue"
                    class="p-0 m-0"
                    :trigger-on-focus="false"
                    @select="handleSelect"
                    :fetch-suggestions="fetchSuggestions"
                    :disabled="disabled"
                >
                    <template slot-scope="{ item }">
                        <div>{{ getDropdownDisplay(item) }}</div>
                    </template>
                </component>
            </el-form-item>
        </component>
        <el-link
            @click="cellClickHandler"
            v-show="!editActive"
            class="justify-start py-1 m-0 hover:text-grey-1 text-grey-2 leading-normal border border-transparent border-l-0 border-r-0 border-t-0"
            :v-html="!linkText ? '&nbsp;': ''"
            :class="{'w-4/5' : !linkText, 'fieldEmpty': !formInput.inputValue}"
        >
            {{ linkText }}
        </el-link>
    </div>
</template>

<script>
export default {
    name: 'AutocompleteCell',
    props: {
        value: {
            type: String,
            default: ''
        },
        displayValue: {
            type: String,
            default: ''
        },
        cellId: {
            type: Number,
            default: null
        },
        editActive: {
            type: Boolean,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        fetchSuggestions: {
            type: Function,
            default: () => {}
        },
        getDropdownDisplay: {
            type: Function,
            default: (item) => {}
        },
        defaultValue: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            focused: false,
            formInput: {inputValue: ''},
            rules: {
                inputValue: [
                    { required: this.isRequired, message: 'Required field', trigger: 'blur' },
                ],
            },
        }
    },
    mounted() {
        this.formInput.inputValue = this.value || this.defaultValue
    },
    methods: {
        cellClickHandler() {
            if (this.disabled) return

            this.focused = true
            this.$emit('edit-row', this.cellId)
        },
        validate(){
            if (this.$refs.formInput) return this.$refs.formInput.validate()
        },
        handleSelect(item){
            this.formInput.inputValue = item.registrationNum
            this.$emit('select', item)
        },
        clearValidate(){
            return this.$refs.formInput.clearValidate()
        }
    },
    computed: {
        linkText(){
            let text =  this.displayValue || this.value || this.defaultValue
            return text || ''
        },
        renderEditableComponent() {
            if (this.editActive) return 'el-autocomplete'
            return ''
        },
        renderForm() {
            if (this.editActive) return 'el-form'
            return ''
        },
    },
    watch: {
        value() {
            if (this.value) this.formInput.inputValue = this.value
        },
        'formInput.inputValue': function() {
            if (this.editActive) this.$emit('input', this.formInput.inputValue)
        },
    },
}
</script>
