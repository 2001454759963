import {
    MODAL_IS_VISIBLE,
    MODAL_DATA,
    MODAL_COMPONENT_NAME,
    UPDATE_LOGIN_EMAIL,
    SET_STAFF_TOTAL,
    SET_STAFF_FIRST_VIEW,
    SET_DOCUMENTS_FIRST_VIEW,
    SET_ACCREDITATION_FIRST_VIEW,
    SET_PRACTICE_ID,
    SET_STATIC_OBJECT,
    SET_DOCS_TOTAL, SET_LIST_FILTER,
    SET_PERMISSIONS,
    SET_ROUTE_TO,
    SET_ROUTE_FROM, SET_WEBINAR_ACCESS_OBJECT,
    SET_FOLDERS, SET_GET_VISIBLE_FOLDERS
} from './mutationTypes'
import { SET_WEBINAR_LOGIN_CODE } from '@/store/base/mutationTypes'

export default {
    [MODAL_IS_VISIBLE](state, isVisible) {
        state.modal.isVisible = isVisible
    },
    [MODAL_DATA](state, componentData) {
        state.modal.componentData = componentData
        // console.log('MODAL_IS_VISIBLE state.modal.componentData ', state.modal.componentData )
    },
    [MODAL_COMPONENT_NAME](state, componentName) {
        state.modal.componentName = componentName
        // console.log('MODAL_IS_VISIBLE state.modal.componentName ', state.modal.componentName )
    },
    [UPDATE_LOGIN_EMAIL](state, email) {
        state.loginEmail = email
    },
    [SET_STAFF_TOTAL](state, staffTotal) {
        state.staffTotal = staffTotal
    },
    [SET_STAFF_FIRST_VIEW](state, isFirstView) {
        state.isStaffFirstView = isFirstView
    },
    [SET_DOCUMENTS_FIRST_VIEW](state, isFirstView) {
        state.isDocumentsFirstView = isFirstView
    },
    [SET_ACCREDITATION_FIRST_VIEW](state, isFirstView) {
        state.isAccreditationFirstView = isFirstView
    },
    [SET_PRACTICE_ID](state, practiceId) {
        state.practiceId = practiceId
    },
    [SET_STATIC_OBJECT](state, staticChoices) {
        state.staticChoices = staticChoices
    },
    [SET_DOCS_TOTAL](state, docsTotal) {
        state.docsTotal = docsTotal
    },
    [SET_LIST_FILTER](state, listFilter) {
        state.listFilter = listFilter
    },
    [SET_PERMISSIONS](state, permissions) {
        state.permissions = permissions
    },
    [SET_ROUTE_TO](state, routeTo) {
        state.routeTo = routeTo
    },
    [SET_ROUTE_FROM](state, routeFrom) {
        state.routeFrom = routeFrom
    },
    [SET_WEBINAR_LOGIN_CODE](state, webinarLoginCode) {
        state.webinarLoginCode = webinarLoginCode
    },
    [SET_WEBINAR_ACCESS_OBJECT](state, webinarAccessObject) {
        state.webinarAccessObject = webinarAccessObject
    },
    [SET_FOLDERS](state, folders) {
        state.folders = folders
    },
    [SET_GET_VISIBLE_FOLDERS](state, getVisibleFolders) {
        state.getVisibleFolders = getVisibleFolders
    },
}
