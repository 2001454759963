<template>
    <div class="relative">
        <h2 class="text-2xl text-center my-4">Approve for Certification Authority</h2>
        <p class="text-base text-center text-grey-1 my-8">This will notify a certification authority to certify this practice. <br/>Are you sure you would like to approve this assessment?</p>
        <div class="flex flex-row justify-center my-4">
            <el-button 
                @click="cancelHandler"
            >
                Cancel
            </el-button>
            <el-button
                type="primary"
                @click="approveHandler"
            >
                Yes, approve
            </el-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ModalApproveCert',
    data() {
        return {
            type: 'approve'
        }
    },
    methods: {
        cancelHandler() {
            // console.log('cancelHandler')
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        approveHandler() {
            // commit mutation
            this.$root.$emit('assessment-action', this.type)
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
    }
}
</script>
