<template>
    <div class="relative">
        <h2 class="text-lg my-4" data-cy="deleteText">Are you sure you want to delete your unavailability?</h2>
        <p class="text-base text-center text-grey-1 my-8">{{ userName }}</p>
        <div class="flex flex-row justify-center my-4">
            <el-button
                    type="primary"
                    @click="deleteHandler"
            >
                Delete Unavailability
            </el-button>
            <el-button
                    @click="cancelHandler"
                    data-cy="cancelHandler"
            >
                Cancel
            </el-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: "ModalDeleteUnavailability",
    computed: {
        ...mapState({
            reasonData: state => state.app.modal.componentData
        }),
        userName() {
            return this.reasonData ? this.reasonData.reason || '' : ''
        }
    },
    mounted() {
        // console.log('mounted() deleteHandler', this.reasonData)
    },
    methods: {
        deleteHandler() {
            // console.log('deleteHandler')
            this.$root.$emit('delete-unavailability', this.reasonData)
        },
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
    }
}
</script>

