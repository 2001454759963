
const AuthMixin =  {
    computed: {
        isStaff() {
            return this.$auth.user() && this.$auth.user()?.staff
        },
        isSurveyor() {
            return this.$auth.user() && this.$auth.user()?.surveyor
        },
        isAdmin() {
            return this.$auth.user() && this.$auth.user()?.admin
        },
        hasSinglePractice() {
            return this.$auth.user() ? this.$auth.user()?.singlePractice || false : false
        },
        isNewUser() {
            // user is new if they aren't an admin and the backend returns the newUser value
            return this.$auth.user() && this.$auth.user()?.newUser && !this.isAdmin || false
        },
        isPracticeManager() {
            return this.$auth.user()?.isPracticeManager ?? false
        },
        hasSpecialAccess() {
            return this.$auth.user()?.hasSpecialAccess
        },
        userId() {
            return this.$auth.user() ? this.$auth.user().userId : null
        },
        userName() {
            return this.$auth.user() ? this.$auth.user().name : null
        },
        userEmail() {
            return this.$auth.user() ? this.$auth.user().email : null
        },
    }
}

const AuthUtilities = {
    setAuthCookies(responseData) {
        // TODO: maybe remove cookies if this is null
        if (!responseData) return

        let csrfToken = responseData.csrftoken,
            connect2SessionId = responseData.sessionid,
            _cookies = this.$cookies || this.Vue.$cookies

        if (connect2SessionId) _cookies.set(process.env.VUE_APP_COOKIE_ID, connect2SessionId, process.env.VUE_APP_COOKIE_TIMEOUT, '', process.env.VUE_APP_COOKIE_DOMAIN)
        if (csrfToken) _cookies.set(process.env.VUE_APP_COOKIE_CSRF, csrfToken, process.env.VUE_APP_COOKIE_TIMEOUT, '', process.env.VUE_APP_COOKIE_DOMAIN)
    },
    clearAuthCookies() {
        const _cookies = this.$cookies || this.Vue.$cookies
        _cookies.remove(process.env.VUE_APP_COOKIE_ID, '', process.env.VUE_APP_COOKIE_DOMAIN)
        _cookies.remove(process.env.VUE_APP_COOKIE_CSRF, '', process.env.VUE_APP_COOKIE_DOMAIN)
    }
}

export {
    AuthMixin,
    AuthUtilities
}
