export default {
    methods: {
        snakeToTitleCase(value) {
            if (!value) return ''

            //ref: https://gist.github.com/kkiernan/91298079d34f0f832054
            return value.split('_').map((item) => {
                return `${item.charAt(0).toUpperCase()}${item.substring(1)}`
            }).join(' ')
        },
        showMessage(options) {
            this.currentMessageDialog = this.$message({
                showClose: true,
                message: this.message,
                type: this.type,
                duration: 0,
                ...(options || {})
            });
        },
        resetMessage() {
            this.message = ''
            this.type = ''
            if (this.currentMessageDialog) {
                this.currentMessageDialog.close()
                this.currentMessageDialog = null
            }
        },
    }
}
