export default {
    computed: {
        summaries() {
            let generalComments = this.generalComments || []
            if (this.assessmentData && this.assessmentData.surveyVisit && this.assessmentData.surveyVisit.surveyVisitors) {
                const surveyVisitor = this.assessmentData.surveyVisit.surveyVisitors.find(sv => this.$auth.user() && sv.user.id === this.$auth.user().userId)
                const surveyors = this.assessmentData.surveyVisit.surveyVisitors
                const surveyorIds = surveyors.map(sv => sv.user.id) || []
                
                generalComments = generalComments.filter(gc => surveyorIds.indexOf(gc.user.id) > -1)

                if (surveyVisitor) {
                    // first we get the index of the current logged in user
                    const idx = generalComments.findIndex(gc => gc.user.id === this.$auth.user().userId)
                    if (idx > -1) {
                        generalComments = [generalComments[idx], ...generalComments.slice(0, idx), ...generalComments.slice(idx + 1)]
                    } else {
                        generalComments = [{
                            id: Math.floor(Math.random() * 100000),
                            text: '',
                            user: surveyVisitor.user || surveyVisitor,
                            newComment: true,
                        }, ...generalComments.slice(0, idx), ...generalComments.slice(idx + 1)]
                    }
                }
        
                // now we check to see if we have all the surveyor boxes
                if (generalComments.length < surveyors.length) {
                    // we check both because we could be dealig with a user object or the current logged in user
                    // each one could have the id on one of the fields
                    const haveSurveyorIds = generalComments.map(gc => {
                        return gc.user.id
                    })
                    generalComments = [...generalComments, ...surveyors.reduce((prev, curr) => {
                        if (haveSurveyorIds.indexOf(curr.user.id) === -1) {
                            prev.push({
                                id: Math.floor(Math.random() * 100000),
                                text: '',
                                user: curr.user || curr,
                                newComment: true,
                            })
                        }
                        return prev
                    }, [])]
                }
            } else {
                generalComments = []
            }

            return generalComments
        }
    }
}
