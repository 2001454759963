<template>
    <component
        v-if="isFirstView || this.forceAccreditationFirstView"
        :is="firstStateComponentName"
        :accreditation="currentAccreditation"
        @start-assessment="startedAssessment"
    />
    <div class="c-accreditation flex flex-col" v-else>
        <div
            class="flex flex-col w-full"
            v-if="certificateData && certificateData.length > 0"
            data-cy="accreditationDownload"
        >
            <h2 class="text-lg lg:text-xl mt-4">Accreditations</h2>
            <ul class="flex flex-row w-full items-start ml-0 overflow-x-auto">
                <li
                    v-for="(cert, index) in certificateData"
                    :key="`cert-${index}`"
                    data-cy="accreditationDownload"
                    class="certificateBox bg-primary-lightest py-3 pl-2 pr-4 m-4 ml-0 text-primary-light flex flex-row rounded"
                >
                    <div class="w-14">
                        <CertIcon class="fill-current" />
                    </div>
                    <div class="flex flex-col justify-center ml-2">
                        <span class="m-0 text-grey-1 text-sm">
                            {{ cert.name }}
                        </span>
                        <div class="flex flex-row">
                            <span class="text-sm inline text-grey-2 mr-2">{{ cert.date }}</span>
                            <a v-if="canDownloadCert(cert)" class="pull-left m-0 hover:text-primary cursor-pointer my-1 uppercase text-xs font-bold" href="#" @click.prevent="downloadHandler(cert)">
                                <fa-icon icon="download" class="mr-1"/>Download
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="flex flex-col">
            <div class="flex flex-row mt-8 justify-between items-center text-primary">
                <h2 class="text-lg lg:text-xl" data-cy="assessmentsTitle">Assessments</h2>
            </div>
            <el-table
                :data="tableDataFiltered"
                v-if="tableDataFiltered"
                class="w-full cursor-pointer"
                ref="assessTable"
                :row-class-name="rowClassName"
                :default-sort="{prop: 'type', order: 'descending'}"
                data-cy="table"
                @row-click="rowClickHandler"
            >
                <el-table-column
                    prop="type"
                    label="type"
                    sortable
                    :sort-method="sortByDate"
                    width="350"
                >
                    <template slot-scope="scope">
                        <div class="flex flex-row">
                            <StatusIcon class="w-14" :rowData="scope.row" />
                            <p class="ml-3 text-grey-1 flex flex-row justify-center xl:flex-row items-center">
                                <span class="mr-1">{{ getType(scope.row) }}</span>
                                <span class="text-grey-2 text-sm">{{ getDate(scope.row) }}</span>
                                <span
                                    v-if="isRowLocked(scope.row)"
                                    class="ml-2"
                                >
                                    <fa-icon icon="lock"></fa-icon>
                                </span>
                                <el-tooltip
                                    v-if="scope.row.type === 'SURVEY_VISIT' && scope.row.surveyVisit.reasonForVisit"
                                    effect="dark"
                                    :content="getVisitReasonText(scope.row.surveyVisit.reasonForVisit)"
                                    placement="right"
                                >
                                    <span class="ml-2">
                                        <fa-icon icon="question-circle" />
                                    </span>
                                </el-tooltip>
                            </p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="dueDate"
                    label="due date"
                    sortable
                    width="150"
                >
                    <template slot-scope="scope">
                        <p class="flex">{{ getDueDate(scope.row) }}</p>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="updated"
                    label="last updated"
                    sortable
                    width="150"
                >
                </el-table-column>
                <el-table-column
                    prop=""
                    label="Assessment Report"
                    width="180"
                    sortable
                >
                    <template slot-scope="scope">
                        <div>
                            <StatusBadge :statusType="getStatusType(scope.row)" />
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop=""
                    label="action plan"
                    width="260"
                    sortable
                >
                    <template slot-scope="scope">
                        <div>
                            <StatusBadge :statusType="getActionPlanType(scope.row)" />
                            <span class="ml-2 my-1 text-red-dark text-xs hidden lg:inline" v-if="scope.row.status === 'ACTION_REQUIRED'">{{ scope.row.notMets.aggregate.count }} NOT MET</span>
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column
                    prop="status"
                    label="progress"
                >
                    <template slot-scope="scope">
                        <ProgressBar class="w-40" :progressObj="setProgress(scope.row)" :status="scope.row.status" />
                    </template>
                </el-table-column> -->
                <el-table-column
                    prop="confirmationFormDownload"
                    label="Survey Confirmation"
                    minWidth="150px"
                >
                    <template slot-scope="scope">
                        <a
                            v-if="scope.row.surveyVisit && scope.row.surveyVisit.status === 'FINAL'"
                            href="#"
                            class="group uppercase text-primary text-sm text-primary-light hover:text-primary"
                            @click.stop.prevent="downloadSurveyConfirmationFormHandler(scope.row.surveyVisit.id)"
                        >
                            <fa-icon icon="download" class="mr-1 group-hover:text-primary-light" /> 
                            <span class="font-bold group-hover:text-primary-light text-xs">Download</span>
                        </a>
                    </template>
                </el-table-column>
                <el-table-column
                    prop=""
                    label=""
                    fixed="right"
                    width="30"
                >
                    <template slot-scope="scope">
                        <fa-icon icon="chevron-right" class="text-grey-2" v-if="!(isRowLocked(scope.row))" />
                    </template>
                </el-table-column>
            </el-table>
            <div class="w-full" v-else>
                <p class="text-grey-1 text-lg">No Assessments available</p>
            </div>
        </div>
    </div>
</template>

<script>
import GET_ACCREDITATION_DATA from '@/graphql/queries/getAccreditations.gql'
import StatusBadge from '@/components/partials/StatusBadge'
import ProgressBar from './partials/ProgressBar'
import DiscussionModule from './partials/DiscussionModule'
import StatusIcon from './partials/StatusIcon'
import { format } from 'date-fns'
import { mapState } from 'vuex'
import AsDetails from './mixins/asDetails'
import Loader from '@/assets/loader.svg'
// svgs
import CertIcon from '@/assets/icons/icons_certificate_blue.svg'
import FirstStateAccreditation from './partials/FirstState'
import camelcaseKeys from 'camelcase-keys'
import { AuthMixin } from '@/components/shared/mixins/authHelpers'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import FileMethods from '@/components/shared/mixins/fileMethods'
import AgreementMethods from '@/components/shared/mixins/agreementMethods'
import snakecaseKeys from 'snakecase-keys'
import { getType } from '@/utils/surveyVisit.helpers'

export default {
    name: 'AccreditationComponent',
    components: {
        FirstStateAccreditation,
        CertIcon,
        StatusBadge,
        StatusIcon,
        Loader,
        ProgressBar,
        DiscussionModule,
    },
    mixins: [AuthMixin, AsDetails, MessageDialog, FileMethods, AgreementMethods],
    apollo: {
        accreditationData: {
            query: GET_ACCREDITATION_DATA,
            variables() {
                return {
                    practiceId: this.practiceId
                }
            },
            skip() {
                return !this.practiceId
            },
            update: data => {
                return camelcaseKeys(data || [], { deep: true })
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            },
        },
    },
    props: {
        forceAccreditationFirstView: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            loading: true
        }
    },
    mounted() {
        // Global event listener
        this.$root.$on('agreement-action', this.updateAssessmentSignsObj)
        this.$root.$on('download-action', this.downloadCertHandler)
    },
    methods: {
        getType: getType,
        getDueDate(row) {
            if (row.type === 'PRESURVEY' && row.status != 'COMPLETED') {
                if (row.accreditation != null && Object.keys(row.accreditation).length) {
                    if (row.accreditation.selfAssessmentDue) {
                        return format(new Date(row.accreditation.selfAssessmentDue), 'dd MMM yy')
                    } else {
                        return ''
                    }
                }
            } else {
                if (row.status === 'ACTION_REQUIRED') {
                    if (row.actionPlanDue !== null) return format(new Date(row.actionPlanDue), 'dd MMM yy')
                    return ''
                }
            }
        },
        rowClickHandler(row) {
            // console.log('rowClickHandler row', row)
            if (!this.isRowLocked(row)) {
                if (!this.requiresAgreement(row)) {
                    this.navigateToAssessment(row)
                } else {
                    if (this.userHasSignedAgreement(row)) {
                        this.navigateToAssessment(row)
                    } else {
                        this.renderAgreementModal(row)
                    }
                }
            }
        },
        navigateToAssessment(row) {
            this.$router.push({
                name: 'Assessment',
                params: {
                    assessmentId: row.id.toString()
                }
            }).catch(err => err)
        },
        getCertName(obj) {
            let year = format(new Date(obj.expiryDate), 'yyyy')
            let date = format(new Date(obj.expiryDate), 'MMM yyyy')
            if (obj.type === 'ACCREDITATION') {
                if (obj.issuingProvider === 'QPA') {
                    return `QPA Certification ${year}`
                } else {
                    return `Third party certification ${year}`
                }
            } else if (obj.type === 'REGISTRATION') {
                return `Notice of Registration ${year}`
            } else if (obj.type === 'EXTENSION') {
                return `Notice of Extension ${year}`
            } else {
                return `Null value ${year}`
            }
        },
        downloadCertHandler(docObj) {
            let payload
            if (docObj && docObj.documentSize) {
                payload = {
                    size: docObj.documentSize
                }
            }
            let url = `web.certificate/${docObj.id}/download_certificate/`
            this.downloadRemoteFileHandler(docObj.certificateName, url, null, payload)
        },
        downloadHandler(cert) {
            console.log('doanload cert handler', cert)
            this.certId = cert.id
            if (!cert.certificate && cert.issuingProvider === 'QPA' && cert.type === 'ACCREDITATION') {
                this.$store.commit('MODAL_IS_VISIBLE', true)
                this.$store.commit('MODAL_COMPONENT_NAME', 'ModalDownloadCertificate')
                this.$store.commit('MODAL_DATA', {certificateName: cert.name, id: cert.id})
            } else {
                this.downloadCertHandler(cert)
            }
        },
        canDownloadCert(cert) {
            if (cert.issuingProvider !== 'QPA' && cert.type === 'ACCREDITATION') return cert.certificate
            return cert.type === 'REGISTRATION' || cert.issuingProvider === 'QPA'
        },
        rowClassName({row}) {
            if (row.status === 'ACTION_REQUIRED') {
                return 'warning-row'
            }
            if (this.isRowLocked(row)) {
                return 'locked-row'
            }
        },
        addAsHandler() {
            console.log('addAsHandler')
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalAddAssessment')
        },
        setProgress(obj) {
            let mets = obj.status === 'IN_PROGRESS' ? obj.complete.aggregate.count : obj.mets.aggregate.count
            let total = obj.surveyVisit ?
                obj.surveyVisit.accreditation.surveyVersion.indicatorsAggregate.aggregate.count :
                obj.accreditation.surveyVersion.indicatorsAggregate.aggregate.count

            return {
                total: total,
                mets: mets,
            }
        },
        sortByDate(a, b) {
            if (!a || !b) return -1
            if (a.type !== b.type) return -1

            let dateA = a.type === 'PRESURVEY' ? new Date(a.created) : new Date(a.surveyVisit.date)
            let dateB = b.type === 'PRESURVEY' ? new Date(b.created) : new Date(b.surveyVisit.date)

            if (dateA == dateB) {
                return 0
            } else if (dateA > dateB) {
                return 1
            } else {
                return -1
            }
        },
        startedAssessment(accreditationId) {
            // console.log('refetching the accreditation data')
            if (this.forceAccreditationFirstView) {
                this.$emit('cancelForceAccreditationFirstView')

                return
            }

            const data = snakecaseKeys({ isFirstView: false, practice: this.practiceId }, { deep: true })
            this.$http({
                method: 'PATCH',
                url: `web.accreditation/${accreditationId}/`,
                data: data
            }).then((response) => {
                console.log('started accreditation response', response)
                this.$apollo.queries.accreditationData.refetch()
            }).catch((err) => {
                console.log('error from starting accreditation', err)
            })
        },
        isRowLocked(row) {
            const status = row && row.status ? row.status : null
            
            if (row.type !== 'PRESURVEY' ) {
                if (status === 'UNDER_REVIEW') {
                    return !this.viewSurveyUnderReview
                }
                if (status === 'IN_PROGRESS' || status === 'PARTIALLY_SUBMITTED') {
                    if (this.permissions && !this.permissions.changeSurveyInProgress) {
                        return !this.permissions.changeSurveyInProgress
                    }
                    return false
                }
            } else {
                switch(status) {
                    case 'UNDER_REVIEW':
                        if (this.permissions && !this.permissions.changeSelfAssessmentUnderReview) {
                            return !this.permissions.changeSelfAssessmentUnderReview
                        }

                }
                return false
            }
        },
        getVisitReasonText(reasonForVisit) {
            const choices = this.staticChoices['survey_visit.reason']
            if (!choices || !choices.length) return ''

            const reason = choices.find(reason => reason.value === reasonForVisit)
            if (reason) return reason.text

            return ''
        },
        downloadSurveyConfirmationFormHandler(suveyVisitId) {
            // this.downloadRemoteFileHandler(null, `web.surveyvisit/${suveyVisitId}/generate_confirmation_pdf/`, 'GET')
            this.$http({url: `web.surveyvisit/${suveyVisitId}/generate_confirmation_pdf/`, method: 'GET', responseType: 'blob'})
                .then((res) => {
                    this.downloadFile(res.data, res.headers['content-disposition'].split('=')[1])
                    this.type = 'success'
                    this.message = 'Successfully downloaded confirmation form.'
                })
                .catch((err) => {
                    console.log('confirmation form download error', err)
                    this.type = 'error'
                    this.message = 'Sorry, your file could not be downloaded at this time, please contact your system administrator'
                })
                .finally(() => {
                    this.showMessage({duration: 5000})
                })
        }
    },
    computed: {
        ...mapState({
            practiceId: state => state.app.practiceId,
            permissions: state => state.app.permissions?.assessment,
            staticChoices: state => state.app.staticChoices
        }),
        viewSurveyUnderReview() {
            if (this.permissions != undefined && this.permissions.viewSurveyUnderReview) {
                return this.permissions.viewSurveyUnderReview
            }
            return false
        },
        tableDataFiltered() {
            if (this.accreditationData && this.accreditationData.assessment) {
                let tableData = this.accreditationData.assessment.map(element => {
                    // console.log('element', element)
                    return {
                        ...element,
                        id: element.id,
                        type: element.type,
                        updated: format(new Date(element.updated), 'dd MMM yy'),
                        lastUpdatedById: element.lastUpdatedById ? format(new Date(element.lastUpdatedById), 'dd MMM yy') : null,
                        status: element.status
                    }
                })

                return tableData
            }
            return []
        },
        certificateData() {
            if (this.accreditationData && this.accreditationData.certificate) {
                let data = this.accreditationData.certificate.map(element => {
                    return {
                        ...element,
                        name: this.getCertName(element),
                        date: format(new Date(element.expiryDate), 'MMM yyyy'),
                    }
                })
                return data
            }
            return []
        },
        currentAccreditation() {
            let accreditation = {}
            console.log('accreditationData updated')
            if (this.accreditationData && this.accreditationData.assessment){
                const assessment = this.accreditationData.assessment.find(a => a.status === 'IN_PROGRESS' || a.status === 'PARTIALLY_SUBMITTED')
                if (assessment && assessment.accreditation) {
                    let data
                    if (Array.isArray(assessment.accreditation)) {
                        accreditation = assessment.accreditation.find(a => a.status.toLowerCase() === 'open')
                        if (!accreditation) {
                            accreditation = assessment.accreditation.find(a => a.status.toLowerCase() === 'closed')
                            if (!accreditation)  {
                                accreditation = {isFirstView: true}
                            }
                        }
                    } else {
                        accreditation = {...assessment.accreditation}
                    }

                } else {
                    accreditation = {
                        isFirstView: false,
                        addAssessment: true
                    }
                }
            }
            return accreditation
        },
        isFirstView() {
            return this.currentAccreditation && this.currentAccreditation.isFirstView
        },
        firstStateComponentName() {
            return this.isFirstView || this.forceAccreditationFirstView ?
                'FirstStateAccreditation' : ''
        }
    },
    watch: {
        practiceId() {
            this.$apollo.queries.accreditationData.start()
        },
        accreditationData() {
            if (this.accreditationData) this.loading = false
        },
        currentAccreditation() {
            this.$store.commit('SET_ACCREDITATION_FIRST_VIEW', this.isFirstView)
        }
    },
    beforeDestroy() {
        this.$root.$off('agreement-action')
        this.$root.$off('download-action')
    },
}
</script>
