<template>
    <div class="bg-primary-light rounded-full flex items-center justify-center" >
        <img v-if="avatar && !error" :src="avatar" class="avatar rounded-full" :alt="initials || name" :title="name" @error="avatarLoadFailedHandler" />
        <div v-else>
            <p v-if="initials" :title="name" class="text-white font-display font-semibold text-sm pt-0 pl-0">{{ initials }}</p>
            <img v-else src="@/assets/images/empty-user.png" class="avatar rounded-full" :title="name" />
        </div>
    </div>
</template>

<script>
import { ProfileUtilities } from '@/components/shared/mixins/profileFns'
export default {
    name: 'ProfileAvatar',
    props: {
        userProfile: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            name: '',
            avatar: '',
            error: false
        }
    },
    mounted() {
        this.loadAvatar()
    },
    computed: {
        initials() {
            return ProfileUtilities.getInitials(this.userProfile)
        }
    },
    methods: {
        loadAvatar() {
            if (this.userProfile) {
                if (this.userProfile.avatar === undefined && this.userProfile.id) {
                    this.$http
                        .get(`user/${this.userProfile.id}/avatar/`)
                        .then(({ data }) => {
                            this.name = data.name
                            this.avatar = data.avatar
                            this.error = false
                        }).catch(() => {
                            console.log('failed to load avatar')
                        })
                } else {
                    this.name = this.userProfile.name
                    this.avatar = this.userProfile.avatar
                    this.error = false
                }
            }
        },

        avatarLoadFailedHandler() {
            this.error = true
        }
    },
    watch: {
        userProfile() {
            this.loadAvatar()
        }
    }
}
</script>
