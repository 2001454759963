import Vue from 'vue'
import { ApolloLink, split, from } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { HttpLink } from 'apollo-link-http'
import { getAccessToken, getAccessTokenAsync } from './auth.helpers'
import { setContext } from 'apollo-link-context'


// Http endpoint
const uriQuery = process.env.VUE_APP_GRAPHQL_HTTP || ''
// const uriMutation = process.env.VUE_APP_GRAPHQL_DJANGO || ''
const uriMutation = process.env.VUE_APP_GRAPHQL_HTTP || ''

const omitDeep = (obj, key) => {
    const keys = Object.keys(obj)
    const newObj = {}
    keys.forEach((i) => {
        if (i !== key) {
            const val = obj[i]
            if (val instanceof Date) newObj[i] = val
            else if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key)
            else if (typeof val === 'object' && val !== null) newObj[i] = omitDeep(val, key)
            else newObj[i] = val
        }
    })
    return newObj
}

const omitDeepArrayWalk = (arr, key) => {
    return arr.map((val) => {
        if (Array.isArray(val)) return omitDeepArrayWalk(val, key)
        else if (typeof val === 'object') return omitDeep(val, key)
        return val
    })
}

const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    // Return the headers to the context so httpLink can read them
    
    const token = await getAccessTokenAsync()
    return {
        headers: {
            Authorization: token ? `Bearer ${token}` : ''
        }
    }
})

const cleanTypenameLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
        operation.variables = omitDeep(operation.variables, '__typename')
    }
    return forward(operation).map((data) => {
        return data
    })
})

const customFetch = (uri, options) => {
    return fetch(uri, options)
    .then((response) => {
        const originalResponse = response.clone()
        
        return response.json().then(json => {
            return {
                response: originalResponse,
                json
            }
        })
    })
    .then(({response, json}) => {
        if (
            json &&
            json.errors &&
            json.errors[0] &&
            json.errors[0].extensions.code === 'invalid-jwt' &&
            Vue.auth.token()
        ) {
            return Vue.auth.refresh().then(() => {
                options.headers.Authorization = `Bearer ${getAccessToken()}`
                return fetch(uri, options)
            }).catch(() => {
                console.log('failed in auth.middleware')
            })
        } else if (json.code && json.code === 'token_not_valid') {
            //redirecting to login is the error code is token_not_value
            Vue.router.push({ name: 'Login' })
        }
        
        return response
    })
        .catch((err) => {
            // if we get to this point a different error must have occurred - the refresh token might have expired
            if (err && err.code && err.code === 'token_not_valid') {
                //redirecting to login is the error code is token_not_value
                Vue.router.push({ name: 'Login' })
            }
        })
}


// Goes to CreateProvider.link as ApolloLink.from(<Array>)
export default [
    authLink,
    split(
        ({ query }) => {
            const definition = getMainDefinition(query)
            // console.log('Apollo split Second definition', definition)
            return definition.operation == 'query'
        },
        new HttpLink({
            uri: uriQuery,
            fetch: customFetch
        }),
        from(
            [cleanTypenameLink, new HttpLink({
                uri: uriMutation,
                fetch: customFetch
            })]
        )
    )
]
