<template>
<div class="c-summary-panel__in-progress flex flex-col w-full">
    <div class="flex flex-row content-center items-center bg-grey-8 rounded-lg w-full p-4">
        <inProgressSvg class="m-2 mr-8" v-show="state === 'survey'"/>
         <img v-show="state === 'self'" src="@/assets/images/startselfassessment.png" class="w-56 mr-3" :style="imgStyle" alt="Start self assessment" />
        <div class="flex flex-col justify-center text-grey-info">
            <h4 class="text-grey-info">{{ heading }}</h4>
            <span v-html="body"></span>
        </div>
    </div>
</div>
</template>

<script>
import inProgressSvg from '@/assets/inprogress.svg'

export default {
    name: 'InfoPanel',
    components: {
        inProgressSvg,
    },
    props: {
        state: {
            type: String,
            default: ''
        }
    },
    computed: {
        heading() {
            if (this.state === 'self') return 'Commence the Self Assessment'
            if (this.state === 'survey') return 'Review Surveyor Assessment'
            return ''
        },
        body() {
            if (this.state === 'self') return '<p class="mb-3">Please complete the assessment and see if the practice meets the RACGP standards for accreditation.</p><p class="mb-3">Checkboxes with <span class="text-green">green text</span> are the standards the practice must meet - simply checking the checkbox will not achieve accreditation.</p><p>This self assessment is an opportunity for the practice to recognise and prepare for the survey visit. The Surveyors will have access and review your self assessment during the survey visit.</p>'
            if (this.state === 'survey') return 'The Surveyor Assessment has been submitted for review by QPA. On reviewing the assessment report, if you identify any required changes please contact the Quality Accreditation Manager to request the report be re-opened for editing.'
            return ''
        },
        imgStyle() {
            // add dynamic styles to image
            return ''
        }
    },
}
</script>
