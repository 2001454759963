export const UPDATE_LOGIN_EMAIL = 'UPDATE_LOGIN_EMAIL'
export const SET_STAFF_TOTAL = 'SET_STAFF_TOTAL'
export const SET_STAFF_FIRST_VIEW = 'SET_STAFF_FIRST_VIEW'
export const SET_DOCUMENTS_FIRST_VIEW = 'SET_DOCUMENTS_FIRST_VIEW'
export const SET_ACCREDITATION_FIRST_VIEW = 'SET_ACCREDITATION_FIRST_VIEW'
export const SET_PRACTICE_ID = 'SET_PRACTICE_ID'
export const SET_STATIC_OBJECT = 'SET_STATIC_OBJECT'

// Modal state mutations
export const MODAL_IS_VISIBLE = 'MODAL_IS_VISIBLE'
export const MODAL_DATA = 'MODAL_DATA'
export const MODAL_COMPONENT_NAME = 'MODAL_COMPONENT_NAME'

export const SET_DOCS_TOTAL = 'SET_DOCS_TOTAL'

export const SET_LIST_FILTER = 'SET_LIST_FILTER'

export const SET_PERMISSIONS = 'SET_PERMISSIONS'

export const SET_ROUTE_TO = 'SET_ROUTE_TO'
export const SET_ROUTE_FROM = 'SET_ROUTE_FROM'

export const SET_WEBINAR_LOGIN_CODE = 'SET_WEBINAR_LOGIN_CODE'
export const SET_WEBINAR_ACCESS_OBJECT = 'SET_WEBINAR_ACCESS_OBJECT'

export const SET_FOLDERS = 'SET_FOLDERS'
export const SET_GET_VISIBLE_FOLDERS = 'SET_GET_VISIBLE_FOLDERS'
