/**
 * function to check only if a value is not null
 *
 * @param value
 * @returns {boolean}
 */
function validValue(value) {
    return value != null
}

/**
 * function only checks for the index of a primitive
 * doesn't check for objects
 *
 * @param value
 * @param index
 * @param self
 * @returns {boolean}
 */
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
}

/**
 * Function to remove an element from the array, doesn't check specific properties
 *
 * @param array
 * @param value
 */
function removeElement(array, value) {
    const index = array.indexOf(value)
    if (index > -1) array.splice(index, 1)
}

const splitListToChunk = (list, chunkSize=25) => {
    let newList = []
    for (let i = 0; i < list.length; i += chunkSize) {
        const chunk = list.slice(i, i + chunkSize)
        newList.push(chunk)
    }
    
    return newList
}

export {validValue, onlyUnique, removeElement, splitListToChunk}
