<template>
    <div class="grid col grid-cols-4 mt-3">
        <div class="col-span-3" v-if="displayActivity">
            <div 
                v-for="(item, index) in comments"
                class="border-b"
                :key="`comment-${index}`"
                data-cy="comment"
            >
                <div class="m-2 mx-3 mb-4 flex flex-row">
                    <ProfileAvatar v-if="!item.edit" :user-profile="item.createdBy" class="w-10 h-10 mr-3 avatar-container" />
                    <div class="flex items-start text-sm w-full" :class="{'justify-between': item.edit && isSelfAssessment}">
                        <div v-if="!item.edit" class="w-full">
                            <span class="flex flex-row items-center mb-1 group hover:text-black">
                                <h4 class="text-sm">{{ getName(item.createdBy) }}{{ getPracticeRelationship(item) }}</h4>
                                <span class="text-xs ml-3 text-grey-2" data-cy="date-output">{{ parseDate(item.created) }}</span>
                                <!-- <el-link
                                    :underline="false"
                                    class="delete-icon text-xs ml-3"
                                    @click.prevent="deleteComment(item)"
                                    v-if="isAdmin"
                                >
                                    <fa-icon icon="trash-alt" class="mr-1"/> DELETE
                                </el-link> -->

                            </span>
                            <p class="whitespace-pre-wrap">{{ item.comment }}</p>
                        </div>
                        <Comment
                            v-model="item.comment"
                            v-else
                           :user-profile="item.createdBy"
                        />
                        <div v-if="canEditComment(item.createdBy)" class="pl-6">
                            <a href="#" @click.prevent="toggleEdit(index)" v-if="!item.edit">
                                <fa-icon icon="pencil-alt" class="mr-1 group-hover:text-primary-light"/>
                            </a>
                            <div class="flex items-start text-sm w-full justify-between" v-else>
                                <a href="#" @click.prevent="saveComment(index)" >
                                    <fa-icon icon="save" class="mr-1 group-hover:text-primary-light"/>
                                </a>
                                <a href="#" @click.prevent="resetComment(index)" >
                                    <fa-icon icon="times" class="mr-1 group-hover:text-primary-light"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-2 mb-4 flex flex-row w-full justify-center">
                <el-link
                    v-if="showLoadMore"
                    type="primary"
                    :underline="false"
                    class="uppercase font-bold text-sm"
                    :disabled="loadMoreDisabled"
                    @click.prevent="loadMore"
                >
                    Load more
                </el-link>
            </div>
        </div>
        <div class="c-comment-module flex flex-col col-span-1">
            <div 
                class="w-full flex flex-col items-center justify-center mb-4 cursor-pointer"
                v-for="(file, index) in filteredFileList"
                :key="`file-${index}`" 
                @click.prevent="downloadHandler(file.id, 'document')"
            >
                <div class="text-grey-4">
                    <FileSvg class="m-3 mb-1 w-16 h-16 fill-current"/>
                </div>
                <p class="text-primary-light hover:text-primary">{{ file.name }}</p>
                <a v-if="canDeleteFile(file.createdBy)" class="p-2" href="#" @click.prevent.stop="showDeleteFileConfirmDialogHandler(file)">
                    <fa-icon icon="trash-alt" class="text-grey-6 hover:text-red"/>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { format } from 'date-fns'
import GET_COMMENTS from '@/graphql/queries/getComments.gql'
import camelcaseKeys from 'camelcase-keys'
import { mapState } from 'pinia'
import FileSvg from '@/assets/icons/icons_file-2.svg'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import FileMethods from '@/components/shared/mixins/fileMethods'
import Comment from '@/components/shared/Comment'
import IndicatorViewPermissions from '@/components/accreditation/mixins/indicatorViewPermissions'
import { AuthMixin } from "@/components/shared/mixins/authHelpers"
import ProfileAvatar from '@/components/partials/ProfileAvatar'
import { useAssessmentStore } from '@/stores/useAssessmentStore'

export default {
    name: 'ActivityModule', 
    components: { Comment, FileSvg, ProfileAvatar },
    mixins: [ MessageDialog, FileMethods, AuthMixin, IndicatorViewPermissions ],
    props: {
        commentsArr: {
            type: Array,
            default: () => []
        },
        commentsAggregate: {
            type: Number,
            default: null
        },
        indicatorRespId: {
            type: Number,
            default: null
        },
        assessmentId: {
            type: String,
            default: null
        },
        indicatorId: {
            type: Number,
            default: null
        },
        fileList: {
            type: Array,
            default: () => []
        },
        status: {
            type: String,
            default: ''
        },
        submissions: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            comments: [],
            getCommentsQueryIsInActive: true,
            loadMoreDisabled: false,
            limit: 5,
        }
    },
    apollo: {
        getComments: {
            query: GET_COMMENTS,
            variables() {
                return {
                    assessmentId: Number(this.assessmentId),
                    indicatorRespId: this.indicatorRespId,
                    indicatorId: this.indicatorId,
                    limit: this.limit
                }
            },
            update(data) {
                this.loadMoreDisabled = false

                // parse data to use camelcase
                const parsedData = camelcaseKeys(data, { deep: true })
                console.log('get comments, parsedData', parsedData)

                return parsedData
            },
            skip() { 
                return this.getCommentsQueryIsInActive
            },
        }
    },
    mounted() {
        this.$root.$on('response-updated', this.responseUpdateHandler)

        this.comments = []
        this.setComments()
    },
    methods: {
        responseUpdateHandler(indicatorRespId) {
            if (indicatorRespId === this.indicatorRespId && !this.getCommentsQueryIsInActive) {
                this.loadMoreDisabled = true
                this.$apollo.queries.getComments.refetch() 
            }
        },
        parseDate(dateString) {
            return format(new Date(dateString), 'd LLL yyyy - h:mma')
        },
        deleteComment(item) {
            this.$http({
                method: 'DELETE',
                url: `web.comment/${item.id}/`
            }).then(res => {
                console.log('successfuly deleted comment, res, id: ',res, item.id)
                this.$root.$emit('update-indicator')
                if (!this.getCommentsQueryIsInActive) {
                    this.loadMoreDisabled = true
                    this.$apollo.queries.getComments.refetch() 
                }
            }).catch(err => {
                console.error('Error deleting a comment in the Activity Module')
                console.error(err)
            })
        },
        getInitials(item) {
            if (item !== null) return `${item.firstName.slice(0, 1)}${item.lastName.slice(0, 1)}`
            return ''
        },
        getName(item) {
            if (item !== null) return `${item.firstName} ${item.lastName}`
            return ''
        },
        getPracticeRelationship(item) {
            return item.practiceRelationship ? ` (${item.practiceRelationship})` : ''
        },
        loadMore() {
            // console.log('loadMore triggered')
            this.loadMoreDisabled = true
            this.getCommentsQueryIsInActive = false
            this.limit += 5
            this.$apollo.queries.getComments.refetch()
        },
        saveComment(idx) {
            console.log('saving item', this.comments[idx])
            this.toggleEdit(idx, false)
            this.$emit('update-comment', this.comments[idx])
        },
        toggleEdit(idx, forceValue) {
            const item = this.comments[idx]
            item.edit = forceValue !== undefined ? forceValue : !item.edit
            if (!item.originalComment) item.originalComment = item.comment
            item.previousComment = item.comment
            this.$set(this.comments, idx, item)
        },
        resetComment(idx, forceValue) {
            const item = this.comments[idx]
            item.edit = forceValue !== undefined ? forceValue : !item.edit
            if (item.originalComment) item.comment = item.originalComment
            this.$set(this.comments, idx, item)
        },
        canEditComment(createdBy) {
            return (createdBy && this.$auth.user()?.userId === createdBy.id && this.canChangeOwnComment) || (this.isSuperAdmin && this.isSelfAssessment)
        },
        canDeleteFile(createdBy) {
            return createdBy && this.$auth.user()?.userId === createdBy.id && this.canDeleteOwnFile
        },
        setComments() {
            if (this.getCommentsIsInActive) this.comments = [...this.commentsArr]
            // console.log('this.getComments', this.getComments)
            else this.comments = [...this.getComments.assessment[0].responses[0].comments]

            this.comments.forEach(comment => {
                comment.edit = false
            })
        },
        showDeleteFileConfirmDialogHandler(file) {
            this.resetMessage()
            if (this.currentMessageDialog) {
                this.currentMessageDialog.close()
                this.currentMessageDialog = null
            }
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalDeleteDocument')
            this.$store.commit('MODAL_DATA', file)
        },
    },
    computed: {
        ...mapState(useAssessmentStore, ['isSelfAssessment', 'isSuperAdmin']),
        displayActivity() {
            return this.commentsArr.length > 0
        },
        aggregate() {
            if (this.getComments === undefined) return this.commentsAggregate
            return this.getComments.assessment[0].responses[0].commentsAggregate.aggregate.count
        },
        showLoadMore() {
            // console.log('showLoadMore triggered', this.getComments)
            return this.aggregate > this.limit
        },
        getCommentsIsInActive() {
            return this.getComments === undefined
        },
        filteredFileList() {
            return this.fileList || []
        }
    },
    watch: {
        commentsArr() {
            this.setComments()
        },
        getComments() {
            this.setComments()
        }
    },
    beforeDestroy() {
        this.$root.$off('response-updated')
    }
}
</script>
