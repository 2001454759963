<template>
    <div>
        <component
            :is="renderForm"
            :model="formInput"
            :rules="rules"
            ref="formInput"
            class="c-editable-cell"
        >
            <el-form-item prop="inputValue">
                <component
                    :is="renderEditableComponent"
                    v-show="editActive"
                    @focus="onCellClick"
                    v-model="formInput.inputValue"
                    class="p-0 m-0"
                    :type="inputType"
                    :autosize="{ minRows: 2, maxRows: 8}"
                    @keypress="isNumber($event)"
                    :disabled="disabled"
                >
                    <slot></slot>
                </component>
            </el-form-item>
        </component>
        <el-link
            @click="cellClickHandler"
            v-show="!editActive"
            class="justify-start py-1 m-0 hover:text-grey-1 text-grey-2 leading-normal border border-transparent border-l-0 border-r-0 border-t-0"
            :v-html="!linkText ? '&nbsp;': ''"
            :class="{'w-4/5' : !linkText, 'fieldEmpty': !formInput.inputValue}"
        >
            {{ linkText }}
        </el-link>
    </div>
</template>

<script>
export default {
    name: 'NumberInputCell',
    props: {
        value: {
            type: String,
            default: ''
        },
        displayValue: {
            type: String,
            default: ''
        },
        cellId: {
            type: Number,
            default: null
        },
        editableComponent: {
            type: String,
            default: 'v-text-field'
        },
        editActive: {
            type: Boolean,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        defaultValue: {
            type: String,
            default: ''
        },
        inputType: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            decimalPointKeyCode: 46,
            focused: false,
            formInput: {inputValue: ''},
            rules: {
                inputValue: [
                    { validator: this.inputValueValidator, trigger: 'blur' },
                    { validator: this.inputValueValidator, trigger: 'change' },
                ],
            },
        }
    },
    mounted() {
        this.formInput.inputValue = this.value || this.defaultValue
    },
    methods: {
        inputValueValidator(rule, value, cb) {
            if (this.isRequired) {
                if (!value) {
                    cb('Field required')
                } else {
                    cb()
                }
            } else {
                cb()
            }
        },
        cellClickHandler() {
            if (this.disabled) return

            console.log('cellClickHandler', this.cellId)
            this.focused = true
            this.$emit('edit-row', this.cellId)
        },
        onCellClick(e) {
            console.log('onCellClick', e)
        },
        validate(){
            return this.$refs.formInput.validate()
        },
        clearValidate(){
            return this.$refs.formInput.clearValidate()
        },
        isNumber(event) {
            event = event || window.event
            let charCode = event.which || event.keyCode
            console.log('event', this.event)
            if ((charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) || (charCode === this.decimalPointKeyCode && this.value.indexOf('.') !== -1)) {
                event.preventDefault()
            } else {
                return true
            }
            return false
        }
    },
    computed: {
        linkText(){
            let text =  this.displayValue || this.value
            return text || ''
        },
        renderEditableComponent() {
            if (this.editActive) return this.editableComponent
            return ''
        },
        renderForm() {
            if (this.editActive) return 'el-form'
            return ''
        },
    },
    watch: {
        value() {
            this.formInput.inputValue = this.value
        },
        'formInput.inputValue': function() {
            if (this.editActive) this.$emit('input', this.formInput.inputValue)
        },
    },
}
</script>
