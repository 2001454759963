const getType = (obj) => {
    if (obj) {
        if (obj.type === 'PRESURVEY') {
            return 'Self Assessment'
        } else {
            switch (obj.surveyVisit.type) {
                case 'ACCREDITATION':
                    return 'Survey'
                case 'SURVEILLANCE':
                    return 'Surveillance'
                case 'RELOCATION':
                    return 'Relocation'
                case 'GAP':
                    return 'GAP'
                default:
                    return 'none'
            }
        }
    }
}

export {
    getType
}
