import Vue from 'vue'
import Vuetify, { VSelect, VTextField } from 'vuetify/lib'
import { light } from '@/plugins/theme/theme'

Vue.use(Vuetify, {
    components: {VSelect, VTextField},
})

const opts = {
    theme: {
        themes: {
            light: light
        }
    },
}

export default new Vuetify(opts)
