<template>
    <div class="relative p-4">
        <div class="flex flex-row justify-center my-4">
            <div 
                class="text-center text-base"
                v-html="body"
            ></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ModalRequestAssistance',
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData,
            isVisible: state => state.app.modal.isVisible,
        }),
        body() {
            return this.data.body || ''
        },
    }
}
</script>