import { format, isValid, parseISO } from 'date-fns'

export default {
    methods: {
        getExpiryDate(accreditation) {
            if (!accreditation || !accreditation.expiryDate) return ''

            const expiryDate = parseISO(accreditation.expiryDate)
            return isValid(expiryDate) ? format(expiryDate, 'dd/MM/yyyy') : ''
        },
    }
}
