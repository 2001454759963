import camelcaseKeys from 'camelcase-keys'

export default {
    methods: {
        preferredDaysDueClickHandler(visit_id) {
            // TODO: Create a new page and use that route name here
            this.$router.push({name: 'SurveyVisitPreferredDays', params: {id: visit_id}})
        }
    }
}
