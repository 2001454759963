import Vue from 'vue'
import { getAccessToken } from './auth.helpers'

export default () => {
    Vue.axios.interceptors.response.use(
        res => res,
        error => {
            if (error && error.response && error.response.status === 401) {
                const config = error.config
                const url = config.url
                if (!url.includes('refresh') && !url.includes('login')) {
                    return Vue.auth.refresh()
                        .then(() => {
                            if (Vue.auth.check()) {
                                config.headers['Authorization'] = `Bearer ${getAccessToken()}`
                                return Vue.axios.request(config)
                            } else {
                                return Promise.reject(error)
                            }
                        })
                        .catch(() => {
                            return Promise.reject(error)
                        })
                }
            }
        
            return Promise.reject(error)
        }
    )
}
