import { format } from 'date-fns'

export default {
    methods: {
        getDate(obj) {
            if (obj) {
                if (obj.type === 'PRESURVEY') {
                    return format(new Date(obj.created), 'MMM yyyy')
                } else {
                    return format(new Date(obj.surveyVisit.date), 'MMM yyyy')
                }
            }
        },
        getStatusType(obj) {
            if (obj && obj.status) {
                switch (obj.status) {
                    case 'IN_PROGRESS':
                        return 'in progress'
                    case 'PARTIALLY_SUBMITTED':
                        return 'in progress'
                    case 'UNDER_REVIEW':
                        return 'under review'
                    case 'ACTION_REQUIRED':
                        return 'complete'
                    case 'APPROVED':
                        return 'complete'
                    case 'COMPLETED':
                        return 'complete'
                    case 'CERTIFIED':
                        return 'complete'
                    default:
                        return ''
                }
            }
        },
        getActionPlanType(obj) {
            if (obj && obj.status) {
                switch (obj.status) {
                    case 'ACTION_REQUIRED':
                        return 'action required'
                    case 'APPROVED':
                        return 'approved'
                    case 'CERTIFIED':
                        return 'certified'
                    default:
                        return ''
                }
            }
        },
    }
}
