import UPDATE_ASSESSMENT from '@/graphql/mutations/updateAssessment.gql'

export default {
    methods: {
        assessmentMutation(assessmentObj, assessmentId, cb) {
            console.log('assessmentMutation', assessmentObj, assessmentId)
            this.$apollo.mutate({
                mutation: UPDATE_ASSESSMENT,
                variables: {
                    assessmentObj: assessmentObj,
                    assessmentId: assessmentId
                }
            }).then(data => {
                console.log('APOLLO mutation assessment, response:', data)
                this.$root.$emit('update-assessment')
                cb()
            }).catch(error => {
                console.log('error', error)
            })
        }
    }
}