import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { from } from 'apollo-link'
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
import Connect2Middleware from '@/utils/auth/auth.middleware'
import { getAccessToken, getAccessTokenAsync } from '@/utils/auth/auth.helpers'
const websocketUrl = process.env.VUE_APP_GRAPHQL_WS || ''
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || ''

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token'
let apolloProvider
let client = null

// Config
const defaultOptions = {
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    httpEndpoint,
    // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
    wsEndpoint: websocketUrl,
    // LocalStorage token
    tokenName: AUTH_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false,
    
    // Set to false so that we can add our own custom default httplink in the 'link' method below.
    defaultHttpLink: false,
    
    // Override default apollo link
    // note: don't override httpLink here, specify httpLink options in the
    // httpLinkOptions property of defaultOptions.
    link: from(Connect2Middleware)
}

// Call this in the Vue app file    
function getOrCreateProvider(options = {}) {
    // console.log('inside apollo config', Vue.auth.token())

    // Create apollo client
    const {apolloClient, wsClient} = createApolloClient({
        ...defaultOptions,
        ...options
    })
    if (wsClient) {
        wsClient.lazy = true
        wsClient.connectionParams = async () => {
            const token = await getAccessTokenAsync()
            return {
                headers: {
                    Authorization: token ? `Bearer ${token}` : ''
                }
            }
        }
        apolloClient.wsClient = wsClient
    }
    client = apolloClient
    
    // Create vue apollo provider
    if (!apolloProvider) {
        apolloProvider = new VueApollo({
            defaultClient: apolloClient,
            defaultOptions: {
                $query: {
                    // fetchPolicy: 'cache-and-network',
                }
            },
            async errorHandler(error) {
                // eslint-disable-next-line no-console
                if (error.message === 'cannot start as connection_init failed with : Could not verify JWT: JWTExpired') {
                    await getAccessTokenAsync()
                    await onLogin(apolloClient)
                }
                console.log(
                    '%cError',
                    'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
                    error.message
                )
            }
        })
    }

    return apolloProvider
}

// Manually call this when user log in
export async function onLogin(apolloClient) {
    if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
    try {
        await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
    if (typeof localStorage !== 'undefined') {
        localStorage.removeItem(AUTH_TOKEN)
    }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
    }
}

export {
    client,
    getOrCreateProvider
}
