<template>
    <div class="flex flex-col c-modal-validation">
        <h2 class="text-center my-4">Before you can submit</h2>
        <p class="text-base text-grey-1 my-4 mx-2">
            <span v-if="generalComments !== undefined && generalComments.length">
                <fa-icon icon="exclamation-triangle" class="mr-1 text-red"/>
                All <b>surveyor general comments</b> need to be completed.<br>
            </span>
            <span v-if="validationList !== undefined && validationList.length">
                <fa-icon icon="exclamation-triangle" class="mr-1 text-red"/>
                The following indicators need to be <b>completed before submitting</b>.
            </span>
            <span v-if="openNonConformities !== undefined && openNonConformities.length">
                <fa-icon icon="exclamation-triangle" class="mr-1 text-red"/>
                The following indicators have non conformities that need to be <b>completed before submitting</b>.
            </span>
        </p>
        <div class="c-modal-validation__content w-full mx-2" v-if="validationList !== undefined && validationList.length">
            <ul class="flex flex-col">
                <li
                    v-for="(item, index) in validationList"
                    :key="`validation-item-${index}`"
                    class="grid grid-cols-4 whitespace-no-wrap"
                >
                    <div class="col-span-2">{{ item.indicatorSet.fullCode }}</div>
                    <i class="col-span-1 text-black el-icon-caret-right text-xl"></i>

                    <div class="col-span-1">{{ getCodeList(item.responses) }}</div>
                </li>
            </ul>
        </div>
        <div class="c-modal-validation__content w-full mx-2" v-if="openNonConformities !== undefined && openNonConformities.length">
            <ul class="flex flex-col">
                <li
                    v-for="(item, index) in openNonConformities"
                    :key="`openNonConformities-${index}`"
                    class="grid grid-cols-4 whitespace-no-wrap"
                >
                    <div class="col-span-2">{{ item.indicator.criterionCode }}</div>
                    <i class="col-span-1 text-black el-icon-caret-right text-xl"></i>
                    <div class="col-span-1">{{ item.indicator.code }}</div>
                </li>
            </ul>
        </div>
        <div class="flex flex-row justify-center my-4">
            <el-button @click="cancelHandler">
                Close
            </el-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ModalValidation',
    computed: {
        ...mapState({
            validationList: state => state.app.modal.componentData.validationList,
            generalComments: state => state.app.modal.componentData.generalComments,
            openNonConformities: state => state.app.modal.componentData.openNonConformities
        })
    },
    methods: {
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        getCodeList(responses) {
            return responses.map(response => response.indicator.code).join(', ')
        }
    }
}
</script>

