<template>
    <div class="c-confidentiality-statement">
        <p>I, <strong>{{ userName }}</strong> at Quality Practice Accreditation agree: </p>
        <div class="c-confidentiality-statement__content text-xs">
            <ol>
                <li>To keep strictly confidential any information, discussions and findings arising from any assessment,
                    training or audit activities and the practice visit; </li>
                <li>Not to discuss the practice’s administrative, financial or clinical systems with any third party (other than
                    authorised QPA personnel), except by instruction of the practice;</li>
                <li>Not to allow any unauthorised third party to examine or view records relating to the practice; </li>
                <li>Not to use or exploit for my own benefit, or improperly use or permit to be used for the benefit of myself
                    or others, any information obtained or knowledge gained from my association with any practice. </li>
            </ol>
            <h4 class="text-sm font-bold">CODE OF ETHICS</h4>
            <p>I agree to observe the highest standards of ethics and integrity in my conduct while engaged by QPA as a QPA
                employee or contractor. In particular I agree to:</p>
            <ol>
                <li>Comply with all applicable laws;</li>
                <li>Complete, to the best of my ability, the assignments for which I have been contracted;</li>
                <li>Abide by QPA policies and procedures regarding the handling of practice information;</li>
                <li>Decline any gifts, gratuities or payments (other than token) offered by the practice;</li>
                <li>Disclose any financial interests or relationships that might influence my decisions or actions during my
                    association with QPA, including interests in suppliers, customers, clients or competitors to QPA management;
                </li>
                <li>Disclose if I have during the previous three (3) years works for, consulted to, or contributed to the
                    management system, including conduct of internal audits, of any the practice for which I am to participate
                    in a practice visit or accreditation audit;</li>
                <li>Not provide or market consultancy services to practices in which I have undertaken accreditation, assessment
                    or audit activities as a QPA employee or contractor, or any other accreditation provider, during the
                    previous three (3) years; </li>
                <li>Agree not to go beyond the scope of the RACGP Standards I am assessing against. </li>
            </ol>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AgreementText',
    props: {
        userName: {
            type: String,
            default: ''
        }
    },
}
</script>
