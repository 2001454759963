import colors from "./colors";

const light = {
    primary: "#bcd00b",
    secondary: "#1C1717",
    // All keys will generate theme styles,
    // Here we add a custom `tertiary` color
    warning: colors.orange.base,
    accent: colors.blue.lighten1,
    error: "#E80729",
    success: "#29ac64",
    info: "#4EBBFF",
};

export { light };
