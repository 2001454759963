import store from '@/store'
import { AuthUtilities } from '@/components/shared/mixins/authHelpers'

export default {
    request: function (req, token) {
        let newToken = JSON.parse(token)
        const currentRoute = this.Vue.router.currentRoute
        if (req.url.includes('refresh')) {
            if (!newToken.refresh) {
                store.commit('SET_ROUTE_TO', currentRoute)
                this.Vue.router.push({'name': 'Login'})
                return ''
            }
            req.data = {
                refresh: newToken.refresh
            }
        }
        // our Vue app shouldn't have the scheme in the url
        // we only do the bearer part for the app, not any external links
        if (req.url.indexOf('amazonaws.com') === -1) {
            this.http.setHeaders.call(this, req, {
                Authorization: 'Bearer ' + newToken.access
            })
        }
    },
    response: function (res) {
        let accessToken  = res.data != null ? res.data.access : null
        let refreshToken = res.data != null ? res.data.refresh : null
        let url = res.config ? res.config.url : ''
    
        if (res.status === 401) {
            const currentRoute = this.Vue.router.currentRoute
            if (currentRoute.name !== 'Login') {
                store.commit('SET_ROUTE_TO', currentRoute)
                this.Vue.router.push({'name': 'Login'})
            }
            return ''
        }
        
        if (url.includes('refresh') || url.includes('login')) {
            /// we only need to check the sessionId and csrf on login/refresh
            AuthUtilities.setAuthCookies.call(this, res.data)
            
            // er only need to do this when we are refreshing or authing
            // we can get the current token from 'this', since we are in the Auth object
            const authToken = JSON.parse(this.token() || '{}')
            let token
            if (accessToken) {
                // we set the refresh token that we get from localStorage since the refresh endpoint we have
                // only returns the updated access token, so we should be ok keeping the refresh token as is
                token = {
                    access: accessToken,
                    refresh: accessToken.refresh || authToken.refresh || ''
                }
            }
            if (refreshToken && token) {
                token.refresh = refreshToken
            }
            
            if (token) {
                return JSON.stringify(token)
            }
        }
    }
};
