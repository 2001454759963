import GET_USER_ROLES from '@/graphql/queries/getUserRoles.gql'
import camelcaseKeys from 'camelcase-keys'

export default {
    data () {
        return {
            rolesLoading: false,
            rolesLoaded: false
        }
    },
    apollo: {
        roles: {
            query: GET_USER_ROLES,
            variables() {
                return {
                    userId: this.userId,
                }
            },
            skip() {
                // we want to run this if skipFiltered is false
                return !this.userId
            },
            update({ roles }) {
                // TODO: need to add code to skip this stuff if user doesn't have
                this.rolesLoaded = true
                return camelcaseKeys(roles, {deep: true})
            },
            watchLoading (isLoading, countModifier) {
                this.rolesLoading = isLoading
            },
            error(error, vm, key, type, options) {
                // for now, we just load it up with random data so we can see something
                this.error = error.message
            }
        },
    },
    computed: {
        hasSurveyorRole() {
            return this.roles && this.roles.length > 0 && this.roles.some(r => r.webQpaUserGroups[0].role.toUpperCase() === 'SU') || null
        },
        hasPracticeManagerRole() {
            return this.roles && this.roles.length > 0 && this.roles.some(r => r.webQpaUserGroups[0].role.toUpperCase() === 'PM') || null
        },
        canViewResources() {
            return this.hasPracticeManagerRole || this.isAdmin || this.isSurveyor
        }
    },
    watch: {
        userId() {
            this.rolesLoading = false
            this.$apollo.queries.roles.refetch()
        }
    }
}
