<template>
    <div class="relative p-4">
        <h2 class="text-lg text-grey-1">{{ heading }}</h2>
        <p>{{ body }}</p>
        <el-button type="primary" @click="clickHandler">{{ buttonText }}</el-button>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ModalDefault',
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData,
            isVisible: state => state.app.modal.isVisible,
        }),
        heading() {
            return this.componentData.heading || ''
        },
        body() {
            return this.componentData.body || ''
        },
    },
    methods: {
        clickHandler() {
            console.log('click handler')
        }
    }
}
</script>
