<template>
    <el-table
        lazy
        ref="filterTable"
        :data="tableDataFiltered"
        :empty-text="emptyText"
        @row-click="handleRowClick"
        header-row-class-name="uppercase"
        :row-class-name="rowClassName"
        :default-sort="{prop: 'expiry', order: 'descending'}"
        class="w-full"
        :span-method="arraySpanMethod"
        @filter-change="filterChangeHandler"
    >
        <el-table-column
            sortable
            prop="name"
            width="300"
            label="Practice">
            <template slot-scope="scope">
                <span class="mr-1">{{ scope.row.name }} 
                </span>
                <span
                    v-if="isRowLocked(scope.row)"
                    class="ml-2"
                >
                    <fa-icon icon="lock"></fa-icon>
                </span>
            </template>
        </el-table-column>
        <el-table-column
            sortable
            prop="organisationPtr.physicalAddress.suburb"
            width="120"
            label="Suburb"
            v-if="!summary"
        >
        </el-table-column>
        <el-table-column
            sortable
            prop="state"
            class-name="with-filter"
            column-key="state"
            :filters="states"
            :filter-method="filterHandler"
            filter-placement="right"
            width="100"
            label="State"
            :filtered-value="filter.columnFilters.state"
            v-if="!summary"
        >
            <template slot="header">
                <div class="fa-container inline-block">
                    <fa-icon icon="filter" class="text-black" />
                </div>
                State
            </template>
        </el-table-column>
        <el-table-column
            prop="expiryDate"
            width="100"
            sortable
            label="Expiry">
        </el-table-column>
        <el-table-column
            sortable
            prop="qam.displayName"
            width="150"
            label="QAM"
            v-if="!summary"
        >
        </el-table-column>

        <!-- NOTE: Leaving contact here for now since we might need it later for the Practice Manager view
        <el-table-column
            sortable
            prop="nominatedAccreditationContact.displayName"
            width="150"
            label="Contact">
        </el-table-column>
        -->
        <el-table-column
            sortable
            width="200"
            class-name="with-filter"
            prop="practiceNetworkName"
            column-key="practiceNetworkName"
            :filters="practiceNetworks"
            :filter-method="filterHandler"
            filter-placement="right"
            label="Network"
            :filtered-value="filter.columnFilters.practiceNetworks"
            v-if="!summary"
        >
            <template slot="header">
                <div class="fa-container inline-block">
                    <fa-icon icon="filter" class="text-black" />
                </div>
                Network
            </template>
        </el-table-column>
        <el-table-column
            column-key="accreditationStatus"
            prop="accreditationStatus"
            width="100"
            label="Cycle"
        >
            <template slot="header">
                Cycle
            </template>
            <template slot-scope="scope">
                <StatusBadge :status-type="scope.row.accreditationStatus" />
            </template>
        </el-table-column>
        <el-table-column
            sortable
            width="165"
            prop="selfAssessmentDue"
            label="SA Due"
        >
            <template #default="{row, column}">
                <el-button
                    v-if="row.hasReapplicationDue"
                    type="danger"
                    size="mini"
                    class="font-bold rounded uppercase align-middle text-xs text-white"
                    @click.stop="renewalClickHandler(row)"
                >
                    Renewal Due
                    <fa-icon icon="chevron-right" class="text-xs"></fa-icon>
                </el-button>                
                <el-button
                    v-else-if="row.hasReapplicationPaymentDue"
                    type="warning"
                    size="mini"
                    class="font-bold rounded uppercase align-middle text-xs text-white"
                    @click.stop="renewalPaymentClickHandler(row)"
                >
                    Renewal Payment Due
                    <fa-icon icon="chevron-right" class="text-xs"></fa-icon>
                </el-button>
<!--                <el-button-->
<!--                    v-if="row.hasSurveyConfirmationDue"-->
<!--                    type="warning"-->
<!--                    size="mini"-->
<!--                    class="font-bold rounded uppercase align-middle text-xs text-white"-->
<!--                    @click.stop="surveyConfirmationDueClickHandler(row)"-->
<!--                >-->
<!--                    Survey Confirmation Due-->
<!--                    <fa-icon icon="chevron-right" class="text-xs"></fa-icon>-->
<!--                </el-button>-->
                <el-button
                    v-else-if="row.preferenceDaysDueVisit"
                    type="danger"
                    size="mini"
                    class="font-bold rounded uppercase align-middle text-xs text-white"
                    @click.stop="preferredDaysDueClickHandler(row.preferenceDaysDueVisit.id)"
                >
                  Set Availability
                  <fa-icon icon="chevron-right" class="text-xs"></fa-icon>
                </el-button>

                <template v-if="!hasDues(row)">
                    {{ row[column.property] }}
                </template>
            </template>
        </el-table-column>
        <el-table-column
            sortable
            width="140"
            prop="svScheduledDate"
            label="SV Scheduled">
        </el-table-column>
        <el-table-column
            sortable
            width="120"
            prop="svBookedDate"
            label="SV Booked">
        </el-table-column>

    </el-table>
</template>

<script>
import { mapState } from 'vuex'
import StatusBadge from '@/components/partials/StatusBadge'
import camelcaseKeys from 'camelcase-keys'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import RenewalHelpers from '@/components/shared/mixins/renewalHelpers'
import SurveyConfirmationDueHelpers from '@/components/shared/mixins/surveyConfirmationDueHelpers'
import PreferredDaysDueHelpers from '@/components/shared/mixins/preferredDaysDueHelpers'
import { PAYMENT_TYPES } from '@/config/constants'

export default {
    name: 'PracticeTable',
    mixins: [HttpMixin, MessageDialog, BackendHelpers, RenewalHelpers, SurveyConfirmationDueHelpers,
      PreferredDaysDueHelpers],
    components: {StatusBadge},
    props: {
        tableData: {
            type: Array,
            default: () => [{
                status: 'Accredited',
                name: 'test Practice 2',
                contact: 'Test Contact',
                physicalAddress: {unit: '', suburb: 'Gundagai', state: 'NSW'},
                contactName: 'Andrew Test2',
                previousExpiryDate: '28/10/2020',
                applicationPaid: '04/10/2019',
                phn: 'IPN'
            }]
        },
        searchTerm: {
            type: String,
            default: ''
        },
        filter: {
            type: Object,
            default: () => {}
        },
        practiceNetworks: {
            type: Array,
            default: () => []
        },
        summary: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            emptyText: 'No practices found for the search criteria',
            accreditationStatuses: [
                {value: 'OPEN', label: 'OPEN', text: 'OPEN'},
                {value: 'CLOSED', label: 'CLOSED', text: 'CLOSED'},
            ],
            actionRequiredStatuses: [
                {value: 'YES', label: 'YES', text: 'YES'},
                {value: 'NO', label: 'NO', text: 'NO'},
            ],
            duration: 3000,
            type: '',
            message: ''
        }
    },
    methods: {
        handleRowClick(row) {
            // converting to a string for routing
            let idString = row.organisationPtrId.toString()
            this.$router.push({name: 'Staff', params: { id: idString }})
            // this.$store.commit('SET_PRACTICE_ID', val.organisationPtrId)
        },
        filterChangeHandler(filters) {
            // first we get the key and there should only be one at this stage
            let filter = {...this.filter}, key = Object.keys(filters)[0]
            if (filters[key] && filters[key].length === 0) {
                if (filter.columnFilters[key] != null) {
                    filter.columnFilters[key] = null
                }
            } else {
                filter.columnFilters = {...this.filter.columnFilters, ...filters}
            }
            this.$emit('filter-change', ...[filter, true])
        },
        filterHandler(value, row, column) {
            // console.log(value, row, column)
            // right now we are returning true cause we don't want any FE filtering for now
            return true
        },
        isRowLocked(row) {
            // if we don't have the user yet then just return false
            if (!this.$auth.user()) return true

            let isSurveyor = false
            let surveyVisit = this.getSurveyVisit(row)
            if (surveyVisit) {
                isSurveyor = surveyVisit.surveyVisitors.find(sv => sv.user && sv.user.id === this.$auth.user().userId) != null
            } else {
                isSurveyor = this.isSurveyor(row)
            }

            return row.status === PAYMENT_TYPES.PaymentPending && isSurveyor && !this.isAdmin && !this.isPracticeManager(row)
        },
        rowClassName({row}) {
            const classNames = []
            if (this.isRowLocked(row)) {
                classNames.push('locked-row')
            } else {
                classNames.push('cursor-pointer')
            }
            if (row.hasReapplicationDue) {
                classNames.push('bg-red-light')
            } else if (row.hasReapplicationPaymentDue) {
                classNames.push('bg-yellow-light')
            }

            return classNames.join(' ')
        },
        hasDues(practice) {
            return practice.hasReapplicationDue || practice.hasReapplicationPaymentDue ||
                practice.hasSurveyConfirmationDue || practice.preferenceDaysDueVisit
        },
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            if (this.hasDues(row)) {
                switch (column.property) {
                    case 'selfAssessmentDue':
                        return [1, 3]
                    case 'svScheduledDate':
                    case 'svBookedDate':    
                        return [0, 0]
                }
            }
        },
        getSurveyVisit(practice) {
            if (practice) {
                return practice.surveyVisits != null ? practice.surveyVisits[0] : null
            }
            return null
        },
        isPracticeManager(practice) {
            if (practice && practice.practiceUsers) {
                const practiceUser = practice.practiceUsers.find(pu => pu.userId === this.$auth.user().userId)
                return practiceUser && practiceUser.role.role === 'PM'
            }
            return null
        },
        isSurveyor(practice) {
            if (practice && practice.practiceUsers) {
                const practiceUser = practice.practiceUsers.find(pu => pu.userId === this.$auth.user().userId)
                return practiceUser && practiceUser.role.role === 'SU'
            }
            return false
        },
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        practiceStatus() {
            return this.staticChoices['practice.status'] || []
        },
        states() {
            let states = this.staticChoices['generic_address.state'] || []
            if (states && states.length > 0) {
                states = states.map(s => {
                    return {
                        label: s.label,
                        value: s.value,
                        text: s.value
                    }
                })
            }
            return states
        },
        tableDataFiltered() {
            return this.tableData
        }
    },
}
</script>

<style scoped>
</style>
