<template>
    <div class="flex flex-col h-full p-4 summary-modal">
        <h2 class="text-2xl text-center text-black mb-4">Mark this indicator as 'critical'?</h2>
        <div class="flex flex-row justify-center my-4">
            <div class="text-center leading-relaxed">
                <p>
                    Marking this indicator as 'critical' will <strong>report this practice to the commission.</strong>
                </p>
                <p>
                    Are you sure this indicator's risk is 'critical'?
                </p>
                <p>
                    Selecting the orange boxes will mark the indicator as 'high', which won't report this practice to the commission.
                </p>
            </div>
        </div>

        <div class="flex flex-row justify-center my-4">
            <v-btn color="default" depressed @click.prevent="cancelHandler">Cancel</v-btn>
            <v-btn
                color="success"
                class="ml-2"
                depressed
                :disabled="inputsDisabled"
                @click="submitHandler"
            >Yes, mark as critical</v-btn>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    name: 'ModalCriticalConfirm',
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData,
            isVisible: state => state.app.modal.isVisible
        }),
        inputsDisabled() {
            return this.data?.inputsDisabled
        }
    },
    methods: {
        cancelHandler() {
            this.$root.$emit('matrix-critical-confirm', {confirm: false})
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        submitHandler() {
            this.$root.$emit('matrix-critical-confirm', {confirm: true})
            this.$store.commit('MODAL_IS_VISIBLE', false)
        }
    }
}
</script>
