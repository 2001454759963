export const DJANGO_BASE_URL = process.env.VUE_APP_DJANGO_BASE_URL
export const DEFAULT_BACKEND_ADMIN_URL = process.env.VUE_APP_DEFAULT_BACKEND_ADMIN_URL

export const DEBUG = process.env.VUE_APP_DEBUG
export const IS_NOT_PROD = process.env.VUE_APP_ENV !== 'production'

export const FOLDER_COLORS = ['ff6c00', 'ffbc00', '81d501', '3edfd9', '4ebbff', '0180d5']

export const SURVEY_DOCUMENT_FOLDER_CODE = process.env.VUE_APP_SURVEY_DOCUMENT_FOLDER_CODE
export const STAFF_DOCUMENT_FOLDER_CODE = process.env.VUE_APP_STAFF_DOCUMENT_FOLDER_CODE
export const PREV_ACCRED_FOLDER_CODE = 'PREV_ACCRED'

export const DEFAULT_ERROR_REPORT_TEXT = 'Error loading the report'

export const DEFAULT_OPACITY = 1
export const INACTIVE_OPACITY = 0.2

export const NON_GP_COLOUR = '#EC8239'
export const GP_COLOUR = '#9b4eff'
export const PRACTICE_COLOUR = '#1C1717'

export const DEFAULT_SCALE = 1.75
export const CLICKED_SCALE = 2.5

export const HttpStatusCodes = {
    OK: 200,
    BadRequest: 400,
    Forbidden: 403,
    NotFound: 404,
    ServerError: 500
}

export const PAYMENT_TYPES = {
    PaymentPending: 'PAYMENT_PENDING'
}
export const SURVEY_TYPES = {
    Accreditation: 'ACCREDITATION',
    Surveillance: 'SURVEILLANCE'
}

export const COMPLIANCE_TYPE_TEXT = {
    NotMet: 'Not Met',
    Met: 'Met',
    NotApplicable: 'N/A',
    Complete: 'Complete'
}
export const COMPLIANCE_TYPES = {
    NotMet: 'NOT_MET',
    Met: 'MET',
    NotApplicable: 'NOT_APPLICABLE',
    Complete: 'COMPLETE'
}

export const GP_TYPES = {
    Any: 'any',
    Gp: 'gp',
    NonGp: 'non_gp'
}

export const EMPLOYMENT_OPTIONS = {
    Any: 'any',
    FullTime: 'full_time',
    Contract: 'contract'
}

export const STYLES_MAP = [
    {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    },
    {
        "featureType": "poi.attraction",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    },
    {
        "featureType": "poi.business",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    },
    {
        "featureType": "poi.government",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    },
    {
        "featureType": "poi.medical",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    },
    {
        "featureType": "poi.place_of_worship",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    },
    {
        "featureType": "poi.school",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    },
    {
        "featureType": "poi.sports_complex",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    },
    {
        "featureType": "road.arterial",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    },
    {
        "featureType": "road.local",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text",
        "stylers": [
        {
            "visibility": "off"
        }
        ]
    }
]

export const TEST_DATA = [{
    'sent_by': 'Amber Duarte (Non-GP)',
    'sent_to': 'nominated.user@practice.com',
    'timestamp': '2023-08-09 08:08AM'
}, {
    'sent_by': 'Faith Patterson (Non-GP)',
    'sent_to': 'nominated.user@practice.com',
    'timestamp': '2023-08-09 08:08AM'
}]