<template>
    <div class="flex flex-col h-full p-4 summary-modal">
        <h2 class="text-2xl text-center text-black mb-4">{{ heading }}</h2>
        <div class="flex flex-row justify-center my-4">
            <div 
                class="text-center"
                v-html="body"
            ></div>
        </div>
        <div class="flex flex-row justify-center my-4">
            <el-button @click="cancelHandler">{{ cancelText }}</el-button>
            <el-button type="success" @click="submitHandler" data-cy="submitForReviewFinal">{{ submitText }}</el-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ModalSubmit',
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData,
            isVisible: state => state.app.modal.isVisible,
        }),
        body() {
            return this.data.body || ''
        },
        heading() {
            return this.data.heading || 'Submit for review'
        },
        submitText() {
            return this.data.submitText || 'Yes, submit'
        },
        cancelText() {
            return this.data.cancelText || 'Cancel'
        },
        type() {
            return this.data.type || ''
        },
    },
    methods: {
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        submitHandler() {
            console.log('click handler')
            // commit mutation
            this.$root.$emit('assessment-action', this.type)
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
    }
}
</script>
