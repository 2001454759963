import camelcaseKeys from 'camelcase-keys'

export default {
    methods: {
        renewalClickHandler(practice) {
            this.$router.push({name: 'practice-reapplication', params: {id: practice.organisationPtrId}})
        },
        async renewalPaymentClickHandler(practice) {
            const httpOptions = this.getPostOrPatchOptions({}, `web.practice/${practice.organisationPtrId}`, true)

            try {
                const response = await this.$http(httpOptions)
                const practice = camelcaseKeys(response.data, {deep: true})
                this.goToBackendUrl(practice.applicationPaymentUrl)
            } catch(e) {
                this.message = 'Sorry, something went wrong while redirecting you to the payment page, please contact your system administrator.'
                this.type = 'error'
                this.showMessage({duration: this.duration})
            }
        }
    }
}
