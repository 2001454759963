<template>
    <div class="c-comment-module flex flex-col">
        <div class="flex flex-col items-start mt-4 w-full">
            <Comment
                v-model="commentInput"
                :saving="saving"
                :user-profile="userProfile"
            />
        </div>
        <div class="flex flex-row justify-end mt-3 w-full">
            <el-button
                type="warning"
                class="uppercase text-xs bg-yellow border-yellow rounded py-3 "
                @click="uploadClickHandler"
            >
                Upload file
            </el-button>
            <el-button
                type="primary"
                class="uppercase text-xs rounded py-3"
                @click="commentClickHandler"
                :disabled="commentsDisabled"
                data-cy="indicatorCommentButton"
            >
                comment
            </el-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Comment from '@/components/shared/Comment'
import { ProfileMixin } from '@/components/shared/mixins/profileFns'

export default {
    name: 'CommentModule',
    components: {
        Comment,
    },
    mixins: [ProfileMixin],
    props: {
        indicatorRespId: {
            type: Number,
            default: null
        },
        fileList: {
            type: Array,
            default: () => []
        },
        status: {
            type: String,
            default: null
        },
        isSelfAssess: {
            type: Boolean,
            default: null
        },
        documentFolderId: {
            type: Number,
            default: null
        },
        uploadToHiddenFolder: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            commentInput: '',
            saving: false
        }
    },
    mounted() {
        this.$root.$on('upload-complete', this.closeDocumentUpload)
    },
    methods: {
        uploadClickHandler() {
            // console.log('uploadClickHandler')
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalUpload')
            this.$store.commit('MODAL_DATA', {
                fileList: this.fileList,
                indicatorRespId: this.indicatorRespId,
                documentLink: {
                    indicatorResponseId: this.indicatorRespId,
                },
                indicatorFileUpload: true,
                folderId: this.documentFolderId,
                hideFolder: !this.isSelfAssess
            })
        },
        commentClickHandler() {
            this.saving = true

            // console.log('uploadClickHandler')
            this.$http({
                method: 'POST',
                url: `web.comment/`,
                data: {
                    practice: this.practiceId,
                    indicator_response: this.indicatorRespId,
                    comment: this.commentInput
                }
            }).then(res => {
                console.log('Indicator comment added, id: ', this.indicatorRespId)
                this.commentInput = ''
            }).finally(() => {
                this.$root.$emit('response-updated', this.indicatorRespId)

                this.saving = false
            }).catch(err => {
                console.error('Error in Indicator comment module, response id = ', this.indicatorRespId)
                console.error(err)
            })
        },
        closeDocumentUpload() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        }
    },
    computed: {
        ...mapState({
            practiceId: state => state.app.practiceId,
        }),
        commentsDisabled() {
            return this.commentInput == null || this.commentInput === ''
        },
    },
    beforeDestroy() {
        this.$root.$off('upload-complete')
    },
}
</script>
