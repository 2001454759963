<template>
    <div class="py-4">
        <div class="flex-row pb-6" v-if="!hideSearch">

            <div class="flex items-end justify-between">
                <Search v-model="search" :placeholder="placeholderText" />
                <div class="flex items-center group h-4">
                    <div class="text-xs text-primary-light uppercase hover:text-primary">
                        <a href="#" @click.prevent="downloadCSVHandler" class="flex items-center">
                            <fa-icon icon="download" class="h-3 mr-1 group-hover:text-primary-light" />
                            <span class="font-bold group-hover:text-primary-light">Download CSV</span>
                        </a>
                    </div>
                    <router-link
                        to="/practice/Add"
                        class="pl-6 text-xs text-primary-light uppercase hover:text-primary flex items-center"
                        data-cy="addPracticeLink"
                    >
                        <fa-icon icon="plus" class="h-3 mr-1 group-hover:text-primary-light" />
                        <span class="font-bold group-hover:text-primary-light">Add Practice</span>
                    </router-link>
                </div>
            </div>
            <div class="flex items-center mt-6">
                <div class="fa-container flex items-center text-xs">
                    <fa-icon icon="filter" class="text-black h-3 mt-1" />
                </div>
                <v-checkbox
                    class="ml-2 mt-0"
                    v-model="cycleOpen"
                    label="Cycle open"
                    @change="filterChangeHandler"
                    :ripple="false"
                    hide-details
                ></v-checkbox>
                <v-checkbox
                    class="ml-4 mt-0"
                    v-model="actionRequired"
                    label="Action required"
                    @change="filterChangeHandler"
                    :ripple="false"
                    hide-details
                ></v-checkbox>
                <v-checkbox
                    class="ml-4 mt-0"
                    v-model="selfAssessmentDue"
                    label="Self assessment due"
                    @change="filterChangeHandler"
                    :ripple="false"
                    hide-details
                ></v-checkbox>
                <v-checkbox
                    class="ml-4 mt-0"
                    v-model="renewalDue"
                    label="Renewal due"
                    @change="filterChangeHandler"
                    :ripple="false"
                    hide-details
                ></v-checkbox>
                <v-checkbox
                    class="ml-4 mt-0"
                    v-model="preferenceDaysDue"
                    label="Preference days due"
                    @change="filterChangeHandler"
                    :ripple="false"
                    hide-details
                ></v-checkbox>
                <v-checkbox
                    class="ml-4 mt-0"
                    v-model="lessExpire"
                    label="Less than 12m to expire"
                    @change="filterChangeHandler"
                    :ripple="false"
                    hide-details
                ></v-checkbox>
            </div>
        </div>
        <div class="a-practice-list" v-if="!loading && foundPractices && foundPractices.length">
            <div class="flex flex-row">
                <div class="flex items-center w-full">
                    <!-- TODO: add in filter -->
                    <PracticeTable
                        :table-data="foundPractices"
                        :search-term="filter.searchVal"
                        :filter="filter"
                        :practice-networks="practiceNetworks"
                        :summary="summary"
                        class="practice-list"
                        @filter-change="updateFilter"
                    />
                </div>
            </div>
            <div v-if="total && total.aggregate" class="text-right">
                <el-pagination
                    class="mt-4"
                    :hide-on-single-page="true"
                    :total="total.aggregate.count"
                    :current-page.sync="filter.currentPage"
                    :page-size="filter.pageSize"
                    @prev-click="prevClick"
                    @next-click="nextClick"
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    layout="prev, pager, next"
                >
                </el-pagination>
            </div>
        </div>
        <div v-else-if="!loading && (!foundPractices || foundPractices.length === 0)">
            <p>No practices found</p>
        </div>
        <div class="flex items-center justify-center h-20 w-full" v-else>
            <Loader class="w-12 h-12" />
        </div>
    </div>
</template>

<script>
import PracticeTable from './partials/PracticeTable'
import LIST_PRACTICES from '@/graphql/queries/listPractices.gql'
import SEARCH_PRACTICES from '@/graphql/queries/searchPractices.gql'
import Loader from '@/assets/loader.svg'
import {addMonths, format, isValid, parseISO} from 'date-fns'
import camelcaseKeys from 'camelcase-keys'
import {mapState} from 'vuex'
import PracticeHelpers from '@/components/shared/mixins/practiceHelpers'
import Search from '@/components/partials/Search'
import FileMethods from '@/components/shared/mixins/fileMethods'

export default {
    name: 'PracticeListComponent',
    components: {
        PracticeTable,
        Search,
        Loader
    },
    mixins: [PracticeHelpers, FileMethods],
    props: {
        pageSize: {
            type: Number,
            default: 25
        },
        hideSearch: {
            type: Boolean,
            default: false
        },
        summary: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            skipFiltered: true,
            loading: true,
            search: '',
            practiceList: [],
            foundPractices: [],
            practiceNetworks: [],
            total: 2,
            totalCount: 5,
            filter: {},
            filters: {
                organisation_ptr: {archived: {_eq: false}},
                active_status: {_eq: "ACTIVE"}
            },
            defaultPageSize: 25,
            defaultPagination: {
                limit: 25,
                offset: 0,
                pageSize: 25,
                currentPage: 0
            },
            defaultColumnFilters: {
                status: null,
                practiceNetworkName: null,
                state: null
            },
            defaultContact: {
                firstName: '',
                lastName: '',
                phone: ''
            },
            debounce: null,
            placeholderText: 'Search all practices',
            cycleOpen: this.$route.query.cycleOpen === 'true',
            actionRequired: this.$route.query.actionRequired === 'true',
            selfAssessmentDue: this.$route.query.selfAssessmentDue === 'true',
            renewalDue: this.$route.query.renewalDue === 'true',
            preferenceDaysDue: this.$route.query.preferenceDaysDue === 'true',
            lessExpire: this.$route.query.lessExpire === 'true'
        }
    },
    apollo: {
        practiceList: {
            query: LIST_PRACTICES,
            debounce: 200,
            variables() {     
                this.setFilters()
                return {
                    limit: this.filter.limit,
                    offset: this.filter.offset,
                    filters: this.filters
                }
            },
            skip() {
                // we want to run this if skipFiltered is false
                return !this.skipFiltered
            },
            result: function({data}) {
                // TODO: need to add code to skip this stuff if user doesn't have
                console.log('the filters as of this call', this.filter)
                if (data && data.practiceList) {
                    let convertedPracticeList = camelcaseKeys(data.practiceList, {deep: true})

                    this.foundPractices = this.convertPracticeList(convertedPracticeList)
                    this.practiceNetworks = [...data.practiceNetworks]
                    this.total = data.total
                }

                this.loading = false
                this.$emit('updated-practice-list', this.foundPractices)
            },
            error(error, vm, key, type, options) {
                // for now, we just load it up with random data so we can see something
                this.error = error.message
            }
        },
        filteredPracticeList: {
            query: SEARCH_PRACTICES,
            debounce: 600,
            variables() {
                this.setFilters()
                return {
                    search: this.search,
                    limit: this.filter.limit,
                    offset: this.filter.offset,
                    filters: this.filters
                }
            },
            skip() {
                return this.skipFiltered
            },
            result({data: {filteredPracticeList, total}}) {
                // we need to skip the result once the debounce is done if we aren't actually filtering
                // because, for some reaosn, the filter is still running even though the skip says to ignore on skip
                if (this.skipFiltered) return

                let convertedPracticeList = camelcaseKeys(filteredPracticeList, {deep: true})

                this.foundPractices = this.convertPracticeList(convertedPracticeList)
                this.total = total

                this.loading = false
            },
            error(error, vm, key, type, options) {
                // for now, we just load it up with random data so we can see something
                this.error = error.message
            }
        }
        // total: LIST_PRACTICES
    },
    computed: {
        // ...mapState({
        //     listFilter: state => state.app.listFilter
        // }),
        accreditationFilter() {
            const practiceFilter = []
            const accreditation = {current_accreditation: {}}
            const reapplication = {reapplications: {}}

            if (this.cycleOpen) {
                accreditation.current_accreditation['status'] = {_eq: 'OPEN'}
            }
            if (this.actionRequired) {
                accreditation.current_accreditation['visits'] = {
                    assessments: {
                        status: {
                            _eq: 'ACTION_REQUIRED'
                        }
                    }
                }
            }
            if (this.selfAssessmentDue) {
                accreditation.current_accreditation['self_assessment'] = {
                    status: {
                        _eq: 'IN_PROGRESS'
                    }
                }
            }
            if (this.lessExpire) {
                const today = new Date()
                const now = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`
                const m12_from_now = `${today.getFullYear()+1}-${today.getMonth()+1}-${today.getDate()}`
                accreditation.current_accreditation['_and'] = [
                    {expiry_date : {_lt: `${m12_from_now}`} },
                    {ext_date: {_lt: `${m12_from_now}`}},
                    {_or: [
                        {expiry_date: {_gt: `${now}`}},
                        {ext_date: {_gt: `${now}`}}
                    ]}
                ]
            }

            if (this.preferenceDaysDue) {
                accreditation.current_accreditation['visits'] = {
                    status: {_eq: 'PREFERRED_DAYS_REQ'},
                    date_preferred_days_notif_sent: {_is_null: false},
                    due: {_gt: "now()"}
                }
            }
            if (this.renewalDue) {
                reapplication.reapplications['status'] = {_eq: 'DUE'}
                practiceFilter.push(reapplication)
            }
            if (this.cycleOpen || this.actionRequired || this.selfAssessmentDue || this.lessExpire || this.preferenceDaysDue) {
                practiceFilter.push(accreditation)
            }

            return practiceFilter
        }
    },
    methods: {
        prevClick(val) {
            this.filter.offset = (val - 1) * this.filter.pageSize
            this.updateFilter({...this.filter})
            // this.refetchResults()
        },
        nextClick(val) {
            this.filter.offset = (val - 1) * this.filter.pageSize
            this.updateFilter({...this.filter})
            // this.refetchResults()
        },
        handleCurrentChange(val) {
            this.filter.offset = (val - 1) * this.filter.pageSize
            this.updateFilter({...this.filter})
            // this.refetchResults()
        },
        handleSizeChange(val) {
            this.filter.limit = val
            this.updateFilter({...this.filter})
            // this.refetchResults()
        },
        updateFilter(val, resetPagination) {
            // only update the filter on the actual PracticeList page
            let filter
            if (resetPagination && !this.loading) {
                filter = {...val, ...this.defaultPagination}
            } else {
                filter = {...val}
            }
            this.filter = {...filter}
        },
        updateFilters() {
            if (this.filter) {
                if (this.filter.limit) {
                    this.limit = this.filter.limit
                }
                if (this.filter.offset != null) {
                    this.offset = this.filter.offset
                }
                if (this.filter.searchVal) {
                    this.search = this.filter.searchVal
                }
                // now we add the default column filters
                this.filter.columnFilters = {
                    ...this.defaultColumnFilters,
                    ...this.filter.columnFilters
                }
                // this.refetchResults()
            }
        },
        resetFilters(filter) {
            this.filters._and?.map((i, index) => {
                if(!filter?.state) {
                    if("organisation_ptr" in i) {
                        this.filters._and.splice(index, 1)
                    }
                }
                if(!filter?.practiceNetworkName) {
                    if("practice_network_name" in i) {
                        this.filters._and.splice(index, 1)
                    }
                }
                if(!filter?.status) {
                    if("status" in i) {
                        this.filters._and.splice(index, 1)
                    }
                }
                if(!filter?.status && !filter?.practiceNetworkName && !filter?.state && !this.accreditationFilter.length) {
                    delete this.filters._and
                }
            })
        },
        setFilters() {
            const columnFilters = this.filter.columnFilters
                
            if((columnFilters?.status || columnFilters?.practiceNetworkName || columnFilters?.state) || this.accreditationFilter.length) {
                this.filters._and = []
                if(columnFilters?.status) {
                    this.filters._and.push({status: {_in: columnFilters.status}})
                }
                if(columnFilters?.practiceNetworkName) {
                    this.filters._and.push({practice_network_name: {_in: columnFilters.practiceNetworkName}})
                }
                if(columnFilters?.state) {
                    this.filters._and.push({organisation_ptr: {physical_address: {state: {_in: columnFilters.state}}}})
                }
                if(this.accreditationFilter.length) {
                    this.filters._and = this.accreditationFilter
                }
            }
            this.resetFilters(columnFilters)
        },
        downloadCSVHandler() {
            let url = `web.practice/download_csv/`
            this.downloadRemoteFileHandler('PracticeList', url, 'GET', this.foundPractices)
        },
        filterChangeHandler() {
            const query = {}
            if (this.cycleOpen) query.cycleOpen = this.cycleOpen
            if (this.actionRequired) query.actionRequired = this.actionRequired
            if (this.selfAssessmentDue) query.selfAssessmentDue = this.selfAssessmentDue
            if (this.renewalDue) query.renewalDue = this.renewalDue
            if (this.preferenceDaysDue) query.preferenceDaysDue = this.preferenceDaysDue
            if (this.lessExpire) query.lessExpire = this.lessExpire
            this.$router.replace({name: 'PracticeList', query})
        },
        convertPracticeList(practices) {
            console.log("practice", practices)
            return practices.map(practice => {
                const accreditation = practice.currentAccreditation
                let nominatedAccreditationContact =
                    accreditation !== undefined && accreditation !== null
                        ? accreditation.nominatedAccreditationContact || this.defaultContact
                        : this.defaultContact

                nominatedAccreditationContact.displayName = `${nominatedAccreditationContact.firstName} ${nominatedAccreditationContact.lastName}`
                practice.nominatedAccreditationContact = nominatedAccreditationContact

                let qam =
                    accreditation !== undefined && accreditation !== null
                        ? accreditation.qam || this.defaultContact
                        : this.defaultContact

                qam.displayName = `${qam.firstName} ${qam.lastName}`
                practice.qam = qam

                practice.accreditationStatus = accreditation != null ? accreditation.status : ''
                practice.actionRequiredStatus = accreditation?.actionRequiredVisits?.length > 0

                if (accreditation && accreditation.selfAssessmentDue) {
                    const selfAssessmentDue = parseISO(accreditation.selfAssessmentDue)
                    practice.selfAssessmentDue = isValid(selfAssessmentDue)
                        ? format(selfAssessmentDue, 'dd/MM/yyyy')
                        : ''
                }

                if (accreditation && accreditation.surveyVisitDue) {
                    const svScheduledDate = parseISO(accreditation.surveyVisitDue)
                    practice.svScheduledDate = isValid(svScheduledDate)
                        ? format(svScheduledDate, 'MMM yyyy')
                        : ''
                }
                if (accreditation && accreditation.visits && accreditation.visits.length > 0) {
                    const svBookedDate = parseISO(accreditation.visits[0].date)
                    practice.svBookedDate = isValid(svBookedDate)
                        ? format(svBookedDate, 'dd/MM/yyyy')
                        : ''
                }
                if (practice.firstApplicationStartedDate) {
                    const startedDate = parseISO(practice.firstApplicationStartedDate)
                    practice.firstApplicationStartedDateDisplay = isValid(startedDate)
                        ? format(startedDate, 'dd/MM/yyyy')
                        : ''
                }

                if (practice.organisationPtr && practice.organisationPtr.physicalAddress) {
                    practice.state = practice.organisationPtr.physicalAddress.state
                }

                practice.expiryDate = this.getExpiryDate(accreditation)
                practice.hasReapplicationDue = practice.reapplicationsAggregate.aggregate.count > 0
                practice.hasReapplicationPaymentDue =
                    practice.reapplicationsPaymentAggregate.aggregate.count > 0

                const visit = Array.isArray(accreditation?.visits) ? accreditation?.visits[0] : null
                practice.hasSurveyConfirmationDue = visit?.confirmationFormStatus === 'SENT'

                if (accreditation) {
                    practice.preferenceDaysDueVisit = accreditation?.preferredDaysVisits?.length > 0 ? accreditation.preferredDaysVisits[0] : null
                } else {
                    practice.preferenceDaysDueVisit = null;
                }

                if (practice.preferenceDaysDueVisit) {
                    const prefDueMonth = parseISO(practice.preferenceDaysDueVisit.due)
                    practice.preferenceDaysDueVisitDueMonth = isValid(prefDueMonth) ? format(prefDueMonth, 'MMMM yyyy') : ''
                } else {
                    practice.preferenceDaysDueVisitDueMonth = null
                }

                return practice
            })
        }
    },
    watch: {
        search() {
            this.filter.searchVal = this.search
            this.skipFiltered = this.search == null || this.search == '' || this.search == undefined
            // resetting the pagination
            this.updateFilter(this.filter, true)
            // this.updateSearch(this.search)
        },
        filter() {
            // this.filter = {...this.listFilter}
            this.updateFilters()
        }
    },
    mounted() {
        this.defaultPagination.limit = this.pageSize
        this.defaultPagination.pageSize = this.pageSize
        this.filter.limit = this.pageSize
        this.filter.pageSize = this.pageSize

        this.cycleOpen = this.$route.query.cycleOpen
        this.actionRequired = this.$route.query.actionRequired
        this.selfAssessmentDue = this.$route.query.selfAssessmentDue
        this.renewalDue = this.$route.query.renewalDue
        this.preferenceDaysDue = this.$route.query.preferenceDaysDue
        this.lessExpire = this.$route.query.lessExpire
        this.updateFilters()
    }
}
</script>
