import Vue from 'vue'
import camelcaseKeys from 'camelcase-keys'
import staticChoices from '@/json/staticChoices.json'
import { VueGotoInstance } from '@/plugins/gotowebinars'

import GET_FOLDERS from '@/graphql/queries/getFolders.gql'
import { FOLDER_COLORS } from '@/config/constants'
import { useAssessmentStore } from '@/stores/useAssessmentStore'

// import axios from 'axios'

export default {
    fetchStaticChoices(store) {
        if (Vue.auth.check() && (!store.state.staticChoices || Object.keys(store.state.staticChoices).length === 0 || Object.keys(store.state.staticChoices).length === 1)){
            // if we just logged in and need to get the full static choices list
            Vue.axios({
                method: 'GET',
                url: `static.choices/`
            }).then(({ data }) => {
                let newChoices = {}
                const keys = Object.keys(data)
                for (let key of keys) {
                    if (typeof data[key] === "number"){
                        newChoices[key] = {
                            value: data[key],
                            label: key,
                            text: key,
                        }
                        continue
                    }
                    newChoices[key] = data[key].map((item) => {
                        return {
                            value: item[0],
                            label: item[1],
                            text: item[1],
                        }
                    })
                }
        
                store.commit('SET_STATIC_OBJECT', newChoices)
            }).catch(err => {
                console.error(err)
            })
        } else if (!Vue.auth.check() && Object.keys(store.state.staticChoices).length === 0) {
            // if we are not logged in then get the default static choices - which should only have the user title
            const data = staticChoices
            let newChoices = {}
            const keys = Object.keys(data)
            for (let key of keys) {
                newChoices[key] = data[key].map((item) => {
                    return {
                        value: item[0],
                        label: item[1],
                        text: item[1],
                    }
                })
            }
            store.commit('SET_STATIC_OBJECT', newChoices)
        }
    },
    getPermissions(state, { practiceId }) {
        const permissionsStore = useAssessmentStore()
        const { updateAssessmentPermissions } = permissionsStore
        if (practiceId) {
            Vue.axios({
                method: 'GET',
                url: `web.practice/${practiceId}/user_permissions/`,
            })
                .then((response) => {
                    const permissions = camelcaseKeys(response.data.permissions, { deep: true })
                    state.commit('SET_PERMISSIONS', permissions)
                    updateAssessmentPermissions(permissions?.assessment)
                })
                .catch((err) => {
                    console.log('something went wrong', err)
                    // if we didn't get the permissions then it means we can't view this practice
                    Vue.router.push({name: 'PracticeList'})
                })
        }
    },
    setPracticeId($store, { practiceId }) {
        if (practiceId !== $store.state.practiceId) {
            $store.commit('SET_STAFF_TOTAL', 0)
            $store.commit('SET_DOCS_TOTAL', 0)

            $store.commit('SET_STAFF_FIRST_VIEW', true)
            $store.commit('SET_DOCUMENTS_FIRST_VIEW', true)
            $store.commit('SET_ACCREDITATION_FIRST_VIEW', true)
        }

        $store.commit('SET_PRACTICE_ID', practiceId)
    },
    async getGoToWebinarAccessToken($store) {
        const code = $store.state.webinarLoginCode
        if (code) {
            try {
                const params = {
                    grant_type: 'authorization_code',
                    code: code,
                    redirect_uri: `${window.location.origin}/GoToWebinars/callback`
                }
                const response = await VueGotoInstance.post(
                    '/oauth/v2/token', null, {
                        params: params,
                        headers: {
                            Authorization: `Basic ${process.env.VUE_APP_GOTOWEBINAR_BASIC_AUTH}`,
                            Accept: 'application/json',
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                )
    
                $store.commit('SET_WEBINAR_ACCESS_OBJECT', response.data)
            } catch (err) {
                console.log('error from getting webinar access object', err)
                //TODO: handle error and try again or inform user to refresh or retry the connection to GoToWebinar
            }
        }
    },
    fetchFolders(store, {apolloClient, getVisibleFolders, practiceId, emptyHidden}) {
        // if variable is null, then we assume we want to see the folders so we make it true
        if (getVisibleFolders == null) getVisibleFolders = !getVisibleFolders
        store.commit('SET_GET_VISIBLE_FOLDERS', getVisibleFolders)
        
        return apolloClient.query({
            query: GET_FOLDERS,
            variables: { practiceId, visible: !!getVisibleFolders, emptyHidden }
        }).then(({ data }) => {
            const folders = camelcaseKeys(data.folders, { deep: true })
            
            folders.forEach((folder, index) => {
                folder.color = FOLDER_COLORS[index % FOLDER_COLORS.length]
            })

            store.commit('SET_FOLDERS', folders)
        })
    },
    showModal({ commit }, { payload, modalName}) {
        commit('MODAL_IS_VISIBLE', true)
        commit('MODAL_DATA', payload)
        commit('MODAL_COMPONENT_NAME', modalName)
    },
    hideModal({ commit }) {
        commit('MODAL_IS_VISIBLE', false)
        commit('MODAL_COMPONENT_NAME', '')
        commit('MODAL_DATA', {})
    }
}
