<template>
<div class="grid grid-cols-2 xl:grid-cols-4 gap-4 my-8">
    <div class="flex flex-col col-span-1 xl:col-span-1 mb-3 xl:mb-0">
        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Nominated accreditation contact</label>
        <el-select
            class="mb-4 p-0"
            v-model="nominatedContacts.nominatedAccreditationContact.id"
            filterable
            placeholder="Enter name"
            @change="updateContact('nominatedAccreditationContact')"
        >
            <el-option
                v-for="item in staffList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
        </el-select>
        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Phone</label>
        <PhoneNumber
            data-cy="nominatedContactPhone"
            name="accreditedContactPhone"
            placeholder="Phone"
            class="mb-4 p-0"
            v-model="nominatedContacts.nominatedAccreditationContact.phone"
            ref="nominatedAccreditationContactPhone"
            :validPhone="true"
            :required="false"
            @blur="updateContactPhone('nominatedAccreditationContact', 'nominatedAccreditationContactPhone')"
        />
    </div>
    <div class="flex flex-col col-span-1 xl:col-span-1 mb-3 xl:mb-0">
        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Nominated GP contact</label>
        <el-select
            class="mb-4 p-0"
            v-model="nominatedContacts.nominatedGpContact.id"
            filterable
            placeholder="Enter staff name"
            @change="updateContact('nominatedGpContact')"
        >
            <el-option
                v-for="item in gpContactList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
        </el-select>
        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Phone</label>
        <PhoneNumber
            name="gpContactPhone"
            placeholder="Phone"
            class="mb-4 p-0"
            v-model="nominatedContacts.nominatedGpContact.phone"
            ref="nominatedGpContactPhone"
            :validPhone="true"
            :required="false"
            @blur="updateContactPhone('nominatedGpContact', 'nominatedGpContactPhone')"
        />
    </div>
    <div class="flex flex-col col-span-1 xl:col-span-1">
        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Nominated Nurse contact</label>
        <el-select
            class="mb-4 p-0"
            v-model="nominatedContacts.nominatedNurseContact.id"
            filterable
            placeholder="Enter staff name"
            @change="updateContact('nominatedNurseContact')"
        >
            <el-option
                v-for="item in nurseContactList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
        </el-select>
        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Phone</label>
        <PhoneNumber
            name="nurseContactPhone"
            placeholder="Phone"
            class="mb-4 p-0"
            v-model="nominatedContacts.nominatedNurseContact.phone"
            ref="nominatedNurseContactPhone"
            :validPhone="true"
            :required="false"
            @blur="updateContactPhone('nominatedNurseContact', 'nominatedNurseContactPhone')"
        />
    </div>
    <div class="flex flex-col col-span-1 xl:col-span-1">
        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Nominated Support contact</label>
        <el-select
            class="mb-4 p-0"
            v-model="nominatedContacts.nominatedSupportContact.id"
            filterable
            placeholder="Enter staff name"
            @change="updateContact('nominatedSupportContact')"
        >
            <el-option
                v-for="item in supportContactList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
        </el-select>
        <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Phone</label>
        <PhoneNumber
            name="supportContactPhone"
            placeholder="Phone"
            class="mb-4 p-0"
            v-model="nominatedContacts.nominatedSupportContact.phone"
            ref="nominatedSupportContactPhone"
            :validPhone="true"
            :required="false"
            @change="updateContactPhone('nominatedSupportContact', 'nominatedSupportContactPhone')"
        />
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import PhoneNumber from '@/components/shared/PhoneNumber'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import snakecaseKeys from 'snakecase-keys'
import MessageDialog from '@/components/shared/mixins/messageDialog'

export default {
    name: 'NominatedContacts',
    mixins: [ HttpMixin, MessageDialog ],
    components: { PhoneNumber },
    props: {
        staffList: {
            type: Array,
            default: () => [{
                value: 'Option1',
                label: 'Option1'
            },
            {
                value: 'Option2',
                label: 'Option2'
            }]
        },
        allStaffList: {
            type: Array,
            default: () => []
        },
        accreditation: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            duration: 3000,
            defaultPhoneNumber: {
                countryCode: 'AU',
                phone: ''
            },
            nominatedContacts: {
                nominatedAccreditationContact: {
                    user: '',
                    phone: {
                        countryCode: 'AU',
                        phone: ''
                    }
                },
                nominatedGpContact: {
                    user: '',
                    phone: {
                        countryCode: 'AU',
                        phone: ''
                    }
                },
                nominatedNurseContact: {
                    user: '',
                    phone: {
                        countryCode: 'AU',
                        phone: ''
                    }
                },
                nominatedSupportContact: {
                    user: '',
                    phone: {
                        countryCode: 'AU',
                        phone: ''
                    }
                },
            }
        }
    },
    mounted() {
        this.fillNominatedContacts()
    },
    methods: {
        fillNominatedContacts() {
            const ignoreKeys = ['typename', 'id']
            if (this.accreditation && this.accreditation.length > 0) {
                const accreditation = this.accreditation[0]
                const keys = Object.keys(accreditation)
                for (let key of keys) {
                    if (accreditation[key] && ignoreKeys.indexOf(key) === -1) {
                        this.nominatedContacts[key] = accreditation[key]
                        if (!this.nominatedContacts[key].phone) {
                            this.nominatedContacts[key].phone = {...this.defaultPhoneNumber}
                        }
                    }
                }
            }
        },

        save(contactKey, staffToSave, skipPhone) {
            const staff = {...staffToSave}
            let promise = Promise.resolve({})
            if (!skipPhone) {
                if (staff.phone && staff.phone.phone === '') {
                    staff.phone = null
                }
                const httpOptions = this.getPostOrPatchOptions(snakecaseKeys(staff, {deep: true}), 'web.practicestaff')
                promise = this.$http(httpOptions)
            }

            promise.then(() => {

                const accreditationObject = {
                    id: this.accreditation[0].id,
                    practice: this.practiceId
                }
                accreditationObject[contactKey] = staff.id
                const httpOptions = this.getPostOrPatchOptions(snakecaseKeys(accreditationObject, {deep: true}), 'web.accreditation')

                return this.$http(httpOptions)
            })
            .then(() => {
                this.message = 'Successfully updated nominated contact'
                this.type = 'success'
            })
            .catch((err) => {
                console.log('Error redirecting', err)
                // TODO: show message and hide when going away from this screen
                this.message = 'Error updating nominated contact'
                this.type = 'error'
            })
            .finally(() => {
                this.showMessage({duration: this.duration})
            })
        },
        saveNominatedContact(contactKey, staffToSave, refKey) {
            if (this.$refs[refKey]) {
                this.$refs[refKey].validate()
                    .then((res) => {
                        this.save(contactKey, staffToSave)
                    })
                    .catch(err => {
                        console.log('the phone number validation failed with error:', err)
                    })
            } else {
                //only get here if we are saving the dropdown part of the select
                this.save(contactKey, staffToSave, true)
            }

        },
        updateContactPhone(contactKey, refKey) {
            const staff = this.allStaffList.find(sl => sl.id === this.nominatedContacts[contactKey].id)
            // staff['phone'] = this.nominatedContacts[contactKey].phone
            if (!staff) {
                return
            }

            const updateObj = {
                id: staff.id,
                phone: this.nominatedContacts[contactKey].phone
            }
            this.saveNominatedContact(contactKey, updateObj, refKey)
        },
        updateContact(contactKey) {
            // this.$emit('update-accreditation-contact', {contactKey, this.nominatedContacts[contactKey]})
            // now we update the accreditation

            const staff = this.allStaffList.find(sl => sl.id === this.nominatedContacts[contactKey].id)
            // if the phone prop on the practice staff object is null, set it to the default
            if (!staff.phone) {
                staff.phone = {...this.defaultPhoneNumber}
            }
            this.nominatedContacts[contactKey] = {...staff}

            this.saveNominatedContact(contactKey, staff)
        }
    },
    computed: {
        ...mapState({
            practiceId: state => state.app.practiceId
        }),
        gpContactList() {
            return this.staffList.length > 0 ? this.staffList.filter(s => s.type.toLowerCase() === 'gp') : []
        },
        nurseContactList() {
            return this.staffList.length > 0 ? this.staffList.filter(s => s.type.toLowerCase() === 'nurse') : []
        },
        supportContactList() {
            return this.staffList.length > 0 ? this.staffList.filter(s => s.type.toLowerCase() === 'support' || s.type.toLowerCase() === 'allied_health') : []
        },
    },
    watch: {
        accreditation() {
            this.fillNominatedContacts()
        }
    }
}
</script>

<style>

</style>
