<template>
    <el-collapse-item
        class="indicator-set__indicator"
        :name="indicatorId"
        v-if="isNotFiltered"
    >
        <div class="indicatorSaveOverlay" v-if="savingIndicator"></div>
        <div class="absolute right-0 left-0" v-if="savingIndicator">
            <div class="flex items-center justify-center h-20 w-full">
                <Loader class="w-12 h-12" />
            </div>
        </div>

        <template slot="title">
            <div class="flex flex-row text-grey-1 text-sm xl:text-base">
                <span class="font-bold font-display mr-3" :class="getCodeColour" data-cy="indicator-code">{{ indicatorSet.code }}</span>
                <span class="indicator-name" data-cy="indicator-name">{{ indicatorSet.name }}</span>
                <span class="indicator-totals ml-4 flex flex-row text-grey-6 text-sm items-center">
                    <span class="mr-1" data-cy="total-docs">{{ getTotalDocs }}</span>
                    <fa-icon icon="paperclip" class="mr-4" />
                    <span class="mr-1" data-cy="total-comments">{{ getTotalComments }}</span>
                    <fa-icon :icon="['far', 'comment']"  class="mr-3" />
                </span>
                <EditingActiveModule
                    :users="indicatorLockArr"
                    :indicatorRespId="indicatorRespId"
                    :userId="userId"
                    :saving="savingIndicator"
                />
            </div>
        </template>
        <div
            class="indicator-view grid grid-cols-1 sm:gap-2 lg:grid-cols-2 xl:ml-4 mt-4 mb-4 xl:gap-4"
            @click="indicatorViewClickHandler"
        >
            <div class="indicator-view__left-col ">
                <!-- this flex start isn't required, not sure why this is here -->
<!--                <div class="flex flex-start">-->
                    <div class="mb-3 text-black font-display font-semibold">Assessment</div>
<!--                </div>-->
                <div
                    class="indicator-view__question mb-4"
                    v-for="item in indicatorSet.checklists"
                    :key="`question-${item.question_number}`"
                >
                    <p class="mb-2 text-sm lg:text-sm">{{ item.question_text }}</p>
                    <!-- surveyor checkboxes -->
                    <el-popover
                        placement="top-start"
                        title="Self Assessment Responses"
                        width="200"
                        trigger="hover"
                        content="The first checkboxes are the self assessment responses given by the practice."
                        v-if="!isSelfAssess"
                    >
                        <fa-icon icon="question-circle" slot="reference" class="grey-1" />
                    </el-popover>

                    <el-checkbox
                        v-if="showCheckAll(item)"
                        class="mb-2 a-check-all"
                        :disabled="selfAsDisabled"
                        ref="checkAllMustHaveItemsRef"
                        label="Check All"
                        v-model="item.checkAll"
                        @change="checkAllMustHaveItems"
                    ></el-checkbox>
                    <el-checkbox-group v-model="checkedItems" v-if="!isSelfAssess" @change="checkedItemsChangeHandler">
                        <div class="flex flex-row"
                            v-for="(checklistItem, index) in item.items"
                            :key="`checklistItem-${item.question_number}-${index}`"
                        >
                            <!-- First checkbox columns are the self assessment responses, second are surveyor assessment -->
                            <el-checkbox class="indicator-view__response-checkbox mr-2" disabled :label="`self-item-${item.question_number}-${index}`"></el-checkbox>
                                {{ checklistItem.item_text }}
                            <el-checkbox
                                class="mb-3"
                                :class="{'indicator-view__response-checkbox--warning': mustHaveIsActive(checklistItem.must_have, checklistItem.item_text), 'must-have-indicator': checklistItem.must_have}"
                                :label="checklistItem.item_text"
                                :disabled="surveyAsCheckboxesDisabled || savingIndicator"
                            >
                            </el-checkbox>
                        </div>
                    </el-checkbox-group>
                    <!-- Self assessment checkboxes -->
                    <el-checkbox-group v-model="checkedItems" v-else>
                        <div class="flex flex-row"
                            v-for="(checklistItem, index) in item.items"
                            :key="`checklistItem-${item.question_number}-${index}`"
                        >
                            <!-- <el-checkbox :class="'indicator-view__response-checkbox--warning'" class="mb-3" :label="checklistItem.item_text"> -->
                            <el-checkbox
                                class="mb-3"
                                :label="checklistItem.item_text"
                                :class="{'indicator-view__response-checkbox--warning': mustHaveIsActive(checklistItem.must_have, checklistItem.item_text)}"
                                :disabled="selfAsDisabled"
                            >
                                {{ checklistItem.item_text }}
                            </el-checkbox>
                        </div>
                    </el-checkbox-group>
                    <!-- {{ checkedItems }} -->
                </div>
                <div class="flex flex-col mt-4">
                    <div class="mb-3 text-black font-display font-semibold">Resources</div>
                    <a
                        v-for="(item, index) in indicatorResources"
                        :key="`doc-${index}`"
                        class="flex flex-row"
                        href="#"
                        @click.prevent="documentClickHandler($event, item)"
                    >
                        <i class="el-icon-document flex items-center mr-2 text-primary text-sm lg:text-base"></i>
                        <p class="text-primary text-sm lg:text-sm">{{ item.name }}</p>
                    </a>
                </div>
            </div>
            <div class="indicator-view__right-col flex flex-col">
                <div class="rounded rounded-lg bg-grey-5 px-4 pt-3 pb-4 flex flex-col">
                    <div class="flex flex-row justify-between grid grid-cols-6 gap-4 w-full">
                        <h4 class="col-span-2 text-black font-semibold block flex items-center">
                            {{ setName }}
                            <i class="text-black el-icon-caret-right text-xl" v-if="indicatorSet.flagged"></i>
                            {{ indicatorSet.code }}
                        </h4>
                        <div class="col-span-4 flex justify-end complianceRadios">
                            <IndicatorRadioGroup
                                v-if="!isSelfAssess"
                                v-model="complianceResponse"
                                :surveyInputsDisabled="surveyInputsDisabled"
                                :savingIndicator="savingIndicator"
                            />
                            <IndicatorButtonGroup
                                v-if="isSelfAssess"
                                v-model="complianceResponse"
                                :surveyInputsDisabled="surveyInputsDisabled"
                                :savingIndicator="savingIndicator"
                            />
                        </div>
                        <div class="col-start-3 col-span-2 flex flex-start items-center" v-show="reviewOrCompleteOrFurtherAction && showFurtherActionRequired">
                            <el-checkbox v-model="furtherActionRequired" :disabled="surveyInputsDisabled">
                                Further Action Required
                            </el-checkbox>
                        </div>
                        <div class="col-start-3 col-span-2 flex flex-start items-center" v-show="showReviewed">
                            <el-checkbox v-model="reviewed" :disabled="surveyInputsDisabled">
                                Reviewed
                            </el-checkbox>
                        </div>
                    </div>
                    <div class="flex flex-col" v-if="!isSelfAssess">
                        <RiskMatrix
                            v-if="complianceResponse === 'NOT_MET'"
                            v-model="riskMatrixResponses"
                            :status="status"
                            :isDisabled="surveyInputsDisabled || savingIndicator"
                            :indicatorLockArr="indicatorLockArr"
                            :indicatorRespId="indicatorSet.responses.id"
                            :userId="userId"
                            @input-interaction="inputInteractionHandler"
                            @select-click-event="inputInteractionHandler"
                        />
                        <CompliantView
                            v-model="riskMatrixResponses"
                            v-if="complianceResponse === 'MET'"
                            :isDisabled="surveyInputsDisabled || savingIndicator"
                            :indicatorLockArr="indicatorLockArr"
                            :indicatorRespId="indicatorSet.responses.id"
                            :userId="userId"
                            @input-interaction="inputInteractionHandler"
                        />
                    </div>
                </div>
                <!-- warning box self-as. -->
                <StatusInformation v-if="showStatusInformation" :success="indicatorSuccessView" />

                <div class="admin-actions rounded rounded-lg bg-yellow-light px-4 flex flex-col mt-4" @click.stop="">
                    <div class="flex flex-row items-center w-full grid grid-cols-6 gap-4 pt-3 pb-4"
                        v-if="viewActivityStatus"
                    >
                        <h4 class="col-span-2 font-bold text-base text-yellow-dark mr-8">Activity</h4>
                        <div class="col-span-4 flex flex-start items-center">
                            <el-radio-group v-model="nonConformityStatus" class="mr-12" :disabled="!changeActivityStatus || savingIndicator" @change="activityStatusChangeHandler(true)">
                                <el-radio class="mr-12" label="OPEN">Open</el-radio>
                                <el-radio class="mr-12" label="CLOSED">Close</el-radio>
                            </el-radio-group>
                            <!--<el-checkbox class="" label="Refer to CA" v-model="adminControls.referToCa" :disabled="!changeActivityStatus || savingIndicator" @change="activityStatusChangeHandler"></el-checkbox>-->
                        </div>
                    </div>
                    <CommentModule
                        v-if="commentingIsActive"
                        class="pb-4"
                        :indicatorRespId="indicatorSet.responses.id"
                        :status="status"
                        :documentFolderId="documentFolderId"
                        :isSelfAssess="isSelfAssess"
                    />
                </div>

                <!-- need the click.stop below because we don't want the ActivityModule affecting the locking-->
                <div @click.stop>
                    <ActivityModule
                        :commentsArr="indicatorSet.responses.comments"
                        :indicatorRespId="indicatorSet.responses.id"
                        :commentsAggregate="getTotalComments"
                        :assessmentId="assessmentId"
                        :indicatorId="indicatorId"
                        :fileList="indicatorSet.responses.documents"
                        :status="status"
                        :submissions="submissions"
                        @update-comment="updateComment"
                    />
                </div>
            </div>
        </div>
    </el-collapse-item>
</template>

<script>
import ActivityModule from '../modules/ActivityModule'
import CommentModule from '../modules/CommentModule'
import EditingActiveModule from '../modules/EditingActiveModule'
import RiskMatrix from './RiskMatrix'
import CompliantView from './CompliantView'
import StatusInformation from './StatusInformation'
import UPDATE_INDICATOR from '@/graphql/mutations/updateIndicatorResponse.gql'
import UPDATE_COMMENT from '@/graphql/mutations/updateComment.gql'
import snakecaseKeys from 'snakecase-keys'
import FileMethods from '@/components/shared/mixins/fileMethods'
import IndicatorViewPermissions from '@/components/accreditation/mixins/indicatorViewPermissions'
import Loader from '@/assets/loader.svg'
import { format } from 'date-fns'
import { AuthMixin } from '@/components/shared/mixins/authHelpers'
import { SURVEY_DOCUMENT_FOLDER_CODE } from '@/config/constants'
import { mapState } from 'vuex'
import FolderMixin from '@/components/shared/mixins/folderMixin'
import IndicatorButtonGroup from '@/components/accreditation/partials/IndicatorButtonGroup'
import IndicatorRadioGroup from '@/components/accreditation/partials/IndicatorRadioGroup'
import { useIndicatorSetStore } from '@/stores/useIndicatorSetStore'
import { computed } from '@vue/composition-api'

export default {
    name: 'IndicatorView',
    props: {
        surveyType: {
            type: String,
            default: null
        },
        indicatorSet: {
            type: Object,
            default: () => {}
        },
        indicatorLockArr: {
            type: Array,
            default: () => []
        },
        setName: {
            type: String,
            default: ''
        },
        indicatorId: {
            type: Number,
            default: null
        },
        filterType: {
            type: String,
            default: ''
        },
        status: {
            type: String,
            default: null
        },
        adminFilter: {
            type: String,
            default: null
        },
        isSelfAssess: {
            type: Boolean,
            default: null
        },
        assessmentId: {
            type: String,
            default: null
        },
        submissions: {
            type: Object,
            default: () => {}
        }
    },
    mixins: [
        AuthMixin,
        FileMethods,
        IndicatorViewPermissions,
        FolderMixin
    ],
    components: {
        ActivityModule,
        RiskMatrix,
        CompliantView,
        StatusInformation,
        CommentModule,
        EditingActiveModule,
        Loader,
        IndicatorButtonGroup,
        IndicatorRadioGroup
    },
    data() {
        return {
            checkedItems: [],
            mustHaveItems: [],
            radioStatus: '',
            toggleDataView: false,
            naToggle: false,
            completeToggle: false,
            complianceResponse: '',
            furtherActionRequired: false,
            reviewed: false,
            isMarkedComplete: null,
            adminControls: {
                referToCa: false,
                nonConformityStatus: '',
            },
            nonConformityStatus: '',
            checkListRespObj: {},
            notInitialRender: false,
            debounceEditTime: 1000,
            indicatorRespId: null,
            activityObj: {
                timer: null,
                flag: true,
            },
            textInput: null,
            checkedItemsEmptyTriggered: false,
            editingIsActive: false,
            riskMatrixResponsesResponse: {
                nonComplianceType: '',
                nonComplianceStatement: null,
                ncStatusCreatedById: null,
                riskImpact: null,
                riskRating: null,
                riskType: '',
                riskLikelihood: null,
                observation: null,
            },
            responseDateClosed: null,
            savingIndicator: false,
            previousCheckedItems: [],
            previousCompliance: '',
            complianceChangeConfirming: false,
            documentFolderId: 0
        }
    },
    beforeMount() {
        this.loadDocumentFolderCode()
        this.addCheckedItem()
        this.setMustHaveArr()
        this.setCheckAll()
        this.indicatorRespId = this.indicatorSet.responses.id
        this.setComplianceResponse()
    },
    mounted() {
        // set user id first, this will be passed to child comps
        this.setAdminControls()
        this.setActionRequired()
        this.setReviewed()

        // emit the intiial value so that the indicator colour is set
        this.$emit('indicator-value', this.emitPayload)
    },
    updated() {
        this.notInitialRender = true
    },
    computed: {
        ...mapState({
            folders: state => state.app.folders,
            practiceId: state => state.app.practiceId,
        }),
        riskMatrixResponses: {
            get() {
                return {
                    nonComplianceType: this.indicatorSet.responses.nonComplianceType || null,
                    nonComplianceStatement: this.indicatorSet.responses.nonComplianceStatement || null,
                    riskImpact: this.indicatorSet.responses.riskImpact || null,
                    riskRating: this.indicatorSet.responses.riskRating || null,
                    riskLikelihood: this.indicatorSet.responses.riskLikelihood || null,
                    riskType: this.indicatorSet.responses.riskType || null,
                    observation: this.indicatorSet.responses.observation || null,
                    ncStatusCreatedById: this.indicatorSet.responses.ncStatusCreatedById || null
                }
            },
            set(data) {
                this.riskMatrixResponsesResponse = {
                    nonComplianceType: data.nonComplianceType || null,
                    nonComplianceStatement: data.nonComplianceStatement || null,
                    riskImpact: data.riskImpact || null,
                    riskRating: data.riskRating || null,
                    riskLikelihood: data.riskLikelihood || null,
                    riskType: data.riskType || null,
                    observation: data.observation || null,
                    ncStatusCreatedById: data.ncStatusCreatedById || null,
                }
            }
        },
        getTotalDocs() {
            return this.indicatorSet.responses &&
                this.indicatorSet.responses.documentsAggregate ?
                this.indicatorSet.responses.documentsAggregate.aggregate.count : 0
        },
        getTotalComments() {
            return this.indicatorSet.responses &&
                this.indicatorSet.responses.commentsAggregate ?
                this.indicatorSet.responses.commentsAggregate.aggregate.count : 0
        },
        emitPayload() {
            let compResp = this.complianceResponse || null
            let actionRequired = this.furtherActionRequired || false
            let reviewed = this.reviewed || false

            return {
                compliance: compResp,
                indicatorId: this.indicatorId,
                furtherActionRequired: actionRequired,
                reviewed: reviewed
            }
        },
        isNotFiltered() {
            let isNotFiltered = true

            if (this.filterType && this.filterType !== 'ALL') {
                if (this.filterType === 'ACTION_REQUIRED') {
                    if (!this.furtherActionRequired) {
                        isNotFiltered = false
                    }
                } else if (this.filterType === 'REVIEWED') {
                    isNotFiltered = this.reviewed
                } else if (this.filterType === 'UNREVIEWED') {
                    isNotFiltered = !this.reviewed
                } else if (this.filterType === 'INCOMPLETE') {
                    if (!this.isSelfAssess) {
                        if (this.complianceResponse === 'NOT_MET') {
                            if (!(
                                    !this.indicatorSet.responses.nonComplianceStatement ||
                                    !this.indicatorSet.responses.nonComplianceType ||
                                    !this.indicatorSet.responses.riskImpact ||
                                    !this.indicatorSet.responses.riskLikelihood ||
                                    !this.indicatorSet.responses.riskRating ||
                                    !this.indicatorSet.responses.riskType
                                )
                            ) { 
                                isNotFiltered = false
                            }
                        } else if (!(this.surveyType === 'ACCREDITATION' && !this.complianceResponse)) {
                            isNotFiltered = false
                        }
                    } else {
                        if (this.complianceResponse != null && this.complianceResponse != '') {
                            isNotFiltered = false
                        }
                    }
                } else if (this.complianceResponse !== this.filterType) {
                    isNotFiltered = false
                }
            }

            if (this.adminFilter !== null && this.adminFilter !== '') {
                if (this.adminFilter !== this.adminControls.nonConformityStatus && this.adminFilter !== 'REFER') {
                    isNotFiltered = false
                }
                if (this.adminFilter === 'REFER' && !this.adminControls.referToCa) {
                    isNotFiltered = false
                }
            }

            return isNotFiltered
        },
        getCodeColour() {
            switch (this.complianceResponse) {
                case 'MET':
                    return 'text-green'
                case 'NOT_MET':
                    return 'text-red'
                case 'NOT_APPLICABLE':
                    return 'text-orange'
                case 'COMPLETE':
                    return 'text-light-blue'
                default:
                    return 'text-grey-2'
            }
        },
        responseId() {
            return this.indicatorSet.responses.id
        },
        complianceRespParsed() {
            if (this.complianceResponse === 'COMPLETE') return null
            return this.complianceResponse
        },
        responseObj() {
            return snakecaseKeys({
                checklists: this.checkListRespObj,
                compliance: this.complianceRespParsed,
                furtherActionRequired: this.furtherActionRequired,
                reviewed: this.reviewed,
                isMarkedComplete: this.isMarkedComplete,
                nonComplianceStatement: this.riskMatrixResponsesResponse.nonComplianceStatement,
                nonConformityStatus: this.nonConformityStatus,
                observation: this.riskMatrixResponsesResponse.observation,
                nonComplianceType: this.riskMatrixResponsesResponse.nonComplianceType,
                referToCa: Boolean(this.adminControls.referToCa),
                riskImpact: this.riskMatrixResponsesResponse.riskImpact,
                riskLikelihood: this.riskMatrixResponsesResponse.riskLikelihood,
                riskRating: this.riskMatrixResponsesResponse.riskRating,
                riskType: this.riskMatrixResponsesResponse.riskType,
                dateClosed: this.responseDateClosed,
                lastUpdatedById: this.$auth.user().userId,
                ncStatusCreatedById: this.riskMatrixResponsesResponse.ncStatusCreatedById
            })
        },
        showStatusInformation() {
            return Boolean(
                this.complianceResponse !== '' &&
                this.complianceResponse !== null &&
                this.complianceResponse !== 'NOT_APPLICABLE' &&
                this.status !== 'ACTION_REQUIRED' &&
                this.isSelfAssess
            )
        },
        indicatorSuccessView() {
            if (this.isSelfAssess) {
                return Boolean(
                    this.complianceResponse === 'COMPLETE' &&
                    this.areMustHavesComplete
                )
            } else {
                return Boolean(
                    (this.complianceResponse === 'NOT_MET' ||
                    this.complianceResponse === 'MET') &&
                    this.areMustHavesComplete
                )
            }
        },
        areMustHavesComplete() {
            const mustHaveArr = this.mustHaveItems.filter(item => {
                return !this.checkedItems.includes(item)
            })
            const hasUncheckedMustHave = Boolean(mustHaveArr.length)
            return !hasUncheckedMustHave
        },
        indicatorResources() {
            if (this.indicatorSet.indicatorResourceIndicators) {
                return this.indicatorSet.indicatorResourceIndicators.map((resource) => {
                    return resource.indicatorResource
                })
            }
            return []
        },
        underReviewOrCompleted() {
            return this.status === 'UNDER_REVIEW' || this.status === 'COMPLETED'
        },
        reviewOrCompleteOrFurtherAction() {
            return this.status === 'UNDER_REVIEW' || this.status === 'COMPLETED'|| this.status === 'ACTION_REQUIRED'
        }
    },
    methods: {
        backupCheckedItems() {
            this.previousCheckedItems = [...this.checkedItems]
        },
        backupCompliance() {
            this.previousCompliance = this.complianceResponse
        },
        addCheckedItem() {
            this.indicatorSet.checklists.forEach(element => {
                element.items.forEach((item, index) => {
                    if (!this.isSelfAssess) {
                        if (element.responses !== undefined && element.responses[item.item_number]) {
                            if(!this.checkedItems.includes(item.item_text)) {
                                this.checkedItems.push(item.item_text)
                            }
                        }
                        if (element.selfResponses !== undefined && element.selfResponses[item.item_number]) {
                            let itemLabel = `self-item-${element.question_number}-${index}`
                            if(!this.checkedItems.includes(itemLabel)) {
                                this.checkedItems.push(itemLabel)
                            }
                        }
                    } else {
                        if (element.responses !== undefined && element.responses[item.item_number]) {
                            if(!this.checkedItems.includes(item.item_text)) {
                                this.checkedItems.push(item.item_text)
                            }
                        }
                    }
                })
            })

            this.backupCheckedItems()
        },
        setChecklistRespObj() {
            // This is an array of the checked checkboxes which gets added to the responseObj
            let response = {}
            this.indicatorSet.checklists.forEach((element) => {
                let currentKey = (element.question_number).toString()
                response[currentKey] = {}
                element.items.forEach((item) => {
                    // need tos tart the response from 1 instead of 0
                    response[currentKey][(item.item_number).toString()] = this.checkedItems.includes(item.item_text)
                })
            })
            // console.log('setChecklistRespObj() reponse', response)
            this.checkListRespObj = response
        },
        setComplianceResponse() {
            this.complianceResponse = this.indicatorSet.responses.compliance
            if (this.complianceResponse === null && this.indicatorSet.responses.isMarkedComplete === true) this.complianceResponse = 'COMPLETE'
            this.backupCompliance()
        },
        setActionRequired() {
            this.furtherActionRequired = this.indicatorSet.responses.furtherActionRequired
        },
        setReviewed() {
            this.reviewed = this.indicatorSet.responses.reviewed
        },
        setAdminControls() {
            this.adminControls.referToCa = this.indicatorSet.responses.referToCa || ''
            this.adminControls.nonConformityStatus = this.indicatorSet.responses.nonConformityStatus === null || this.indicatorSet.responses.nonConformityStatus === undefined ?
                'OPEN' : this.indicatorSet.responses.nonConformityStatus
            this.nonConformityStatus = this.adminControls.nonConformityStatus
        },
        indicatorViewClickHandler(e) {
            this.$logger.debug(`${format(new Date(), 'dd/MM/yyyy')} ====== Indicator view clickeed, running handler`)
            if (e && e.srcElement.tagName !== 'TEXTAREA' && ((this.isSelfAssess && !this.selfAsDisabled) || !this.surveyAsCheckboxesDisabled)) {
                setTimeout(() => {
                    this.callEditingEvent()
                })
            }
        },
        activityStatusChangeHandler(ieOpenClose) {
            if (ieOpenClose) {
                this.responseDateClosed = this.nonConformityStatus === 'CLOSED' ? format(new Date(), 'yyyy-MM-dd') : null
            }

            if (this.changeActivityStatus) {
                this.callEditingEvent()
            }
        },
        inputInteractionHandler(data) {
            this.textInput = data || {}
            this.callEditingEvent(true)
        },
        callEditingEvent(isTextAreaClick = false) {
            // console.log('CALL EditingEvent', this.textInput)
            // This flag is to block any reactive events that occur when editing commences
            this.editingIsActive = true

            let resp = {
                id: this.indicatorRespId,
                fields: null
            }

            // if textarea or select has been clicked and it is a focus event
            if (isTextAreaClick && this.textInput !== null && this.textInput.event === 'focus') {
                if (this.textInput.name !== 'select') resp['fields'] = this.textInput.name
            }

            // clear timeout on any new action
            clearTimeout(this.activityObj.timer)

            if (this.complianceChangeConfirming) return

            // if editing-stopped event has been triggered
            if (this.activityObj.flag) {
                this.$root.$emit('indicator-editing-started', resp)
                this.activityObj.flag = false
            }

            // reintialise timer if not a click in
            if (!isTextAreaClick) {
                this.setEditingEvent()
            }
            // don't reset editing event as the user is in a focus state
            if (this.textInput !== null && this.textInput.event !== 'focus') {
                this.setEditingEvent()
            }
        },
        setEditingEvent() {
            this.$logger.debug(`${format(new Date(), 'dd/MM/yyyy')} ====== setting editing event`)
            this.activityObj.timer = setTimeout(() => {
                // we are saving, so need to tell the indicator view to disable any more edits for this indicator
                this.savingIndicator = true

                // this.$emit('indicator-saving', true)

                // console.log('setTimeout called, editing-stopped for: ', this.indicatorRespId)
                this.$root.$emit('indicator-editing-stopped')
                // user stopped editing - send mutation for indicator response
                this.responseMutationCall().then(() => {
                    this.adminControls.nonConformityStatus = this.nonConformityStatus
                })
                this.activityObj.flag = true
                this.editingIsActive = false
            }, this.debounceEditTime)
        },
        mustHaveIsActive(itemMustHave, itemQuestion) {
            return Boolean(itemMustHave)
        },
        setMustHaveArr() {
            this.indicatorSet.checklists.forEach(element => {
                element.items.forEach((item) => {
                    if (item.must_have === 1) this.mustHaveItems.push(item.item_text)
                })
            })
        },
        setCheckAll() {
            this.indicatorSet.checklists.forEach(element => {
                //we check if we have a must have
                let haveRequired = false
                for (let i = 0; i < element.items.length; i++) {
                    haveRequired = element.items[i].must_have === 1
                    if (haveRequired) break
                }
                if (haveRequired) {
                    const mustHaveSet = new Set(this.mustHaveItems)
                    const checkedItemsSet = new Set(this.checkedItems)
                    //setting the checkAll value
                    element.checkAll = this.isSubSet(mustHaveSet, checkedItemsSet)
                }
            })
        },
        checkAllMustHaveItems(checked) {
            this.indicatorSet.checklists.forEach(element => {
                element.items.forEach((item) => {
                    if (!item.must_have) {
                        return
                    }

                    const pos = this.checkedItems.indexOf(item.item_text)
                    if (checked && pos < 0) {
                        this.checkedItems.push(item.item_text)
                    } else if (!checked && pos > -1) {
                        this.checkedItems.splice(pos, 1)
                    }
                })
            })
            this.checkedItemsChangeHandler()
        },
        checkedItemsChangeHandler() {
            if (!this.isSelfAssess) {
                const complianceResponseBeforeChanging = this.complianceResponse
                if (this.areMustHavesComplete) {
                    this.complianceResponse = 'MET'
                } else if (this.complianceResponse == 'MET') {
                    this.complianceResponse = 'NOT_MET'
                }
    
                if (complianceResponseBeforeChanging && complianceResponseBeforeChanging !== this.complianceResponse) {
                    this.complianceChangeConfirming = true
                    this.showComplianceChangeConfirmPopup(complianceResponseBeforeChanging)
                }
            }
        },
        showComplianceChangeConfirmPopup(complianceResponseBeforeChanging) {
            this.$root.$on('indicator-compliance-change-confirm', this.confirmComplianceChange)
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                keepOpen: true,
                previousCompliance: complianceResponseBeforeChanging
            })
            const componentName = this.complianceResponse == 'MET' ? 'ModalComplianceSetMetConfirm' : 'ModalComplianceUnsetMetConfirm'
            this.$store.commit('MODAL_COMPONENT_NAME', componentName)
        },
        confirmComplianceChange({confirm}) {
            this.$root.$off('indicator-compliance-change-confirm')
            this.complianceChangeConfirming = false
            if (confirm) this.callEditingEvent()
            else this.cancelComplianceChange()
        },
        cancelComplianceChange() {
            this.$logger.debug(`${format(new Date(), 'dd/MM/yyyy')} ====== cancelling compliance change: checkedItems`, this.checkedItems)
            this.$logger.debug(`${format(new Date(), 'dd/MM/yyyy')} ====== cancelling compliance change: previously checkedItems`, this.previousCheckedItems)
            this.checkedItems = [...this.previousCheckedItems]
            this.complianceResponse = this.previousCompliance

            this.$root.$emit('indicator-editing-stopped', true)
            this.activityObj.flag = true
            this.editingIsActive = false
        },
        responseMutationCall() {
            // console.log('responseMutationCall this.responseObj', this.responseObj)
            this.$logger.debug(`${format(new Date(), 'dd/MM/yyyy')} ====== responses before save for id: ${this.responseId}`, this.responseObj)
            return this.$apollo.mutate({
                mutation: UPDATE_INDICATOR,
                variables: {
                    responseObj: this.responseObj,
                    responseId: this.responseId
                }
            }).then(data => {
                // console.log('APOLLO mutation indicatorResponse, response:', data)
                this.$logger.debug(`${format(new Date(), 'dd/MM/yyyy')} ====== after save id: ${this.responseId}`, data)
            }).catch(error => {
                this.$root.$emit('show-indicator-message', {
                    message: 'Indicator update failed',
                    type: 'error'
                })
            }).finally(() => {
                // console.log('APOLLO mutation 2')
                this.$root.$emit('update-indicator')
                this.$root.$emit('update-assessment')
                // delaying the
                setTimeout(() => {
                    this.savingIndicator = false
                }, 500)
            })
        },
        documentClickHandler(event, resource) {
            if (!resource.file) {
                const fileLink = document.createElement('a')
                fileLink.href = resource.url || resource.file
                fileLink.target = '_blank'
                document.body.appendChild(fileLink)
                fileLink.click()
            } else {
                this.downloadHandler(resource.id, 'indicator-resource')
            }
        },
        showCheckAll(item) {
            // this.$logger.debug(`${format(new Date(), 'dd/MM/yyyy')} ====== running checkall ${this.responseId}`, {})
            return !this.isSelfAssess && item.items && item.items.length > 0 && item.items.some(checklistItem => checklistItem.must_have)
        },
        isSubSet(leftSet, rightSet) {
            if(leftSet.size > rightSet.size) return false

            for (let elem of leftSet) {
                // if any of the element of
                // this is not present in the
                // otherset then return false
                if(!rightSet.has(elem))
                    return false
            }
            return true
        },
        updateComment(comment) {
            // this.indicatorSet.responses.comments = [...comments]
            const {id, ...otherProps} = snakecaseKeys(comment, {deep: true})
            const commentObj = {
                comment: otherProps.comment
            }
            this.$apollo.mutate({
                mutation: UPDATE_COMMENT,
                variables: {
                    commentObj: commentObj,
                    commentId: id
                }
            }).then(data => {
                // console.log('APOLLO mutation indicatorResponse, response:', data)
                console.debug('comment saved', data)
            }).catch(error => {
                this.$root.$emit('show-indicator-message', {
                    message: 'Comment update failed',
                    type: 'error'
                })
            }).finally(() => {
                // // console.log('APOLLO mutation 2')
                this.$root.$emit('update-indicator')
                this.$root.$emit('update-assessment')
                // delaying the
                setTimeout(() => {
                    this.savingIndicator = false
                }, 500)
            })
        },
        async loadDocumentFolderCode() {
            if (this.isSelfAssess) {
                this.documentFolderId = this.indicatorSet.documentFolderId
                return
            }

            const surveyDocumentFolder = await this.getFolderByCode(SURVEY_DOCUMENT_FOLDER_CODE)
            if (surveyDocumentFolder) this.documentFolderId = surveyDocumentFolder.id
        }
    },
    watch: {
        adminControls: {
            handler() {
                // console.log('indicatorView adminControls: ', this.adminControls)
                this.$emit('admin-controls', {
                    adminControls: this.adminControls,
                    id: this.indicatorId,
                    compliance: this.complianceResponse,
                    furtherActionRequired: this.furtherActionRequired,
                    reviewed: this.reviewed
                })
            },
            deep: true,
        },
        complianceResponse() {
            // console.log('complianceResponse', this.complianceResponse)
            this.$emit('indicator-value', this.emitPayload)
            if (this.isSelfAssess) {
                this.isMarkedComplete = this.complianceResponse === 'COMPLETE' ? true : null
            }
        },
        furtherActionRequired() {
            // console.log('complianceResponse', this.complianceResponse)
            this.$emit('indicator-value', this.emitPayload)
        },
        indicatorSet() {
            this.$emit('indicator-value', this.emitPayload)

            // reset flag that allows update of checklistRespObj
            this.checkedItemsEmptyTriggered = true

            // Set these on data change
            this.setComplianceResponse()
            this.setAdminControls()
            this.setActionRequired()
            this.setReviewed()

            // only trigger these events when indicator set is updated from the parent
            if (!this.editingIsActive) {
                this.checkedItems = []
                this.addCheckedItem()
            }
        },
        reloadChecklists() {
            if (this.reloadChecklists) {
                this.checkedItems = []
                this.addCheckedItem()
            }
        },
        filterType() {
            this.$emit('indicator-value', this.emitPayload)
        },
        checkedItems() {
            if (!this.checkedItemsEmptyTriggered) this.setChecklistRespObj()
            this.checkedItemsEmptyTriggered = false
        },
    },
    destroyed() {
        this.$root.$off('indicator-compliance-change-confirm')
        this.$store.commit('MODAL_IS_VISIBLE', false)
    },
    setup() {
        const indicatorSetStore = useIndicatorSetStore()
        // const { computedReloadChecklists: reloadChecklists } = storeToRefs(indicatorSetStore)
        const reloadChecklists = computed(() => indicatorSetStore.computedReloadChecklists)

        return { reloadChecklists }
    }
}
</script>
