<template>
    <v-form ref="uploadForm" v-model="isValid" class="flex flex-col items-center justify-center">
        <h3>Upload documents</h3>
        <UploadInterface
            ref="uploadInterface"
            class="w-full m-4"
            :fileList="componentData.fileList"
            :indicatorRespId="componentData.indicatorRespId"
            :indicatorFileUpload="componentData.indicatorFileUpload"
            :documentLink="componentData.documentLink"
            :allowMultiple="componentData.allowMultiple"
            :documentType="componentData.documentType"
            :folderId="folderId"
            @show-message="showMessageEvent"
            @reset-message="resetMessageEvent"
            @upload-complete="uploadComplete"
        />
        
        <div class="mb-4" v-show="!hideFolder">
            <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">Folder name</label>
            <v-select solo flat dense
                label="Folder"
                :items="folders"
                item-text="name"
                item-value="id"
                v-model="folderId"
                style="width: 300px"
                :readonly="folderReadonly"
                :rules="[required]"
            ></v-select>
        </div>
        <div>
            <v-btn color="default" depressed @click.prevent="cancelHandler">Cancel</v-btn>
            <v-btn class="ml-2" color="success" depressed @click.prevent="uploadHandler">Upload</v-btn>
        </div>
    </v-form>
</template>

<script>
import UploadInterface from '@/components/partials/UploadInterface'
import {mapState} from 'vuex'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import {FormRules} from '@/components/shared/mixins/formMixins'

export default {
    name: 'ModalUpload',
    props: {
        fileList: {
            type: Array,
            default: () => []
        }
    },
    components: {
        UploadInterface
    },
    mixins: [MessageDialog, FormRules],
    data() {
        return {
            isValid: true
        }
    },
    beforeMount() {
        this.$store.dispatch('fetchFolders', {
            apolloClient: this.$apolloProvider.defaultClient,
            practiceId: this.practiceId
        })
    },
    methods: {
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        uploadHandler() {
            this.isValid = this.$refs.uploadForm.validate()
            if (!this.isValid) return

            this.$refs.uploadInterface.upload()
        },
        uploadComplete(payload) {
            this.$root.$emit('upload-complete', payload)
            this.cancelHandler()
        },
        showMessageEvent(message) {
            this.message = message.message
            this.type = message.type
            this.showMessage()
        },
        resetMessageEvent() {
            this.resetMessage()
        }
    },
    computed: {
        ...mapState({
            folders: state => state.app.folders,
            practiceId: state => state.app.practiceId,
            componentData: state => state.app.modal.componentData
        }),
        folderId() {
            return Number(this.componentData?.folderId)
        },
        hideFolder() {
            return this.componentData?.hideFolder
        },
        folderReadonly() {
            return this.folderId != null
        }
    },
    beforeDestroy() {
        this.resetMessage()
    }
}
</script>
