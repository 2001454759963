<template>
    <div class="c-summary-panel grid lg:grid-cols-5 xl:grid-cols-2 w-full" v-if="isSurveyView">
        <SurveyResults :assessmentData="assessmentData" class="lg:col-span-3 xl:col-span-1"/>
        <div class="flex flex-col lg:col-span-2 xl:col-span-1">
            <div class="flex mb-3">
                <div class="flex items-center">
                    <ProfileAvatar :user-profile="userProfile" class="w-8 h-8 mr-3" />
                    <h2 class="text-base xl:text-xl font-normal">Surveyor summary</h2>
                    <a
                        href="#"
                        class="ml-3 group uppercase text-sm text-primary-light hover:text-primary"
                        @click.prevent="editSummaryHandler"
                        v-if="editDisabled"
                    >
                        <fa-icon icon="pencil-alt" class="mr-1 group-hover:text-primary-light"/>
                        <span class="font-bold group-hover:text-primary-light">edit</span>
                    </a>
                </div>
            </div>
            <div class="flex flex-col">
                <div
                    class="readMoreClass c-summary-panel__text text-sm"
                    data-cy="surveyorSummary"
                    v-html="summary"
                ></div>

                <a
                    href="#"
                    @click.prevent="readMoreHandler"
                    class="text-sm text-primary-light hover:text-primary mt-1 c-summary-panel__read-more"
                    data-cy="readMoreSummaryButton"
                    v-if="showReadMore"
                >
                    <span class="uppercase text-xs font-bold">Read more</span>
                </a>
            </div>
        </div>
    </div>
    <InfoPanel :state="isSurveyUnderReview ? 'survey' : 'self'" v-else-if="isSelfAssessView || isSurveyUnderReview" />
</template>

<script>
import SurveyResults from './SurveyResults'
import InfoPanel from './InfoPanel'
import { mapState } from 'vuex'
import generalComments from '../mixins/generalComments'
import ProfileAvatar from '@/components/partials/ProfileAvatar'
import { ProfileMixin } from '@/components/shared/mixins/profileFns'

export default {
    name: 'SummaryPanel',
    mixins: [generalComments, ProfileMixin],
    components: {
        SurveyResults,
        InfoPanel,
        ProfileAvatar
    },
    props: {
        assessmentData: {
            type: Object,
            default: () => {}
        },
        isSelfAssess: {
            type: Boolean,
            default: null
        }
    },
    data() {
        return {
            readMore: false,
            maxSummaryLength: 500
        }
    },
    mounted() {
        // console.log('SummaryPanel assessmentData', JSON.stringify(this.assessmentData))
    },
    methods: {
        name(user) {
            return user != null ? `${user.firstName} ${user.lastName}` : ''
        },
        editSummaryHandler(e) {
            // console.log('editSummaryHandler e: ', e)
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', { editMode: true, assessmentId: this.assessmentData.id, assessmentData: this.assessmentData })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalSummary')
        },
        readMoreHandler(e) {
            // console.log('readMoreHandler e: ', e)
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', { editMode: false, assessmentId: this.assessmentData.id, assessmentData: this.assessmentData })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalSummary')
        },
    },
    computed: {
        ...mapState({
            summaryData: state => state.app.modal.componentData,
            permissions: state => state.app.permissions.assessment
        }),
        status() { return this.assessmentData.status || null },
        summary() {
            let generalComments = this.summaries

            // leaving the assignment to make it a bit more readable
            const summary = generalComments.reduce((prev, curr) => {
                return `${prev}<div class="font-bold uppercase text-xs">${this.name(curr.user)}:</div><div class="pb-6">${curr.text}</div>`
            }, '')

            return summary
        },
        generalComments() {
            return this.assessmentData.generalComments
        },
        readMoreClass() {
            if (this.readMore) return 'open'
            return ''
        },
        showReadMore() {
            return this.summary && this.summary.replace(/\n/g, '').replace(' ', '').length > this.maxSummaryLength
        },
        editDisabled() {
            return (
                this.status === 'ACTION_REQUIRED' &&
                this.permissions?.changeActionPlan
            )
        },
        isSurveyView() {
            if(!this.isSelfAssess) {
                return this.status === 'ACTION_REQUIRED' || this.status === 'APPROVED'
            }
            return false
        },
        isSelfAssessView() {
            if (this.isSelfAssess) return this.status === 'IN_PROGRESS' || this.status === 'UNDER_REVIEW'
            return false
        },
        isSurveyUnderReview() {
            if (!this.isSelfAssess) return this.status === 'UNDER_REVIEW'
            return false
        },
    },
    watch: {
        // assessmentData: {
        //     handler() {
        //         console.log('WATCH assessmentData', this.assessmentData)
        //     },
        //     deep: true
        // },
        // readMore() {
        //     console.log('readMore', this.readMore)
        // },
        // summaryData() {
        //     console.log('WATCH this.summaryData', this.summaryData)
        // }
    }
}
</script>
