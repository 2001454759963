<template>
<div class="c-modal__add-assessment relative flex flex-col items-center">
    <h2 class="mt-8" data-cy="headerTitle">New Assessment</h2>
    <el-form class="flex flex-col items-center">
        <div class="c-modal__add-assessment__row flex flex-col justify-center my-4">
            <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight ml-2">Type</label>
            <el-select v-model="surveyType">
                <el-option
                    v-for="(title, index) in assessmentTypes"
                    :key="`title-${index}`"
                    :label="title.label"
                    :value="title.value"
                    data-cy="surveyType"
                ></el-option>
            </el-select>
        </div>
        <div class="c-modal__add-assessment__row flex flex-col justify-center my-4">
            <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight ml-2">Visit date (optional)</label>
            <el-date-picker type="date" placeholder="dd/mm/yyyy" format="dd/MM/yyyy" v-model="dateData" data-cy="dateData"></el-date-picker>
        </div>
        <div class="c-modal__add-assessment__row flex flex-col justify-center my-4">
            <el-checkbox class="indicator-view__response-checkbox mr-2" :label="label" v-model="copyIndicators"></el-checkbox>
        </div>
        <div class="flex flex-row justify-center my-8">
            <el-button @click="cancelHandler" data-cy="cancelHandler">Cancel</el-button>
            <el-button type="primary" @click="submitHandler">Submit</el-button>
        </div>
    </el-form>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ModalAddAssessment',
    data() {
        return {
            surveyType: 'ACCREDITATION',
            dateData: '',
            label: 'Copy indicator tickboxes from last assessment',
            copyIndicators: true,
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        assessmentTypes() {
            return this.staticChoices ?
                [...this.staticChoices['new_assessment_types'],] : []
        }
    },
    methods: {
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        submitHandler() {
            // commit mutation
        },
    }
}
</script>
