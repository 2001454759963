<template>
    <div class="flex flex-col h-full p-4 summary-modal" v-if="!loading">
        <h2 class="text-2xl text-center text-black">{{ heading }}</h2>
        <p class="text-red text-sm" v-if="warning">{{ warning }}</p>
        <div class="modal-body">
            <div v-for="(summary, index) in summaries"
                :key="`summary-${index}`"
            >
                <div class="clearfix mt-4 mb-2">
                    <ProfileAvatar :user-profile="summary.user" class="mr-3 w-8 h-8 inline-block relative float-left" />
                    <div class="inline-block relative float-left pt-1">
                        <p class="text-sm">{{ name(summary.user) }}</p>
                    </div>
                </div>
                <div
                    v-if="!editMode || (editMode && !ownedByCurrentUser(summary))"
                    v-html="summary.text"
                ></div>
                <vue-editor
                    :ref="`summaryText-${index}`"
                    :name="`summaryText-${index}`"
                    :id="`summaryText-${index}`"
                    v-model="summary.text"
                    :rows="12"
                    v-else
                    class="w-full"
                />
            </div>
        </div>
        <div class="flex flex-row justify-center my-4" v-if="editMode">
            <el-button @click="cancelHandler">Cancel</el-button>
            <el-button type="success" @click="submitHandler">Save</el-button>
        </div>
        <div class="flex flex-row justify-center my-4" v-else>
            <el-button type="primary" @click="cancelHandler">Close</el-button>
        </div>
    </div>
    <div class="flex items-center justify-center h-20 w-full" v-else>
        <Loader class="w-12 h-12" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import UpdateAssessment from '@/components/accreditation/mixins/updateAssessment'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import SUB_GENERAL_COMMENTS from '@/graphql/subscriptions/subGeneralComments.gql'
import camelcaseKeys from 'camelcase-keys'
import GeneralComments from '@/components/accreditation/mixins/generalComments'
import Loader from '@/assets/loader.svg'
import { VueEditor } from 'vue2-editor'
import ProfileAvatar from '@/components/partials/ProfileAvatar'

export default {
    name: 'ModalSummary',
    components: { Loader, VueEditor, ProfileAvatar },
    mixins: [UpdateAssessment, HttpMixin, GeneralComments],
    apollo: {
        $subscribe: {
            generalComments: {
                query: SUB_GENERAL_COMMENTS,
                variables() {
                    return {
                        assessmentId: Number(this.assessmentId)
                    }
                },
                skip() {
                    return !this.assessmentId
                },
                result({ data: { generalComments } }) {
                    this.generalComments = camelcaseKeys(generalComments || [], {deep: true})
                    this.loading = false
                }
            },
        }
    },
    data() {
        return {
            loading: true,
            generalComments: []
        }
    },
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData,
            isVisible: state => state.app.modal.isVisible,
        }),
        heading() {
            return !this.editMode ? 'Surveyor Summary' : 'Edit Summary'
        },
        editMode() {
            return this.data && this.data.editMode ? this.data.editMode : false
        },
        assessmentId() {
            return this.data && this.data.assessmentId ? this.data.assessmentId : null
        },
        assessmentData() {
            return this.data && this.data.assessmentData ? this.data.assessmentData : null
        },
        warning() {
            return this.data && this.data.warning ? this.data.warning : null
        },
    },
    methods: {
        getMinRows(summary) {
            return this.ownedByCurrentUser(summary) ? 15 : 6
        },
        ownedByCurrentUser(summary) {
            return summary.user.id === this.$auth.user().userId || this.$auth.user().admin
        },
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        initials(user) {
            // console.log('ProfileDisplay this.$auth', this.$auth)
            if (user != null) {
                let names = user.name ? user.name.split(' ') : user.firstName  ? [user.firstName, user.lastName] : ''
                let initials = ''
                if (names.length) {
                    for (let name of names) {
                        initials += name.slice(0, 1)
                    }
                    return initials
                }
                return '-'
            }
            return '-'
        },
        name(user) {
            return user != null ? `${user.firstName} ${user.lastName}` : ''
        },
        submitHandler() {
            // commit mutation
            // should only be updating the summary owned by the user
            // however, as an admin, we need to get each one and save it

            if (this.$auth.user().admin) {
                this.submitAsAdmin()
                return
            }
            const idx = this.summaries.findIndex(s => s.user.id === this.$auth.user().userId)
            const summary = {...this.summaries.find(s => s.user.id === this.$auth.user().userId) || {}}
            if (summary.newComment) {
                delete summary.id
                delete summary.newComment
            }
            const httpOptions = this.getPostOrPatchOptions({
                ...summary,
                archived: false,
                assessment: this.assessmentId,
                user: summary.user.id
            }, 'web.generalcomment')

            this.$http(httpOptions)
                .then(({ data }) => {
                    this.summaries[idx] = {...data, user: summary.user}
                    // this.$apollo.provider.defaultClient.clearStore()

                    this.$store.commit('MODAL_IS_VISIBLE', false)

                    // this.$store.commit('MODAL_DATA', {})
                    this.$root.$emit('general-comments-update')
                })
                .catch((err) => {
                    console.log('error saving summary', err)
                })
        },
        submitAsAdmin() {
            const promises = []
            for (let summary of this.summaries) {
                if (summary.newComment) {
                    delete summary.id
                    delete summary.newComment
                }
                const httpOptions = this.getPostOrPatchOptions({
                    ...summary,
                    archived: false,
                    assessment: this.assessmentId,
                    user: summary.user.id
                }, 'web.generalcomment')
                promises.push(this.$http(httpOptions))
            }

            Promise.all(promises)
                .then((response) => {
                    this.$store.commit('MODAL_IS_VISIBLE', false)

                    // this.$store.commit('MODAL_DATA', {})
                    this.$root.$emit('general-comments-update')
                })
                .catch((err) => {
                    console.log('error saving summary', err)
                })
        }
    },
    mounted() {
        console.log('summaries when we mount the component', this.summaries)
    }
}
</script>
