import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api'
import axios from 'axios'

export const useIssueCertificateStore = defineStore('issueCertificate', () => {
    const _certifyForm = ref({
        certifyDate: null,
        issueDate: null,
        commencementDate: null,
        expiryDate: null,
        certifier: null,
    })
    const _certificateId = ref(null)
    const _assessmentId = ref('')
    const _userId = ref('')
    
    const computedCertifyForm = computed({
        get: () => _certifyForm.value,
        set: (newForm) => _certifyForm.value = { ..._certifyForm.value, ...(newForm || {})}
    })
    const computedAssessmentId = computed(() => _assessmentId.value)
    const computedUserId = computed(() => _userId.value)
    
    const updateCertifyForm = (val) => {
        _certifyForm.value = { ..._certifyForm.value, ...val }
    }
    
    const updateAssessmentId = (val) => {
        _assessmentId.value = val
    }
    const updateUserId = (val) => {
        _userId.value = val
    }
    
    const getPotentialCommencement = async (id) => {
        if (!_certificateId.value || _certificateId.value !== id) {
            _certificateId.value = id
        } else {
            // if we have the same value
            return computedCertifyForm.value?.commencementDate
        }
        return new Promise((resolve, reject) => {
            axios.get(`web.assessment/${id}/potential_commencement_date/`)
                .then(({ data }) => {
                    updateCertifyForm({ commencementDate: new Date(data.date)})
                    resolve(data.date)
                })
                .catch((err) => {
                    console.error(err)
                    reject(err)
                })
        })
    }
    
    return { computedCertifyForm, computedUserId, computedAssessmentId, updateCertifyForm, updateAssessmentId, updateUserId, getPotentialCommencement }
})
