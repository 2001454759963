<template>
    <div class="flex flex-col h-full p-4 summary-modal">
        <h2 class="text-2xl text-center text-black mb-4">This action will mark indicator as Not Met!</h2>
        <div class="flex flex-row justify-center my-4">
            <div class="text-center">
                <p>
                    This indicator was already marked as 'Met'. If you uncheck a required checkbox, it will mark this indicator as 'Not Met'.
                </p>
                <p>
                    Are you sure you would like to uncheck the required checkbox?
                </p>
            </div>
        </div>

        <div class="flex flex-row justify-center my-4">
            <v-btn
                type="button"
                color="grey"
                outlined
                depressed
                @click="cancelHandler"
            >Cancel</v-btn>
            <v-btn
                type="submit"
                color="success"
                class="ml-2"
                depressed
                @click="submitHandler"
            >Yes, check &amp; mark this indicator as Not Met</v-btn>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex'
import {FormRules, FormEvents} from '@/components/shared/mixins/formMixins'
import {format, formatISO, isValid, parse, parseISO} from 'date-fns'

export default {
    name: 'ModalComplianceUnsetMetConfirm',
    mixins: [FormRules, FormEvents],
    mounted(){
        console.log('We want to set it as Un-met now')
    },
    methods: {
        cancelHandler() {
            this.$root.$emit('indicator-compliance-change-confirm', {confirm: false})
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        submitHandler() {
            this.$root.$emit('indicator-compliance-change-confirm', {confirm: true})
            this.$store.commit('MODAL_IS_VISIBLE', false)
        }
    },
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData,
            isVisible: state => state.app.modal.isVisible
        }),
    },
}
</script>
