import axios from 'axios'

export class AssessmentService {
    async setReviewed(assessmentId) {
        const url = `web.assessment/${assessmentId}/nc_reviewed_email/`
        try {
            const resp = await axios.post(url)
            const { status, data } = resp
            return { status, data }
        } catch (e) {
            const { response } = e
            return {
                status: response?.status,
                data: response?.data
            }
        }
    }
}

export const assessmentService = new AssessmentService()
