<template>
    <div class="w-full">
        <div class="absolute z-10">
            <ProfileAvatar
                class="ml-3 mt-2 w-10 h-10"
                :user-profile="userProfile"
            />
        </div>
        <el-input
            v-model="commentInput"
            :placeholder="placeholderText"
            type="textarea"
            ref="commentTextArea"
            class="c-comment-module__textarea w-full z-0"
            :autosize="{ minRows: 3, maxRows: 6}"
            rows="8"
            :disabled="saving"
            data-cy="indicatorComment"
        >
        </el-input>
    </div>
</template>

<script>
import ProfileAvatar from '@/components/partials/ProfileAvatar'
import { AuthMixin } from '@/components/shared/mixins/authHelpers'

export default {
    name: "CommentComponent",
    components: { ProfileAvatar },
    mixins: [ AuthMixin ],
    props: {
        value: {
            type: String,
            default: ''
        },
        saving: {
            type: Boolean,
            default: false
        },
        userProfile: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        commentInput: {
            get() {
                return this.value || ''
            },
            set(comment) {
                this.$emit('input', comment)
            }
        },
        placeholderText() {
            return this.isSurveyor ? 'Enter any observations or comments relating to the indicator. Click "Comment" to save the comment.' : 'Enter comment'
        }
    },
}
</script>
