<template>
    <div class="add-staff-link m-0 absolute pl-2">
        <el-link
            type="primary"
            :underline="false"
            @click.prevent="addUserClickHandler"
            class="uppercase font-bold text-sm"
            data-cy="addStaff"
        >
            <fa-icon icon="plus" class="mr-1" />Add staff
        </el-link>
    </div>
</template>

<script>
export default {
    name: 'addUserRow',
    methods: {
        addUserClickHandler() {
            this.$emit('add-user')
        }
    },
}
</script>
