<template>
    <v-btn-toggle v-model="complianceResponse" :disabled="surveyInputsDisabled || savingIndicator">
        <v-btn :ripple="false" :value="complianceTypes.Complete">Mark complete</v-btn>
        <v-btn :ripple="false" :value="complianceTypes.NotApplicable">Not applicable</v-btn>
    </v-btn-toggle>
</template>

<script>
import { COMPLIANCE_TYPES } from '@/config/constants'

export default {
    name: 'IndicatorButtonGroup',
    props: {
        value: {
            type: String,
            default: null
        },
        surveyInputsDisabled: {
            type: Boolean,
            default: null
        },
        savingIndicator: {
            type: Boolean,
            default: null
        }
    },
    data() {
        return {
            complianceResponse: '',
            complianceTypes: COMPLIANCE_TYPES
        }
    },
    mounted() {
        this.complianceResponse = this.value
    },
    watch: {
        complianceResponse() {
            const complianceResponse = this.complianceResponse || ''
            this.$emit('input', complianceResponse)
        }
    }
}
</script>
