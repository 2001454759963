export default {
    methods: {
        getHumanReadable(val) {
            if (!val && (typeof val !== 'string' || typeof val !== 'number')) return ''
            let fileSize = parseInt(val, 10)
            if (typeof fileSize !== 'number' || isNaN(fileSize)) {
                throw new TypeError('Expected a number')
            }
    
            let exponent,
                unit,
                neg = fileSize < 0,
                units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    
            if (neg) {
                fileSize = -fileSize
            }
    
            if (fileSize < 1) {
                return (neg ? '-' : '') + fileSize + ' B'
            }
    
            exponent = Math.min(Math.floor(Math.log(fileSize) / Math.log(1000)), units.length - 1)
            fileSize = (fileSize / Math.pow(1000, exponent)).toFixed(2) * 1
            unit = units[exponent]
    
            return (neg ? '-' : '') + fileSize + ' ' + unit
        }
    }
}
