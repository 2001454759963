import VueLogger, { StringifyAndParseObjectsHook } from 'vue-logger-plugin'
import axios from 'axios'

const CloudWatchLogHook = {
    run(event) {
        // post to
        // post to BE to push to cloudwatch
        axios.post('/utils/log/', {severity: event.level, data: event.argumentArray})
    }
}

const options = {
    enabled: true,
    level: 'debug',
    beforeHooks: [
        StringifyAndParseObjectsHook
    ],
    afterHooks: [
        CloudWatchLogHook
    ]
}

export default new VueLogger(options)
