<template>
    <div>
        <v-text-field
            v-bind="$attrs"
            :value="formattedDatetime"
            readonly
            clearable
            @click="textFieldClickHandler"
            @click:clear="clearDatetime"
        />
        <VDatetime
            ref="datetimepicker"
            :type="type"
            :format="format"
            v-model="datetime"
            style="height:0;width:0;overflow:hidden"
        ></VDatetime>
    </div>
</template>

<script>
import { Datetime as VDatetime} from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import {format, formatISO, isValid, parse, parseISO} from 'date-fns'

export default {
    name: 'EditableDatetimePicker',
    inheritAttrs: false,
    props: {
        type: {
            type: String,
            default: 'datetime'
        },
        format: {
            type: String,
            default: 'yyyy-MM-dd HH:mm'
        },
        value: {
            type: String,
            default: null
        }
    },
    components: {VDatetime},
    data() {
        return {
            datetime: null
        }
    },
    created() {
        this.loadDatetime()
    },
    methods: {
        textFieldClickHandler(evt) {
            this.$refs.datetimepicker.open(evt)
        },
        loadDatetime() {
            if (!this.value) {
                this.datetime = null
                return
            }

            const obj = parse(this.value, this.format, new Date())

            if (isValid(obj)) this.datetime = formatISO(obj)
            else this.datetime = null
        },
        clearDatetime() {
            this.datetime = null
        }
    },
    computed: {
        formattedDatetime() {
            if (!this.datetime) return null

            const obj = parseISO(this.datetime)
            if (!isValid(obj)) return ''

            return format(obj, this.format, new Date())
        }
    },
    watch: {
        value(val) {
            this.loadDatetime()
        },
        datetime(val) {
            this.$emit('input', this.formattedDatetime)
        }
    }
}
</script>
