export default {
    states: (state) => state?.staticChoices['generic_address.state'],
    mmmArr: (state) => state?.staticChoices['practice.mmm'],
    phnArr: (state) => state?.staticChoices['practice.phn'],
    expirySort: (state) => state?.staticChoices['svplanner.practice_sort.expiry'],
    // costRatingSort: (state) => state?.staticChoices['svplanner.surveyor_sort.cost_rating'],
    costRatingSort: () => [{value: 'rate_classification', text: 'Cost Rating, Asc'}, {value: '-rate_classification', text: 'Cost Rating, Desc'}],
    staffTitles: (state) => state?.staticChoices['practice_staff.title'] || [],
    costRatings: (state) => state?.staticChoices['surveyor.rate_classification'] || [],
    surveyVisitReasons: (state) => state?.staticChoices['survey_visit.reason'] || [],
    surveyVisitConfirmationFormTemplate: (state) => state?.staticChoices['survey_visit.confirmation_form_template'] || [],
    modalIsVisible: (state) => state?.modal?.isVisible,
    componentName: (state) => state?.modal?.componentName,
    componentData: (state) => state?.modal?.componentData,
}
