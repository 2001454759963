<template>
    <div class="assessment" v-if="indicatorSets && indicatorResponses && assessmentData">
        <div class="flex flex-row w-full justify-between my-4">
            <div class="flex flex-row justify-start items-start lg:items-center" data-cy="assessmentTitleWrapper">
                <router-link :to="{name: 'Accreditation'}">
                    <fa-icon icon="chevron-left" class="text-black mr-2 hover:text-primary-light" />
                </router-link>
                <StatusIcon class="w-12 hideSmallTablet" :rowData="assessmentData" svgColour="text-grey-6" />
                <div
                    class="flex flex-col lg:flex-row"
                    data-cy="assessmentTitle"
                >
                    <p
                        class="font-semibold text-lg lg:text-xl ml-0 lg:ml-2 text-black flex flex-row justify-center mr-1 lg:mr-2 font-display"
                    >
                        {{ getType(assessmentData) }}
                    </p>
                    <p class="text-grey-2 text-base lg:text-xl ml-0 lg:mr-2">{{ getDate(assessmentData) }}</p>
                </div>
                <StatusBadge :statusType="getBadge()" class="mr-2" data-cy="assessmentStatus" />
            </div>
            <ActionsPanel
                class="items-start lg:items-center"
                :assessmentData="assessmentData"
                :isSelfAssess="isSelfAssess"
                :validationObj="invalidResponsesObj"
                @complete-survey="markUnmarkedAsComplete"
                @clear-survey="clearSurvey"
            />
        </div>
        <div class="flex flex-row w-full justify-between my-6">
            <SummaryPanel :assessmentData="assessmentData" :isSelfAssess="isSelfAssess"/>
        </div>

        <div class="flex justify-between items-center border-b-2 mb-2">
            <div class="flex flex-row justify-between">
                <div class="flex flex-row">
                    <el-tabs class="assessment-tabs" v-model="activeFilter" :stretch="true" v-if="!isSelfAssess">
                        <!-- surveyor filters -->
                        <el-tab-pane name="ALL">
                            <div slot="label" class="assessment-tabs__tab-header font-display font-semibold text-sm xl:text-base px-2">
                                All
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="MET">
                            <div slot="label" class="assessment-tabs__tab-header font-display font-semibold text-sm xl:text-base px-2">
                                Met
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="NOT_MET">
                            <div slot="label" class="assessment-tabs__tab-header font-display font-semibold text-sm xl:text-base px-2">
                                Not Met
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="NOT_APPLICABLE" v-if="naFilterIsVisible">
                            <div slot="label" class="assessment-tabs__tab-header font-display font-semibold text-sm xl:text-base px-2">
                                N/A
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="INCOMPLETE">
                            <div slot="label" class="assessment-tabs__tab-header font-display font-semibold text-sm xl:text-base px-2">
                                Incomplete
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                    <!-- self-assessment filters -->
                    <el-tabs class="assessment-tabs" v-model="activeFilter" :stretch="true" v-else>
                        <el-tab-pane name="ALL">
                            <div slot="label" class="assessment-tabs__tab-header font-display font-semibold text-sm xl:text-base xl:text-basepx-2">
                                All
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="COMPLETE">
                            <div slot="label" class="assessment-tabs__tab-header font-display font-semibold text-sm xl:text-base xl:text-base px-2">
                                Marked Complete
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="NOT_APPLICABLE" v-if="naFilterIsVisible">
                            <div slot="label" class="assessment-tabs__tab-header font-display font-semibold text-sm xl:text-base px-2">
                                N/A
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="INCOMPLETE">
                            <div slot="label" class="assessment-tabs__tab-header font-display font-semibold text-sm xl:text-base px-2">
                                Incomplete
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="ACTION_REQUIRED" v-if="furtherActionFilterIsVisible">
                            <div slot="label" class="assessment-tabs__tab-header font-display font-semibold text-sm xl:text-base px-2">
                                Further action required
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="REVIEWED" v-if="reviewFilterIsVisible">
                            <div slot="label" class="assessment-tabs__tab-header font-display font-semibold text-sm xl:text-base px-2">
                                Reviewed
                            </div>
                        </el-tab-pane>
                        <el-tab-pane name="UNREVIEWED" v-if="reviewFilterIsVisible">
                            <div slot="label" class="assessment-tabs__tab-header font-display font-semibold text-sm xl:text-base px-2">
                                Unreviewed
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            <div class="flex flex-row items-center justify-between">
                <fa-icon icon="filter" class="text-black" v-if="activeFilter === 'NOT_MET' && status === 'ACTION_REQUIRED'"/>
                <el-select
                    v-model="adminFilter"
                    v-if="activeFilter === 'NOT_MET' && status === 'ACTION_REQUIRED'"
                    class="filter-dropdown"
                >
                    <el-option label="All" value=""></el-option>
                    <el-option label="Open" value="OPEN"></el-option>
                    <el-option label="Closed" value="CLOSED"></el-option>
                    <el-option label="Refer to CA" value="REFER"></el-option>
                </el-select>
                <el-button
                    class="ml-2 py-2 my-1 uppercase text-xs font-bold"
                    @click="openAll"
                    type="secondary"
                >
                    Open all
                </el-button>
                <el-button
                    class="ml-2 py-2 my-1 uppercase text-xs font-bold"
                    @click="closeAll"
                    type="secondary"
                >
                    Close all
                </el-button>
            </div>
        </div>
        <el-collapse
            v-model="setCollapse"
            @change="indicatorSetUpdateId"
            v-if="activeFilter === 'ALL' || activeFilter !== 'ALL' && currentFilter.length > 0"
        >
            <!-- {{ setCollapse }} -->
            <IndicatorSet
                v-for="(indicatorSet, index) in indicatorSets"
                :key="`IndicatorSet-${index}`"
                :indicatorSet="indicatorSet"
                :assessmentId="assessmentId"
                :surveyType="surveyType"
                :indicatorsData="indicatorsData"
                :indicatorId="indicatorContainsId(indicatorId, indicatorSet)"
                :filterSet="currentFilter"
                :filterType="activeFilter"
                :adminFilter="adminFilterState"
                :status="status"
                :isSelfAssess="isSelfAssess"
                :indicatorLockArr="indicatorLockArr"
                :submissions="submissions"
                @expand-parent="expandCollapse"
            />
        </el-collapse>
        <div class="text-center text-grey-info py-4" v-else>
            Sorry, no indicators found.
        </div>
    </div>
    <div class="flex items-center justify-center h-20" v-else>
        <Loader class="w-12 h-12" />
    </div>
</template>

<script>
import GET_INDICATORS_SET from '@/graphql/queries/getIndicatorSet.gql'
import GET_INDICATOR_RESP from '@/graphql/queries/getIndicatorsResponse.gql'
import GET_ASSESSMENT from '@/graphql/queries/getAssessment.gql'
import SUB_INDICATOR_LOCK from '@/graphql/subscriptions/subIndicatorLock.gql'
import IndicatorSet from './IndicatorSet'
import StatusIcon from './StatusIcon'
import Loader from '@/assets/loader.svg'
import AsDetails from '../mixins/asDetails'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import ActionsPanel from './ActionsPanel'
import SummaryPanel from './SummaryPanel'
import StatusBadge from '@/components/partials/StatusBadge'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { mapState } from 'vuex'
import { AuthMixin } from '@/components/shared/mixins/authHelpers'
import AgreementMethods from '@/components/shared/mixins/agreementMethods'
import UPSERT_INDICATOR_RESPONSES from '@/graphql/mutations/upsertIndicatorResponses.gql'
import RoleMixin from '@/components/shared/mixins/roleHelpers'
import { COMPLIANCE_TYPES, SURVEY_TYPES } from '@/config/constants'
import { getType } from '@/utils/surveyVisit.helpers'
import { useIndicatorSetStore } from '@/stores/useIndicatorSetStore'
import { mapActions } from 'pinia'
import { useAssessmentStore } from '@/stores/useAssessmentStore'

export default {
    name: 'AssessmentComponent',
    components: {
        IndicatorSet,
        Loader,
        StatusIcon,
        ActionsPanel,
        SummaryPanel,
        StatusBadge,
    },
    mixins: [ AuthMixin, AsDetails, MessageDialog, AgreementMethods, RoleMixin ],
    data() {
        return {
            setCollapse: [],
            setCollapseId: null,
            setCollapseChildId: null,
            activeFilter: 'ALL',
            surveyVersionId: null,
            indicatorsFilterObj: {},
            status: '',
            adminFilter: '',
            indicatorLockArr: [],
            skipNavigate: true,
            doNotRefetch: false,
            reloadChecklists: false
        }
    },
    props: {
        indicatorSetId: {
            type: String,
            default: null
        },
        indicatorId: {
            type: String,
            default: null
        },
        assessmentId: {
            type: String,
            default: null
        },
    },
    mounted() {
        // clear locks, TODO: make this react to destroy event when navigating to another component
        this.deleteIndicatorLock()

        this.setCollapseId = this.indicatorSetId ? Number(this.indicatorSetId) : null
        this.setCollapseChildId = this.indicatorId ? Number(this.indicatorId) : null

        // Event listeners
        this.$root.$on('update-indicator', () => {
            // console.log('$root.$on update-indicator')
            this.getIndicatorResponses()
        })
        this.$root.$on('update-assessment', () => {
            // console.log('$root.$on update-assessment')
            this.getAssessmentData()
        })
        this.$root.$on('response-updated', () => { this.getIndicatorResponses() })
        this.$root.$on('document-list-update', () => { this.getIndicatorResponses() })
        this.$root.$on('show-indicator-message', data => {
            // console.log('error on ASSESSMENT')
            this.message = data.message
            this.type = data.type
            this.showMessage({duration: 5000})
        })
        this.$root.$on('indicator-editing-started', respObj => { this.updateIndicatorLock(respObj) })
        this.$root.$on('indicator-editing-stopped', (flag) => { this.deleteIndicatorLock(flag) })
        this.$root.$on('general-comments-update', () => {
            this.getIndicatorResponses()
            this.getAssessmentData()
        })

        // actions panel listeners
        this.$root.$on('refetch-assessment', () => { this.getAssessmentData() })

        this.$root.$on('agreement-action', this.updateAssessmentSignsObj)
        this.$root.$on('agreement-cancel', this.doRedirectToAccreditationsList)
        
        this.$root.$on('delete-document', this.deleteIndicatorFileHandler)
    },
    apollo: {
        indicatorSets: {
            query: GET_INDICATORS_SET,
            update: data => {
                // console.log('indicatorSets', data)
                return data.indicatorSets
            },
            variables() {
                return {
                    surveyVersionId: this.surveyVersionId
                }
            },
            skip() {
                return !this.surveyVersionId
            }
        },
        indicatorResponses: {
            query: GET_INDICATOR_RESP,
            update: data => {
                // console.log('DATA checks:', data)
                if (data &&
                    Object.keys(data).length !== 0 &&
                    data.assessment[0] &&
                    data.assessment[0].responses !== undefined
                ){
                        const parsedData = camelcaseKeys(data.assessment[0], { deep: true })
                        // console.log('indicatorResponses', parsedData)
                        return parsedData
                }
                return []
            },
            variables() {
                return {
                    assessmentId: Number(this.assessmentId)
                }
            },
            skip() {
                return !this.assessmentId
            }
        },
        assessmentData: {
            query: GET_ASSESSMENT,
            variables() {
                return {
                    assessmentId: Number(this.assessmentId)
                }
            },
            skip() {
                return !this.assessmentId
            },
            update: data => {
                // console.log('assessmentData', data)
                let parsedData = {}
                if (data.assessment) {
                    parsedData = camelcaseKeys(data.assessment[0] || {}, { deep: true })
                }
                return parsedData
            }
        },
        $subscribe: {
            indicatorLockArr: {
                query: SUB_INDICATOR_LOCK,
                variables() {
                    return {
                        assessmentId: Number(this.assessmentId)
                    }
                },
                result(data) {
                    let arr = camelcaseKeys(data, { deep: true })
                    this.indicatorLockArr = arr.data.indicatorLock
                }
            }
        }
    },
    methods: {
        ...mapActions(useIndicatorSetStore, ['updateReloadChecklists']),
        ...mapActions(useAssessmentStore, ['updateAssessment']),
        getType: getType,
        showAgreementModal(assessment) {
            if (
                this.requiresAgreement(assessment) && !this.userHasSignedAgreement(assessment)
            ) this.renderAgreementModal(assessment, true, true)
        },
        doRedirect(assessmentData) {
            // if assessmentData is null, we haven't loaded the object yet
            if (assessmentData == null) return false
            // if we don't have the status (i.e. we don't have any keys) or we do have the status and we don't have permission, do redirect
            const keys = Object.keys(assessmentData)
            if (!keys.length) {
                return true
            }

            return this.isAssessmentLocked(assessmentData)
        },
        isAssessmentLocked(assessmentData) {
            const status = assessmentData && assessmentData.status ? assessmentData.status : null

            if (assessmentData.type !== 'PRESURVEY' ) {
                if (status === 'UNDER_REVIEW') {
                    if (this.permissions && !this.permissions.viewSurveyUnderReview) {
                        return !this.permissions.viewSurveyUnderReview
                    }
                    return false
                }
                if (status === 'IN_PROGRESS' || status === 'PARTIALLY_SUBMITTED') {
                    if (this.permissions && !this.permissions.changeSurveyInProgress) {
                        return !this.permissions.changeSurveyInProgress
                    }
                    return false
                }
            } else {
                switch(status) {
                    case 'UNDER_REVIEW':
                        if (this.permissions && !this.permissions.changeSelfAssessmentUnderReview) {
                            return !this.permissions.changeSelfAssessmentUnderReview
                        }

                }
            }

            return false
        },
        getBadge() {
            const status = this.getStatusType(this.assessmentData)
            const action = this.getActionPlanType(this.assessmentData)

            if (status === 'complete' && action === 'action required') return action
            if (status === 'complete' && action === 'approved') return action
            return status
        },
        async getIndicatorResponses() {
            // console.log('getIndicatorResponses')
            await this.$apollo.queries.indicatorResponses.refetch()
        },
        async getAssessmentData() {
            // console.log('getAssessmentData')
            await this.$apollo.queries.assessmentData.refetch()
        },
        updateIndicatorLock(respObj) {
            let resp = {
                assessment: Number(this.assessmentId),
                indicatorResponse: respObj.id
            }
            // if a textarea was clicked on, pass that field for the indicator
            if (respObj.fields !== null) resp['fields'] = respObj.fields
            const payload = snakecaseKeys(resp)

            this.$http({
                method: 'POST',
                url: `web.indicatorlock/`,
                data: payload
            }).then(() => {
                console.log(`updated indicatorRespId: ${respObj.id}`)
            }).catch(err => {
                console.error('Error updating Indicator lock')
                console.error(err)
            })
        },
        deleteIndicatorLock(flag = false) {
            // API designed to delete only the user that is sending the request
            // Could be a problem if user is using app on multiple tabs
            this.doNotRefetch = flag

            this.$http({
                method: 'POST',
                url:`web.assessment/${this.assessmentId}/delete_for_user/`
            }).then(() => {
                console.log('lock deleted for assessment')
            }).catch(err => {
                console.error('error in Asessment.vue deleteIndicatorLock(): ', err)
            })
        },
        expandCollapse(id, childId) {
            // setCollapseId is the parent collapse item of theIndicatorSet
            this.setCollapseId = id
            this.setCollapseChildId = childId
        },
        indicatorContainsId(id, set) {
            let result = null
            let isInSet = set.indicatorSetIndicator.find((item) => {
                return item.indicatorId === Number(id)
            })

            if(isInSet) return id
            return null
        },
        setRoute() {
            // console.log('setRoute this.setCollapseId', this.setCollapseId)
            let params = {}
            let setCollapseRoute = this.setCollapseId !== null ? this.setCollapseId.toString() : ''
            let setCollapseChildRoute = ''
            if (setCollapseRoute) setCollapseChildRoute = this.setCollapseChildId !== null && this.setCollapseChildId !== undefined ? this.setCollapseChildId.toString() : ''

            if (setCollapseRoute) params['indicatorSetId'] = setCollapseRoute
            if (setCollapseRoute && setCollapseChildRoute) params['indicatorId'] = setCollapseChildRoute

            this.$router.push({
                name: 'Assessment',
                params
            }).catch(err => err)
        },
        indicatorSetUpdateId(activeNames) {
            // console.log('indicatorSetUpdateId', activeNames)
            if (activeNames.length) {
                this.setCollapseId = activeNames[activeNames.length - 1]
            } else if (!activeNames.includes(this.setCollapseId)){
                this.setCollapseId = null
            }
        },
        getFilteredIndicatorSets(filterArr, sets) {
            return sets.filter(setItem => {
                return filterArr.find(filterItem => {
                    return setItem.id === filterItem
                })
            })
        },
        setIndicatorsFilterObj() {
            let met = []
            let notMet = []
            let notApplicable = []
            let complete = []
            let incomplete = []
            let furtherActionRequired = []
            let reviewedResponses = []
            let unreviewedResponses = []

            if(!this.isSelfAssess) {
                this.indicatorsData.forEach(indicatorItem => {
                    const indId = indicatorItem.id
                    indicatorItem.indicatorSetIndicator.find(setItem => {
                        const response = setItem?.indicator?.responses
                        const setId = response.indicatorId
                        const compliance = response.compliance

                        if (compliance === 'MET') {
                            if (!met.includes(indId)) met.push(indId)
                        } else if (compliance === 'NOT_MET') {
                            if (!notMet.includes(indId)) notMet.push(indId)

                            if (
                                !response.nonComplianceStatement ||
                                !response.nonComplianceType ||
                                !response.riskImpact ||
                                !response.riskLikelihood ||
                                !response.riskRating ||
                                !response.riskType
                            ) {
                                if (!incomplete.includes(indId)) incomplete.push(indId)
                            }
                        } else if (compliance === 'NOT_APPLICABLE') {
                            if (!notApplicable.includes(indId)) notApplicable.push(indId)
                        } else if (!compliance && this.surveyType === 'ACCREDITATION') {
                            if (!incomplete.includes(indId)) incomplete.push(indId)
                        }
                    })
                })
            } else {
                this.indicatorsData.forEach(indicatorItem => {
                    const indId = indicatorItem.id
                    indicatorItem.indicatorSetIndicator.find(setItem => {
                        const isMarkedComplete = setItem.indicator.responses.isMarkedComplete
                        const actionRequired = setItem.indicator.responses.furtherActionRequired
                        const reviewed = setItem.indicator.responses.reviewed
                        const resp = setItem.indicator.responses.compliance

                        if (isMarkedComplete) {
                            if (!complete.includes(indId)) complete.push(indId)
                        } else if (resp === 'NOT_APPLICABLE') {
                            if (!notApplicable.includes(indId)) notApplicable.push(indId)
                        } else if (!isMarkedComplete && !resp) {
                            if (!incomplete.includes(indId)) incomplete.push(indId)
                        }

                        if (actionRequired) {
                            if (!furtherActionRequired.includes(indId)) furtherActionRequired.push(indId)
                            // if it's action_required and not completed, we should put it on the incomplete list
                            if (!isMarkedComplete && !incomplete.includes(indId)) incomplete.push(indId)
                        }
                        if (reviewed) {
                            if (!reviewedResponses.includes(indId)) reviewedResponses.push(indId)
                        } else {
                            if (!unreviewedResponses.includes(indId)) unreviewedResponses.push(indId)
                        }
                    })
                })
            }

            return {
                met,
                notMet,
                notApplicable,
                complete,
                incomplete,
                furtherActionRequired,
                reviewedResponses,
                unreviewedResponses
            }
        },
        openAll() {
            this.setCollapse = this.allParentIds
            this.$root.$emit('open-all')
        },
        closeAll() {
            this.setCollapse = []
            this.$root.$emit('close-all')
        },
        getFilterText(activeFilter) {
            if (!activeFilter) return ''
            let filterText

            switch(activeFilter) {
                case 'ALL':
                    filterText = 'All'
                    break
                case 'NOT_APPLICABLE':
                    filterText = 'N/A'
                    break
                case 'ACTION_REQUIRED':
                    filterText = 'Further action required'
                    break
                case 'COMPLETE':
                    filterText = 'Marked complete'
                    break
                case 'INCOMPLETE':
                    filterText = 'Incomplete'
                    break
                case 'REVIEWED':
                    filterText = 'Reviewed'
                    break
                case 'UNREVIEWED':
                    filterText = 'Uneviewed'
                    break
                default:
                    filterText = ''
            }

            return filterText
        },
        doRedirectToAccreditationsList() {
            this.$router.push({name: 'Accreditation', params: {id: this.practiceId.toString()}})
                .catch(e => console.log(e))
        },
        setChecklistRespObj(checklists) {
            // This is an array of the checked checkboxes which gets added to the responseObj
            let response = {}
            checklists.forEach((element) => {
                let currentKey = (element.question_number).toString()
                response[currentKey] = {}
                element.items.forEach((item) => {
                    response[currentKey][(item.item_number).toString()] = item.must_have === 1
                })
            })
            return response
        },
        mapChecklistRespObj(checklists) {
            let response = {}
            checklists.forEach((element) => {
                let currentKey = (element.question_number).toString()
                response[currentKey] = {}
                element.items.forEach((item, idx) => {
                    response[currentKey][(item.item_number).toString()] = element?.responses?.[idx]
                })
            })
            return response
        },

        saveResponseList(responseList) {
            // console.log('completed indicator sets', responseList)
            const mappedResponses = snakecaseKeys(responseList, {deep: true})
            // need to send the list of indicator response updates as a list
            this.$apollo.mutate({
                mutation: UPSERT_INDICATOR_RESPONSES,
                variables: {
                    responses: mappedResponses
                }
            }).then(data => {
                // console.log('APOLLO mutation indicatorResponse, response:', data)
                console.log('finished updating responses')
            }).catch(error => {
                this.$root.$emit('show-indicator-message', {
                    message: 'Failed updating all indicators',
                    type: 'error'
                })
            }).finally(async () => {
                // console.log('APOLLO mutation 2')
                await this.getIndicatorResponses()
                await this.getAssessmentData()
                // this.reloadChecklists = true
                this.updateReloadChecklists(true)
                // delaying the
                setTimeout(() => {
                    this.savingIndicator = false
                    // this.reloadChecklists = false
                    this.updateReloadChecklists(false)
                }, 500)
            })
        },
        markUnmarkedAsComplete(e) {
            let responseList = []
            this.indicatorsData.forEach((indicatorSet) => {
                indicatorSet.indicatorSetIndicator.forEach((indicatorSetIndicator, idx) => {
                    const indicator = indicatorSetIndicator.indicator
                    // // first we have to check if it is marked already as met
                    // console.log('==== responses', indicator, indicator.responses)

                    if (indicator.responses?.compliance) {
                        const { typename, commentsAggregate, documentsAggregate, documents, comments, indicator: temp, ...respObj } = indicator.responses
                        // const foundIndicatorResponse = [ ...this.indicatorsData ]
                        const mappedChecklists = this.mapChecklistRespObj(indicator.checklists)
                        responseList.push({ ...respObj, archived: false, assessmentId: Number(this.assessmentId), checklists: mappedChecklists })
                    } else {
                        let responseObj = {
                            checklists: this.setChecklistRespObj(indicator.checklists),
                            compliance: 'MET',
                            furtherActionRequired: false,
                            reviewed: false,
                            isMarkedComplete: true,
                            nonComplianceStatement: '',
                            nonConformityStatus: '',
                            observation: '',
                            nonComplianceType: '',
                            referToCa: false,
                            riskImpact: '',
                            riskLikelihood: '',
                            riskRating: null,
                            riskType: '',
                            dateClosed: null,
                            archived: false,
                            assessmentId: Number(this.assessmentId),
                            indicatorId: indicator.id,
                        }
                        responseList.push({
                            id: indicator.responses.id,
                            ...responseObj
                        })
                    }
                })
            })

            this.saveResponseList(responseList)
        },
        clearSurvey(e) {
            console.log('clear the survey visit')
            let responseList = []
            for(let indicatorSet of this.indicatorsData) {
                for (let indicatorSetIndicator of indicatorSet.indicatorSetIndicator) {
                    const indicator = indicatorSetIndicator.indicator

                    let responseObj = {
                        checklists: [],
                        compliance: '',
                        furtherActionRequired: false,
                        reviewed: false,
                        isMarkedComplete: false,
                        nonComplianceStatement: '',
                        nonConformityStatus: '',
                        observation: '',
                        nonComplianceType: '',
                        referToCa: false,
                        riskImpact: '',
                        riskLikelihood: '',
                        riskRating: null,
                        riskType: '',
                        dateClosed: null,
                        archived: false,
                        assessmentId: Number(this.assessmentId),
                        indicatorId: indicator.id,
                    }
                    responseList.push({
                        id: indicator.responses.id,
                        ...responseObj
                    })
                }
            }
            // console.log('completed indicator sets', responseList)
            const mappedResponses = snakecaseKeys(responseList, {deep: true})
            // need to send the list of indicator response updates as a list
            this.$apollo.mutate({
                mutation: UPSERT_INDICATOR_RESPONSES,
                variables: {
                    responses: mappedResponses
                }
            }).then(data => {
                // console.log('APOLLO mutation indicatorResponse, response:', data)
                console.log('finished updating responses')
            }).catch(error => {
                this.$root.$emit('show-indicator-message', {
                    message: 'Failed updating all indicators',
                    type: 'error'
                })
            }).finally(() => {
                // console.log('APOLLO mutation 2')
                this.getIndicatorResponses()
                this.getAssessmentData()
                // this.reloadChecklists = true
                this.updateReloadChecklists(true)

                // delaying the
                setTimeout(() => {
                    this.savingIndicator = false
                    // this.reloadChecklists = false
                    this.updateReloadChecklists(false)
                }, 500)
            })

        },
        deleteIndicatorFileHandler(id) {
            // now we clear the modal data
            this.$store.commit('MODAL_DATA', {})
            if (id !== null && id !== undefined) {
                this.$http
                    .put(`document/${id}/`, {archived: true})
                    .then((response) => {
                        this.type = 'success'
                        this.message = 'Successfully deleted the indicator file.'
                        this.getIndicatorResponses()
                    })
                    .catch(err => {
                        this.type = 'error'
                        this.message = 'Could not delete the indicator file, please '
                    })
                .finally(() => {
                    // this.showMessage()
                    this.showMessage({duration: 5000})
                })
            }
        },

    },
    computed: {
        ...mapState({
            practiceId: state => state.app.practiceId,
            permissionsData: state => state.app.permissions
        }),
        permissions() {
            return this.permissionsData ? this.permissionsData.assessment : {}
        },
        generalComments() {
            return this.assessmentData.generalComments
        },
        indicatorsData() {
            // format indicator data to include responses
            if (this.indicatorResponses && this.indicatorSets) {
                const dataArr = this.indicatorSets.map(indicatorSet => {
                    const setData = indicatorSet.indicatorSetIndicator.map(setItem => {

                        let dataResp
                        if (this.indicatorResponses.responses) {
                            dataResp = this.indicatorResponses.responses.find(respItem => {
                                return respItem.indicatorId === setItem.indicatorId
                            })
                        }

                        let selfResp
                        if (this.indicatorResponses.surveyVisit?.accreditation?.selfAssessment?.responses) {
                            selfResp = this.indicatorResponses.surveyVisit.accreditation.selfAssessment.responses.find(respItem => {
                                return respItem.indicatorId === setItem.indicatorId
                            })
                        } else {
                            selfResp = {}
                        }

                        let otherResp = {...dataResp, checklists: null}
                        dataResp = dataResp ? dataResp.checklists : {}
                        selfResp = selfResp ? selfResp.checklists : {}

                        let checklists = []
                        checklists = setItem.indicator.checklists.map((element, index) => {
                            let obj = {
                                ...element,
                                responses: dataResp ? dataResp[element.question_number] : [],
                                selfResponses: selfResp ? selfResp[element.question_number] : [],
                            }

                            return obj
                        })

                        let obj = Object.assign({}, setItem, {
                            indicator: {
                                ...setItem.indicator,
                                checklists: checklists,
                                responses: otherResp
                            }
                        })

                        return obj
                    })

                    return {
                        ...indicatorSet,
                        indicatorSetIndicator: setData
                    }
                })
                // console.log('dataArr', dataArr)
                return dataArr
            }
            return []
        },
        currentFilter() {
            if (this.activeFilter === 'ALL') {
                return []
            } else if (this.activeFilter === 'MET') {
                if (this.indicatorsFilterObj.met && this.indicatorsFilterObj.met.length) return this.indicatorsFilterObj.met
                return []
            } else if (this.activeFilter === 'NOT_MET') {
                if (this.indicatorsFilterObj.notMet && this.indicatorsFilterObj.notMet.length) return this.indicatorsFilterObj.notMet
                return []
            } else if (this.activeFilter === 'NOT_APPLICABLE') {
                if (this.indicatorsFilterObj.notApplicable && this.indicatorsFilterObj.notApplicable.length) return this.indicatorsFilterObj.notApplicable
                return []
            } else if (this.activeFilter === 'ACTION_REQUIRED') {
                if (this.indicatorsFilterObj.furtherActionRequired && this.indicatorsFilterObj.furtherActionRequired.length) return this.indicatorsFilterObj.furtherActionRequired
                return []
            } else if (this.activeFilter === 'COMPLETE') {
                if (this.indicatorsFilterObj.complete && this.indicatorsFilterObj.complete.length) return this.indicatorsFilterObj.complete
                return []
            }  else if (this.activeFilter === 'INCOMPLETE') {
                if (this.indicatorsFilterObj.incomplete && this.indicatorsFilterObj.incomplete.length) return this.indicatorsFilterObj.incomplete
                return []
            }  else if (this.activeFilter === 'REVIEWED') {
                if (this.indicatorsFilterObj.reviewedResponses && this.indicatorsFilterObj.reviewedResponses.length) return this.indicatorsFilterObj.reviewedResponses
                return []
            }  else if (this.activeFilter === 'UNREVIEWED') {
                if (this.indicatorsFilterObj.unreviewedResponses && this.indicatorsFilterObj.unreviewedResponses.length) return this.indicatorsFilterObj.unreviewedResponses
                return []
            } else {
                return []
            }
        },
        adminFilterState() {
            if (this.activeFilter === COMPLIANCE_TYPES.NotMet) return this.adminFilter
            return null
        },
        isSelfAssess() {
            if (this.assessmentData) return this.assessmentData.type === 'PRESURVEY'
            return false
        },
        naFilterIsVisible() {
            return this.status !== 'ACTION_REQUIRED'
        },
        furtherActionFilterIsVisible() {
            return this.status === 'COMPLETED' || this.status === 'UNDER_REVIEW'
        },
        reviewFilterIsVisible() {
            return this.status === 'COMPLETED' || this.status === 'UNDER_REVIEW'
        },
        allParentIds() {
            let arr = []
            for (const indicator in this.indicatorSets) {
                arr.push(this.indicatorSets[indicator].id)
            }
            return arr
        },
        indicatorLockArrParsed() {
            return this.indicatorLockArr
        },
        invalidResponsesObj() {
            const invalidResponses = []
            const generalComments = []
            const openNonConformities = []

            // Validation is only for survey_visit assessments
            if (!this.isSelfAssess) {
                const currentSummaries = this.indicatorResponses.generalComments

                if (currentSummaries.length > 0) {
                    currentSummaries.forEach(item => {
                        if (!item.text) generalComments.push(item)
                    })
                    // if (surveyors.length > 0) {}
                } else {
                    generalComments.push({text: ''})
                }

                const unorderedInvalidResponses = []

                this.indicatorResponses.responses.forEach(item => {
                    let invalid = false

                    if (this.surveyType === SURVEY_TYPES.Accreditation && !item.compliance) {
                        unorderedInvalidResponses.push(item)
                        invalid = true
                    }

                    if (
                        !invalid &&
                        item.compliance === COMPLIANCE_TYPES.NotMet &&
                        (
                            !item.nonComplianceStatement ||
                            !item.nonComplianceType ||
                            !item.riskImpact ||
                            !item.riskLikelihood ||
                            !item.riskRating ||
                            !item.riskType
                        )
                    )  {
                        unorderedInvalidResponses.push(item)
                        invalid = true
                    }

                    //Temporary validation, should be removed later if the checkboxes work correctly
                    if (!invalid) {
                        let indicatorObj = null
                        // first check if we can find the indicator and set it to indicatorObj
                        this.indicatorSets.some(indicatorSet => (
                            indicatorObj = indicatorSet.indicatorSetIndicator.find(ind => ind.indicatorId === item.indicatorId))
                        )

                        let allMustHaveItemsChecked = false
                        let noneOfMustHavesChecked = false
                        let haveAnyMustHaves = false
                        let mustHaveCheckedList = []
                        if (item.checklists) {
                            // now go through all checklists and see if any return false, cause then `every` breaks out
                            indicatorObj.indicator.checklists.every(element => {
                                const checklist = item.checklists[element.question_number.toString()]
                                // first we check if we have any 'must_have's in the checklist, if we don't then it's valid
                                haveAnyMustHaves = element.items.some((item) => item.must_have)
                                if (!haveAnyMustHaves) return noneOfMustHavesChecked = allMustHaveItemsChecked = true

                                // check if the current item is required, and do a further check to see if it's been checked
                                // every will break out as soon as one of these hasn't been checked
                                const verifyList = []
                                element.items.every((item) => {
                                    if (item.must_have === 1) {
                                        allMustHaveItemsChecked = checklist && checklist[`${item.item_number}`]
                                        mustHaveCheckedList.push(allMustHaveItemsChecked)
                                    }
                                    verifyList.push(allMustHaveItemsChecked)
                                    // return allMustHaveItemsChecked
                                    return true
                                })

                                // if it's Surveillance and none of the must_haves are checked, then it's valid
                                // so we assume if the current checklist item is empty, then it's valid from out point of view
                                if (this.surveyType === SURVEY_TYPES.Surveillance && verifyList.every(v => !v)) noneOfMustHavesChecked = true
                                return allMustHaveItemsChecked
                            })
                        } else {
                            allMustHaveItemsChecked = false
                        }

                        if (!item.compliance &&
                            (
                                this.surveyType === SURVEY_TYPES.Accreditation ||
                                (this.surveyType === SURVEY_TYPES.Surveillance && (!noneOfMustHavesChecked || haveAnyMustHaves))
                            )
                        ) {
                            if (allMustHaveItemsChecked) unorderedInvalidResponses.push(item)
                        } else if (item.compliance === COMPLIANCE_TYPES.Met) {
                            if (!allMustHaveItemsChecked || !mustHaveCheckedList.every(m => !!m)) unorderedInvalidResponses.push(item)
                        }
                    }

                    if (
                        item.nonConformityStatus === 'OPEN' &&
                        item.compliance === COMPLIANCE_TYPES.NotMet &&
                        !item.referToCa
                    ) openNonConformities.push(item)
                })

                this.indicatorSets.forEach(indicatorSet => {
                    const responses = []

                    indicatorSet.indicatorSetIndicator.forEach(indicator => {
                        const index = unorderedInvalidResponses.findIndex(item => {
                            return item.indicatorId === indicator.indicatorId
                        })
                        if (index > -1) {
                            const invalidResponse = unorderedInvalidResponses[index]
                            responses.push(invalidResponse)
                            unorderedInvalidResponses.splice(index, -1)
                        }
                    })

                    if (responses.length) {
                        invalidResponses.push({
                            indicatorSet: indicatorSet,
                            responses: responses
                        })
                    }
                })
            }

            return {
                invalidResponses,
                generalComments,
                openNonConformities,
            }
        },
        submissions() {
            return this.assessmentData ? this.assessmentData.submissions : {}
        },
        accreditation() {
            return this.assessmentData ? this.assessmentData.accreditation : {}
        },
        surveyType() {
            return this.assessmentData?.surveyVisit?.type ?? null
        }
    },
    watch: {
        currentFilter() {
            if (this.currentFilter && this.currentFilter.length) {
                this.setCollapse = this.currentFilter
            } else {
                this.setCollapse = []
                this.setCollapse.push(this.setCollapseId)
            }
        },
        indicatorSets() {
            if (this.indicatorSets && this.indicatorSets.length) {
                this.indicatorSets.forEach(indicatorSet => {
                    if (indicatorSet.indicatorSetIndicator && indicatorSet.indicatorSetIndicator.length) {
                        indicatorSet.indicatorSetIndicator.forEach(indicatorSetIndicator => {
                            if (indicatorSetIndicator.indicator.indicatorResourceIndicators && indicatorSetIndicator.indicator.indicatorResourceIndicators.length) {
                                indicatorSetIndicator.indicator.indicatorResourceIndicators = indicatorSetIndicator.indicator.indicatorResourceIndicators.filter(resource => {
                                    if (this.isAdmin) return true

                                    if (this.isSurveyor && resource.indicatorResource.forSurveyor) {
                                        return true
                                    }
                                    if (this.hasPracticeManagerRole && resource.indicatorResource.forPm) {
                                        return true
                                    }

                                    return false
                                })
                            }
                        })
                    }
                })
            }
        },
        indicatorsData() {
            this.indicatorsFilterObj = this.setIndicatorsFilterObj()
        },
        adminFilterState() {
            if (this.adminFilterState === null) this.adminFilter = null
        },
        setCollapseId() {
            // Set url for last opened accordion based on setCollapseId
            this.setRoute()

            let containsId = this.setCollapse.includes(this.setCollapseId)
            if (!containsId && this.setCollapseId !== null) {
                this.setCollapse.push(this.setCollapseId)
            }
        },
        setCollapseChildId() {
            this.setRoute()
        },
        assessmentData() {
            if (this.doRedirect(this.assessmentData)) {
                this.doRedirectToAccreditationsList()
                return // We need to return here to prevent the confidentiality popup coming
            }

            if (this.assessmentData && this.assessmentData.surveyVisit) {
                this.surveyVersionId = this.assessmentData.surveyVisit.accreditation.surveyVersionId
            } else if (this.assessmentData && this.assessmentData.accreditation) {
                this.surveyVersionId = this.assessmentData.accreditation.surveyVersionId
            }

            this.status = this.assessmentData.status
            this.updateAssessment(this.assessmentData)
            this.showAgreementModal(this.assessmentData)
        },
        status() {
            if (this.status === 'ACTION_REQUIRED') {
                this.activeFilter = this.isSelfAssess ? 'ACTION_REQUIRED' : 'NOT_MET'
            }
        },
        indicatorLockArr() {
            if (!this.indicatorLockArr.length) {
                if (this.doNotRefetch) this.doNotRefetch = false
                else this.$apollo.queries.indicatorResponses.refetch()
            }
        },
        permissions() {
            if (this.permissions && this.doRedirect(this.assessmentData)) {
                this.$router.push({name: 'Accreditation', params: {id: this.practiceId.toString()}})
            }
        },
        rolesLoaded(loaded) {
            if (loaded) {
                this.$apollo.queries.indicatorSets.refetch()
            }
        },
    },
    beforeDestroy() {
        this.$root.$off('update-indicator')
        this.$root.$off('update-assessment')
        this.$root.$off('response-updated')
        this.$root.$off('document-list-update')
        this.$root.$off('show-indicator-message')
        this.$root.$off('indicator-editing-started')
        this.$root.$off('indicator-editing-stopped')
        this.$root.$off('general-comments-update')
        this.$root.$off('refetch-assessment')
        this.$root.$off('agreement-action')
        this.$root.$off('agreement-cancel')
        this.$root.$off('delete-document')
    },
}
</script>
