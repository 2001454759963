<template>
  <div class="c-risk-matrix flex flex-col items-start">
        <div class="flex flex-col items-start mt-4 w-full">
            <p class="text-sm mb-3">Opportunities for Improvement</p>
            <el-input
                v-model="observation"
                type="textarea"
                class="w-full"
                :autosize="{ minRows: 3, maxRows: 6}"
                rows="6"
                :disabled="inputDisabled"
                @focus="focusHandler"
                @blur="blurHandler"
            >
            </el-input>
        </div>
  </div>
</template>

<script>
export default {
    name: 'CompliantView',
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        isDisabled: {
            type: Boolean,
            default: null
        },
        indicatorLockArr: {
            type: Array,
            default: () => []
        },
        indicatorRespId: {
            type: Number,
            default: null
        },
        userId: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            observation: ''
        }
    },
    mounted() {
        this.setData()
    },
    methods: {
        focusHandler() {
            this.$emit('input-interaction', { 
                name: 'observation',
                event: 'focus'
            })
        },
        blurHandler() {
            this.$emit('input-interaction', { 
                name: 'observation',
                event: 'blur'
            })
        },
        setData() {
            this.observation = this.value.observation
        },
    },
    computed: {
        inputDisabled() {
            for (let item of this.indicatorLockArr) {
                if (item.indicatorResponseId === this.indicatorRespId) {
                    if (!this.isDisabled) return this.inputIsLocked
                }
            }
            return this.isDisabled
        },
        inputIsLocked() {
            for (let item of this.indicatorLockArr) {
                if (item.indicatorResponseId === this.indicatorRespId) {
                    if (item.fields === 'observation') {
                        return item.userId !== this.userId
                    } 
                    return false
                }
            }
            return false
        },
    },
    watch: {
        observation() {
            this.$emit('input', {
                ...this.value, 
                observation: this.observation
            })
        },
        value: {
            handler() {
                this.setData()
            },
            deep: true
        },
    },
}
</script>
