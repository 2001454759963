import { render, staticRenderFns } from "./CompliantView.vue?vue&type=template&id=646e1df8&"
import script from "./CompliantView.vue?vue&type=script&lang=js&"
export * from "./CompliantView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports