<template>
    <div class="c-modal-agreement flex flex-col h-full p-4" data-cy="modalDownloadCertificate">
        <h2 class="text-2xl text-center text-black mb-4">Download Certificate</h2>
        <div class="flex flex-row justify-center">
        </div>
        <div class="relative">
            <el-form
                class="flex flex-col justify-center my-4"
                :model="dowloadCertificate"
                ref="contractAgreement"
            >
                <p>Please choose the document size below.</p>
                <v-container fluid>
                    <v-radio-group v-model="dowloadCertificate.documentSize" row>
                        <v-radio label="A3" value="A3" color="primary"></v-radio>
                        <v-radio label="A4" value="A4" color="primary"></v-radio>
                    </v-radio-group>
                </v-container>
                <el-form-item class="flex flex-row justify-center my-4">
                    <el-button @click="cancelHandler">Cancel</el-button>
                    <el-button type="success" @click="submitHandler">Download</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: "ModalDownloadCertificate",
    data() {
        return {
            dowloadCertificate: {
                documentSize: 'A3'
            },
        }
    },
    computed: {
        ...mapState({
            data: state => state.app.modal.componentData,
            isVisible: state => state.app.modal.isVisible,
        }),
    },
    methods: {
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        submitHandler() {
            console.log('submitHandler downloadCertificate', this.dowloadCertificate)

            // console.log('item validated', valid)
            this.$root.$emit('download-action', {...this.data, ...this.dowloadCertificate})
            this.$store.commit('MODAL_IS_VISIBLE', false)
        }
    }
}
</script>
