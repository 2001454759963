<template>
    <div v-if="(!loading || !firstLoad) && !practiceLoading">
        <div class="c-staff w-full h-auto flex flex-col" v-if="!(isStaffFirstView || this.forceStaffFirstView)" data-cy="staffTable">
            <div
                class="flex flex-col"
            >
                <div
                    v-if="showRenewalPaymentMessage"
                    class="w-full py-3 px-4 mt-2 bg-primary-lightest rounded flex justify-between items-center"
                >
                    <div class="text-sm text-primary-light">
                        <fa-icon icon="info-circle" class="text-primary-light mr-1"></fa-icon>
                        Please ensure your staff details below are up to date for the current accreditation cycle.
                    </div>
                    <el-link
                        type="primary"
                        :underline="false"
                        class="text-sm"
                        data-cy="closeRenewalPaymentMessage"
                        @click.prevent="closeRenewalPaymentMessage"
                    >
                        Dismiss
                    </el-link>
                </div>

                <StaffTable
                    class="w-full"
                    :tableData="StaffList"
                    :filterOptions="filterOptions"
                    @table-data-change="updateTableData"
                    @staff-mutation="staffMutation"
                />
                <NominatedContacts :staffList="filteredContacts" :accreditation="currentAccreditation" :allStaffList="StaffList" />
            </div>
        </div>
        <component :is="firstStateComponentName" @enter-staff-list="enterStaffList" v-else />
    </div>
    <div class="flex items-center justify-center h-20 w-full" v-else>
        <Loader class="w-12 h-12" />
    </div>
</template>

<script>
import StaffTable from './partials/StaffTable'
import NominatedContacts from './partials/NominatedContacts'
import GET_PRACTICE from '@/graphql/queries/getPractice.gql'
import GET_STAFF_LIST from '@/graphql/queries/getStaffList.gql'
import GET_CURRENT_ACCREDITATION_CONTACTS from '@/graphql/queries/getCurrentAccreditationContacts.gql'
import Loader from '@/assets/loader.svg'
import camelcaseKeys from 'camelcase-keys'
import { mapState } from 'vuex'
import FirstStateStaff from './partials/FirstState'
import { parse } from 'date-fns'
import snakecaseKeys from 'snakecase-keys/index'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import { PAYMENT_TYPES } from '@/config/constants'

export default {
    name: 'StaffComponent',
    mixins: [ BackendHelpers, HttpMixin ],
    components: {
        FirstStateStaff,
        StaffTable,
        NominatedContacts,
        Loader,
    },
    props: {
        forceStaffFirstView: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            loading: true,
            firstLoad: true,
            practiceLoading: true,
            StaffList: [],
            currentAccreditation: [],
            filterOptions: [
                {
                    value: 'employment',
                    label: 'employment'
                },{
                    value: 'email',
                    label: 'email'
                },{
                    value: 'firstName',
                    label: 'First name'
                },{
                    value: 'lastName',
                    label: 'Last name'
                },{
                    value: 'fteBand',
                    label: 'FTE band'
                },{
                    value: 'title',
                    label: 'Title'
                },{
                    value: 'type',
                    label: 'Type'
                }
            ]
        }
    },
    mounted() {
        // force the refretch
        this.staffMutation()
        // console.log('Staff mounted, this.practiceId', this.practiceId)
        this.$root.$on('staff-send-invite', (staff) => {
            this.staffMutation()
        })
    },
    apollo: {
        practice: {
            query: GET_PRACTICE,
            variables() {
                return {
                    id: this.practiceId
                }
            },
            skip() {
                return !this.practiceId
            },
            update(data) {
                const practice = camelcaseKeys(data.practice || {}, { deep: true })
                practice.hasReapplicationDue = practice.reapplicationsAggregate.aggregate.count > 0
                practice.hasReapplicationPaymentDue = practice.reapplicationsPaymentAggregate.aggregate.count > 0
                return practice
            },
            watchLoading (isLoading, countModifier) {
                if (isLoading) {
                    this.practiceLoading = isLoading
                }
            }
        },
        StaffList: {
            query: GET_STAFF_LIST,
            variables() {
                return {
                    practiceId: this.practiceId
                }
            },
            update: (data) => {
                return !data.StaffList ? [] : data.StaffList.map((item, index) => {
                    const staff = camelcaseKeys({
                        ...item,
                        active: false,
                    }, {deep: true})

                    // formatting dates as we go into the staff tab, cause we are parsing them back when saving the row
                    // look at StaffTable for the conversion back
                    if (staff.startDate && typeof staff.startDate == 'string') {
                        staff.startDate = parse(staff.startDate, 'yyyy-MM-dd', new Date())
                    }
                    if (staff.resignationDate && typeof staff.resignationDate == 'string') {
                        staff.resignationDate = parse(staff.resignationDate, 'yyyy-MM-dd', new Date())
                    }

                    return staff
                })
            },
            skip() {
                // console.log('STAFF skip', this.practiceId, !this.practiceId)
                return !this.practiceId
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            },
        },
        currentAccreditation: {
            query: GET_CURRENT_ACCREDITATION_CONTACTS,
            variables() {
                return {
                    practiceId: this.practiceId
                }
            },
            skip() {
                return !this.practiceId
            },
            update(data) {
                return camelcaseKeys(data.accreditation || [], { deep: true })
            }
        }
    },
    computed: {
        ...mapState({
            practiceId: state => state.app.practiceId
        }),
        filteredContacts() {
            let arr = this.StaffList.filter(s => s.firstName != null && s.lastName != null && s.type != null)
            arr = arr.map((item) => {
                return {
                    value: item.id,
                    label: `${item.firstName} ${item.lastName}`,
                    type: item.type,
                }
            })

            let filteredArr = arr.filter((elem, index, self) => {
                return index == self.indexOf(elem)
            })

            // console.log('filteredArray', filteredArr)
            return filteredArr
        },
        firstStateComponentName() {
            return this.isStaffFirstView || this.forceStaffFirstView ?
                'FirstStateStaff' : ''
        },
        isStaffFirstView() {
            return this.practice && this.practice.isStaffFirstView
        },
        showRenewalPaymentMessage() {
            return this.practice && this.practice.showMessageAfterRenewalPayment
        }
    },
    methods: {
        updateTableData(tableData) {
            this.StaffList = tableData
        },
        staffMutation() {
            this.$apollo.queries.StaffList.refetch()
            this.$apollo.queries.currentAccreditation.refetch()
        },
        enterStaffList() {
            if (this.forceStaffFirstView) {
                this.$emit('cancelForceStaffFirstView')

                return
            }

            this.loading = true
            this.$http
                .patch(`web.practice/${this.practiceId}/`, snakecaseKeys({ isStaffFirstView: false }, { deep: true }))
                .then((response) => {
                    console.log('fetching the practice info and staff')
                    this.$apollo.queries.practice.refetch()
                    this.$apollo.queries.StaffList.refetch()
                })
                .catch((err) => {
                    this.loading = false
                })
        },
        closeRenewalPaymentMessage() {
            this.loading = true
            this.$http
                .patch(`web.practice/${this.practiceId}/`, snakecaseKeys({ showMessageAfterRenewalPayment: false }, { deep: true }))
                .then((response) => {
                    this.$apollo.queries.practice.refetch()
                })
                .catch((err) => {
                    this.loading = false
                })
        }
    },
    watch: {
        StaffList() {
            this.loading = false
            this.firstLoad = false
            this.$store.commit('SET_STAFF_TOTAL', this.StaffList.length)
        },
        async practice() {
            if (this.practice && this.practice.status) {
                const reapp_stage = this.practice.hasReapplicationPaymentDue || this.practice.hasReapplicationDue
                if (this.practice.status.toUpperCase() === PAYMENT_TYPES.PaymentPending && !reapp_stage) {
                    // redirect only if new (not a reapplication)
                    await this.redirectOnPaymentPending(this.practice)
                } else {
                    this.$store.commit('SET_STAFF_FIRST_VIEW', this.isStaffFirstView)
                }
                setTimeout(() => {
                    this.practiceLoading = false
                }, 1000)
            }
        }
    },
    beforeDestroy() {
        this.$root.$off('staff-send-invite')
    }
}
</script>
