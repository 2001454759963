<template>
    <component :is="getIcon" :class="highlightIcon" />
</template>

<script>
import CertIcon from '@/assets/icons/icons_certificate_blue.svg'
import FolderIcon from '@/assets/icons/icons_folder.svg'
import FollowUpIcon from '@/assets/icons/icons_followup.svg'
import SelfAsIcon from '@/assets/icons/icons_selfassessment.svg'
import SurveyIcon from '@/assets/icons/icons_survey.svg'

export default {
    name: 'StatusIcon',
    components: { 
        CertIcon,
        FolderIcon,
        FollowUpIcon,
        SelfAsIcon,
        SurveyIcon,
    },
    props: {
        rowData: {
            type: Object,
            default: () => {}
        },
        svgColour: {
            type: String,
            default: ''
        },
    },
    mounted() {
        // console.log('StatusIcon rowData', this.rowData)
    },
    computed: {
        getIcon() {
            if (this.rowData) {
                if (this.rowData.type === 'PRESURVEY') {
                    return 'SelfAsIcon'
                } else {
                    switch (this.rowData.surveyVisit.type) {
                        case 'ACCREDITATION':
                            return 'SurveyIcon'
                        case 'SURVEILLANCE':
                            return 'FollowUpIcon'
                        case 'RELOCATION':
                            return 'FollowUpIcon'
                        case 'GAP':
                            return 'FolderIcon'
                        default:
                            return ''
                    }
                }
            }
            return ''
        },
        highlightIcon() {
            if (this.rowData && !this.svgColour) {
                if (this.rowData.status && this.rowData.status === 'ACTION_REQUIRED') {
                    return 'text-red-dark'
                }
                return 'text-grey-6'
            }
            return this.svgColour
        },
    },
}
</script>