export default {
    methods: {
        requiresAgreement(accreditation) {
            return (accreditation.status === 'IN_PROGRESS' && accreditation.type !== 'PRESURVEY')
        },
        userHasSignedAgreement(accreditation) {
            if (accreditation.confidentialitySigns !== null) {
                const signsArr = Object.keys(accreditation.confidentialitySigns)
            
                if (signsArr.length) {
                    for (let id of signsArr) {
                        if (parseInt(id) === parseInt(this.userId)) {
                            return true
                        }
                    }
                }
            }
            return false
        },
        renderAgreementModal(accreditation, emitCancel, keepOpen) {
            // console.log('renderAgreementModal', accreditation)
            this.$store.commit('MODAL_IS_VISIBLE', true)
            this.$store.commit('MODAL_DATA', {
                userName: this.userName,
                userId: this.userId,
                assessmentId: accreditation.id,
                confidentialitySigns: { ...accreditation.confidentialitySigns },
                emitCancel: emitCancel,
                keepOpen: keepOpen,
            })
            this.$store.commit('MODAL_COMPONENT_NAME', 'ModalAgreement')
        },
        updateAssessmentSignsObj(respObj) {
            // console.log('updateAssessmentSignsObj', respObj)
            this.$http({
                method: 'POST',
                url: `web.assessment/${respObj.id}/sign/`
            }).then(resp => {
                console.log('mutation updateAssessmentSignsObj, resp:', resp)
                if (!this.skipNavigate) {
                    this.navigateToAssessment(respObj)
                }
            }).catch(error => {
                console.error('updateAssessmentSignsObj', error)
            })
        },
    }
}
