<template>
<div class="c-modal__add-assessment relative flex flex-col items-center">
    <h2 class="mt-8" data-cy="headerTitle">Issue Certificate</h2>
        <div
            class="flex flex-col justify-center my-4"
            v-html="messageHtml"
        >
        </div>
    <el-form
        class="flex flex-col items-center"
        ref="certifyForm"
        :model="certifyForm"
        :rules="validationRules"
    >
        <div class="grid grid-cols-2 gap-4">
            <div class="col-span-1">
                <el-form-item class="flex flex-col justify-center my-4" prop="certifyDate">
                    <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight ml-2">Certification date<sup>*</sup></label>
                    <el-date-picker
                        type="date"
                        format="dd/MM/yyyy"
                        v-model="certifyForm.certifyDate"
                        data-cy="dateData"
                    ></el-date-picker>
                </el-form-item>
            </div>
            <div class="col-span-1">
                <el-form-item class="flex flex-col justify-center my-4" prop="issueDate">
                    <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight ml-2">Issue date<sup>*</sup></label>
                    <el-date-picker
                        type="date"
                        format="dd/MM/yyyy"
                        v-model="certifyForm.issueDate"
                        data-cy="dateData"
                    ></el-date-picker>
                </el-form-item>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4">
            <div class="col-span-1">
                <el-form-item class="flex flex-col justify-center my-4" prop="expiryDate">
                    <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight ml-2">Expiry date<sup>*</sup></label>
                    <el-date-picker
                        type="date"
                        format="dd/MM/yyyy"
                        v-model="certifyForm.expiryDate"
                        data-cy="dateData"
                    ></el-date-picker>
                </el-form-item>
            </div>
            <div class="col-span-1">
                <el-form-item class="flex flex-col justify-center my-4" prop="expiryDate">
                    <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight ml-2">Commencement date<sup>*</sup></label>
                    <el-date-picker
                        type="date"
                        format="dd/MM/yyyy"
                        v-model="certifyForm.commencementDate"
                        data-cy="dateData"
                    ></el-date-picker>
                </el-form-item>
            </div>
        </div>
        <div class="grid grid-cols-2 gap-4">
            <div class="col-span-1">
                <el-form-item class="flex flex-col justify-center my-4" prop="certifier">
                    <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight ml-2">Certified by<sup>*</sup></label>
                    <el-select v-model="certifyForm.certifier" placeholder="Select a user" class="w-full">
                        <el-option
                            v-for="user in certifiers"
                            :key="`certifier-${user.value}`"
                            :label="user.label"
                            :value="user.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
        </div>
        <!-- <v-menu
            ref="menu1"
            v-model="certifyForm.issueDate"
            :close-on-content-click="false"
            transition="scale-transition"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="certifyForm.issueDate"
                    label="Date"
                    hint="MM/DD/YYYY format"
                    persistent-hint
                    prepend-icon="event"
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker v-model="certifyForm.issueDate" no-title @input="menu1 = false"></v-date-picker>
        </v-menu> -->
        <el-form-item class="flex flex-col justify-center my-4 w-full" name="certifier">
            <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight ml-2">Conditions</label>
            <el-input
                type="text"
                v-model="condition"
            ></el-input>
        </el-form-item>
        <el-form-item class="flex flex-row justify-center my-8">
            <el-button @click="cancelHandler" data-cy="cancelHandler">Cancel</el-button>
            <el-button type="primary" @click.stop="submitHandler">Issue</el-button>
        </el-form-item>
    </el-form>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { format, formatISO, add } from 'date-fns'
import GET_CERTIFIERS from '@/graphql/queries/getCertifiers.gql'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { useIssueCertificateStore } from '@/stores/useIssueCertificateStore'
import { storeToRefs } from 'pinia'

export default {
    name: 'ModalCertificate',
    data() {
        return {
            messageHtml: '<p>This will notify the practice of the certification.</p><p>Are you sure you would like to approve this assessment?</p>',
            certLength: { years: 3 },
            certifier: null,
            condition: '',
            validationRules: {
                certifyDate: [{ required: true, message: 'Please input a date', trigger: 'change' }],
                issueDate: [{ required: true, message: 'Please input a date', trigger: 'change' }],
                expiryDate: [{ required: true, message: 'Please input a date', trigger: 'change' }],
                certifier: [{ required: true, message: 'Certified user required', trigger: 'change' }],
            },
            certifyForm: {
                certifyDate: null,
                issueDate: null,
                commencementDate: null,
                expiryDate: null,
                certifier: null,
            }
        }
    },
    apollo: {
        certifiers: {
            query: GET_CERTIFIERS,
            update: data => {
                const userArr = camelcaseKeys(data.certifiers, { deep: true })
                const certifiers = userArr.map(user => {
                    return {
                        label: `${user.firstName} ${user.lastName}`,
                        value: user.id
                    }
                })
                return certifiers
            },
        }
    },
    mounted() {
        const tempCertifyForm = { ...this.computedCertifyForm }
        if (this.userId !== null || this.userId !== undefined) {
            tempCertifyForm.certifier = this.userId
        }
        tempCertifyForm.certifyDate = new Date()
        tempCertifyForm.issueDate = new Date()
        tempCertifyForm.expiryDate = formatISO(add(new Date(), this.certLength), { representation: 'date' })
        this.updateCertifyForm(tempCertifyForm)
        if (tempCertifyForm.commencementDate == null) {
            this.getPotentialCommencement(this.id).then((data) => console.log('======= found this date', data))
        }
        this.certifyForm = { ...tempCertifyForm }
    },
    methods: {
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        },
        submitHandler() {
            this.$refs.certifyForm.validate(valid => {
                if(valid) {
                    // console.log('form is valid')
                    // commit mutation
                    this.mutationCall()
                }
            })
        },
        mutationCall() {
            this.$http({
                method: 'POST',
                url: `web.assessment/${this.id}/certify/`,
                data: snakecaseKeys({
                    certificationDate: this.parseDate(this.certifyForm.certifyDate),
                    issueDate: this.parseDate(this.certifyForm.issueDate),
                    expiryDate: this.parseDate(this.certifyForm.expiryDate),
                    commencementDate: this.parseDate(this.certifyForm.commencementDate),
                    condition: this.condition,
                    certifiedBy: this.certifyForm.certifier,
                })
            }).then(resp => {
                console.log('cert mutationCall', resp)
                this.$root.$emit('refetch-assessment')
                this.$store.commit('MODAL_IS_VISIBLE', false)
                this.$store.commit('MODAL_DATA', {})
            }).catch(err => {
                console.error(err)
            })
        },
        parseDate(dateStr) {
            const date = dateStr !== null ? new Date(dateStr) : new Date()
            return formatISO(date, { representation: 'date' })
        },
    },
    computed: {
        ...mapState({
            id: state => state.app.modal.componentData.assessmentId,
            userId: state => state.app.modal.componentData.userId,
        }),
    },
    watch: {
        'certifyForm.certifyDate': function() {
            if (this.certifyForm.certifyDate) {
                this.certifyForm.expiryDate = formatISO(add(new Date(this.certifyForm.certifyDate), this.certLength), { representation: 'date' })
            }
        },
        certifyForm: {
            handler: function () {
                this.updateCertifyForm(this.certifyForm)
            },
            deep: true
        },
        'computedCertifyForm.commencementDate': function() {
            if (this.computedCertifyForm?.commencementDate !== this.certifyForm.commencementDate) {
                this.certifyForm.commencementDate = this.computedCertifyForm?.commencementDate
            }
        }
    },
    setup() {
        const issueCertificateStore = useIssueCertificateStore()
        const { getPotentialCommencement, updateCertifyForm } = issueCertificateStore
        const { computedCertifyForm } = storeToRefs(issueCertificateStore)

        return { computedCertifyForm, issueCertificateStore, getPotentialCommencement, updateCertifyForm }
    }
}
</script>
