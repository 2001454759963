import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-staff__table editable_table flex flex-col"},[_c(VDataTable,{ref:"documentTable",staticClass:"c-table",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.value,"data-cy":"documentTable","show-select":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.created))+" ")]}},{key:"item.fileSize",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getHumanReadable(item.fileSize))+" ")]}},{key:"item.editRecord",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"c-staff__table__edit-record ml-2 absolute border-l border-grey-5",class:{'active-tr': item.active}},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.downloadHandler(item.id, 'document')}}},[_c('fa-icon',{staticClass:"text-grey-6 hover:text-dark-blue",attrs:{"icon":"download"}})],1),_c('a',{staticClass:"mx-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteHandler(item)}}},[_c('fa-icon',{staticClass:"text-grey-6 hover:text-red",attrs:{"icon":"trash-alt","data-cy":"deleteRecord"}})],1)])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }