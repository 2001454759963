<template>
    <v-navigation-drawer v-model="localDrawer" absolute temporary :width="280">
        <v-list-item class="px-2 menuArea">
            <v-list-item-icon class="menuButton">
                <v-btn
                    icon
                    @click.stop="localDrawer = !localDrawer"
                    :ripple="false"
                >
                    <fa-icon icon="bars" class="text-black" />
                </v-btn>
            </v-list-item-icon>
            <img
                src="@/assets/connect_logo2.png"
                alt="connect logo"
                class="navLogo block"
            />
        </v-list-item>

        <!-- might use this later :x <v-divider></v-divider>-->

        <v-list dense nav>
            <v-list-item
                :class="{ active: isActive('Dashboard') }"
                id="dashboard"
                :to="{ name: 'dashboard' }"
                :ripple="false"
            >
                <v-list-item-icon>
                    <DashboardSvg class="fill-current" />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="px-2"
                        >Dashboard</v-list-item-title
                    >
                </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-if="!isNewUser"
                :class="{ active: isActive('Practice/List') }"
                id="practices"
                :to="{ name: 'PracticeList' }"
                data-cy="goToPracticeListMenuItem"
                :ripple="false"
            >
                <v-list-item-icon>
                    <PracticesSvg class="fill-current" />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="px-2"
                        >Practices</v-list-item-title
                    >
                </v-list-item-content>
            </v-list-item>

            <div class="border-t-2" v-if="!isNewUser">
                <div class="flex items-center justify-start mt-4">
                    <p class="font-bold text-xs ml-1 mr-1 uppercase text-black">
                        Reports
                    </p>
                    <p
                        class="text-xs uppercase font-bold text-green bg-green-light px-1 rounded"
                    >
                        New
                    </p>
                </div>
                <v-list-item
                    :class="{ active: isActive('Reports/Management') }"
                    id="managementReport"
                    :to="{ name: 'Reports/Management' }"
                    data-cy="goToReportsMenuItem"
                    :ripple="false"
                >
                    <v-list-item-icon>
                        <ReportsSvg class="fill-current" />
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="px-2"
                            >Management Report</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>

                <v-list-item
                    :class="{ active: isActive('Reports/Analytics') }"
                    id="analyticsReports"
                    :to="{ name: 'Reports/Analytics' }"
                    data-cy="goToReportsMenuItem"
                    :ripple="false"
                >
                    <v-list-item-icon>
                        <ReportsSvg class="fill-current" />
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="px-2"
                            >Analytics Report</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>
            </div>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import DashboardSvg from "@/assets/icons/nav/dashboard.svg";
import PracticesSvg from "@/assets/icons/nav/practices.svg";
import ReportsSvg from "@/assets/icons/icons_indicators.svg";
import { AuthMixin } from "@/components/shared/mixins/authHelpers";

export default {
    name: "NavigationMenu",
    components: {
        DashboardSvg,
        PracticesSvg,
        ReportsSvg,
    },
    mixins: [AuthMixin],
    props: {
        value: {
            type: Boolean,
            default: null,
        },
    },
    data() {
        return {
            items: [
                { title: "Home", icon: "mdi-home-city" },
                { title: "My Account", icon: "mdi-account" },
                { title: "Users", icon: "mdi-account-group-outline" },
            ],
        };
    },
    methods: {
        isActive(url) {
            return this.$route.path.includes(url);
        },
    },
    computed: {
        localDrawer: {
            get() {
                return this.value;
            },
            set(drawer) {
                this.$emit("input", drawer);
            },
        },
    },
};
</script>
