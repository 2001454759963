<template>
    <footer class="main_footer py-4 px-2">
        <div class="container flex flex-row justify-between">
            <div class="text-left mt-6">
                <p class="text-xs">
                    For help call
                    <a href="tel:0269444042" class="text-primary-light text-xs font-bold">02 6944 4042</a> or
                    email <a href="mailto:info@qpa.health" class="text-primary-light text-xs font-bold">info@qpa.health</a>
                </p>
                <p class="text-sm">
                    <a href="//www.qpa.health" class="text-primary-light text-xs font-bold">qpa.health</a>
                </p>
            </div>
            <div class="text-right mt-6">
                <p class="text-xs">Copyright {{getCurrentYear}} Connect | ABN 26 081 986 932</p>
                <p class="text-xs">
                    Solution by
                    <a href="https://codesource.com.au/" target="_blank" class="text-primary-light font-bold text-xs">CodeSource</a>
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'GlobalFooter',
    computed: {
        getCurrentYear() {
            const date = new Date()
            return date.getFullYear()
        }
    }
}
</script>
