<template>
    <div class="relative tnc-wrapper">
        <h2 class="text-lg my-4">Terms &amp; Conditions of accreditation</h2>
        <p class="text-base text-center text-grey-1 my-8 tnc-content" v-html="termsAndConditions"></p>
        <div class="flex flex-row justify-center my-4">
            <el-button
                @click="cancelHandler"
            >
                Cancel
            </el-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'ModalTermsAndConditions',
    computed: {
        ...mapState({
            termsAndConditions: state => state.app.modal.componentData
        })
    },
    methods: {
        cancelHandler() {
            this.$store.commit('MODAL_IS_VISIBLE', false)
        }
    }
}
</script>

