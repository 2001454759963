export default {
    methods: {
        downloadFile(fileBlob, fileName) {
            const fileURL = URL.createObjectURL(fileBlob)
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('target', '_blank')
            fileLink.setAttribute('download', fileName)
            document.body.appendChild(fileLink)
            fileLink.click()
        },
        downloadFromURL(url, name) {
            const fileLink = document.createElement('a')
            fileLink.href = url
            fileLink.setAttribute('target', '_blank')
            fileLink.setAttribute('download', name)
            document.body.appendChild(fileLink)
            fileLink.click()
        },
        downloadHandler(id, endpoint) {
            console.log('Download Handler')
            
            // reseting the shown message dialog
            if (!this.resetMessage){
                this.$emit('reset-message')
            }
            else {
                this.resetMessage()
            }
            this.$http
                .get(`${endpoint}/${id}/`)
                .then(({ data }) => {
                    console.log('download response', data)
                    // now we do the programmatic file click
                    // const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                    const fileURL = data.urls
                    const fileLink = document.createElement('a')
                    fileLink.href = fileURL
                    fileLink.setAttribute('target', '_blank')
                    fileLink.setAttribute('download', data.name)
                    document.body.appendChild(fileLink)
                    fileLink.click()
                    this.type = 'success'
                    this.message = 'Successfully downloaded document'
                })
                .catch((err) => {
                    console.log('document download error', err)
                    this.type = 'error'
                    this.message = 'Sorry, your file could not be downloaded at this time, please contact your system administrator'
                })
                .finally(() => {
                    if (!this.showMessage){
                        this.$emit('show-message', {message: this.message, type: this.type})
                    } else {
                        this.showMessage({duration: 5000})
                    }
                })
        },
        downloadRemoteFileHandler(name, url, method, payload) {
            // let reader = new FileReader()
            this.$http({
                method: method || 'POST',
                url: url,
                data: payload,
                responseType: 'blob'
            }).then((resp) => {
                const fileUrl = URL.createObjectURL(resp.data)
                const fileLink = document.createElement('a')
                const contentDisposition = resp.headers['content-disposition']
                let filename = ''
                if (contentDisposition) {
                    // the filename should be the second argument here, if it's not
                    try {
                        filename = contentDisposition ? contentDisposition.split('=')[1] : name || ''
                    } catch (e) {
                        console.log('did not return the filename in the content-disposition header')
                        filename = name || ''
                    }
                } else {
                    filename = name || ''
                }
                fileLink.href = fileUrl
                fileLink.setAttribute('target', '_blank')
                fileLink.setAttribute('download', filename)
                document.body.appendChild(fileLink)
                fileLink.click()
                this.type = 'success'
                this.message = 'Successfully downloaded document'
            }).catch(err => {
                console.error('Error in Accreditations.vue > downloadCertHandler(): ', err)
                this.message = 'Oops, something happened when you were trying to download that document'
                this.type = 'error'
            }).finally(() => {
                if (this.showMessage){
                    this.showMessage({duration: 3000})
                }
            })
        },
    }
}
