export default {
    methods: {
        async getFolderByCode(code) {
            await this.$store.dispatch('fetchFolders', {
                apolloClient: this.$apolloProvider.defaultClient,
                getVisibleFolders: !!this.getVisibleFolders,
                practiceId: this.practiceId,
                emptyHidden: this.emptyHidden
            })
            return this.folders.find(({code: c}) => c == code)
        }
    }
}
