const FormBuilderMethods = {
    methods: {
        isComplete(item) {
            let hasSubCompleteSvg
            if (item.mappedQuestionSets) {
                for (let subQuestion of item.mappedQuestionSets) {
                    hasSubCompleteSvg = this.isComplete(subQuestion)
                    if (!hasSubCompleteSvg) return hasSubCompleteSvg
                }
            }
            if (!hasSubCompleteSvg && (!item.questions || item.questions.length === 0)) return true
        
            for (let question of item.questions) {
                if (!question.value) return false
            }
            return true
        },
        getTotalQuestions(questionSets) {
            let total = 0
            for (let questionSet of questionSets) {
                if (!questionSet.mappedQuestionSets) total += questionSet.questions ? questionSet.questions.length : 0
                else total += this.getTotalQuestions(questionSet.mappedQuestionSets)
            }

            return total
        },
        getNumberComplete(questionSets) {
            let total = 0
            for (let questionSet of questionSets) {
                if (!questionSet.mappedQuestionSets) {
                    total += questionSet.questions ? questionSet.questions.filter(q => q.value != null && q.value !== '').length : 0
                }
                else total += this.getNumberComplete(questionSet.mappedQuestionSets)
            }

            return total
        },
    },
}

const DEFAULT_MAX_LENGTH = 4
const FormRules = {
    methods: {
        required: value => !!value || 'Required.',
        requiredNoText: value => !!value || '',
        min: v => v.length >= 8 || 'Min 8 characters',
        max: v => v.length <= 16 || 'Max 16 characters',
        maxNumber: v => String(v)?.length < DEFAULT_MAX_LENGTH || 'Max is 999',
        noZeroStart: v => String(v)?.length === 1 || String(v)?.indexOf(0) !== 0 || 'Number cannot start with a 0',
        numbersOnly: v => {
            if (!v || typeof v === 'number' || !v.trim() || !isNaN(parseInt(v)) && v >= 0) return true
            return 'Value has to be a number'
        },
        timeOnly: v => {
            const timeRegex = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/
            
            if (!v || !v.trim() || timeRegex.test(v)) return true
            return 'Value should be in form hh:mm am|pm'
        }
    }
}

const ALLOWED_KEYCODE = [8, 9, 37, 39, 46]

const FormEvents = {
    methods: {
        timeOnlyEvent(e) {
            const val = e.key
            console.log('key pressed', e)
            if (!/[0-9:AaPpMm ]/.test(val) && ALLOWED_KEYCODE.indexOf(e.keyCode) === -1) {
                e.preventDefault()
            }
        },
        numbersOnlyEvent(e) {
            // we try and convert it with the '+' operator
            const val = +e.key
            // if it's not NaN, then we have a number key press
            console.log('key pressed', e)
            if (isNaN(val) && ALLOWED_KEYCODE.indexOf(e.keyCode) === -1) {
                e.preventDefault()
            }
        }
    }
}

export {
    FormBuilderMethods,
    FormRules,
    FormEvents
}
