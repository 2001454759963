<template>
    <div class="global-header w-full flex justify-between">
        <div>
            <div class="menuButton inline-block">
                <v-btn
                    icon
                    @click.stop="toggleDrawer"
                    data-cy="menuButton"
                    :ripple="false"
                >
                    <fa-icon icon="bars" class="text-black" />
                </v-btn>
            </div>

            <div class="global-header__logo inline-block align-middle">
                <img
                    src="@/assets/connect_logo2.png"
                    alt="connect logo"
                    class="block"
                />
            </div>
        </div>
        <div>
            <ProfileDisplay />
        </div>
    </div>
</template>

<script>
import ProfileDisplay from "./ProfileDisplay";

export default {
    name: "GlobalHeader",
    components: {
        ProfileDisplay,
    },
    props: {
        value: {
            type: Boolean,
            default: null,
        },
    },
    data() {
        return {
            drawer: null,
        };
    },
    methods: {
        toggleDrawer() {
            this.$emit("input", !this.value);
        },
    },
};
</script>

<style></style>
